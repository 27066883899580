import React, {useState} from 'react'
import styled, {css} from 'styled-components'
import {Icon, IconName} from '../icon/icon'

type Variant = 'greyscale' | 'color' | 'muted'

interface SelectProps<T> {
  onChange: (value: T) => void
  value?: T
  getDisplayValue?: (value: T) => string
  iconLeft?: IconName
  iconRight?: IconName
  label: string
  upLabel?: string
  inline?: boolean
  options: (T | Option<T> | {group: string; options: Option<T>[]})[]
  disabled?: boolean
  variant?: Variant
  firstOptionText?: string
  firstOptionDisabled?: boolean
  readOnly?: boolean
}

interface Option<T> {
  value: T
  text: string
  disabled?: boolean
}

interface StyledIconProps {
  inline?: boolean
  disabled?: boolean
  readOnly?: boolean
}

const iconShared = css`
  width: 60px;
  height: 60px;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
`

const inlineStyles = css`
  width: 40px;
  height: 40px;
`

const StyledIconLeft = styled.div<StyledIconProps>`
  order: -1;
  ${iconShared}
  ${props => props.inline && inlineStyles}
  opacity: ${props => (props.disabled ? '0.1' : '1')};
`

const StyledIconRight = styled.div<StyledIconProps>`
  order: 1;
  ${iconShared}
  ${props => props.inline && inlineStyles}
  opacity: ${props => (props.disabled || props.readOnly ? '0.1' : '1')};
`

const color = css`
  color: ${p => p.theme.blue};
`
const greyscale = css`
  color: ${p => p.theme.black};
`
const muted = css`
  color: ${p => p.theme.grey50};
`

const StyledSelect = styled.select`
  width: 100%;
  height: 100%;
  font-size: ${props => props.theme.fontSize};
  border: transparent;
  color: inherit;
  border-radius: none;
  outline: none;
  transition: all 0.1s ease;
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
  justify-content: space-between;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
  top: 0;
  position: absolute;
  opacity: 0;
`

export const SelectContainer = styled.div<{
  focused: boolean
  inline: boolean
  iconLeft?: IconName
  variant?: Variant
  disabled?: boolean
  readOnly?: boolean
}>`
  min-height: 40px;
  width: 100%;
  line-height: ${({theme}) => theme.lineHeight};
  display: flex;
  border-radius: ${props => props.theme.borderRadius};
  transition: all 0.1s ease;
  align-items: flex-start;
  max-width: $max-width;
  ${props =>
    (props.variant === 'color' && color) ||
    (props.variant === 'greyscale' && greyscale) ||
    (props.variant === 'muted' && muted)}
  ${props =>
    props.iconLeft
      ? null
      : props.inline
        ? css`
            padding-left: 15px;
          `
        : css`
            padding-left: 20px;
          `}
  position: relative;
  cursor: pointer;
  justify-content: space-between;

  ${({readOnly, focused, theme}) =>
    readOnly
      ? css`
          background: ${theme.white};
          border: solid 1px ${theme.grey20};
        `
      : css`
          background: ${focused ? theme.grey10 : theme.grey05};
          &:focus,
          &:hover {
            background-color: ${theme.grey10};
          }

          &:active {
            background-color: ${theme.grey15};
          }
        `}
`

const LabelWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  overflow: hidden;
  max-width: 100%;
`

const Label = styled.div<{inline: boolean; up?: boolean}>`
  display: flex;
  flex-direction: column;
  color: ${({theme}) => theme.grey50};
  user-select: none;
  transition: all 0.1s ease;
  line-height: ${({theme}) => theme.lineHeight};
  ${props =>
    props.up
      ? props.inline
        ? css`
            display: none;
          `
        : css`
            font-size: ${({theme}) => theme.fontSizeS};
            padding-top: 0px;
          `
      : null}
`

const StyledDisplayValue = styled.div<{disabled?: boolean; readOnly?: boolean}>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: inherit;
  opacity: ${props => (props.disabled && !props.readOnly ? '0.5' : '1')};
`

export const Select = <T extends string | number>({
  onChange,
  value,
  iconLeft,
  iconRight = 'arrow_down',
  label,
  upLabel,
  inline = false,
  variant,
  firstOptionText = '...',
  firstOptionDisabled = false,
  disabled,
  readOnly,
  ...props
}: SelectProps<T>) => {
  // normalise options
  const options = props.options.map(opt => {
    return typeof opt === 'object' ? opt : {value: opt, text: String(opt)}
  })
  const [focused, setFocused] = useState(false)

  // flatten in case of optgroups
  const flatOptions = options.flatMap(o => ('options' in o ? o.options : [o]))

  const getDisplayValue = (val: T) => {
    if ('getDisplayValue' in props && props.getDisplayValue) {
      return props.getDisplayValue(val)
    }

    const opt = flatOptions.find(o => o.value === val)
    if (opt) {
      return typeof opt === 'object' ? opt.text : opt
    }

    return ''
  }

  const displayValue = value ? getDisplayValue(value) : ''

  return (
    <SelectContainer
      variant={variant}
      inline={inline}
      iconLeft={iconLeft}
      focused={focused}
      disabled={disabled}
      readOnly={readOnly}
    >
      {iconLeft && (
        <StyledIconLeft inline={inline}>
          <Icon name={iconLeft} />
        </StyledIconLeft>
      )}
      <StyledIconRight inline={inline} disabled={disabled} readOnly={readOnly}>
        <Icon name={iconRight} />
      </StyledIconRight>
      <LabelWrapper>
        {displayValue ? (
          <>
            <Label inline={inline} up={true}>
              {upLabel ? upLabel : label}
            </Label>
            <StyledDisplayValue disabled={disabled} readOnly={readOnly}>
              {displayValue}
            </StyledDisplayValue>
          </>
        ) : (
          <Label inline={inline}>{label}</Label>
        )}
      </LabelWrapper>
      <StyledSelect
        onChange={e => {
          const opt = flatOptions.find(o => e.target.value === String(o.value))
          if (opt) {
            onChange(opt.value)
          }
        }}
        value={value === '' ? undefined : value}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        disabled={disabled ?? readOnly}
        data-testid={
          label ? `select-${label.toLowerCase().replace(/ /g, '-')}` : undefined
        }
        {...props}
      >
        {!firstOptionDisabled && <option>{firstOptionText}</option>}
        {options.map((optOrGroup, i) =>
          'options' in optOrGroup ? (
            <optgroup label={optOrGroup.group} key={`${optOrGroup.group}-${i}`}>
              {optOrGroup.options.map((opt, j) => (
                <option
                  value={opt.value}
                  key={`${opt.value}-${j}`}
                  disabled={opt.disabled}
                >
                  {opt.text}
                </option>
              ))}
            </optgroup>
          ) : (
            <option
              value={optOrGroup.value}
              key={`${optOrGroup.value}-${i}`}
              disabled={optOrGroup.disabled}
            >
              {optOrGroup.text}
            </option>
          )
        )}
      </StyledSelect>
    </SelectContainer>
  )
}

import * as t from 'io-ts'
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router'
import {OrganisationAuthConfig} from '../../common/types'
import {
  AuthLargeHeader,
  AuthLogo,
  AuthMedia,
  AuthMessage,
  AuthSmallHeader
} from '../../components/auth/layout'
import {Button} from '../../components/library/buttons'
import {TextInput} from '../../components/library/form'
import {PasswordInput} from '../../components/library/form/password-input'
import {usePersistedState} from '../../hooks/use-persisted-state'
import {useAuth} from '../../lib/auth'
import {Authenticator, CognitoError, SignInResult} from '../../lib/cognito'
import {APP_FLAVOUR, ROUTING_API_URL} from '../../lib/env'
import {useFocusFirstFormInput} from '../../lib/hooks'
import {useNodeLazy} from '../../lib/jsonAPI'
import {setLocalOrganisationId} from '../../lib/local-store'
import {FormRow} from '../form'
import {LinkButton} from '../library/buttons/button'
import {Toggle} from '../library/toggles/toggle'
import {AuthButtonGroup, Error, P} from './layout'
import {AuthComplete} from './types'

interface SignInFormProps {
  auth: Authenticator
  email?: string
  onComplete: AuthComplete
  children?: React.ReactNode
  formOnly?: boolean
  showLogo?: boolean
  redirectToPath?: string
  ssoEnabled?: boolean
  ssoOrgId?: string
  /** allow the user to enter an org id but prefill the form */
  ssoSuggestedOrgId?: string
  allowSSO?: boolean
  allowEmailPassword?: boolean
}

interface Credentials {
  email: string
  password: string
}

export const SignInForm = ({
  auth,
  email = '',
  onComplete,
  children,
  formOnly = false,
  showLogo = true,
  redirectToPath,
  ssoOrgId,
  ssoSuggestedOrgId,
  allowSSO = true,
  allowEmailPassword = true
}: SignInFormProps) => {
  const [error, setError] = useState('')
  const [mode, setMode] = usePersistedState(
    ssoOrgId ? 'sso' : 'login',
    'last-login-option',
    t.union([t.literal('sso'), t.literal('login')])
  )

  // if ssoOrgId prop is provided, we want it to take precedence over whatever
  // was previously stored in localstorage
  useEffect(() => {
    if (ssoOrgId) {
      setMode('sso')
    }
  }, [setMode, ssoOrgId])

  const [loadOrgConfig] = useNodeLazy<OrganisationAuthConfig>(
    `${ROUTING_API_URL}/auth-config/`,
    undefined,
    {authIsOptional: true}
  )
  const navigate = useNavigate()
  const [totpCallback, setTotpCallback] =
    useState<(code: string) => Promise<void>>()
  const [authConfig, setAuthConfig] = useState<OrganisationAuthConfig>()

  useEffect(() => {
    // reset client id to default, if showing the regular sign-in form
    if (mode === 'login') {
      auth.setClientId(null)
    }
  }, [auth, mode])

  const ssoRedirect = async (organisationId: string, appClientId: string) => {
    setLocalOrganisationId(organisationId)
    await auth.setClientId(appClientId)
    if (redirectToPath) {
      // Note I'm now setting `sso_success_redirect_url in accept-invitation.tsx instead of passing the redirectTo prop
      // as I really only want a redirect to happen for SSO users, not regular sign-in users
      // Using redirectTo with signup/:secret unnecessarily causes a reload of the accept-invitation page for non sso users
      // via the submit() function which is problematic
      sessionStorage.setItem('sso_success_redirect_url', redirectToPath)
    }

    const url = auth.getAuthUrl('login')

    if (APP_FLAVOUR === 'sfmc-plugin') {
      // open a popup instead of redirecting when in an iframe
      window.open(url, 'atomic-workbench-sso')
    } else {
      window.location.assign(url)
    }

    return
  }

  const getAuthConfig = async (organisationId: string) => {
    setError('')
    const orgConfig = await loadOrgConfig(organisationId)
    setLocalOrganisationId(organisationId)
    if (!orgConfig) {
      setError('Invalid organization id')
      return
    }

    if (!orgConfig.attributes.ssoEnabled) {
      setError(
        `SSO is not enabled for that organisation. ${
          allowEmailPassword ? 'Please log in instead' : ''
        }`
      )
      return
    }

    return orgConfig
  }

  const submit = async (formData: Credentials) => {
    setError('')

    let result: SignInResult | undefined

    try {
      result = await auth.signIn(formData.email, formData.password)
    } catch (err) {
      if (err instanceof CognitoError) {
        if (
          err.message === 'Password attempts exceeded' ||
          err.message ===
            'Unable to proceed with login due to MFA issue, contact support' ||
          err.message.includes('Unexpected challenge')
        ) {
          setError(err.message)
          return
        }
        setError(
          'Problem with login, please check details and try again or contact support'
        )
        return
      } else {
        throw err
      }
    }

    if (result.state === 'totp') {
      const {sendTOTPCode} = result
      setTotpCallback(
        () => (code: string) =>
          sendTOTPCode(code)
            .then(() => {
              onComplete('success', {
                username: formData.email,
                password: formData.password
              })
              if (redirectToPath) {
                navigate(redirectToPath, {replace: true})
              }
            })
            .catch(e => {
              if (e.message.includes('Invalid code received for user')) {
                setError('Invalid code. Please try again.')
              } else {
                setError(e.message)
              }
            })
      )
    } else {
      await Promise.resolve(
        onComplete(result.state, {
          username: formData.email,
          password: formData.password
        })
      )
      if (result.state === 'success' && redirectToPath) {
        navigate(redirectToPath, {replace: true})
      }
    }
  }

  return (
    <>
      {totpCallback ? (
        <TotpForm
          error={error}
          cancel={() => setTotpCallback(undefined)}
          submit={totpCallback}
          formOnly={formOnly}
        />
      ) : (
        <>
          {!formOnly && (
            <>
              {showLogo && <AuthLogo />}
              <AuthLargeHeader>Log in</AuthLargeHeader>
              {!authConfig && (
                <AuthMessage>
                  {mode === 'sso'
                    ? 'With your authentication provider'
                    : 'With your Atomic account'}
                </AuthMessage>
              )}
            </>
          )}
          {allowSSO && allowEmailPassword && !authConfig && (
            <Toggle
              data-testid={'login-switcher'}
              style={{width: '100%', marginBottom: '10px'}}
              options={[
                {value: 'login', text: 'Login'},
                {value: 'sso', text: 'SSO'}
              ]}
              value={mode}
              onChange={v => {
                setMode(v)
                setError('')
              }}
              legacyMode={true}
            />
          )}
          {mode === 'sso' && allowSSO ? (
            <SSOForm
              error={error}
              ssoRedirect={ssoRedirect}
              getAuthConfig={getAuthConfig}
              orgId={ssoOrgId}
              suggestedOrgId={ssoSuggestedOrgId}
              authConfig={authConfig}
              setAuthConfig={setAuthConfig}
            />
          ) : (
            <CredentialsForm error={error} email={email} submit={submit} />
          )}
        </>
      )}
      {children && <P>{children}</P>}
    </>
  )
}

interface CredentialsFormProps {
  error: string
  email: string
  submit: (data: Credentials) => Promise<void>
}

const CredentialsForm = ({error, email, submit}: CredentialsFormProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<Credentials>({
    email,
    password: ''
  })

  const formRef = useFocusFirstFormInput()
  const {auth} = useAuth()
  const disabled =
    loading || !formData.email.includes('@') || !formData.password

  return (
    <form
      style={{width: '100%'}}
      onSubmit={async e => {
        e.preventDefault()
        setLoading(true)
        await submit(formData)
        setLoading(false)
      }}
      ref={formRef}
    >
      {error && <Error data-testid={'login-error'}>{error}</Error>}

      <>
        <FormRow>
          <TextInput
            type="email"
            name="email"
            label="Email address"
            required={true}
            onChange={value => setFormData(c => ({...c, email: value}))}
            value={formData.email}
            readOnly={!!email}
            data-testid="login-email"
          />
        </FormRow>
        <FormRow>
          <PasswordInput
            name="password"
            label="Password"
            required={true}
            onChange={password => setFormData(c => ({...c, password}))}
            value={formData.password}
            data-testid="login-password"
          />
        </FormRow>
      </>

      <AuthButtonGroup>
        <Button
          type="submit"
          appearance="primary"
          size="large"
          disabled={disabled}
          style={{
            width: '100%'
          }}
        >
          {loading ? 'Loading...' : 'Log in'}
        </Button>
      </AuthButtonGroup>
      <P>
        <LinkButton
          size="large"
          appearance="transparent"
          href={auth.getAuthUrl('forgotPassword')}
          target="_blank"
        >
          Forgot password
        </LinkButton>
      </P>
    </form>
  )
}

interface SSOFormProps {
  error: string
  ssoRedirect: (orgId: string, appClientId: string) => Promise<void>
  orgId?: string
  suggestedOrgId?: string
  getAuthConfig: (orgId: string) => Promise<void | OrganisationAuthConfig>
  setAuthConfig: React.Dispatch<
    React.SetStateAction<OrganisationAuthConfig | undefined>
  >
  authConfig: OrganisationAuthConfig | undefined
}

const SSOForm = ({
  ssoRedirect,
  error,
  orgId,
  suggestedOrgId,
  getAuthConfig,
  setAuthConfig,
  authConfig
}: SSOFormProps) => {
  const [organisationId, setOrganisationId] = usePersistedState(
    orgId ?? '',
    'last-sso-org',
    t.string
  )

  // if the org id is passed in as a prop, we use it directly rather than what's
  // in state, but we want it to be updated in localstorage for next time
  useEffect(() => {
    if (orgId || suggestedOrgId) {
      setOrganisationId((orgId ?? suggestedOrgId) as string)
    }
  }, [orgId, setOrganisationId, suggestedOrgId])

  const [loading, setLoading] = useState(false)
  const formRef = useFocusFirstFormInput()
  const disabled = loading || !organisationId

  if (!authConfig) {
    return (
      <form
        onSubmit={async e => {
          e.preventDefault()
          setLoading(true)
          getAuthConfig(organisationId).then(config => {
            if (config) {
              if (config.attributes.appClients.length === 1) {
                ssoRedirect(
                  organisationId,
                  config.attributes.appClients[0].appClientId
                )
                return
              }
              setAuthConfig(config)
            }
            setLoading(false)
          })
        }}
        ref={formRef}
      >
        {error && <Error data-testid={'login-error'}>{error}</Error>}
        {!orgId ? (
          <FormRow>
            <TextInput
              name="organisationId"
              label="Organization ID"
              onChange={setOrganisationId}
              value={organisationId}
              data-testid="login-organisation-id"
            />
          </FormRow>
        ) : null}
        <AuthButtonGroup>
          <Button
            data-testid="login-sso"
            type="submit"
            appearance="primary"
            size="large"
            disabled={disabled}
            style={{
              width: '100%'
            }}
          >
            {loading ? 'Loading...' : 'Continue with SSO'}
          </Button>
        </AuthButtonGroup>
      </form>
    )
  } else {
    return (
      <>
        <p style={{margin: '25px 0'}}>
          Select identity provider for Organization ID <b>{organisationId}</b>
        </p>
        {authConfig.attributes.appClients.map(client => (
          <Button
            key={client.appClientId}
            appearance="secondary"
            size="large"
            // disabled={!appClientId || loading}
            style={{
              width: '100%',
              margin: '0 0 20px'
            }}
            onClick={() => {
              setLoading(true)
              ssoRedirect(organisationId, client.appClientId)
              // allow some time for redirect
              setTimeout(() => setLoading(false), 1000)
            }}
          >
            {`Log in with ${client.appClientName}`}
          </Button>
        ))}

        <Button
          iconLeft="back_large"
          style={{
            marginTop: '80px'
          }}
          onClick={() => {
            setAuthConfig(undefined)
          }}
          appearance="transparent"
          size="small"
        >
          Go back
        </Button>
      </>
    )
  }
}

interface TotpFormProps {
  error: string
  submit: (code: string) => Promise<void>
  cancel: () => void
  formOnly: boolean
}

const TotpForm = ({error, submit, cancel, formOnly}: TotpFormProps) => {
  const [loading, setLoading] = useState(false)
  const [totpCode, setTotpCode] = useState('')
  const formRef = useFocusFirstFormInput()

  return (
    <>
      {!formOnly && (
        <>
          <AuthMedia icon="two_step" />
          <AuthSmallHeader>Verify with code</AuthSmallHeader>
          <AuthMessage>
            To finish logging in, verify your identity by entering the 6-digit
            code you received in the authentication app on your phone.
          </AuthMessage>
        </>
      )}
      <form
        style={{width: '100%'}}
        onSubmit={e => {
          e.preventDefault()
          submit(totpCode)
          setLoading(false)
        }}
        ref={formRef}
      >
        {error && <Error>{error}</Error>}
        <FormRow>
          <TextInput
            upLabel="Code"
            label="Enter code..."
            required={true}
            onChange={code =>
              // 6 numeric characters only
              setTotpCode(code.replace(/[^\d]/, '').slice(0, 6))
            }
            value={totpCode}
          />
        </FormRow>

        <AuthButtonGroup>
          <Button
            type="submit"
            appearance="primary"
            size="large"
            disabled={loading || totpCode.length !== 6}
            style={{
              width: '100%'
            }}
          >
            {loading ? 'Loading...' : 'Log in'}
          </Button>
        </AuthButtonGroup>
        <P>
          <Button size="large" appearance="transparent" onClick={cancel}>
            Back to login
          </Button>
        </P>
      </form>
    </>
  )
}

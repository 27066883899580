import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomEndUserDetailFragment = { __typename?: 'CustomEndUserType', id: string, allowTest: boolean, nickname: string, created: string, environmentId: string, authSecret?: string | null, businessUserAccountId?: number | null, profile: unknown, status?: string | null, organisationBusinessUserAccount?: { __typename?: 'BusinessUserAccount', email?: string | null, name?: string | null } | null };

export type TestUserListQueryVariables = Types.Exact<{
  environmentId: Types.Scalars['String']['input'];
}>;


export type TestUserListQuery = { __typename?: 'Query', endUsers: { __typename?: 'RetrieveTestUsersResponse', totalCount: number, nodes: Array<{ __typename?: 'CustomEndUserType', id: string, allowTest: boolean, nickname: string, created: string, environmentId: string, authSecret?: string | null, businessUserAccountId?: number | null, profile: unknown, status?: string | null, organisationBusinessUserAccount?: { __typename?: 'BusinessUserAccount', email?: string | null, name?: string | null } | null }> } };

export type BulkCreateUsersMutationVariables = Types.Exact<{
  rows: Array<Types.EndUserRow> | Types.EndUserRow;
  environmentId: Types.Scalars['String']['input'];
  tags?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>>;
}>;


export type BulkCreateUsersMutation = { __typename?: 'Mutation', endUsersBulkCreationMutation?: boolean | null };

export type UpdateEndUserMutationVariables = Types.Exact<{
  environmentId: Types.Scalars['String']['input'];
  id: Types.Scalars['String']['input'];
  nickname?: Types.InputMaybe<Types.Scalars['String']['input']>;
  profile?: Types.InputMaybe<Types.Scalars['JSON']['input']>;
  allowTest?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type UpdateEndUserMutation = { __typename?: 'Mutation', updateEndUser?: { __typename?: 'UpdateEndUserResponse', endUser: { __typename?: 'CustomEndUserType', id: string, allowTest: boolean, nickname: string, created: string, environmentId: string, authSecret?: string | null, businessUserAccountId?: number | null, profile: unknown, status?: string | null, organisationBusinessUserAccount?: { __typename?: 'BusinessUserAccount', email?: string | null, name?: string | null } | null } } | null };

export type CreateTestEndUserMutationVariables = Types.Exact<{
  environmentId: Types.Scalars['String']['input'];
  id: Types.Scalars['String']['input'];
  nickname: Types.Scalars['String']['input'];
}>;


export type CreateTestEndUserMutation = { __typename?: 'Mutation', createTestEndUser?: { __typename?: 'CreateTestEndUserResponse', endUser: { __typename?: 'CreateTestEndUserEndUser', id: string, environmentId: string, nickname: string } } | null };

export type EndUserSegmentDetailFragment = { __typename?: 'EndUserSegment', id: string, name: string, nestedFilterConditions: unknown };

export type CreateSegmentMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  environmentId: Types.Scalars['String']['input'];
  filterConditions?: Types.InputMaybe<Types.Scalars['JSON']['input']>;
}>;


export type CreateSegmentMutation = { __typename?: 'Mutation', createEndUserSegment?: { __typename?: 'CreateEndUserSegmentPayload', endUserSegment?: { __typename?: 'EndUserSegment', id: string, name: string, nestedFilterConditions: unknown } | null } | null };

export type UpdateSegmentMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  environmentId: Types.Scalars['String']['input'];
  filterConditions?: Types.InputMaybe<Types.Scalars['JSON']['input']>;
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpdateSegmentMutation = { __typename?: 'Mutation', updateEndUserSegment?: { __typename?: 'UpdateEndUserSegmentPayload', endUserSegment?: { __typename?: 'EndUserSegment', id: string, name: string, nestedFilterConditions: unknown } | null } | null };

export type EndUserSegmentsQueryVariables = Types.Exact<{
  environmentId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  cursor?: Types.InputMaybe<Types.Scalars['Cursor']['input']>;
}>;


export type EndUserSegmentsQuery = { __typename?: 'Query', endUserSegments?: { __typename?: 'EndUserSegmentsConnection', nodes: Array<{ __typename?: 'EndUserSegment', id: string, name: string, nestedFilterConditions: unknown }> } | null };

export type DeleteEndUserSegmentMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  environmentId: Types.Scalars['String']['input'];
}>;


export type DeleteEndUserSegmentMutation = { __typename?: 'Mutation', deleteEndUserSegment?: { __typename?: 'DeleteEndUserSegmentPayload', clientMutationId?: string | null } | null };

export type RemoveEndUserMutationVariables = Types.Exact<{
  environmentId: Types.Scalars['String']['input'];
  id: Types.Scalars['String']['input'];
}>;


export type RemoveEndUserMutation = { __typename?: 'Mutation', endUserDeleteWithDynamo?: { __typename?: 'EndUserDeleteResponse', success?: boolean | null } | null };

export const CustomEndUserDetailFragmentDoc = gql`
    fragment CustomEndUserDetail on CustomEndUserType {
  id
  allowTest
  nickname
  created
  environmentId
  authSecret
  businessUserAccountId
  profile
  organisationBusinessUserAccount {
    email
    name
  }
  status
}
    `;
export const EndUserSegmentDetailFragmentDoc = gql`
    fragment EndUserSegmentDetail on EndUserSegment {
  id
  name
  nestedFilterConditions
}
    `;
export const TestUserListDocument = gql`
    query TestUserList($environmentId: String!) {
  endUsers: retrieveTestUsersQuery(environmentId: $environmentId) {
    nodes {
      ...CustomEndUserDetail
    }
    totalCount
  }
}
    ${CustomEndUserDetailFragmentDoc}`;

/**
 * __useTestUserListQuery__
 *
 * To run a query within a React component, call `useTestUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestUserListQuery({
 *   variables: {
 *      environmentId: // value for 'environmentId'
 *   },
 * });
 */
export function useTestUserListQuery(baseOptions: Apollo.QueryHookOptions<TestUserListQuery, TestUserListQueryVariables> & ({ variables: TestUserListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestUserListQuery, TestUserListQueryVariables>(TestUserListDocument, options);
      }
export function useTestUserListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestUserListQuery, TestUserListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestUserListQuery, TestUserListQueryVariables>(TestUserListDocument, options);
        }
export function useTestUserListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TestUserListQuery, TestUserListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TestUserListQuery, TestUserListQueryVariables>(TestUserListDocument, options);
        }
export type TestUserListQueryHookResult = ReturnType<typeof useTestUserListQuery>;
export type TestUserListLazyQueryHookResult = ReturnType<typeof useTestUserListLazyQuery>;
export type TestUserListSuspenseQueryHookResult = ReturnType<typeof useTestUserListSuspenseQuery>;
export type TestUserListQueryResult = Apollo.QueryResult<TestUserListQuery, TestUserListQueryVariables>;
export const BulkCreateUsersDocument = gql`
    mutation BulkCreateUsers($rows: [EndUserRow!]!, $environmentId: String!, $tags: [String]) {
  endUsersBulkCreationMutation(
    rows: $rows
    environmentId: $environmentId
    tags: $tags
  )
}
    `;

/**
 * __useBulkCreateUsersMutation__
 *
 * To run a mutation, you first call `useBulkCreateUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateUsersMutation, { data, loading, error }] = useBulkCreateUsersMutation({
 *   variables: {
 *      rows: // value for 'rows'
 *      environmentId: // value for 'environmentId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useBulkCreateUsersMutation(baseOptions?: Apollo.MutationHookOptions<BulkCreateUsersMutation, BulkCreateUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkCreateUsersMutation, BulkCreateUsersMutationVariables>(BulkCreateUsersDocument, options);
      }
export type BulkCreateUsersMutationHookResult = ReturnType<typeof useBulkCreateUsersMutation>;
export type BulkCreateUsersMutationResult = Apollo.MutationResult<BulkCreateUsersMutation>;
export type BulkCreateUsersMutationOptions = Apollo.BaseMutationOptions<BulkCreateUsersMutation, BulkCreateUsersMutationVariables>;
export const UpdateEndUserDocument = gql`
    mutation UpdateEndUser($environmentId: String!, $id: String!, $nickname: String, $profile: JSON, $allowTest: Boolean) {
  updateEndUser: updateEndUserMutation(
    id: $id
    environmentId: $environmentId
    nickname: $nickname
    profile: $profile
    allowTest: $allowTest
  ) {
    endUser {
      ...CustomEndUserDetail
    }
  }
}
    ${CustomEndUserDetailFragmentDoc}`;

/**
 * __useUpdateEndUserMutation__
 *
 * To run a mutation, you first call `useUpdateEndUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEndUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEndUserMutation, { data, loading, error }] = useUpdateEndUserMutation({
 *   variables: {
 *      environmentId: // value for 'environmentId'
 *      id: // value for 'id'
 *      nickname: // value for 'nickname'
 *      profile: // value for 'profile'
 *      allowTest: // value for 'allowTest'
 *   },
 * });
 */
export function useUpdateEndUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEndUserMutation, UpdateEndUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEndUserMutation, UpdateEndUserMutationVariables>(UpdateEndUserDocument, options);
      }
export type UpdateEndUserMutationHookResult = ReturnType<typeof useUpdateEndUserMutation>;
export type UpdateEndUserMutationResult = Apollo.MutationResult<UpdateEndUserMutation>;
export type UpdateEndUserMutationOptions = Apollo.BaseMutationOptions<UpdateEndUserMutation, UpdateEndUserMutationVariables>;
export const CreateTestEndUserDocument = gql`
    mutation CreateTestEndUser($environmentId: String!, $id: String!, $nickname: String!) {
  createTestEndUser(environmentId: $environmentId, id: $id, nickname: $nickname) {
    endUser {
      id
      environmentId
      nickname
    }
  }
}
    `;

/**
 * __useCreateTestEndUserMutation__
 *
 * To run a mutation, you first call `useCreateTestEndUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestEndUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestEndUserMutation, { data, loading, error }] = useCreateTestEndUserMutation({
 *   variables: {
 *      environmentId: // value for 'environmentId'
 *      id: // value for 'id'
 *      nickname: // value for 'nickname'
 *   },
 * });
 */
export function useCreateTestEndUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateTestEndUserMutation, CreateTestEndUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTestEndUserMutation, CreateTestEndUserMutationVariables>(CreateTestEndUserDocument, options);
      }
export type CreateTestEndUserMutationHookResult = ReturnType<typeof useCreateTestEndUserMutation>;
export type CreateTestEndUserMutationResult = Apollo.MutationResult<CreateTestEndUserMutation>;
export type CreateTestEndUserMutationOptions = Apollo.BaseMutationOptions<CreateTestEndUserMutation, CreateTestEndUserMutationVariables>;
export const CreateSegmentDocument = gql`
    mutation CreateSegment($name: String!, $environmentId: String!, $filterConditions: JSON) {
  createEndUserSegment(
    input: {endUserSegment: {name: $name, environmentId: $environmentId, nestedFilterConditions: $filterConditions}}
  ) {
    endUserSegment {
      ...EndUserSegmentDetail
    }
  }
}
    ${EndUserSegmentDetailFragmentDoc}`;

/**
 * __useCreateSegmentMutation__
 *
 * To run a mutation, you first call `useCreateSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSegmentMutation, { data, loading, error }] = useCreateSegmentMutation({
 *   variables: {
 *      name: // value for 'name'
 *      environmentId: // value for 'environmentId'
 *      filterConditions: // value for 'filterConditions'
 *   },
 * });
 */
export function useCreateSegmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateSegmentMutation, CreateSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSegmentMutation, CreateSegmentMutationVariables>(CreateSegmentDocument, options);
      }
export type CreateSegmentMutationHookResult = ReturnType<typeof useCreateSegmentMutation>;
export type CreateSegmentMutationResult = Apollo.MutationResult<CreateSegmentMutation>;
export type CreateSegmentMutationOptions = Apollo.BaseMutationOptions<CreateSegmentMutation, CreateSegmentMutationVariables>;
export const UpdateSegmentDocument = gql`
    mutation UpdateSegment($id: String!, $environmentId: String!, $filterConditions: JSON, $name: String) {
  updateEndUserSegment(
    input: {patch: {nestedFilterConditions: $filterConditions, name: $name}, environmentId: $environmentId, id: $id}
  ) {
    endUserSegment {
      ...EndUserSegmentDetail
    }
  }
}
    ${EndUserSegmentDetailFragmentDoc}`;

/**
 * __useUpdateSegmentMutation__
 *
 * To run a mutation, you first call `useUpdateSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSegmentMutation, { data, loading, error }] = useUpdateSegmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      environmentId: // value for 'environmentId'
 *      filterConditions: // value for 'filterConditions'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateSegmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSegmentMutation, UpdateSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSegmentMutation, UpdateSegmentMutationVariables>(UpdateSegmentDocument, options);
      }
export type UpdateSegmentMutationHookResult = ReturnType<typeof useUpdateSegmentMutation>;
export type UpdateSegmentMutationResult = Apollo.MutationResult<UpdateSegmentMutation>;
export type UpdateSegmentMutationOptions = Apollo.BaseMutationOptions<UpdateSegmentMutation, UpdateSegmentMutationVariables>;
export const EndUserSegmentsDocument = gql`
    query EndUserSegments($environmentId: String, $first: Int = 20, $cursor: Cursor) {
  endUserSegments(
    first: $first
    after: $cursor
    condition: {environmentId: $environmentId}
    orderBy: CREATED_DESC
  ) {
    nodes {
      ...EndUserSegmentDetail
    }
  }
}
    ${EndUserSegmentDetailFragmentDoc}`;

/**
 * __useEndUserSegmentsQuery__
 *
 * To run a query within a React component, call `useEndUserSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEndUserSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEndUserSegmentsQuery({
 *   variables: {
 *      environmentId: // value for 'environmentId'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useEndUserSegmentsQuery(baseOptions?: Apollo.QueryHookOptions<EndUserSegmentsQuery, EndUserSegmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EndUserSegmentsQuery, EndUserSegmentsQueryVariables>(EndUserSegmentsDocument, options);
      }
export function useEndUserSegmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EndUserSegmentsQuery, EndUserSegmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EndUserSegmentsQuery, EndUserSegmentsQueryVariables>(EndUserSegmentsDocument, options);
        }
export function useEndUserSegmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EndUserSegmentsQuery, EndUserSegmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EndUserSegmentsQuery, EndUserSegmentsQueryVariables>(EndUserSegmentsDocument, options);
        }
export type EndUserSegmentsQueryHookResult = ReturnType<typeof useEndUserSegmentsQuery>;
export type EndUserSegmentsLazyQueryHookResult = ReturnType<typeof useEndUserSegmentsLazyQuery>;
export type EndUserSegmentsSuspenseQueryHookResult = ReturnType<typeof useEndUserSegmentsSuspenseQuery>;
export type EndUserSegmentsQueryResult = Apollo.QueryResult<EndUserSegmentsQuery, EndUserSegmentsQueryVariables>;
export const DeleteEndUserSegmentDocument = gql`
    mutation DeleteEndUserSegment($id: String!, $environmentId: String!) {
  deleteEndUserSegment(input: {id: $id, environmentId: $environmentId}) {
    clientMutationId
  }
}
    `;

/**
 * __useDeleteEndUserSegmentMutation__
 *
 * To run a mutation, you first call `useDeleteEndUserSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEndUserSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEndUserSegmentMutation, { data, loading, error }] = useDeleteEndUserSegmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      environmentId: // value for 'environmentId'
 *   },
 * });
 */
export function useDeleteEndUserSegmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEndUserSegmentMutation, DeleteEndUserSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEndUserSegmentMutation, DeleteEndUserSegmentMutationVariables>(DeleteEndUserSegmentDocument, options);
      }
export type DeleteEndUserSegmentMutationHookResult = ReturnType<typeof useDeleteEndUserSegmentMutation>;
export type DeleteEndUserSegmentMutationResult = Apollo.MutationResult<DeleteEndUserSegmentMutation>;
export type DeleteEndUserSegmentMutationOptions = Apollo.BaseMutationOptions<DeleteEndUserSegmentMutation, DeleteEndUserSegmentMutationVariables>;
export const RemoveEndUserDocument = gql`
    mutation RemoveEndUser($environmentId: String!, $id: String!) {
  endUserDeleteWithDynamo(id: $id, environmentId: $environmentId) {
    success
  }
}
    `;

/**
 * __useRemoveEndUserMutation__
 *
 * To run a mutation, you first call `useRemoveEndUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEndUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEndUserMutation, { data, loading, error }] = useRemoveEndUserMutation({
 *   variables: {
 *      environmentId: // value for 'environmentId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveEndUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEndUserMutation, RemoveEndUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEndUserMutation, RemoveEndUserMutationVariables>(RemoveEndUserDocument, options);
      }
export type RemoveEndUserMutationHookResult = ReturnType<typeof useRemoveEndUserMutation>;
export type RemoveEndUserMutationResult = Apollo.MutationResult<RemoveEndUserMutation>;
export type RemoveEndUserMutationOptions = Apollo.BaseMutationOptions<RemoveEndUserMutation, RemoveEndUserMutationVariables>;
import * as Types from '../../data/workbench-api/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCommandItemsQueryVariables = Types.Exact<{
  environmentId: Types.Scalars['String']['input'];
  organisationId: Types.Scalars['String']['input'];
}>;


export type GetCommandItemsQuery = { __typename?: 'Query', cardTemplates?: { __typename?: 'CardTemplatesConnection', nodes: Array<{ __typename?: 'CardTemplate', id: string, name: string, currentVersionName?: string | null, currentStatus?: Types.CardTemplateStatus | null }> } | null, actionFlowConfigs?: { __typename?: 'ActionFlowConfigsConnection', nodes: Array<{ __typename?: 'ActionFlowConfig', id: string, name?: string | null }> } | null, themes?: { __typename?: 'ThemesConnection', nodes: Array<{ __typename?: 'Theme', id: string, name: string }> } | null, getOrganisationAccess?: { __typename?: 'OrganisationAccess', id: string, name: string, legacyCardsEnabled?: boolean | null, environments?: Array<{ __typename?: 'OrganisationAccessEnvironment', id: string, name: string } | null> | null } | null };


export const GetCommandItemsDocument = gql`
    query GetCommandItems($environmentId: String!, $organisationId: String!) {
  cardTemplates(
    condition: {environmentId: $environmentId}
    filter: {currentStatus: {in: [DRAFT, PUBLISHED]}}
  ) {
    nodes {
      id
      name
      currentVersionName
      currentStatus
    }
  }
  actionFlowConfigs(condition: {environmentId: $environmentId}) {
    nodes {
      id
      name
    }
  }
  themes(condition: {environmentId: $environmentId}) {
    nodes {
      id
      name
    }
  }
  getOrganisationAccess(organisationId: $organisationId) {
    id
    name
    environments {
      id
      name
    }
    legacyCardsEnabled
  }
}
    `;

/**
 * __useGetCommandItemsQuery__
 *
 * To run a query within a React component, call `useGetCommandItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommandItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommandItemsQuery({
 *   variables: {
 *      environmentId: // value for 'environmentId'
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useGetCommandItemsQuery(baseOptions: Apollo.QueryHookOptions<GetCommandItemsQuery, GetCommandItemsQueryVariables> & ({ variables: GetCommandItemsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommandItemsQuery, GetCommandItemsQueryVariables>(GetCommandItemsDocument, options);
      }
export function useGetCommandItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommandItemsQuery, GetCommandItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommandItemsQuery, GetCommandItemsQueryVariables>(GetCommandItemsDocument, options);
        }
export function useGetCommandItemsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCommandItemsQuery, GetCommandItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCommandItemsQuery, GetCommandItemsQueryVariables>(GetCommandItemsDocument, options);
        }
export type GetCommandItemsQueryHookResult = ReturnType<typeof useGetCommandItemsQuery>;
export type GetCommandItemsLazyQueryHookResult = ReturnType<typeof useGetCommandItemsLazyQuery>;
export type GetCommandItemsSuspenseQueryHookResult = ReturnType<typeof useGetCommandItemsSuspenseQuery>;
export type GetCommandItemsQueryResult = Apollo.QueryResult<GetCommandItemsQuery, GetCommandItemsQueryVariables>;
import {ApolloError} from '@apollo/client'
import {Mutation} from '@apollo/client/react/components'
import {DocumentNode} from 'graphql'
import React, {useEffect, useState} from 'react'
import {notify} from '../lib/bugsnag'
import {useFocusFirstFormInput} from '../lib/hooks'
import {useToast} from '../lib/toast'
import {Button, IconButton} from './library/buttons/index'
import {Variant} from './library/buttons/icon-button'
import {TextInput} from './library/form'
import {InputProps} from './library/form/text'
import styled, {css} from 'styled-components'
import {Tooltip} from './library/tooltip/tooltip'

// wraps cancel and submit buttons at the bottom of a form
export const ButtonGroup = styled.div`
  display: flex;
  padding-top: 30px;

  /* allow submit button to be first in tab order */
  flex-direction: row-reverse;
  justify-content: flex-start;

  > * {
    margin-left: 10px;
  }
`

export const ButtonLeft = styled.div`
  margin-left: auto;
  flex: -1;
`

// wraps a block-level form field element within a form
export const FormRow = styled.div`
  padding: 10px 0;
`

export const FormSpacer = styled.div`
  height: 20px;
`

export const FormError = styled.p`
  color: ${p => p.theme.red};
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  min-height: 60px;
  padding: 20px;
  margin: 0 0 20px;
  border-radius: ${p => p.theme.borderRadius};
  background: ${p => p.theme.redLightest};
  line-height: ${p => p.theme.lineHeightCompact};
  font-size: ${p => p.theme.fontSize};
`

interface LegacyFormProps<P> {
  obj: Partial<P>
  title: string
  submitText?: string
  mutation: DocumentNode
  update?: (data: any) => void
  children: (
    obj: Partial<P>,
    updateObj: (p: Partial<P>) => void
  ) => React.ReactNode
  style?: React.CSSProperties
  isValid?: (p: Partial<P>) => boolean
  onChange?: (p: Partial<P>) => void
  onCancel?: (p: Partial<P>) => void
  getErrorMessage?: (error: string) => string
}

export const LegacyForm = <P extends {}>({
  obj,
  mutation,
  children,
  update,
  isValid,
  onChange,
  submitText = 'Save',
  getErrorMessage,
  onCancel
}: LegacyFormProps<P>) => {
  const toaster = useToast()
  const [localObj, setLocalObj] = useState<Partial<P>>(obj)
  useEffect(() => {
    setLocalObj(obj)
  }, [obj])

  const updateLocalObj = (patch: Partial<P>) => {
    const updated = {
      ...localObj,
      ...patch
    }
    setLocalObj(updated)
    if (onChange) {
      onChange(updated)
    }
  }

  const formRef = useFocusFirstFormInput()

  // check validity of current object
  const valid = isValid ? isValid(localObj) : true

  return (
    <Mutation
      mutation={mutation}
      onCompleted={(data: unknown) => {
        toaster('Success')
        if (update) {
          update(data)
        }
      }}
    >
      {(
        doMutation: (e: {variables: Partial<P>}) => void,
        {loading, ...opts}: any
      ) => (
        <form
          ref={formRef}
          onSubmit={e => {
            e.preventDefault()
            doMutation({
              variables: localObj
            })
          }}
        >
          {opts.error ? (
            <FormError>
              {getErrorMessage
                ? getErrorMessage(opts.error.message)
                : opts.error.message}
            </FormError>
          ) : null}

          {children(localObj, updateLocalObj)}

          <ButtonGroup>
            <Button type="submit" disabled={!valid || loading} size="large">
              {submitText}
            </Button>
            {onCancel && (
              <Button
                onClick={() => onCancel(localObj)}
                appearance="tint"
                size="large"
              >
                Cancel
              </Button>
            )}
          </ButtonGroup>
        </form>
      )}
    </Mutation>
  )
}

type CommaSeparatedList = (string | null)[] | null | undefined

type CommaSeparatedListInputProps = Omit<
  InputProps,
  'value' | 'onChange' | 'onBlur'
> & {
  value: CommaSeparatedList | string
  onChange: (value: CommaSeparatedList) => void
}

export const CommaSeparatedListInput = ({
  value,
  onChange,
  ...props
}: CommaSeparatedListInputProps) => {
  const normalisedValue = () =>
    value ? (typeof value === 'string' ? value : value.join(', ')) : ''

  const [localValue, setLocalValue] = useState(normalisedValue)

  const inputProps = {
    ...props,
    onBlur: () => setLocalValue(normalisedValue()),
    value: localValue,
    onChange: updated => {
      setLocalValue(updated)
      const list = updated
        ? updated
            .split(',')
            .filter(v => !!v) // strip out any nulls
            .map(v => v.trim())
            .filter(v => !!v)
        : []
      onChange(list.length ? list : null)
    }
  } as InputProps

  return <TextInput {...inputProps} />
}

interface InputWithHelpProps {
  renderInput: (helpButton: React.ReactNode) => React.ReactNode
  help: React.ReactNode
  iconVariant?: Variant
  maxWidth?: string
  margin?: string
}
export const InputWithHelp = ({
  renderInput,
  help,
  iconVariant,
  maxWidth,
  margin
}: InputWithHelpProps) => {
  const [visible, setVisible] = useState(false)
  return (
    <>
      {renderInput(
        <Tooltip isLight={false} position="left" content="View help">
          <IconButton
            size="large"
            variant={iconVariant ? iconVariant : 'color'}
            onClick={e => {
              e.preventDefault()
              setVisible(!visible)
            }}
            icon="info"
          ></IconButton>
        </Tooltip>
      )}
      <HelpText maxWidth={maxWidth} margin={margin} visible={visible}>
        {help}
      </HelpText>
    </>
  )
}

export const HelpText = styled.p<{
  maxWidth?: string
  error?: boolean
  visible?: boolean
  margin?: string
}>`
  max-width: ${p => (p.maxWidth ? p.maxWidth : '400px')};
  font-size: ${p => p.theme.fontSizeS};
  margin: ${p => (p.margin ? p.margin : '10px 0 0')};
  color: ${p => p.theme.grey50};
  ${props =>
    props.error &&
    css`
      color: ${props.theme.red};
    `}
  ${props =>
    !props.visible &&
    css`
      display: none;
    `}
`

export type FormProps = {
  error?: string | null | Error | ApolloError
  onSubmit: () => void
  onCancel?: () => void
  children: React.ReactNode
  submitText?: string
  cancelText?: string
  isValid?: boolean
  style?: React.CSSProperties
  renderButtons?:
    | ((
        isValid: boolean,
        saveText?: string,
        cancel?: () => void,
        cancelLabel?: string
      ) => React.ReactElement)
    | null
  overflow?: string
  loading?: boolean
}

export const Form = ({
  onSubmit,
  onCancel,
  children,
  error,
  style,
  loading = false,
  submitText = 'Save',
  cancelText = 'Cancel',
  isValid = true,
  renderButtons = (valid, saveText, cancel, cancelLabel) => (
    <>
      <Button type="submit" disabled={!valid} size="large">
        {saveText}
      </Button>
      {cancel && (
        <Button onClick={cancel} appearance="tint" size="large">
          {cancelLabel}
        </Button>
      )}
    </>
  )
}: FormProps) => {
  const formRef = useFocusFirstFormInput()

  const getFriendlyError = (err: Error) => {
    notify(err)
    return 'Something went wrong, please try again.'
  }

  const errorMessage = error
    ? error instanceof Error
      ? getFriendlyError(error)
      : error
    : null

  return (
    <form
      ref={formRef}
      onSubmit={e => {
        e.preventDefault()
        onSubmit()
      }}
      style={style}
    >
      {error ? <FormError>{errorMessage}</FormError> : null}
      {children}
      {renderButtons ? (
        <ButtonGroup>
          {renderButtons(isValid && !loading, submitText, onCancel, cancelText)}
        </ButtonGroup>
      ) : null}
    </form>
  )
}

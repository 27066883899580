import {css, createGlobalStyle} from 'styled-components'

function opacify(color: string, amount: number): string {
  color = color.replace(/rgb/g, 'rgba')
  color = color.substring(0, color.length - 1) + ',' + amount + ')'
  return color
}

function mix(color: string, mixer: string, amount: number): string {
  const i = parseInt,
    r = Math.round,
    P = 1 - amount,
    colorArray = color.split(','),
    a = colorArray[0],
    b = colorArray[1],
    c = colorArray[2],
    d = colorArray[3],
    mixerArray = mixer.split(','),
    e = mixerArray[0],
    f = mixerArray[1],
    g = mixerArray[2],
    h = mixerArray[3],
    x = d || h,
    d1 = x
      ? ',' +
        (!d
          ? h
          : !h
            ? d
            : r((parseFloat(d) * P + parseFloat(h) * amount) * 1000) / 1000 +
              ')')
      : ')'
  return (
    'rgb' +
    (x ? 'a(' : '(') +
    r(
      i(a[3] === 'a' ? a.slice(5) : a.slice(4)) * P +
        i(e[3] === 'a' ? e.slice(5) : e.slice(4)) * amount
    ) +
    ',' +
    r(i(b) * P + i(f) * amount) +
    ',' +
    r(i(c) * P + i(g) * amount) +
    d1
  )
}

function darken(color: string, amount: number): string {
  return mix(color, 'rgba(0,0,0)', amount)
}

const dark = 'rgb(0,0,0)'
const light = 'rgb(255,255,255)'
const positive = 'rgb(0,130,60)'
const primary = 'rgb(30,110,250)'
const negative = 'rgb(220,50,70)'
const warning = 'rgb(250,140,0)'
const info = 'rgb(250,200,0)'

const brandMagenta = 'rgb(255,60,90)'
const brandPink = 'rgb(255,197,206)'
const brandNavy = 'rgb(0,20,60)'

export const defaultTheme = {
  // Base colors
  black: dark,
  white: light,
  magenta: brandMagenta,
  pink: brandPink,
  navy: brandNavy,

  // Greys
  grey02: opacify(dark, 0.02),
  grey05: opacify(dark, 0.05),
  grey10: opacify(dark, 0.1),
  grey15: opacify(dark, 0.15),
  grey20: opacify(dark, 0.2),
  grey35: opacify(dark, 0.35),
  grey50: opacify(dark, 0.5),
  grey65: opacify(dark, 0.65),
  grey80: opacify(dark, 0.8),
  grey90: opacify(dark, 0.9),
  grey100: opacify(dark, 1),

  // Greys
  white05: opacify(light, 0.05),
  white10: opacify(light, 0.1),
  white15: opacify(light, 0.15),
  white20: opacify(light, 0.2),
  white35: opacify(light, 0.35),
  white50: opacify(light, 0.5),
  white65: opacify(light, 0.65),
  white80: opacify(light, 0.8),
  white90: opacify(light, 0.9),
  white100: opacify(light, 1),

  // Green
  green: positive,
  greenLightest: opacify(positive, 0.1),
  greenLight: opacify(positive, 0.2),
  greenDark: darken(positive, 0.1),
  greenDarkest: darken(positive, 0.2),

  // Blue
  blue: primary,
  blueLightest: opacify(primary, 0.1),
  blueLight: opacify(primary, 0.2),
  blueDark: darken(primary, 0.1),
  blueDarkest: darken(primary, 0.2),

  // Navy
  navy10: opacify(brandNavy, 0.1),
  navy60: opacify(brandNavy, 0.6),

  // Red
  red: negative,
  redLightest: opacify(negative, 0.1),
  redLighter: opacify(negative, 0.15),
  redLight: opacify(negative, 0.2),
  redDark: darken(negative, 0.1),
  redDarkest: darken(negative, 0.2),

  // Orange
  orange: warning,
  orangeLightest: opacify(warning, 0.1),
  orangeLight: opacify(warning, 0.2),
  orangeDark: darken(warning, 0.1),
  orangeDarkest: darken(warning, 0.2),

  // Yellow
  yellow: info,
  yellowLightest: opacify(info, 0.1),
  yellowLight: opacify(info, 0.2),
  yellowDark: darken(info, 0.1),
  yellowDarkest: darken(info, 0.2),

  // Font size
  fontSizeXS: '12.5px',
  fontSizeS: '14.5px',
  fontSize: '16.5px',
  fontSizeL: '20px',
  fontSizeXL: '25px',
  fontSizeXXL: '30px',

  // Line height
  lineHeightXS: '15px',
  lineHeightS: '17px',
  lineHeight: '20px',
  lineHeightL: '25px',
  lineHeightXL: '30px',
  lineHeightXXL: '35px',
  lineHeightCompact: '20px',

  // Box shadow
  shadowBorder: '0px 0px 0px 1px ' + opacify(dark, 0.1),
  shadowDepth1: '0px 1px 5px ' + opacify(dark, 0.2),
  shadowDepth2: '0px 2px 10px ' + opacify(dark, 0.1),
  shadowDepth3: '0px 4px 20px ' + opacify(dark, 0.2),

  // Radius
  borderRadiusS: '3px',
  borderRadius: '5px',
  borderRadiusL: '10px',
  borderRadiusXL: '20px',
  borderRadiusPill: '999px',

  // Animations
  durationS: '0.1s',
  duration: '0.2s',
  durationL: '0.3s',

  // 2023 rework follows, these should be used going forward
  // https://www.figma.com/design/RNjdyuEKffDm9VSAQIxdUu/%E2%9A%9B%EF%B8%8F-Workbench?node-id=8298-5533&t=RawQgZOsNY71RVmi-4
  colors: {
    navy1: 'hsla(220, 32%, 29%, 1)',
    navy2: 'hsla(220, 13%, 91%, 1)',
    navy3: 'hsla(220, 13%, 87%, 1)',
    navy4: 'hsla(220, 13%, 82%, 1)',
    navy5: 'hsla(220, 13%, 73%, 1)',
    navy6: 'hsla(220, 13%, 65%, 1)',
    navy7: 'hsla(221, 13%, 56%, 1)',
    navy8: 'hsla(220, 15%, 47%, 1)',
    navy9: 'hsla(220, 21%, 38%, 1)',
    navy10: 'hsla(220, 32%, 29%, 1)',
    navy11: 'hsla(220, 51%, 21%, 1)',
    navy12: 'hsla(220, 100%, 12%, 1)',
    blue3: 'hsla(210, 100%, 96%, 1)',
    blue4: 'hsla(210, 100%, 94%, 1)',
    blue5: 'hsla(209, 96%, 90%, 1)',
    blue6: 'hsla(209, 82%, 85%, 1)',
    blue7: 'hsla(208, 78%, 77%, 1)',
    blue8: 'hsla(206, 82%, 65%, 1)',
    blue10: 'hsla(208, 100%, 47%, 1)',
    blue11: 'hsla(218, 96%, 55%, 1)',
    blue12: 'hsla(218, 79%, 49%, 1)',
    blue13: 'hsla(218, 79%, 44%, 1)',
    blue14: 'hsla(211, 100%, 27%, 1)',
    orange3: 'hsla(25, 100%, 95%, 1)',
    orange9: 'hsla(24, 94%, 50%, 1)',
    orange10: 'hsla(24, 100%, 39%, 1)',
    orange11: 'hsla(24, 100%, 37%, 1)',
    green10: 'hsla(132, 43%, 42%, 1)',
    green11: 'hsla(133, 50%, 32%, 1)',
    green12: 'hsla(133, 50%, 30%, 1)',
    grey2: 'hsla(210, 17%, 98%, 1)',
    grey3: 'hsla(210, 17%, 95%, 1)',
    grey4: 'hsla(210, 12%, 93%, 1)',
    greyA4: 'hsla(211, 93%, 11%, .08)',
    grey5: 'hsla(216, 11%, 91%, 1)',
    greyA5: 'hsla(215, 93%, 11%, .1)',
    grey6: 'hsla(206, 12%, 89%, 1)',
    grey7: 'hsla(210, 11%, 86%, 1)',
    grey8: 'hsla(205, 11%, 78%, 1)',
    grey10: 'hsla(206, 6%, 52%, 1)',
    grey11: 'hsla(206, 6%, 44%, 1)',
    grey12: 'hsla(0, 0%, 0%, 1)',
    red9: 'hsla(358, 75%, 59%, 1)',
    red10: 'hsla(353, 71%, 53%, 1)',
    red11: 'hsla(353, 63%, 43%, 1)',
    red12: 'hsla(358, 86%, 30%, 1)',
    plum4: 'hsla(300, 63%, 94%, 1)',
    plum5: 'hsla(298, 55%, 90%, 1)',
    plum10: 'hsla(292, 50%, 47%, 1)',
    plum12: 'hsla(292, 100%, 26%, 1)',
    lime4: 'hsla(84, 75%, 87%, 1)',
    lime5: 'hsla(85, 72%, 82%, 1)',
    lime10: 'hsla(75, 81%, 27%, 1)'
  },
  fontWeights: {
    // TODO rename bold to semiBold & rename newBold (700) as bold.
    newBold: 700,
    bold: 600,
    medium: 500,
    regular: 400
  },
  fontSizes: {
    xsmall: '12px',
    small: '13px',
    medium: '14px',
    semiLarge: '15px',
    large: '16px'
  },
  lineHeights: {
    xsmall: '16px',
    small: '16px',
    medium: '20px',
    large: '20px'
  },
  letterSpacing: {
    xsmall: '-0.24px',
    small: '-0.26px',
    medium: '-0.28px',
    large: '-0.32px',
    xlarge: '-0.48px'
  },
  border: {
    radius: '4px'
  }
} as const

export type ThemeColors = keyof typeof defaultTheme.colors

export type ThemeType = typeof defaultTheme

// based on docs here https://styled-components.com/docs/api#create-a-declarations-file
declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends ThemeType {}
}

// Convert the theme into CSS variables that match figma
export const GlobalStyle = createGlobalStyle`
  :root {
    ${Object.entries(defaultTheme.colors).map(
      // names these inline with figma, ie. grey10 becomes --color-grey-10
      ([name, value]) => css`--color-${name.replace(/(\d)/, '-$1')}: ${value};
      `
    )}
  }
`

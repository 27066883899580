import styled from 'styled-components'
import React from 'react'
import SmallSearchIcon from '../../assets/icons/search-small.svg'
import {useFocusFirstFormInput} from '../../lib/hooks'

const SearchBox = styled.form`
  border: 1px solid ${({theme}) => theme.grey10};
  border-radius: 4px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 6px 16px 16px 12px;
`

const StyledSearchIcon = styled(SmallSearchIcon)`
  color: ${({theme}) => theme.black};
`

const SearchInput = styled.input`
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  flex-grow: 1;
  font-size: ${({theme}) => theme.fontSizeXS};
`

export const EnvironmentFilter = ({
  onChange,
  value
}: {
  onChange: (text: string) => void
  value: string
}) => {
  const ref = useFocusFirstFormInput()
  return (
    <SearchBox onSubmit={e => e.preventDefault()} ref={ref}>
      <StyledSearchIcon />
      <SearchInput
        id="search-environment"
        onChange={e => onChange(e.target.value)}
        placeholder="Search..."
        type="search"
        value={value}
      />
    </SearchBox>
  )
}

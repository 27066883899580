import {useEffect, useState, useCallback, useRef, useMemo} from 'react'
import {getApi, RequestMethod} from './fetch'
import {APINode, ResponseError} from '../common/types'
import {getToken} from './api'

interface ListResponse<T> {
  data: T[]
}

type Options = {
  method?: RequestMethod
  variables?: Record<string, unknown>
  headers?: Record<string, string>
  authIsOptional?: boolean // allow unauthenticated requests
}

export const useListLazy = <DataType extends APINode>(
  url: string,
  organisationId?: string,
  options?: Options
): [
  () => Promise<void>,
  {
    list: DataType[]
    errors: ResponseError[] | undefined
    loading: boolean
  }
] => {
  const [loading, setLoading] = useState<boolean>(false)
  const [list, setList] = useState<DataType[]>([])
  const [errors, setErrors] = useState<ResponseError[]>()
  const headers = options?.headers
  const authIsOptional = options?.authIsOptional

  const fetch = useCallback(
    async (abortController?: AbortController) => {
      setLoading(true)
      setErrors(undefined)
      try {
        const {json, ok, status, body} = await getApi({
          organisationId,
          getToken,
          authIsOptional
        })('get', url, undefined, headers, abortController).finally(() =>
          setLoading(false)
        )
        if (ok) {
          if (json) {
            const res = json as ListResponse<DataType>
            setList(res.data)
          }
        } else {
          if (json) {
            const res = json as Record<string, unknown>
            if ('errors' in res) {
              setErrors(res.errors as ResponseError[])
            }
            if ('error' in res) {
              setErrors([res.error] as ResponseError[])
            }
          }
          if (status > 399) {
            setErrors([
              {
                status,
                title: 'Request failed',
                detail: body
              }
            ])
          }
        }
      } catch (error) {
        setLoading(false)
        const err =
          typeof error === 'string' ? new Error(error) : (error as Error)
        setErrors([
          {
            status: 500,
            title: err.message,
            detail: err.message
          }
        ])
      }
    },
    [organisationId, authIsOptional, url, headers]
  )

  const data = useMemo(() => {
    return {list, errors, loading}
  }, [list, errors, loading])

  return [fetch, data]
}

export const useList = <DataType extends APINode>(
  url: string,
  organisationId?: string,
  options?: Options
) => {
  const [ready, setReady] = useState(false)
  const [fetch, data] = useListLazy<DataType>(url, organisationId, options)

  useEffect(() => {
    fetch().finally(() => setReady(true))
  }, [fetch])

  const res = useMemo(() => {
    return {...data, loading: data.loading || !ready, refetch: fetch}
  }, [data, ready, fetch])

  return res
}

export const useNodeLazy = <DataType extends APINode>(
  url: string,
  organisationId?: string,
  options?: Options
) => {
  const [node, setNode] = useState<DataType>()
  const [errors, setErrors] = useState<ResponseError[]>()
  const [loading, setLoading] = useState<Record<number, boolean>>()
  const [saving, setSaving] = useState<boolean>(false)
  const headers = options?.headers
  const authIsOptional = options?.authIsOptional

  const setJSONErrors = (res: {body: string; json: unknown}) => {
    if (res.json && typeof res.json === 'object') {
      const json = res.json as Record<string, unknown>
      if ('errors' in json) {
        setErrors(json.errors as ResponseError[])
      }
      if ('error' in json) {
        setErrors([json.error] as ResponseError[])
      }
    } else {
      setErrors([res.body] as unknown as ResponseError[])
    }
  }

  const api = useMemo(
    () => getApi({organisationId, getToken, authIsOptional}),
    [authIsOptional, organisationId]
  )

  const save = useCallback(
    async ({
      node,
      onCompleted,
      abortController
    }: {
      node: DataType
      onCompleted?: (res: unknown) => void
      abortController?: AbortController
    }) => {
      setErrors(undefined)
      setSaving(true)
      try {
        const res = await api(
          node.id ? 'put' : 'post',
          url,
          node,
          headers,
          abortController
        ).finally(() => setSaving(false))
        if (res.ok) {
          if (onCompleted) {
            onCompleted(res.json)
          }
        } else {
          setJSONErrors(res)
        }
      } catch (error) {
        setSaving(false)
        const err =
          typeof error === 'string' ? new Error(error) : (error as Error)
        setErrors([
          {
            status: 500,
            title: err.message,
            detail: err.message
          }
        ])
      }
    },
    [api, url, headers]
  )

  const method = options?.method ?? 'get'
  const variables = useRef(options?.variables)
  const requestIdRef = useRef(0)

  const fetch = useCallback(
    async (
      params?: string | Record<string, unknown>,
      abortController?: AbortController,
      ignoreErrorStrings?: string[]
    ): Promise<DataType | void> => {
      const requestId = (requestIdRef.current += 1)
      setErrors(undefined)
      setLoading({[requestId]: true})
      // 👆 If you make request A and abort it, then make request B
      // request A's finally can be called after request B has started
      // this causes the loading state to be set to false when it should be true
      try {
        const res = await api(
          method,
          `${url}${typeof params === 'string' ? params : ''}`,
          {
            ...(variables.current ? variables.current : {}),
            ...(params && typeof params === 'object' ? params : {})
          },
          headers,
          abortController
        ).finally(() => {
          setLoading(s => (s && requestId in s ? {[requestId]: false} : s))
          // See note above. Abort controller triggered finally can come in late after another request has started
        })
        if (res.ok) {
          if (res.json) {
            const {data}: {data: DataType} = res.json as {data: DataType}
            setNode(data)
            return data
          }
        } else {
          setJSONErrors(res)
        }
      } catch (error) {
        setLoading(s => (s && requestId in s ? {[requestId]: false} : s))
        const err =
          typeof error === 'string' ? new Error(error) : (error as Error)
        if (
          [
            ...(ignoreErrorStrings ?? []),
            'The user aborted a request.'
          ].includes(err.message)
        ) {
          return
        }
        setErrors([
          {
            status: 500,
            title: err.message,
            detail: err.message
          }
        ])
      }
    },
    [url, method, headers, api]
  )

  const currentRequestLoading = loading?.[requestIdRef.current]

  return [
    fetch,
    {
      node,
      loading: currentRequestLoading || saving,
      save,
      saving,
      errors
    }
  ] as const
}

export const useNode = <DataType extends APINode>(
  url: string,
  organisationId?: string,
  options?: Options
) => {
  const [ready, setReady] = useState(false)
  const [fetch, data] = useNodeLazy<DataType>(url, organisationId, options)
  const variables = useRef(options?.variables)
  useEffect(() => {
    fetch(variables.current).finally(() => setReady(true))
  }, [fetch])

  const res = useMemo(() => {
    return {...data, loading: data.loading || !ready, refetch: fetch}
  }, [data, ready, fetch])

  return res
}

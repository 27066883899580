import React from 'react'
import styled, {css} from 'styled-components'
import {Icon, IconName} from '../icon/icon'

export const DropdownEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  text-align: center;
`

export const DropdownEmptyTitle = styled.div`
  font-size: ${({theme}) => theme.fontSizeL};
  line-height: ${({theme}) => theme.lineHeightL};
  margin-top: 10px;
`

export const DropdownEmptyMessage = styled.div`
  font-size: ${({theme}) => theme.fontSize};
  line-height: ${({theme}) => theme.lineHeight};
  color: ${({theme}) => theme.grey50};
  margin-top: 10px;
`

export const DropdownHeader = styled.header`
  padding: 10px 0;
  border-bottom: 1px solid ${p => p.theme.grey10};
  > h4 {
    padding: 10px 20px;
    margin: 0;
  }
`
export const DropdownFooter = styled.footer`
  padding: 10px 0;
`

export const DropdownPinnedAction = styled.footer`
  border-top: 1px solid ${p => p.theme.grey10};
  padding-top: 10px;
  margin-top: 10px;
`

export const DropdownBody = styled.div`
  padding: 10px 0;
  + footer {
    border-top: 1px solid ${p => p.theme.grey10};
  }
`
const StyledDropdownIcon = styled.span<{variant: string}>`
  width: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  svg {
    flex: none;
    color: ${p => (p.variant === 'color' ? p.theme.blue : p.theme.grey50)};
  }
`

interface DropdownItemIconProps {
  icon?: IconName | React.ReactNode
  variant?: 'greyscale' | 'color'
  className?: string
}

export const DropdownItemIcon = ({
  variant = 'greyscale',
  icon,
  className
}: DropdownItemIconProps) => {
  return (
    <StyledDropdownIcon className={className} variant={variant}>
      {typeof icon === 'string' ? <Icon name={icon as IconName} /> : icon}
    </StyledDropdownIcon>
  )
}

export const DropdownSectionTitle = styled.div`
  padding: 20px 20px 5px 20px;
  font-weight: bold;
`

export const DropdownSection = styled.div`
  &:not(:first-of-type) {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid ${p => p.theme.grey10};
    ${DropdownSectionTitle} {
    }
  }
`

export interface ItemProps {
  padded?: boolean
  checkboxRow?: boolean
  switchRow?: boolean
  minWidth?: number
  isAction?: boolean
}

interface BasicItemProps extends ItemProps {
  children: React.ReactNode
  style?: React.CSSProperties
  disabled?: boolean
  title?: string
}

export const StyledDropdownItem = styled.div<BasicItemProps>`
  ${props =>
    props.isAction &&
    css`
      color: ${p => p.theme.blue};
    `}
  /* padding is optional */
  ${props =>
    props.padded &&
    css`
      padding: 10px 20px;
    `}
  ${({disabled, switchRow, checkboxRow, theme}) =>
    (switchRow || checkboxRow) &&
    css`
      label {
        padding: 5px 20px;
        display: flex;
        ${switchRow &&
        css`
          justify-content: space-between;
        `}
      }
      ${!disabled &&
      css`
        &:hover,
        &:focus {
          background: ${theme.grey05};
        }
        &:active {
          background: ${theme.grey10};
        }
      `}
    `}
    ${({disabled, switchRow, checkboxRow}) =>
    disabled &&
    !switchRow &&
    !checkboxRow &&
    css`
      opacity: 0.2;
      cursor: not-allowed;
      a {
        pointer-events: none;
      }
    `}
  a, span.item {
    outline: none;
    display: flex;
    padding: 10px 20px;
    min-height: 40px;
    ${({disabled, theme}) =>
      !disabled &&
      css`
        &:hover,
        &:focus {
          background: ${theme.grey05};
        }
        &:active {
          background: ${theme.grey10};
        }
      `}
  }
`

export const DropdownItem = ({children, ...props}: BasicItemProps) => {
  return <StyledDropdownItem {...props}>{children}</StyledDropdownItem>
}

import React, {Component, createRef, ReactNode} from 'react'
import styled, {css} from 'styled-components'
import {Icon, IconName} from '../icon/icon'

interface StyledFieldsProps {
  up: boolean
  padding?: string
}

interface StyledInlineLabelProps {
  align?: 'center' | 'left' | 'right'
}

interface StyledInputProps extends StyledFieldsProps {
  isFocused: boolean
}

interface StyledTextInputProps {
  appearance: 'primary'
  disabled: boolean
  error?: boolean
  align?: 'center' | 'left' | 'right'
}

const inputClass = css`
  box-sizing: border-box;
  font-family:
    atomic___Inter,
    -apple-system,
    BlinkMacSystemFont,
    Roboto,
    Arial,
    san-serif;
  font-size: ${props => props.theme.fontSize};

  height: auto;
  width: 100%;
  display: flex;
  border-radius: 5px;
  transition: all 0.1s ease;
  cursor: text;
  color: ${props => props.theme.grey50};
  padding: 0px;
  &:focus-within,
  &:hover {
    background: ${props => props.theme.grey10};
  }
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active {
    transition: 'color 9999s ease-out, background-color 9999s ease-out';
    transition-delay: 9999s;
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }

  svg {
    color: ${props => props.theme.grey100};
  }

  input {
    &::-webkit-inner-spin-button {
      display: none;
    }
    // No longer present in Chrome 83
    &::-webkit-clear-button {
      transition: all 0.1s ease;
      -webkit-appearance: none;
      height: 40px;
      width: 40px;
      border-radius: ${p => p.theme.borderRadiusPill};
      padding: 0;
      margin-top: -20px;
      margin-right: 10px;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 6L6 14M14 14L6 6' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
      cursor: pointer;
      &:hover,
      &:focus {
        background-color: ${p => p.theme.grey05};
      }
      &:active {
        background-color: ${p => p.theme.grey10};
      }
    }
    &::-webkit-calendar-picker-indicator {
      transition: all 0.1s ease;
      -webkit-appearance: none;
      height: 40px;
      width: 40px;
      border-radius: ${p => p.theme.borderRadiusPill};
      padding: 0;
      margin-top: -20px;
      margin-right: -10px;
      color: transparent;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 3.5C7 2.94772 6.55228 2.5 6 2.5C5.44772 2.5 5 2.94772 5 3.5H7ZM15 3.5C15 2.94772 14.5523 2.5 14 2.5C13.4477 2.5 13 2.94772 13 3.5H15ZM5 6H15V4H5V6ZM15 6V16H17V6H15ZM15 16H5V18H15V16ZM5 16V6H3V16H5ZM4 9H16V7H4V9ZM7 5V3.5H5V5H7ZM15 5V3.5H13V5H15ZM5 16H5H3C3 17.1046 3.89543 18 5 18V16ZM15 16V18C16.1046 18 17 17.1046 17 16H15ZM15 6H17C17 4.89543 16.1046 4 15 4V6ZM5 4C3.89543 4 3 4.89543 3 6H5V6V4Z' fill='black'/%3E%3Ccircle cx='12.5' cy='13.5' r='1.5' fill='black'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
      cursor: pointer;
      &:hover,
      &:focus {
        background-color: ${p => p.theme.grey05};
      }
      &:active {
        background-color: ${p => p.theme.grey10};
      }
    }
  }
`

const Primary = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  overflow: hidden;
`
const iconShared = css`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SytledMediaLeft = styled.div`
  order: -1;
  z-index: 1;
  svg {
    color: ${p => p.theme.grey100};
  }
  ${iconShared}
`

const SytledMediaRight = styled.div`
  order: 1;
  z-index: 1;
  svg {
    color: ${p => p.theme.grey100};
  }
  ${iconShared}
`

export const StyledInlineLabel = styled.div<StyledInlineLabelProps>`
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-size: ${props => props.theme.fontSize};
  line-height: ${props => props.theme.lineHeight};
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 0.1s ease;
  position: absolute;
  pointer-events: none;
  padding: 0 15px;

  ${p => p.align && 'justify-content: flex-end;'}

  ${p => {
    switch (p.align) {
      case 'right':
        return 'text-align: right;'
      case 'center':
        return 'text-align: center;'
      default:
        return 'text-align: left;'
    }
  }}
`

const StyledInput = styled.input<StyledInputProps>`
  width: 100%;
  font-size: inherit;
  border: transparent;
  color: ${props =>
    // hide the datetime-local native ui until it has a value or is focused
    // otherwise it interferes with our labels
    props.type === 'datetime-local' && !props.isFocused && !props.value
      ? 'transparent'
      : props.theme.black};
  border-radius: none;
  outline: none;
  background-color: inherit;
  transition: all 0.1s ease;
  line-height: ${props => props.theme.lineHeight};
  padding: 20px 20px 10px;

  :not(:placeholder-shown) {
    padding-top: 30px;
  }
  &:focus {
    &::placeholder {
      padding-top: 0px;
      color: red;
    }
  }
  min-height: 60px;
  &::placeholder {
    transition: all 0.1s ease;
    white-space: nowrap;
    height: 20px;
  }

  ${props =>
    props.up &&
    css`
      &::placeholder {
        padding-top: 5px;
      }
      opacity: 1;
      padding-top: 30px;
    `}
  ${props =>
    props.readOnly &&
    css`
      border: 1px solid ${props.theme.grey20};
      background: white;
      border-radius: ${props.theme.borderRadius};
    `}
`

export const StyledLabel = styled.div<StyledFieldsProps>`
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-size: ${props => props.theme.fontSize};
  line-height: ${props => props.theme.lineHeight};
  top: 0;
  padding: ${props => props.padding ?? '20px 20px 0'};
  display: flex;
  align-items: center;
  transition: all 0.1s ease;
  position: absolute;
  pointer-events: none;

  ${props =>
    props.up &&
    css`
      padding-top: 10px;
      font-size: ${p => p.theme.fontSizeS};
    `}

  ${SytledMediaLeft} ~ div {
    margin-left: 0;

    ${StyledInput} {
      padding: 30px 20px 10px 0px;
    }
  }

  ${SytledMediaRight} ~ div {
    margin-right: 0;
    ${StyledInput} {
      padding: 30px 0px 10px 20px;
    }
  }

  ${SytledMediaLeft} + ${SytledMediaRight} ~ div {
    ${StyledInput} {
      padding: 30px 0px 10px 0px;
    }
  }
`

const StyledTextInput = styled.div<StyledTextInputProps>`
  ${inputClass}

  overflow: hidden;

  ${SytledMediaLeft} ~ div {
    margin-left: -60px;

    ${StyledInput} {
      padding: 30px 20px 10px 60px;
    }
    ${StyledLabel} {
      padding-left: 60px;
    }
  }

  ${SytledMediaRight} ~ div {
    margin-right: -60px;

    ${StyledInput} {
      padding: 30px 60px 10px 20px;
    }
    ${StyledLabel} {
      padding-right: 60px;
    }
  }

  ${SytledMediaLeft} + ${SytledMediaRight} ~ div {
    margin-right: 0;

    ${StyledInput} {
      padding: 30px 60px 10px 60px;
    }
    ${StyledLabel} {
      padding-right: 60px;
      padding-left: 60px;
    }
  }

  ${props =>
    props.error
      ? css`
          box-shadow: inset 0px 0px 0px 2px ${({theme}) => theme.colors.red10};
        `
      : ''}

  background: ${p => p.theme.grey05};
  &:focus-within,
  &:hover {
    background: ${p => p.theme.grey10};
  }
  &:active {
    background: ${p => p.theme.grey15};
  }

  ${SytledMediaLeft},
  ${SytledMediaRight} {
    width: 60px;
    height: 60px;
  }
`

const StyledInlineTextInput = styled.div<StyledTextInputProps>`
  ${inputClass}
  ${StyledInput} {
    padding-left: 15px;
    padding-right: 15px;
    :not(:placeholder-shown) {
      padding-top: 10px;
    }
    min-height: 40px;
    &::placeholder {
      padding: 0;
    }
    padding-top: 5px;
    &::placeholder {
      padding-top: 0px;
    }
    ${p => {
      switch (p.align) {
        case 'right':
          return 'text-align: right;'
        case 'center':
          return 'text-align: center;'
        default:
          return 'text-align: left;'
      }
    }}
  }
  ${SytledMediaLeft} ~ div {
    ${StyledInput} {
      padding: 10px 15px 10px 0px;
    }
    ${StyledInlineLabel} {
      padding-left: 0px;
    }
  }

  ${SytledMediaRight} ~ div {
    ${StyledInput} {
      padding: 10px 0px 10px 15px;
    }
    ${StyledInlineLabel} {
      padding-right: 0px;
    }
  }

  ${SytledMediaLeft} + ${SytledMediaRight} ~ div {
    ${StyledInput} {
      padding: 10px 0px 10px 0px;
    }
    ${StyledInlineLabel} {
      padding-right: 0px;
      padding-left: 0px;
    }
  }

  box-sizing: border-box;

  ${props =>
    props.error
      ? css`
          box-shadow: inset 0px 0px 0px 2px ${({theme}) => theme.colors.red10};
        `
      : ''}

  background: ${p => p.theme.grey05};
  &:focus-within,
  &:hover {
    background: ${p => p.theme.grey10};
  }
  &:active {
    background: ${p => p.theme.grey15};
  }

  ${SytledMediaLeft},
  ${SytledMediaRight} {
    width: 40px;
    height: 40px;
  }
`

interface InputState {
  isFocused: boolean
}

interface BaseInputProps {
  name?: string
  appearance?: 'primary'
  type?: string
  value?: string
  label?: string
  upLabel?: string
  iconLeft?: IconName
  iconRight?: IconName
  buttonLeft?: React.ReactNode
  buttonRight?: React.ReactNode
  disabled?: boolean
  required?: boolean
  readOnly?: boolean
  onChange: (value: string, name: string) => void
  onBlur?: (value: string, name: string) => void
  inline?: boolean
  style?: React.CSSProperties
  pattern?: RegExp
  tabIndex?: number
  autoComplete?: 'new-password' | 'one-time-code' | 'off'
  min?: string
  max?: string
  step?: string
  error?: boolean
  align?: 'left' | 'right' | 'center'
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

interface InputWithPlaceholderProps extends BaseInputProps {
  label?: string
}

interface InputWithLabelProps extends BaseInputProps {
  upLabel?: string
}

export type InputProps = InputWithPlaceholderProps | InputWithLabelProps

export class TextInput extends Component<InputProps, InputState> {
  constructor(props: InputProps) {
    super(props)
    this.state = {isFocused: false}
    this.id = String(Math.random())
  }

  private id: string
  private inputRef = createRef<HTMLInputElement>()

  private handleFocus = () => {
    this.setState({isFocused: true})
  }

  private handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    this.setState({isFocused: false})
    if (this.props.onBlur) {
      this.props.onBlur(e.target.value, e.target.name)
    }
  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.props.pattern) {
      return this.props.onChange(e.target.value, e.target.name)
    }

    if (e.target.value.match(this.props.pattern)) {
      return this.props.onChange(e.target.value, e.target.name)
    }
  }

  private handleClick = () => {
    const node = this.inputRef.current

    if (node && this.props.readOnly) {
      node.focus()
      node.select()
    }
  }

  private getIconLeft = (iconLeft: IconName) => {
    return (
      <SytledMediaLeft>
        <Icon name={iconLeft} />
      </SytledMediaLeft>
    )
  }

  private getIconRight = (iconRight: IconName) => {
    return (
      <SytledMediaRight>
        <Icon name={iconRight} />
      </SytledMediaRight>
    )
  }

  private getButtonLeft = (buttonLeft: ReactNode) => {
    return <SytledMediaLeft>{buttonLeft}</SytledMediaLeft>
  }

  private getButtonRight = (buttonRight: ReactNode) => {
    return <SytledMediaRight>{buttonRight}</SytledMediaRight>
  }

  public componentDidMount() {
    // hack to account for chrome autofilling the input but not telling us
    setTimeout(() => {
      const el = document.getElementById(this.id)
      if (el) {
        let match = false
        try {
          // may throw if the pseudo-selector isn't supported
          match = el.matches('*:-webkit-autofill')
        } catch (_) {
          match = false
        } finally {
          if (match) {
            this.setState({isFocused: true})
          }
        }
      }
    }, 200)
  }

  public render() {
    const {
      type = 'text',
      appearance = 'primary',
      name,
      value,
      label,
      upLabel,
      iconLeft = '',
      iconRight = '',
      buttonLeft,
      buttonRight,
      inline,
      style,
      tabIndex = 0,
      autoComplete,
      align,
      ...props
    } = this.props
    const id = this.id
    const pattern = props.pattern ? String(props.pattern) : undefined

    return inline ? (
      <StyledInlineTextInput
        error={props.error}
        align={align}
        style={style}
        disabled={!!props.disabled || !!props.readOnly || false}
        appearance={appearance}
        onClick={() => this.handleClick()}
      >
        {iconLeft ? this.getIconLeft(iconLeft) : null}
        {iconRight ? this.getIconRight(iconRight) : null}
        {buttonLeft ? this.getButtonLeft(buttonLeft) : null}
        {buttonRight ? this.getButtonRight(buttonRight) : null}
        <Primary>
          <StyledInlineLabel align={align}>
            {value === ''
              ? this.state.isFocused || props.readOnly
                ? upLabel
                  ? upLabel
                  : label
                : label
              : ''}
          </StyledInlineLabel>
          <StyledInput
            tabIndex={tabIndex}
            type={type}
            name={name}
            id={id}
            value={value}
            isFocused={this.state.isFocused}
            onFocus={this.handleFocus}
            up={false}
            ref={this.inputRef}
            {...props}
            pattern={pattern}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            autoComplete={autoComplete}
          />
        </Primary>
      </StyledInlineTextInput>
    ) : (
      <StyledTextInput
        error={props.error}
        style={style}
        disabled={!!props.disabled || !!props.readOnly || false}
        appearance={appearance}
        onClick={() => this.handleClick()}
      >
        {iconLeft ? this.getIconLeft(iconLeft) : null}
        {iconRight ? this.getIconRight(iconRight) : null}
        {buttonLeft ? this.getButtonLeft(buttonLeft) : null}
        {buttonRight ? this.getButtonRight(buttonRight) : null}
        <Primary>
          <StyledLabel up={!!this.state.isFocused || value !== ''}>
            {this.state.isFocused || props.readOnly || value !== ''
              ? upLabel
                ? upLabel
                : label
              : label}
          </StyledLabel>
          <StyledInput
            tabIndex={tabIndex}
            type={type}
            name={name}
            id={id}
            value={value}
            isFocused={this.state.isFocused}
            onFocus={this.handleFocus}
            up={this.state.isFocused || !!value}
            ref={this.inputRef}
            {...props}
            pattern={pattern}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            autoComplete={autoComplete}
          />
        </Primary>
      </StyledTextInput>
    )
  }
}

import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useMeasure} from 'react-use'

export type Position = {
  top: string
  left: string
  height?: string
  width?: string
  originalWidth?: number // width of the dropdown, not the trigger - existing code will return the trigger width if the dropdown is smaller
}

const useDropdownPosition = <E extends HTMLElement>(
  triggerElement: React.RefObject<E>,
  dropdownActive: boolean,
  closeDropdown?: () => void,
  forcedDropdownDirection?: 'up' | 'down'
) => {
  const [dropdownRef, size] = useMeasure<E>()
  const [triggerPosition, setTriggerPosition] = useState(new DOMRect())
  const [triggerWidth, setTriggerWidth] = useState(0)
  const [viewport, setViewport] = useState({height: 0, width: 0})
  const scrollRequestId = useRef<number | null>(null)

  const triggerOffset = 5
  const viewportOffset = 10

  const calculatePosition = useCallback(() => {
    if (triggerElement.current) {
      const triggerRect = triggerElement.current.getBoundingClientRect()
      setTriggerPosition(triggerRect)
      setTriggerWidth(triggerRect.width)
      setViewport({height: window.innerHeight, width: window.innerWidth})
    }
    // triggerElement is a ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (dropdownActive) {
      calculatePosition()
    }
  }, [dropdownActive, calculatePosition])

  useEffect(() => {
    const keyListen = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && e.target === triggerElement.current) {
        e.preventDefault()
        closeDropdown?.()
      }
    }

    const onResize = () => {
      calculatePosition()
    }

    const onScroll = () => {
      const current = triggerElement.current
      if (current && !scrollRequestId.current) {
        scrollRequestId.current = requestAnimationFrame(() => {
          setTriggerPosition(current.getBoundingClientRect())
          scrollRequestId.current = null
        })
      }
    }

    document.addEventListener('keydown', keyListen)
    window.addEventListener('resize', onResize)
    window.addEventListener('scroll', onScroll)

    calculatePosition()

    return () => {
      document.removeEventListener('keydown', keyListen)
      window.removeEventListener('resize', onResize)
      window.removeEventListener('scroll', onScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatePosition, closeDropdown])

  const dropdownPosition = useMemo<Position>(() => {
    if (dropdownActive && size.height !== 0) {
      let top = 0
      let left = 0

      const availableWidthRight =
        viewport.width - (triggerPosition.left + 2 * viewportOffset)

      const availableHeightBelow =
        viewport.height -
        (triggerPosition.bottom + triggerOffset + viewportOffset)

      const availableHeightAbove =
        triggerPosition.top - (triggerOffset + viewportOffset)

      const height = Math.max(availableHeightBelow, availableHeightAbove)

      if (availableWidthRight >= size.width) {
        left = triggerPosition.left
      } else {
        left = triggerPosition.right - size.width
      }

      if (forcedDropdownDirection === 'down') {
        top = triggerPosition.bottom + triggerOffset + window.scrollY
      } else if (forcedDropdownDirection === 'up') {
        top =
          triggerPosition.top -
          (size.height + 2 * triggerOffset) +
          window.scrollY
      } else {
        if (availableHeightBelow >= height) {
          top = triggerPosition.bottom + triggerOffset + window.scrollY
        } else {
          top =
            triggerPosition.top -
            Math.min(size.height + 2 * triggerOffset, triggerPosition.top) +
            window.scrollY
        }
      }

      // Set the width of the element to the width of the trigger if the trigger is wider than the dropdown
      const width = triggerWidth >= size.width ? triggerWidth : undefined

      return {
        top: top + 'px',
        left: left + 'px',
        height: height + 'px',
        width: width ? width + 'px' : undefined,
        originalWidth: size.width
      }
    } else {
      // default render offscreen until we have measurements
      return {top: '0px', left: '-10000px', height: undefined}
    }
  }, [
    dropdownActive,
    forcedDropdownDirection,
    size.height,
    size.width,
    triggerPosition.bottom,
    triggerPosition.left,
    triggerPosition.right,
    triggerPosition.top,
    triggerWidth,
    viewport.height,
    viewport.width
  ])

  return [
    //@ts-ignore - useMeasure gives a reference to attach to an element, this appears to be the correct way to type that manually
    dropdownRef as React.MutableRefObject<E | null>,
    dropdownPosition
  ] as const
}

export default useDropdownPosition

import {generatePath, matchPath} from 'react-router'

/** { somewild: 'newValue' } */
type Overrides = Record<string, string>
/** [ /fromPath/:somewild , /toPath/:somewild ] */
type Routings = [string, string][]

type RewritePath = (
  routings: Routings,
  overrides: Overrides,
  fallback: string
) => (path: string) => string

export const rewritePath: RewritePath =
  (routings, overrides, fallback) => path => {
    const reroute = (from: string, to: string) => (pathName: string) => {
      const match = matchPath(from, pathName)
      if (!match) return undefined
      const {params} = match
      const gen = generatePath(to, {...params, ...overrides})
      return gen
    }

    return (
      routings.reduce((matched: string | undefined, [from, to]) => {
        if (matched) return matched
        return reroute(from, to)(path)
      }, undefined) ?? fallback
    )
  }

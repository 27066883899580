import React, {ReactNode, useRef} from 'react'
import {Icon, IconName} from '../icon/icon'
import * as LegacyStyles from './legacy-styles'
import * as Styles from './styles'

export type ToggleOptions<T> = {
  value: T
  text: ReactNode
  icon?: string
  iconSelected?: string
}[]

interface ToggleProps<T> {
  onChange: (value: T) => void
  value: T
  label?: ReactNode
  labelPosition?: 'left' | 'right'
  options: ToggleOptions<T>
  style?: React.CSSProperties
  lozengeStyle?: React.CSSProperties
  tabIndex?: number
  isIcon?: boolean
  isSmall?: boolean
  readOnly?: boolean
  legacyMode?: boolean
}

export const Toggle = <T extends string | number>({
  onChange,
  value,
  label,
  labelPosition = 'right',
  options,
  isIcon,
  tabIndex,
  isSmall,
  readOnly,
  legacyMode,
  lozengeStyle,
  ...props
}: ToggleProps<T>) => {
  const idRef = useRef('id-' + Math.random())
  const index = options.findIndex(opt => opt.value === value)

  const StyledContainer = legacyMode
    ? LegacyStyles.StyledContainer
    : Styles.StyledContainer
  const StyledToggle = legacyMode
    ? LegacyStyles.StyledToggle
    : Styles.StyledToggle
  const Inner = legacyMode ? LegacyStyles.Inner : Styles.Inner
  const Label = legacyMode ? LegacyStyles.Label : Styles.Label
  const Option = legacyMode ? LegacyStyles.Option : Styles.Option
  const Lozenge = legacyMode ? LegacyStyles.Lozenge : Styles.Lozenge

  const iconStyles = {marginRight: '8px'}

  return (
    <StyledContainer>
      {label && labelPosition === 'left' ? <Label>{label}</Label> : null}
      <StyledToggle
        isIcon={isIcon}
        isWithLabel={!!label}
        readOnly={readOnly}
        {...props}
      >
        <Inner
          isSmall={isSmall}
          count={options.length}
          index={index !== -1 ? index : 0}
          readOnly={readOnly}
        >
          {options.map(({value: val, text, icon, iconSelected}) => (
            <Option isSmall={isSmall} key={val}>
              {icon && val !== value ? (
                <Icon name={icon as IconName} style={iconStyles} />
              ) : null}
              {iconSelected && val === value ? (
                <Icon name={iconSelected as IconName} style={iconStyles} />
              ) : null}
              {isIcon ? <Icon name={text as IconName} /> : text}
              <input
                data-testid={`toggle-${val}`}
                tabIndex={tabIndex}
                type="radio"
                disabled={readOnly}
                name={idRef.current}
                value={val}
                checked={val === value}
                style={readOnly ? {pointerEvents: 'none'} : undefined}
                // use a closure rather than the DOM value to enable number
                // values as well as strings
                onChange={(
                  (v: T) => () =>
                    onChange(v)
                )(val)}
              />
            </Option>
          ))}
          <Lozenge style={lozengeStyle} />
        </Inner>
      </StyledToggle>
      {label && labelPosition === 'right' ? <Label>{label}</Label> : null}
    </StyledContainer>
  )
}

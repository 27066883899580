import React, {ReactNode} from 'react'
import {PageBody} from '../components/page-styles'
import {Navigate} from 'react-router-dom'
export class ErrorBoundary extends React.Component<{children: ReactNode}> {
  state = {hasError: false, home: false}

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {hasError: true}
  }

  reload = () => {
    this.setState({hasError: false})
  }

  home = () => {
    this.setState({home: true})
  }

  render() {
    if (this.state.home) {
      return <Navigate to="/" />
    }

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <PageBody>
          <p>
            Something went wrong. <a onClick={this.reload}>Retry</a> or{' '}
            <a onClick={this.home}>start again</a>
          </p>
        </PageBody>
      )
    }

    return this.props.children
  }
}

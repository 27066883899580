import React, {ReactNode, useCallback, useRef, useState} from 'react'
import {createPortal} from 'react-dom'
import styled, {keyframes} from 'styled-components'
import useDropdownPosition, {
  Position
} from '../../../hooks/use-dropdown-position'
import ClickAwayListener from '../../../lib/click-away-listener'
import {mergeRefs} from '../../../lib/helpers'
import {IconButton} from '../buttons/icon-button'
import {IconName} from '../icon/icon'

export type DropdownTrigger = (
  handleClick: () => void,
  ref: React.Ref<HTMLElement>,
  isOpen?: boolean,
  controlDropdown?: (openDropdown: boolean) => void
) => ReactNode

type DropdownChildren = (closeDropdown: () => void) => ReactNode

type Order = 'up' | 'down'

export type DropdownDirection = 'up' | 'down'
export interface DropdownProps {
  id?: string
  children: DropdownChildren
  trigger: DropdownTrigger
  minWidth?: number | false
  maxWidth?: number
  translatePosition?: string
  order?: Order
  showOverflow?: boolean
  onCloseDropdown?: () => void
  style?: React.CSSProperties
  disabled?: boolean
  forcedDropdownDirection?: DropdownDirection
  footer?: DropdownChildren
  ignoreTriggerArea?: boolean
}

interface StyledDropdownProps {
  dropdownPosition: Position
  minWidth?: number
  maxWidth?: number
  translatePosition?: string
  order?: Order
  showOverflow?: boolean
}

const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const StyledDropdownContents = styled.div<StyledDropdownProps>`
  display: flex;
  flex-direction: column;
  width: auto;
  opacity: 0;
  animation: ${fade} 0.1s forwards ease-in-out;
  max-height: ${props => props.dropdownPosition.height};
  position: absolute;
  background: ${props => props.theme.white};
  box-shadow: 0px 2px 10px ${props => props.theme.grey10};
  border-radius: ${props => props.theme.borderRadius};
  padding: 0px;
  margin-top: 5px;
  left: ${props => props.dropdownPosition.left};
  top: ${props => props.dropdownPosition.top};
  ${p => (p.showOverflow ? `overflow-y: visible;` : `overflow-y: auto;`)}
  ${p => {
    switch (p.order) {
      case 'up':
        return `
        z-index: 11;
      `
      case 'down':
        return `
        z-index: 9;
      `
      default:
        return `
        z-index: 10;
      `
    }
  }}

  ${props => {
    if (props.minWidth !== undefined || props.maxWidth !== undefined) {
      return `${props.minWidth ? `min-width: ${props.minWidth}px;` : ''}
              ${props.maxWidth ? `max-width: ${props.maxWidth}px;` : ''}`
    } else if (props.dropdownPosition.width) {
      return `width: ${props.dropdownPosition.width};`
    }
    return null
  }}

  ${props => {
    if (props.translatePosition) {
      return ` transform: translate(${props.translatePosition});`
    } else {
      return null
    }
  }};
`
const ScrollingDropdownContent = styled.div<{showOverflow?: boolean}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${p => (p.showOverflow ? `overflow-y: visible;` : `overflow-y: auto;`)}
  height: 100%;
`
const StickyFooter = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`
export const Dropdown: React.FC<DropdownProps> = ({
  onCloseDropdown,
  order,
  id,
  showOverflow,
  minWidth,
  maxWidth,
  translatePosition,
  style,
  trigger,
  forcedDropdownDirection,
  footer,
  ignoreTriggerArea = false,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const triggerRef = useRef<HTMLElement>(null)
  const dropdownRef = useRef<HTMLElement>(null)

  const [elRef, dropdownPosition] = useDropdownPosition(
    triggerRef,
    isOpen,
    onCloseDropdown,
    forcedDropdownDirection
  )

  const toggleDropdown = useCallback(() => {
    setIsOpen(o => !o)
  }, [])

  const controlDropdown = useCallback((openDropdown: boolean) => {
    setIsOpen(openDropdown)
  }, [])

  const closeDropdown = useCallback(() => {
    setIsOpen(false)
    if (onCloseDropdown) {
      onCloseDropdown()
    }
  }, [onCloseDropdown])

  const handleClickOutside = useCallback(
    (event: FocusEvent | MouseEvent | TouchEvent) => {
      if (event.type === 'focusin') {
        // Don't close dropdowns when elements grab focus
        // First noticed here https://www.notion.so/atomic-io/Have-to-click-button-twice-to-add-a-new-step-578bf8851880469eb557420eb77b17c2?pvs=4
        // I don't think this is something we ever want, but we could add a prop to allow it if we have a need for this behaviour in the future
        return
      }

      const node = dropdownRef.current

      const target = event.target as HTMLElement
      const eventDropdownParent = target.closest('.dropdown-contents')
      const eventDropdownTribute = target.closest('.tribute-container')

      // ignore clicks on children dropdowns
      const excludeDropdown = eventDropdownParent ?? eventDropdownTribute
      // ignore clicks on self
      const excludeSelf = node?.contains(target)
      const excludeTrigger = ignoreTriggerArea
        ? triggerRef.current?.contains(target)
        : false
      if (node && !(excludeSelf || excludeDropdown || excludeTrigger)) {
        closeDropdown()
      }
    },
    [closeDropdown, ignoreTriggerArea]
  )

  return (
    <>
      {trigger(toggleDropdown, triggerRef, isOpen, controlDropdown)}
      {isOpen &&
        createPortal(
          <ClickAwayListener onClickAway={handleClickOutside}>
            <StyledDropdownContents
              id={id ? `dropdown-${id}` : undefined}
              className="dropdown-contents" // heads up we check for this className in library/modal
              showOverflow={showOverflow}
              order={order}
              minWidth={minWidth || undefined}
              maxWidth={maxWidth}
              translatePosition={translatePosition}
              dropdownPosition={dropdownPosition}
              style={style}
              ref={mergeRefs([elRef, dropdownRef])}
            >
              <ScrollingDropdownContent showOverflow={showOverflow}>
                {props.children(closeDropdown)}
              </ScrollingDropdownContent>
              {footer ? (
                <StickyFooter>{footer(closeDropdown)}</StickyFooter>
              ) : null}
            </StyledDropdownContents>
          </ClickAwayListener>,
          document.body
        )}
    </>
  )
}

interface IconDropdownProps {
  children: DropdownChildren
  icon: IconName
  minWidth?: number
  size?: 'xsmall' | 'small' | 'large'
  appearance?: 'primary' | 'bordered' | 'transparent' | 'tint' | 'raised'
  rotateIcon?: number
  variant?: 'color' | 'greyscale' | 'muted'
  style?: React.CSSProperties
  order?: Order
  onIconClicked?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onCloseDropdown?: () => void
}

export const IconDropdown = ({
  size = 'small',
  variant,
  children,
  icon,
  minWidth,
  appearance = 'transparent',
  rotateIcon = undefined,
  style,
  order,
  onCloseDropdown,
  onIconClicked
}: IconDropdownProps) => (
  <Dropdown
    style={style}
    minWidth={minWidth}
    order={order}
    onCloseDropdown={onCloseDropdown}
    trigger={(handleClick, ref) => (
      <IconButton
        variant={variant}
        icon={icon}
        size={size}
        rotateIcon={rotateIcon}
        appearance={appearance}
        onClick={e => {
          onIconClicked?.(e)
          handleClick()
        }}
        ref={ref}
      />
    )}
  >
    {children}
  </Dropdown>
)

import AtomicSDK, {
  RuntimeVariableResolverCallback
} from '@atomic.io/action-cards-web-sdk'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import CardIcon from '../assets/icons/card_with_bite.svg'
import CheckIcon from '../assets/icons/circle_check_small.svg'
import styled from 'styled-components'
import {createMagicLink} from '../components/magic-links'
import {useContainers} from '../hooks/use-containers'
import {useEndUser} from '../hooks/use-end-user'
import {AccessBusinessUser} from '../use-organisation'
import {ENVIRONMENT_NAME, WORKBENCH_CLIENT_URL} from './env'
import {Tooltip} from '../components/library/tooltip/tooltip'
import {useAtomicSDKContext} from '../providers/atomic-sdk'
import {IconButton} from '../components/library/buttons'
import {
  onButtonPressed,
  AtomicSDKOnButtonPressed,
  AtomicSourcedModal
} from './atomic-sdk-on-button-pressed'

type SDKRuntimeData = {
  organisation:
    | {
        id: string
        name: string
      }
    | undefined
  environment:
    | {
        id: string
        name: string
      }
    | undefined
  currentBusinessUser: AccessBusinessUser | undefined
}

const useRuntimeVariablesCallback = ({
  organisation,
  environment,
  currentBusinessUser
}: SDKRuntimeData): [boolean, RuntimeVariableResolverCallback] => {
  const [containers, {loading: loadingContainers}] = useContainers(
    organisation?.id,
    environment?.id
  )
  const [endUser, {loading: loadingEndUsers}] = useEndUser({
    organisationId: organisation?.id,
    environmentId: environment?.id,
    id: currentBusinessUser?.linkedTestUserId
  })

  const defaultContainer =
    // it's possible that there might not be a default stream container
    containers?.find(c => c.isDefault) ?? (containers && containers[0])

  // generate magic link only once we have all the required bits
  const magicLink =
    organisation &&
    environment &&
    defaultContainer &&
    endUser &&
    endUser.authSecret
      ? createMagicLink({
          organisationId: organisation.id,
          environmentId: environment.id,
          endUserId: endUser.id,
          streamContainerId: defaultContainer.id,
          authSecret: endUser.authSecret,
          appFlavour: ENVIRONMENT_NAME === 'master' ? 'demo' : 'shell'
        })
      : ''

  const loading =
    !organisation ||
    !environment ||
    !currentBusinessUser ||
    !magicLink ||
    loadingContainers ||
    loadingEndUsers

  const runtimeVariables = useMemo(
    () => ({
      organisationId: organisation?.id,
      organisationName: organisation?.name,
      environmentId: environment?.id,
      environmentName: environment?.name,
      workbenchBaseUrl:
        organisation?.id && environment?.id
          ? `${WORKBENCH_CLIENT_URL}/${organisation.id}/${environment.id}`
          : '',
      firstName: currentBusinessUser?.firstName ?? '',
      lastName: currentBusinessUser?.lastName ?? '',
      magicLink

      // Current account id
      // Current account name
    }),
    [
      organisation,
      environment,
      currentBusinessUser?.firstName,
      currentBusinessUser?.lastName,
      magicLink
    ]
  )

  const onRuntimeVariablesRequested =
    useCallback<RuntimeVariableResolverCallback>(
      (cards, callback) => {
        for (const card of cards) {
          for (const [name, value] of Object.entries(runtimeVariables)) {
            card.runtimeVariables.set(name, value ?? '')
          }
        }
        callback(cards)
      },
      [runtimeVariables]
    )

  return [loading, onRuntimeVariablesRequested]
}

type AtomicEmbedProps = {
  organisation: {id: string; name: string} | undefined
  environment: {id: string; name: string} | undefined
  streamContainerId: string
  currentBusinessUser: AccessBusinessUser | undefined
}

export const AtomicPublishedActionFlow = ({
  streamContainerId,
  newCardSeen,
  setNewCardSeen,
  flowConfigId,
  flowConfigVersion,
  ...runtimeData
}: AtomicEmbedProps & {
  newCardSeen: boolean
  setNewCardSeen: (seen: boolean) => void
  flowConfigId: string
  flowConfigVersion: number
}) => {
  const embedRef = useRef<any>()

  const {atomicVisible, updateCardCount: onCardCountChanged} =
    useAtomicSDKContext()

  const [loadingVars, onRuntimeVariablesRequested] =
    useRuntimeVariablesCallback(runtimeData)

  const [modalData, setModalData] = useState<AtomicSDKOnButtonPressed>()

  const initAtomicEmbed = useCallback(
    (el: HTMLDivElement) => {
      if (embedRef.current) {
        embedRef.current.stop()
        embedRef.current = null
      }
      if (el && !loadingVars) {
        const onPress = onButtonPressed(setModalData)
        embedRef.current = AtomicSDK.singleCard(el, {
          streamContainerId,
          onCardCountChanged,
          onRuntimeVariablesRequested,
          // unlikey that we'd use these, but can't hurt to have the option!
          onLinkButtonPressed: onPress,
          onSubmitButtonPressed: onPress
        })

        // Only ever show the card for this current version and flow
        embedRef.current.streamFilters
          .addPayloadVariableFilter('flowConfigId')
          .equals(flowConfigId)
        embedRef.current.streamFilters
          .addPayloadVariableFilter('version')
          .equals(flowConfigVersion)
        embedRef.current.streamFilters.apply()

        // Ensure that we only show the container once a new card is seen
        AtomicSDK.observeSDKEvents(event => {
          if (newCardSeen) return
          if (event.eventName !== 'card-feed-updated') return
          if (event.sdkContext.containerId !== streamContainerId) return
          if (event.properties.cardCount > 0) {
            setNewCardSeen(true)
          }
        })
      }
    },
    [
      streamContainerId,
      onCardCountChanged,
      onRuntimeVariablesRequested,
      setNewCardSeen,
      newCardSeen,
      loadingVars,
      flowConfigId,
      flowConfigVersion,
      setModalData
    ]
  )

  if (!atomicVisible) return null

  return (
    <>
      {modalData ? (
        <AtomicSourcedModal data={modalData} setModalData={setModalData} />
      ) : null}
      <AtomicPublishedActionFlowContainer
        style={{...(!newCardSeen ? {display: 'none'} : {})}}
        ref={initAtomicEmbed}
      />
    </>
  )
}

const AtomicPublishedActionFlowContainer = styled.div`
  iframe {
    display: flex;
    justify-content: center;
    // we don't actually want this in the center of the page
    // we want it in the center of the content (excluding side nav)
    margin-left: 20px;
    width: 480px;
  }
`

export const AtomicSingleCard = ({
  streamContainerId,
  ...runtimeData
}: AtomicEmbedProps) => {
  const embedRef = useRef<any>()

  const {
    atomicVisible,
    streamVisible,
    updateCardCount: onCardCountChanged
  } = useAtomicSDKContext()

  const [loadingVars, onRuntimeVariablesRequested] =
    useRuntimeVariablesCallback(runtimeData)

  const [modalData, setModalData] = useState<AtomicSDKOnButtonPressed>()

  const initAtomicEmbed = useCallback(
    (el: HTMLDivElement) => {
      if (embedRef.current) {
        embedRef.current.stop()
        embedRef.current = null
      }
      if (el && !loadingVars) {
        const onPress = onButtonPressed(setModalData)
        embedRef.current = AtomicSDK.singleCard(el, {
          streamContainerId,
          onCardCountChanged,
          onRuntimeVariablesRequested,
          onLinkButtonPressed: onPress,
          onSubmitButtonPressed: onPress
        })
      }
    },
    [
      streamContainerId,
      onCardCountChanged,
      onRuntimeVariablesRequested,
      loadingVars,
      setModalData
    ]
  )

  if (!atomicVisible) return null

  return (
    <>
      {modalData ? (
        <AtomicSourcedModal data={modalData} setModalData={setModalData} />
      ) : null}
      <SingleCardContainer
        className={!streamVisible ? 'show-stream' : ''}
        ref={initAtomicEmbed}
      />
    </>
  )
}

const SingleCardContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 320px;

  .atomic-sdk-frame {
    pointer-events: none;
    position: absolute;
    bottom: 0px;
    right: -340px;
    margin: 10px;
    width: 100%;

    transition: 250ms right;
  }

  // only show priority card when not viewing stream
  &.show-stream .atomic-sdk-frame.has-card {
    opacity: 1;
    pointer-events: all;
    right: 0;
  }

  .modal-subview {
    // override the height set by the SDK
    height: 100% !important;
    width: 340px;

    position: fixed;
    right: -340px;
    top: 0;

    margin: 0;

    pointer-events: all;
    &.has-subview {
      right: 0px;
    }
  }
`

export const AtomicStream = ({
  streamContainerId,
  ...runtimeData
}: AtomicEmbedProps) => {
  const embedRef = useRef<any>()

  const {
    atomicVisible,
    streamVisible,
    updateCardCount: onCardCountChanged,
    setStreamVisible
  } = useAtomicSDKContext()

  const [loadingVars, onRuntimeVariablesRequested] =
    useRuntimeVariablesCallback(runtimeData)

  // don't initialise the SDK until the stream container is shown for the first
  // time, but once initialised, keep it initialised
  const [streamWasShown, setStreamWasShown] = useState(false)
  useEffect(() => {
    if (streamVisible) {
      setStreamWasShown(true)
    }
  }, [streamVisible])

  const [modalData, setModalData] = useState<AtomicSDKOnButtonPressed>()

  const initAtomicEmbed = useCallback(
    (el: HTMLDivElement) => {
      if (embedRef.current) {
        embedRef.current.stop()
        embedRef.current = null
      }
      if (el && !loadingVars) {
        const onPress = onButtonPressed(setModalData)
        embedRef.current = AtomicSDK.embed(el, {
          onCardCountChanged,
          streamContainerId,
          onRuntimeVariablesRequested,
          customStrings: {
            cardListTitle: 'Actions'
          },
          onLinkButtonPressed: onPress,
          onSubmitButtonPressed: onPress
        })
      }
    },
    [
      onCardCountChanged,
      streamContainerId,
      onRuntimeVariablesRequested,
      loadingVars,
      setModalData
    ]
  )

  return (
    <>
      {modalData ? (
        <AtomicSourcedModal data={modalData} setModalData={setModalData} />
      ) : null}
      <IconButton icon="close" onClick={() => setStreamVisible(false)} />
      {atomicVisible && streamWasShown ? (
        <StreamContainer ref={initAtomicEmbed} />
      ) : null}
    </>
  )
}

const StreamContainer = styled.div`
  flex: 1;
  border-left: 1px solid ${p => p.theme.grey10};
  > iframe {
    height: 100%;
    width: 100%;
  }
`

const HeaderTooltip = styled(Tooltip)`
  display: flex;
`

type AtomicBellProps = {
  onClick: () => void
  cardCount: number | undefined
}

export const AtomicBell = ({onClick, cardCount}: AtomicBellProps) => {
  const [bounce, setBounce] = useState(false)
  const prevCount = useRef(cardCount ?? 0)
  useEffect(() => {
    if (cardCount === undefined) {
      return () => null
    }

    // store last count
    const prev = prevCount.current
    prevCount.current = cardCount

    // if the count has increased, wiggle the counter, removing the class after
    // the animation completes so it's ready for next time
    if (cardCount > prev) {
      setBounce(true)
      const timer = setTimeout(() => setBounce(false), 3000)
      return () => clearTimeout(timer)
    }

    return () => null
  }, [cardCount])

  return (
    <HeaderTooltip
      content="Things to do"
      position="bottom-right"
      offsetBottom="4px"
    >
      <BellWrapper
        role="button"
        onClick={onClick}
        className={`${cardCount !== undefined ? 'count-fetched' : ''} ${
          cardCount ? 'has-cards' : ''
        } ${bounce ? 'bounce' : ''}`}
      >
        <CardIcon className="main" width="30px" height="30px" />
        <BellCount cardCount={cardCount}>{cardCount ?? 0}</BellCount>
        <CheckIcon className="check" />
      </BellWrapper>
    </HeaderTooltip>
  )
}

const time = 240

// visual styling and sizing of count icon
// this is the size of the icon when its "active"
// by default its transformed to 60% of this size
const BellCount = styled.span<{cardCount: number | undefined}>`
  font-weight: 700;
  font-size: 14px;
  min-width: 28px;
  height: 28px;
  border-radius: 28px;
  border: 2px solid ${p => p.theme.white};
  background: ${p =>
    p.cardCount ? p.theme.colors.orange9 : p.theme.colors.grey10};
  color: ${p => p.theme.white};
  letter-spacing: -0.33px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: top right;
  transform: scale(0.6);
  margin-right: 1px;

  // grey ring around count when expanded - hidden initially
  &::after {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border: 2px solid
      ${p => (p.cardCount ? p.theme.colors.blue11 : p.theme.colors.grey8)};
    border-radius: 17px;
    opacity: 0;
    transition: ${time * 0.2}ms opacity;
    transition: none;
  }
`

const BellWrapper = styled.a`
  margin-left: 16px;
  position: relative;
  color: ${p => p.theme.colors.grey11};

  .main {
    color: currentColor;
  }

  // positioning and initial state of top-right icons
  ${BellCount},
  > .check {
    position: absolute;
    top: 2px;
    right: 0px;
    transition:
      ${time * 0.67}ms transform,
      ${time * 0.5}ms opacity;
    opacity: 0;
  }
  > .check {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    transition: ${time * 0.5}ms opacity;
  }

  // when count fetch completes show either the check or bell
  > .check {
    opacity: 1;
  }

  &.count-fetched.has-cards {
    > .check {
      opacity: 0;
    }
    ${BellCount} {
      opacity: 1;
    }
    color: ${p => p.theme.colors.blue11};
  }

  // when there are cards, bounce the count icon on hover and when the bounce
  // class is added, which happens when new cards come in
  &:hover,
  &.count-fetched.bounce {
    ${BellCount} {
      opacity: 1;
      transform: none;
      transition: ${time * 0.67}ms transform
        cubic-bezier(0.175, 0.885, 0.32, 1.275);

      &::after {
        opacity: 1;
        transition-delay: ${time * 0.5}ms;
        transition: ${time * 0.4}ms opacity ease-out;
      }
    }
    > .check {
      opacity: 0;
    }
  }
`

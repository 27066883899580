import {gql} from '@apollo/client'
import {useEffect, useMemo, useRef, useState} from 'react'
import {getClient} from '../lib/api'
import {ListEnvStreamContainersQuery} from './generated/use-containers'

type StreamContainer = {
  id: string
  name: string
  streamId: string
  streamName: string
  isDefault: boolean
}

export const useContainers = (
  organisationId: string | undefined,
  environmentId: string | undefined,
  onCompleted?: (data: ListEnvStreamContainersQuery) => void
) => {
  const [streamContainers, setStreamContainers] = useState<
    StreamContainer[] | undefined
  >()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()

  const onCompletedRef = useRef(onCompleted)
  onCompletedRef.current = onCompleted

  useEffect(() => {
    if (!environmentId || !organisationId) {
      return () => null
    }

    setLoading(true)
    const q = getClient(
      organisationId
    ).watchQuery<ListEnvStreamContainersQuery>({
      query: LIST_ENV_STREAM_CONTAINERS_QUERY,
      variables: {environmentId},
      fetchPolicy: 'cache-and-network'
    })

    const sub = q.subscribe(({data, ...result}) => {
      const containers = data.environment?.streamContainers
        .nodes as StreamContainer[]
      if (containers) {
        setStreamContainers(
          data.environment?.streamContainers.nodes as StreamContainer[]
        )
        if (onCompletedRef.current) {
          onCompletedRef.current(data)
        }
      }
      if (result.error) {
        setError(result.error.message)
      } else {
        setError(undefined)
      }
      setLoading(false)
    })

    return () => {
      sub.unsubscribe()
    }
  }, [organisationId, environmentId])

  const state = useMemo(() => ({error, loading}), [error, loading])

  return [streamContainers, state] as const
}

const LIST_ENV_STREAM_CONTAINERS_QUERY = gql`
  query ListEnvStreamContainers($environmentId: String!) {
    environment(id: $environmentId) {
      id
      streamContainers: activeStreamContainers {
        nodes {
          id
          name
          streamId
          streamName
          isDefault
        }
      }
    }
  }
`

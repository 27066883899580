import memoizeOne from 'memoize-one'
import {organisationUri} from './api'

export type RequestMethod = 'post' | 'put' | 'patch' | 'get' | 'delete'

const parseBody = async (
  response: Response
): Promise<{text: string; json?: unknown}> => {
  // don't assume the server will respect Content-Type
  const text = await response.text()
  try {
    return {
      text,
      json: JSON.parse(text)
    }
  } catch (e) {
    console.warn('Non JSON body')
  }
  return {
    text
  }
}

export type APIResponse = {
  status: number
  body: string
  json: unknown
  ok: boolean
  response: Response
}

export const getApi = memoizeOne(
  ({
    organisationId,
    getToken,
    authIsOptional
  }: {
    organisationId?: string
    getToken?: () => undefined | string | Promise<string | undefined>
    authIsOptional?: boolean // allow unauthenticated requests
  } = {}) =>
    async (
      method: RequestMethod,
      route: string,
      data?: Record<string, unknown>,
      headers?: Record<string, string>,
      abortController?: AbortController
    ): Promise<APIResponse> => {
      const token = getToken ? await getToken() : undefined

      // If a getToken callback was provided, it should return a token, unless authIsOptional
      if (!authIsOptional && getToken && !token) {
        throw new Error('Failed to resolve authorization token')
      }

      if (!organisationId && !route.match(/^http/)) {
        throw new Error(
          'Full endpoint url must be provided as organization id is not present'
        )
      }

      let url =
        !organisationId || route.match(/^http/)
          ? route
          : `${organisationUri(organisationId)}${route}`

      if (method === 'get' && data) {
        // @ts-ignore URLSearchParams wants Record<string,string> but Record<string,unknown> will work fine
        const params = new URLSearchParams(data).toString()
        url = `${url}?${params}`
      }

      const response = await fetch(url, {
        signal: abortController ? abortController.signal : undefined,
        method,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          ...(token ? {authorization: `Bearer ${token}`} : {}),
          ...(headers ? headers : {}),
          ...(organisationId
            ? {
                'x-atomic-test-host': `${organisationId}.customer-api.atomic.io`
              }
            : {})
        },
        ...(data && method !== 'get' ? {body: JSON.stringify(data)} : {})
      })
      const {status} = response

      const {text, json} = await parseBody(response)
      return {
        status,
        body: text,
        json,
        response,
        ok: response.ok
      }
    }
)

import React from 'react'
import {Icon, IconName} from '../icon/icon'
import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'
import LoaderImage from '../../../assets/loader.gif'

export type IconButtonSize =
  | 'xsmall'
  | 'small'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'raise'

type Appearance = 'primary' | 'bordered' | 'transparent' | 'tint' | 'raised'
export type Variant = 'color' | 'greyscale' | 'muted'

interface WrapperProps {
  appearance?: Appearance
  variant?: Variant
  size?: IconButtonSize
  disabled?: boolean
  className?: string
  tabIndex?: number
  style?: React.CSSProperties
}

export interface BaseIconButtonProps extends WrapperProps {
  icon: IconName
  rotateIcon?: number
  children?: React.ReactNode
}

const primary = css`
  background-color: ${({theme}) => theme.blue};
  color: ${({theme}) => theme.white};
`
const tint = css`
  background-color: ${({theme}) => theme.grey05};
  color: ${({theme}) => theme.black};
`
const bordered = css`
  background: ${({theme}) => theme.white};
  border: 1px solid ${({theme}) => theme.grey10};
  color: ${({theme}) => theme.black};
`
const transparent = css`
  background: none;
  color: ${({theme}) => theme.black};
`
const xsmall = css`
  width: 24px;
  height: 24px;
`
const small = css`
  width: 30px;
  height: 30px;
`
const large = css`
  width: 40px;
  height: 40px;
`
const xlarge = css`
  width: 50px;
  height: 50px;
`

const xxlarge = css`
  width: 60px;
  height: 60px;
`
const color = css`
  color: ${({theme}) => theme.blue};
`
const greyscale = css``

const muted = css`
  color: ${({theme}) => theme.grey50};
`

const raised = css`
  background: ${({theme}) => theme.white};
  box-shadow: ${({theme}) => theme.shadowDepth1};
`

const buttonStyles = css<WrapperProps>`
  background-color: transparent;
  transition:
    color 0.1s ease,
    background 0.1s ease,
    border 0.1s ease;
  border: none;
  border-radius: 999px;
  color: ${({theme}) => theme.black};
  text-align: center;
  padding: 0;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;

  &[disabled] {
    cursor: default;
    ${p => {
      switch (p.appearance) {
        case 'primary':
          return `background-color: ${p.theme.grey50}`
        case 'bordered':
          return `color: ${p.theme.grey50}`
        case 'raised':
          return `color: ${p.theme.grey50}`
        case 'tint':
          return `color: ${p.theme.grey50}`
        case 'transparent':
          return `color: ${p.theme.grey50}`
        default:
          return `color: ${p.theme.grey50}`
      }
    }}
  }

  &:not([disabled]) {
    &:focus,
    &:hover {
      ${p => {
        switch (p.appearance) {
          case 'primary':
            return `background-color: ${p.theme.blueDark}`
          case 'bordered':
            return `background-color: ${p.theme.white}; box-shadow: inset 0px 0px 100px 100px ${p.theme.grey05};`
          case 'raised':
            return `background-color: ${p.theme.white};  box-shadow: ${p.theme.shadowDepth2};`
          case 'tint':
            return `background-color: ${p.theme.grey10}`
          case 'transparent':
            return `background-color: ${p.theme.grey05}`
          default:
            return `background-color: ${p.theme.grey05}`
        }
      }}
    }
    &:active {
      ${p => {
        switch (p.appearance) {
          case 'primary':
            return `background-color: ${p.theme.blueDarkest};`
          case 'bordered':
            return `background-color: ${p.theme.white}; box-shadow: inset 0px 0px 100px 100px ${p.theme.grey10};`
          case 'raised':
            return `background-color: ${p.theme.white}; box-shadow: ${p.theme.shadowDepth1};`
          case 'tint':
            return `background-color: ${p.theme.grey15};`
          case 'transparent':
            return `background-color: ${p.theme.grey10};`
          default:
            return `background-color: ${p.theme.grey10};`
        }
      }}
    }
  }

  ${props =>
    (props.appearance === 'primary' && primary) ||
    (props.appearance === 'tint' && tint) ||
    (props.appearance === 'bordered' && bordered) ||
    (props.appearance === 'raised' && raised) ||
    (props.appearance === 'transparent' && transparent) ||
    (!props.appearance && transparent)};

  ${props =>
    (props.size === 'xxlarge' && xxlarge) ||
    (props.size === 'xlarge' && xlarge) ||
    (props.size === 'large' && large) ||
    (props.size === 'small' && small) ||
    (props.size === 'xsmall' && xsmall) ||
    (!props.size && small)};

  ${props =>
    (props.variant === 'color' && color) ||
    (props.variant === 'greyscale' && greyscale) ||
    (props.variant === 'muted' && muted)};
`

const StyledButton = styled.button<WrapperProps>`
  ${buttonStyles}
`

interface IconButtonProps extends Omit<BaseIconButtonProps, 'icon'> {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  icon?: IconName | 'loader'
  iconDimensions?: {height: number; width: number}
}

export const IconButton = React.forwardRef<HTMLElement, IconButtonProps>(
  (
    {
      onClick,
      icon,
      rotateIcon,
      children,
      tabIndex,
      iconDimensions,
      ...commonProps
    },
    ref
  ) => (
    <StyledButton
      {...commonProps}
      type="button"
      tabIndex={tabIndex}
      onClick={onClick}
      ref={ref as React.Ref<HTMLButtonElement>}
    >
      {children ? (
        children
      ) : icon ? (
        icon === 'loader' ? (
          <img
            src={LoaderImage}
            style={{
              height: iconDimensions ? `${iconDimensions.height}px` : '30px',
              width: iconDimensions ? `${iconDimensions.width}px` : undefined,
              opacity: '0.2',
              mixBlendMode: 'multiply'
            }}
          />
        ) : (
          <Icon
            {...commonProps}
            rotate={rotateIcon}
            name={icon}
            height={iconDimensions ? `${iconDimensions.height}px` : undefined}
            width={iconDimensions ? `${iconDimensions.width}px` : undefined}
          />
        )
      ) : null}
    </StyledButton>
  )
)

const StyledRouterLink = styled(Link)`
  ${buttonStyles}
`

interface RouterLinkIconButtonProps extends BaseIconButtonProps {
  to: string
}

export const RouterLinkIconButton = ({
  to,
  icon,
  rotateIcon,
  children,
  ...commonProps
}: RouterLinkIconButtonProps) => (
  <StyledRouterLink to={to} {...commonProps}>
    {children ? (
      children
    ) : (
      <Icon {...commonProps} rotate={rotateIcon} name={icon} />
    )}
  </StyledRouterLink>
)

export const ButtonStyledA = styled.a`
  ${buttonStyles}
`

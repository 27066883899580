import {RoutingOrganisation} from '../common/types'
import {ERR_NO_ORGANISATIONS, getToken} from './api'
import {ROUTING_API_URL} from './env'
import {getApi} from './fetch'
import {getLocalOrganisationId, setLocalOrganisationId} from './local-store'

let organisationId: string
let activeAppClientId: string | undefined

export const getOrganisationId = async (
  appClientId: string,
  errorOnEmpty = true
) => {
  if (!organisationId || activeAppClientId !== appClientId) {
    // TODO this could potentially be Re implemented as a hook similar to
    // useOrganisations, to avoid duplication here
    const res = await getApi({getToken})(
      'get',
      `${ROUTING_API_URL}/organisations`,
      undefined,
      {'app-client-id': appClientId}
    )
    if (res.ok && res.json && typeof res.json === 'object') {
      const json = res.json as Record<string, unknown>
      const data =
        json && 'data' in json && Array.isArray(json.data) ? json.data : []
      const orgs = data as RoutingOrganisation[]
      if (orgs.length > 0) {
        const lastOrganisationId = getLocalOrganisationId()
        if (lastOrganisationId) {
          const found = orgs.find(({id}) => id === lastOrganisationId)
          if (found) {
            // eslint-disable-next-line require-atomic-updates
            organisationId = found.id
          }
        }

        if (!organisationId) {
          organisationId = orgs[0].id
        }

        setLocalOrganisationId(organisationId)

        // eslint-disable-next-line require-atomic-updates
        activeAppClientId = appClientId
      }
    }
  }

  if (!organisationId && errorOnEmpty) {
    throw new Error(ERR_NO_ORGANISATIONS)
  }

  return organisationId
}

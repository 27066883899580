import React from 'react'
import {Icon, IconName} from '../icon/icon'
import styled from 'styled-components'

const EmptyIcon = styled.div`
  svg {
    color: ${({theme}) => theme.grey100};
  }
`

const NoResultsContainer = styled.div<{large: boolean}>`
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${({theme}) => theme.grey50};
  font-size: ${({theme}) => theme.fontSize};
  line-height: ${({theme}) => theme.lineHeight};
  text-align: center;

  > p {
    max-width: 500px;
    margin: 0 0 5px;
  }
  // Exact height to equal 300px, minus padding on wrapper and border top and bottom
  ${p => (p.large ? `min-height: 238px` : null)}
`

const EmptyHeading = styled.div`
  font-size: ${({theme}) => theme.fontSizeL};
  line-height: ${({theme}) => theme.lineHeightL};
  text-align: center;
  margin: 10px 0;
  max-width: 500px;
  color: ${p => p.theme.black};
`

export const NoResults = ({
  icon,
  message = 'Nothing to show here yet',
  heading,
  children,
  large = false
}: NoResultsProps) => (
  <NoResultsContainer large={large}>
    <EmptyIcon>{icon ? <Icon name={icon}></Icon> : null}</EmptyIcon>
    <EmptyHeading>{heading ? heading : 'No data'}</EmptyHeading>
    {message ? <p>{message}</p> : null}
    {children}
  </NoResultsContainer>
)

export type NoResultsProps = {
  icon?: IconName
  message?: string
  heading?: string
  children?: React.ReactNode
  large?: boolean
}

export const NoResultsPermissions = ({
  icon = 'padlock',
  heading = 'No access',
  message = "Sorry, you don't have permission to view these records",
  ...props
}: NoResultsProps) => (
  <NoResults icon={icon} message={message} heading={heading} {...props} />
)

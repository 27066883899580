import {ApolloProvider, gql} from '@apollo/client'
import React, {ReactNode} from 'react'
import {Link, useLocation} from 'react-router-dom'
import styled from 'styled-components'
import Configuration from '../assets/icons/configuration.svg'
import SVGCopySmall from '../assets/icons/copy-small.svg'
import Dash from '../assets/icons/dash.svg'
import SVGEnvironmentSmall from '../assets/icons/environment-small.svg'
import SVGLockSmall from '../assets/icons/lock-small.svg'
import SVGActionFlows from '../assets/icons/nav/action-flows.svg'
import SVGCards from '../assets/icons/nav/cards.svg'
import SVGConfigure from '../assets/icons/nav/configure.svg'
import SVGCustomers from '../assets/icons/nav/customers.svg'
import SVGDocs from '../assets/icons/nav/docs.svg'
import SVGInsights from '../assets/icons/nav/insights.svg'
import SVGLogo from '../assets/icons/nav/logo.svg'
import SVGManage from '../assets/icons/nav/manage.svg'
import SVGTools from '../assets/icons/nav/tools.svg'
import SVGOrganisationSmall from '../assets/icons/organisation-small.svg'
import SVGProfileSmall from '../assets/icons/profile-small.svg'
import {CmdkActionGetter} from '../cmdk/types'
import {EnvironmentNode} from '../environment-provider'
import {useFlags} from '../hooks/use-flags'
import {useAnalytics, useUserIdentifiedLink} from '../lib/analytics/analytics'
import {getClient} from '../lib/api'
import {AuthStateAuth} from '../lib/cognito'
import {OrganisationNode} from '../use-organisation'
import {fontMedium} from '../views/action-flow/components/library/css'
import {useManageAccountMenuQuery} from './generated/sidebar'
import {Avatar} from './library/avatar/avatar'
import {Copyable} from './library/copy'
import {DropdownItem} from './library/dropdown/content'
import {Dropdown} from './library/dropdown/dropdown'
import {Icon} from './library/icon/icon'
import {Tooltip} from './library/tooltip/tooltip'

const Nav = styled.nav`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`

const NavLinkGroup = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin-bottom: 25px;
`

const NavLinkFooter = styled(NavLinkGroup)`
  margin-top: auto;
  margin-bottom: 10px;
`

const NavIconWrap = styled.button`
  width: 28px;
  height: 28px;
  display: flex;
  padding: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: ${p => p.theme.colors.navy6};
  background-color: transparent;
  margin: 0;
  border: none;
  &:hover,
  &.active {
    background-color: ${p => p.theme.colors.navy10};
    color: white;
  }
  &:focus {
    outline: 1px solid ${p => p.theme.colors.blue10};
  }
`

const DocLine = styled.div`
  width: 40px;
  height: 1px;
  position: absolute;
  background-color: ${p => p.theme.colors.navy7};
  bottom: 44px;
`

const AvatarButton = styled.button`
  display: inline-block;
  background-color: transparent;
  border: none;
  border-radius: 16px;
  width: 32px;
  height: 32px;
  &:hover,
  &.active {
    background: ${p => p.theme.colors.navy10};
  }
  &:focus {
    outline: 1px solid ${p => p.theme.colors.blue10};
  }
`

type NavItemProps = {
  icon: ReactNode
  label: string
  to?: string
  href?: string
  target?: string
  onClick?: () => void
  className?: string
  isActive?: boolean
}
export const NavItem = React.forwardRef(
  (
    {
      icon,
      label,
      to,
      href,
      onClick,
      className,
      isActive,
      ...anchorProps
    }: NavItemProps,
    ref: React.Ref<HTMLElement>
  ) => {
    const location = useLocation()
    const active =
      typeof isActive === 'boolean'
        ? isActive
        : to && location.pathname.indexOf(to) === 0

    const classNames = [active ? 'active' : '', className]
      .filter(Boolean)
      .join(' ')

    const inner = (
      <NavIconWrap
        ref={ref as React.Ref<HTMLButtonElement>}
        tabIndex={0}
        className={classNames}
        onClick={onClick}
        role="button"
      >
        {icon}
      </NavIconWrap>
    )

    return (
      <Tooltip
        content={label}
        isLight={false}
        position="right"
        offsetRight="-10px"
      >
        {to ? ( // tabIndex -1 to prevent focus on inner followed by outer in chrome
          <Link to={to} {...anchorProps} tabIndex={-1}>
            {inner}
          </Link>
        ) : href ? (
          <a href={href} {...anchorProps} tabIndex={-1}>
            {inner}
          </a>
        ) : (
          inner
        )}
      </Tooltip>
    )
  }
)

const DropdownBody = styled.div`
  padding: 8px 0;
  + footer {
    border-top: 1px solid ${p => p.theme.grey10};
  }
`

const DropdownTitle = styled.h3`
  padding: 5px 16px;
  margin: 0;
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  & + ${DropdownBody} {
    padding-top: 0;
  }
`
const DropdownFooter = styled.footer`
  padding: 8px 0;
`

const DropdownNavItem = styled(DropdownItem)`
  a {
    ${fontMedium}
  }
`

interface SidebarProps {
  environment?: EnvironmentNode
  organisation?: OrganisationNode
  authState: AuthStateAuth
  signOut: () => void
  testUserId?: string
}

export const Sidebar = ({
  environment,
  organisation,
  authState,
  signOut,
  testUserId
}: SidebarProps) => {
  const flags = useFlags(environment?.organisationId)
  const analytics = useAnalytics()
  const addSubQueryParam = useUserIdentifiedLink()
  const location = useLocation()

  return (
    <Nav>
      <NavLinkGroup style={{marginTop: '10px', marginBottom: '25px'}}>
        <NavItem
          to="/"
          data-testid="sidebar-logo"
          isActive={false}
          icon={<SVGLogo />}
          label="Home"
        />
        {environment ? (
          <>
            {organisation?.legacyCardsEnabled ? (
              <NavItem
                data-testid="sidebar-nav-cards"
                to={`/${environment.organisationId}/${environment.id}/cards`}
                icon={<SVGCards />}
                label="Cards"
              />
            ) : null}
            {flags.actionFlows || !organisation?.legacyCardsEnabled ? (
              <NavItem
                data-testid="sidebar-nav-af"
                isActive={location.pathname.includes(
                  `/${environment.id}/action-flow`
                )}
                to={`/${environment.organisationId}/${environment.id}/action-flows`}
                icon={<SVGActionFlows />}
                label="Action Flows"
              />
            ) : null}
            <NavItem
              data-testid="sidebar-nav-customers"
              to={`/${environment.organisationId}/${environment.id}/customers`}
              icon={<SVGCustomers />}
              label="Customers"
            />
            <NavItem
              data-testid="sidebar-nav-dashboard"
              to={`/${environment.organisationId}/${environment.id}/dashboard`}
              icon={<SVGInsights />}
              label="Insights"
            />
            <NavItem
              data-testid="sidebar-nav-tools"
              to={`/${environment.organisationId}/${environment.id}/tools`}
              icon={<SVGTools />}
              label="Tools"
            />
            <NavItem
              data-testid="sidebar-nav-configuration"
              to={`/${environment.organisationId}/${environment.id}/configuration`}
              icon={<SVGConfigure />}
              label="Configuration"
            />
          </>
        ) : null}
      </NavLinkGroup>

      <NavLinkFooter>
        {organisation ? (
          <ApolloProvider client={getClient(organisation.id)}>
            <ManageAccountMenu organisationId={organisation.id} />
          </ApolloProvider>
        ) : null}
        <Dropdown
          translatePosition={'44px,46px'}
          trigger={(handleClick, ref, isOpen) => (
            <Tooltip
              content="Profile"
              isLight={false}
              position="right"
              offsetRight="-10px"
            >
              <AvatarButton
                style={{width: '32px', height: '32px'}}
                onClick={handleClick}
                className={
                  isOpen ||
                  (organisation?.id &&
                    location.pathname.indexOf(`/${organisation.id}/profile`) ===
                      0)
                    ? 'active'
                    : ''
                }
              >
                <Avatar
                  name={authState.email}
                  size="small"
                  ref={ref}
                  style={{height: '20px', width: '20px'}}
                />
              </AvatarButton>
            </Tooltip>
          )}
        >
          {closeDropdown =>
            organisation?.id ? (
              <>
                <DropdownBody style={{minWidth: '240px'}}>
                  <DropdownNavItem>
                    <Link
                      to={`/${organisation.id}/profile`}
                      onClick={closeDropdown}
                    >
                      <SVGProfileSmall />
                      View profile
                    </Link>
                  </DropdownNavItem>
                  {testUserId ? (
                    <DropdownNavItem>
                      <a style={{height: '30px'}} onClick={closeDropdown}>
                        <SVGCopySmall />
                        <Copyable value={testUserId}>
                          {() => <p>Copy my test user Id</p>}
                        </Copyable>
                      </a>
                    </DropdownNavItem>
                  ) : null}
                </DropdownBody>
                <DropdownFooter>
                  <DropdownNavItem>
                    <a
                      onClick={() => {
                        signOut()
                        closeDropdown()
                      }}
                    >
                      <SVGLockSmall />
                      Log out
                    </a>
                  </DropdownNavItem>
                </DropdownFooter>
              </>
            ) : (
              // no org just show logout
              <DropdownBody style={{minWidth: '240px'}}>
                <DropdownNavItem>
                  <a
                    onClick={() => {
                      signOut()
                      closeDropdown()
                    }}
                  >
                    <SVGLockSmall />
                    Log out
                  </a>
                </DropdownNavItem>
              </DropdownBody>
            )
          }
        </Dropdown>
        <DocLine />
        <NavItem
          href={addSubQueryParam('https://documentation.atomic.io/')}
          icon={<SVGDocs />}
          label="Documentation"
          onClick={() => {
            if (environment) {
              analytics.track({
                event: 'Redirected to external URL',
                properties: {
                  url: 'https://documentation.atomic.io/',
                  environment_id: environment.id,
                  org_id: environment.organisationId,
                  category: 'general'
                }
              })
            }
          }}
          target="_blank"
        />
      </NavLinkFooter>
    </Nav>
  )
}

const ManageAccountMenu = ({organisationId}: {organisationId: string}) => {
  const {data} = useManageAccountMenuQuery({variables: {organisationId}})
  const location = useLocation()

  return (
    <Dropdown
      translatePosition={'48px,44px'}
      trigger={(handleClick, ref, isOpen) => (
        <NavItem
          data-testid="sidebar-nav-configuration"
          onClick={handleClick}
          isActive={
            isOpen ||
            location.pathname.indexOf(
              `/${organisationId}/preferences/organization`
            ) === 0 ||
            location.pathname.indexOf(`/${organisationId}/environments`) ===
              0 ||
            location.pathname.indexOf(`/${organisationId}/permissions`) === 0
          }
          ref={ref}
          icon={<SVGManage />}
          label="Manage"
        />
      )}
    >
      {closeDropdown => (
        <>
          <DropdownTitle>Manage account</DropdownTitle>
          <DropdownBody style={{minWidth: '240px'}}>
            <DropdownNavItem>
              <Link
                to={`/${organisationId}/preferences/organization`}
                onClick={closeDropdown}
              >
                <SVGOrganisationSmall />
                Organization
              </Link>
            </DropdownNavItem>
            <DropdownNavItem>
              <Link
                to={`/${organisationId}/environments`}
                onClick={closeDropdown}
              >
                <SVGEnvironmentSmall />
                Environments
              </Link>
            </DropdownNavItem>
            <DropdownNavItem>
              <Link
                to={`/${organisationId}/permissions`}
                onClick={closeDropdown}
              >
                <SVGProfileSmall width={'16px'} />
                <span>
                  Workbench members&nbsp;
                  <span style={{color: '#687076'}}>
                    {data?.query?.businessUsers?.totalCount
                      ? data.query.businessUsers.totalCount.toLocaleString()
                      : ''}
                  </span>
                </span>
              </Link>
            </DropdownNavItem>
          </DropdownBody>
        </>
      )}
    </Dropdown>
  )
}

gql`
  query ManageAccountMenu($organisationId: String!) {
    query {
      businessUsers(first: 0, condition: {organisationId: $organisationId}) {
        totalCount
      }
    }
  }
`

export const getActionsForSidebar: CmdkActionGetter = () => [
  // note some actions from the above are included in provider.tsx
  {
    name: 'Customers',
    to: 'customers',
    icon: <Icon name="users" />
  },
  {
    name: 'Insights',
    to: 'dashboard',
    icon: <Dash />
  },
  {
    name: 'Tools',
    to: 'tools',
    // note we need a known id since we use it as a parent
    id: 'tools',
    icon: <Icon name="tools" />
  },
  {
    name: 'Configuration',
    to: 'configuration',
    // note we need a known id since we use it as a parent
    id: 'configuration',
    icon: <Configuration />
  },
  {
    id: 'profile',
    name: 'Your profile',
    scope: 'organisation',
    to: 'profile'
  },
  {
    id: 'preferences',
    name: 'Manage organization',
    scope: 'organisation',
    to: 'preferences'
  },
  {
    id: 'environments',
    name: 'Manage environments',
    scope: 'organisation',
    to: 'environments'
  },
  {
    id: 'permissions',
    name: 'Workbench members',
    scope: 'organisation',
    to: 'permissions'
  }
]

import styled, {css} from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`

export const Label = styled.div`
  font-size: ${p => p.theme.fontSize};
  color: ${props => props.theme.black};
  display: flex;
  align-items: center;
  height: 30px;
`

export const StyledToggle = styled.div<{
  isIcon?: boolean
  isWithLabel?: boolean
  readOnly?: boolean
}>`
  display: inline-block;
  ${p => (!p.isIcon || p.isWithLabel) && `width: 200px;`}
  border-radius: ${p => p.theme.borderRadiusPill};
  background: ${p => p.theme.grey05};
  padding: 2px;
  transition: background 0.1s ease;
  ${({readOnly, theme}) =>
    !readOnly &&
    css`
      &:hover,
      &:focus-within {
        background: ${theme.grey10};
      }
    `}
`

export const Lozenge = styled.span`
  border-radius: ${p => p.theme.borderRadiusPill};
  background: ${p => p.theme.white};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition:
    box-shadow 0.1s ease,
    margin-left 0.3s ease;
  box-shadow: ${p => p.theme.shadowDepth1};
`

export const Option = styled.span<{isSmall?: boolean}>`
  position: relative;
  border-radius: ${p => p.theme.borderRadiusPill};
  transition: color 0.1s ease;
  pointer-events: none;
  ${p => (p.isSmall ? 'min-width: 30px;' : 'min-width: 36px;')}
  > input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    pointer-events: auto;
  }
`

export const Inner = styled.div<{
  count: number
  index: number
  isSmall?: boolean
  readOnly?: boolean
}>`
  display: flex;
  position: relative;
  ${p => (p.isSmall ? 'height: 30px;' : 'height: 36px;')}
  font-size: ${p => p.theme.fontSizeS};
  line-height: ${p => p.theme.lineHeightS};
  color: ${p => p.theme.blue};
  > ${Lozenge} {
    width: ${p => 100 / p.count + '%'};
    z-index: 0;
    margin-left: ${p => (p.index / p.count) * 100 + '%'};
  }
  > ${Option} {
    flex: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
    &:before {
      content: '';
      z-index: -1;
      width: 100%;
      height: 100%;
      position: absolute;
    }
    ${({readOnly, index, theme}) =>
      !readOnly &&
      css`
        &:hover,
        &:focus {
          color: ${theme.blueDark};
        }
        &:active {
          color: ${theme.blueDarkest};
          transition: background 0.1s ease;
          background: ${theme.grey05};
        }
        &:nth-child(${index + 1}) {
          &:hover,
          &:focus {
            & ~ ${Lozenge} {
              box-shadow: ${theme.shadowDepth2};
            }
            color: ${theme.blue};
          }
          &:active {
            background: transparent;
            & ~ ${Lozenge} {
              box-shadow: ${theme.shadowDepth1};
            }
            transition: background 0.1s ease;
          }
        }
      `}
  }
`

import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

// A quick attempt to implement a frecency
const FrecencyContext = createContext<
  | {
      visit: (id: string) => void
      sort: <T extends {id: string}>(items: T[]) => T[]
      getWeight: (id: string) => number
    }
  | undefined
>(undefined)

export const useFrecency = () => {
  const ctx = useContext(FrecencyContext)
  if (!ctx) {
    throw new Error('Calling useFrecency outside FrecencyProvider')
  }
  return ctx
}

type FrecencyState = {
  // maxVisits: number
  visited: {
    [hashedId: string]: {
      lastVisited: number
    }
  }
}

const ITEMS_TO_KEEP = 100

// keep the size down by only keeping the latest N visits
const pruneState = (state: FrecencyState): FrecencyState => {
  // eslint-disable-next-line functional/immutable-data
  const newVisited = Object.entries(state.visited)
    // desc (latest first)
    .sort((a, b) => b[1].lastVisited - a[1].lastVisited)
    .slice(0, ITEMS_TO_KEEP)

  return {
    visited: Object.fromEntries(newVisited)
  }
}

const saveState = (state: FrecencyState) => {
  localStorage.setItem('frecency_cmdk', JSON.stringify(state))
}

const loadState = (): FrecencyState | undefined => {
  // use some key prefix so that we could expand this provider to handle multiple 'stores' in future
  const saved = localStorage.getItem('frecency_cmdk')
  if (!saved) return undefined
  return JSON.parse(saved)
}

// halflife = 30 days
// with a long tail https://www.desmos.com/calculator/3qijhx7gxb
// https://wiki.mozilla.org/User:Jesse/NewFrecency
const DECAY = Math.E ** -(Math.log(2) / 30) // 0.977

// NOTE this is actually just a recency with a decay rather than 'frecency' since we don't track freq.
// could add frequency by keeping track of the dates that a command was run and the max number of visits
// then summing for a total score (and dividing by the potential most)
// however that seems like overkill for this
export const FrecencyProvider = ({children}: {children: ReactNode}) => {
  const [frecencyState, setFrecencyState] = useState<FrecencyState>(
    loadState() ?? {visited: {}}
  )

  const visit = useCallback((id: string) => {
    setFrecencyState(state => {
      const unixNowMs = Date.now()
      const newState = {
        ...state,
        visited: {
          ...state.visited,
          [simpleHash(id)]: {
            lastVisited: unixNowMs
          }
        }
      }
      const prunedState = pruneState(newState)
      setTimeout(() => saveState(prunedState), 0)
      return prunedState
    })
  }, [])

  // returns number between 0 - 1 (1 being most visited)
  const getWeight = useCallback(
    (id: string) => {
      const hashedId = simpleHash(id)
      const daysSinceLastVisit =
        hashedId in frecencyState.visited
          ? (Date.now() - frecencyState.visited[hashedId].lastVisited) /
            86_400_000_000 // milliseconds in a day
          : undefined

      // Apply decay
      const score =
        daysSinceLastVisit === undefined
          ? 0
          : Math.pow(DECAY, daysSinceLastVisit)
      return score
    },
    [frecencyState]
  )

  const context = useMemo(
    () => ({
      visit,
      sort: <T extends {id: string}>(toSort: T[]) =>
        [...toSort].sort(
          (a, b) => getWeight(simpleHash(b.id)) - getWeight(simpleHash(a.id))
        ),
      getWeight
    }),
    [getWeight, visit]
  )
  return (
    <FrecencyContext.Provider value={context}>
      {children}
    </FrecencyContext.Provider>
  )
}

// https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
const simpleHash = (str: string): string => {
  let hash = 0
  if (str.length === 0) return hash.toString()
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i)
    hash |= 0 // Convert to 32bit integer
  }
  return hash.toString()
}

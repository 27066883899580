import React from 'react'
import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'
import {Icon, IconName} from '../icon/icon'

export type Appearance =
  | 'primary'
  | 'secondary'
  | 'transparent'
  | 'tint'
  | 'raised'
  | 'secondary-light'
  | 'warning'
  | 'warning-secondary'
type Size = 'xsmall' | 'small' | 'large'
type Variant = 'color' | 'greyscale'
type Weight = 'bold' | 'light'
type Stretch = 'justify' | 'center'

interface StyledButtonProps {
  appearance?: Appearance // default primary
  size?: Size // default small
  variant?: Variant // default color
  weight?: Weight // default bold
  stretch?: Stretch // default center
  iconLeft?: IconName
  iconRight?: IconName
  rotateIcon?: number
  truncate?: boolean
  leftAlign?: boolean
  textAlign?: 'left' | 'right'
  isRounded?: boolean
  iconStyle?: React.CSSProperties
}

const primary = css`
  background-color: ${({theme}) => theme.blue};
  color: ${({theme}) => theme.white};
`
const secondary = css`
  background-color: ${({theme}) => theme.white};
  color: ${({theme}) => theme.blue};
  box-shadow: inset 0 0 0 1px ${({theme}) => theme.grey10};
`
const secondaryLight = css`
  background: ${({theme}) => theme.grey05};
  color: ${({theme}) => theme.grey100};
`
const transparent = css`
  background: none;
  color: ${({theme}) => theme.blue};
`
const tint = css`
  background-color: ${({theme}) => theme.grey05};
  color: ${({theme}) => theme.blue};
`
const warning = css`
  background-color: ${({theme}) => theme.red};
  color: ${({theme}) => theme.white};
`
const warningSecondary = css`
  background-color: ${({theme}) => theme.white};
  color: ${({theme}) => theme.red};
  box-shadow: inset 0 0 0 1px ${({theme}) => theme.grey10};
`

const xsmall = (leftAlign?: boolean, isRounded?: boolean) => css`
  min-height: 20px;
  line-height: ${({theme}) => theme.lineHeightXS};
  font-size: ${({theme}) => theme.fontSizeXS};
  margin-left: ${leftAlign ? '-5px' : 0};
  padding: ${isRounded ? '2.5px 10px' : '2.5px 5px'};
`
const small = (leftAlign?: boolean, isRounded?: boolean) => css`
  min-height: 30px;
  line-height: 20px;
  font-size: ${({theme}) => theme.fontSizeS};
  margin-left: ${leftAlign ? '-10px' : 0};
  padding: ${isRounded ? '5px 15px' : '5px 10px'};
`
const large = (leftAlign?: boolean, isRounded?: boolean) => css`
  min-height: 40px;
  line-height: 20px;
  font-size: ${({theme}) => theme.fontSize};
  margin-left: ${leftAlign ? '-15px' : 0};
  padding: ${isRounded ? '10px 20px' : '10px 15px'};
`
const color = css``

const greyscale = css`
  color: ${({theme}) => theme.black};
`
const raised = css`
  color: ${({theme}) => theme.blue};
  background: ${({theme}) => theme.white};
  box-shadow: ${({theme}) => theme.shadowDepth1};
`

const bold = css`
  font-weight: bold;
`
const light = css`
  font-weight: normal;
`
const xsIconRight = css`
  svg {
    margin-left: 0.125em;
  }
`
const xsIconLeft = css`
  svg {
    margin-right: 0.125em;
  }
`
const smallIconRight = css`
  svg {
    margin-left: 0.5em;
  }
`
const smallIconLeft = css`
  svg {
    margin-right: 0.5em;
  }
`
const largeIconRight = css`
  svg {
    margin-left: 0.5em;
  }
`
const largeIconLeft = css`
  svg {
    margin-right: 0.5em;
  }
`
const center = css`
  display: flex;
  width: 100%;
`
const justify = css`
  display: flex;
  width: 100%;
  > div {
    text-align: left;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
`

const truncate = css`
  max-width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  justify-content: flex-start;
  text-overflow: ellipsis;
`

const IconAligner = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1px; /* to prevent expanding beyond line height */
  transform: translateY(1px); /* to center with text */
`

const ContentAligner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

// This adds back the space removed by margins
const pseudo = `
  width: 1px;
  height: 100%;
  content: '';
  left: 0;
  top: 0;
`

const StyledSegment = styled.div<{appearance: Appearance}>`
  display: inline-flex;
  vertical-align: middle;

  // First, but not only button
  button:first-of-type:not(:only-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  // All but first button
  button:nth-of-type(n + 2) {
    ${p => {
      switch (p.appearance) {
        case 'secondary':
          return `margin-left: -1px;`
        default:
          return `margin-left: 1px;`
      }
    }}
  }

  // All middle buttons
  button:nth-of-type(n + 2):not(:last-child) {
    border-radius: 0;

    ${p =>
      p.appearance === 'secondary' &&
      `
      position: relative;
      &:after {${pseudo}}
      `}
  }

  // Last, but not only button
  button:last-of-type:not(:only-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    ${p =>
      p.appearance === 'secondary' &&
      `
      position: relative;
      &:after {${pseudo}}
      `}
  }

  // All but last button
  button:not(:last-child) {
    ${p =>
      p.appearance === 'secondary' &&
      `
      position: relative;
      &:before {${pseudo}}
      `}
  }
`

const buttonStyles = css<StyledButtonProps>`
  background-color: ${({theme}) => theme.black};
  min-height: 40px;
  text-decoration: none;
  transition:
    color 0.1s ease,
    background 0.1s ease,
    border 0.1s ease;

  border: none;
  border-radius: ${p =>
    p.isRounded ? `${p.theme.borderRadiusPill}` : `${p.theme.borderRadius}`};

  color: ${({theme}) => theme.white};
  font-size: ${({theme}) => theme.fontSize};

  line-height: 25px;
  ${p => {
    switch (p.textAlign) {
      case 'left':
        return 'text-align: left;'
      case 'right':
        return 'text-align: right;'
      default:
        return 'text-align: center;'
    }
  }}
  padding: 0;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  position: relative;

  &:focus {
    outline: none;
  }

  &[disabled] {
    cursor: not-allowed;
    ${p => {
      switch (p.appearance) {
        case 'primary':
          return `background-color: ${p.theme.grey50}`
        case 'secondary':
        case 'secondary-light':
        case 'warning-secondary':
          return `color: ${p.theme.grey50}`
        case 'raised':
          return `color: ${p.theme.grey50}`
        case 'tint':
          return `color: ${p.theme.grey50}`
        case 'transparent':
          return `color: ${p.theme.grey50}`
        default:
          return `background-color: ${p.theme.grey50}`
      }
    }}
  }

  &:not([disabled]) {
    &:focus,
    &:hover {
      text-decoration: none;
      ${p => {
        switch (p.appearance) {
          case 'primary':
            return `background-color: ${p.theme.blueDark};`
          case 'secondary-light':
            return `background-color: ${p.theme.grey10};`
          case 'secondary':
          case 'warning-secondary':
            return `background: linear-gradient(${p.theme.grey05}, ${p.theme.grey05}), linear-gradient(${p.theme.white}, ${p.theme.white});`
          case 'raised':
            return `background-color: ${p.theme.white};  box-shadow: ${p.theme.shadowDepth2};`
          case 'tint':
            return `background-color: ${p.theme.grey10};`
          case 'transparent':
            return `background-color: ${p.theme.grey05};`
          case 'warning':
            return `background-color: ${p.theme.redDark};`
          default:
            return `background-color: ${p.theme.blueDark};`
        }
      }}
    }
    &:active {
      ${p => {
        switch (p.appearance) {
          case 'primary':
            return `background-color: ${p.theme.blueDarkest}`
          case 'secondary':
          case 'warning-secondary':
            return `background: linear-gradient(${p.theme.grey10}, ${p.theme.grey10}), linear-gradient(${p.theme.white}, ${p.theme.white})`
          case 'secondary-light':
            return `background-color: ${p.theme.grey50};`
          case 'raised':
            return `background-color: ${p.theme.white}; box-shadow: ${p.theme.shadowDepth1}`
          case 'tint':
            return `background-color: ${p.theme.grey15}`
          case 'transparent':
            return `background-color: ${p.theme.grey10}`
          case 'warning':
            return `background-color: ${p.theme.redDarkest};`
          default:
            return `background-color: ${p.theme.blueDarkest}`
        }
      }}
    }
  }

  ${props => {
    switch (props.appearance) {
      case 'secondary':
        return secondary
      case 'secondary-light':
        return secondaryLight
      case 'raised':
        return raised
      case 'transparent':
        return transparent
      case 'tint':
        return tint
      case 'warning':
        return warning
      case 'warning-secondary':
        return warningSecondary
      default:
        return primary
    }
  }}

  ${props => {
    switch (props.size) {
      case 'large':
        return large(
          props.leftAlign && props.appearance === 'transparent',
          props.isRounded
        )
      case 'xsmall':
        return xsmall(
          props.leftAlign && props.appearance === 'transparent',
          props.isRounded
        )
      default:
        return small(
          props.leftAlign && props.appearance === 'transparent',
          props.isRounded
        )
    }
  }}

  ${props => {
    switch (props.variant) {
      case 'greyscale':
        return greyscale
      default:
        return color
    }
  }}

  ${props => {
    switch (props.weight) {
      case 'light':
        return light
      default:
        return bold
    }
  }}

  ${props => {
    if (props.iconLeft) {
      switch (props.size) {
        case 'large':
          return largeIconLeft
        case 'xsmall':
          return xsIconLeft
        default:
          return smallIconLeft
      }
    }
    return null
  }}

  ${props => {
    if (props.iconRight) {
      switch (props.size) {
        case 'large':
          return largeIconRight
        case 'xsmall':
          return xsIconRight
        default:
          return smallIconRight
      }
    }
    return null
  }}

  ${props => {
    switch (props.stretch) {
      case 'justify':
        return justify
      case 'center':
        return center
      default:
        return null
    }
  }}

  ${props => {
    switch (props.truncate) {
      case true:
        return truncate
      case false:
        return null
      default:
        return false
    }
  }}
`

const StyledButton = styled.button<StyledButtonProps>`
  ${buttonStyles}
`

interface BaseButtonProps extends StyledButtonProps {
  disabled?: boolean
  children?: React.ReactNode
  style?: React.CSSProperties
  title?: string
}

interface StandardButtonProps extends BaseButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLButtonElement>) => void
}

interface SubmitButtonProps extends BaseButtonProps {
  type: 'submit'
}

export type ButtonProps = StandardButtonProps | SubmitButtonProps

export const Button = React.forwardRef<HTMLElement, ButtonProps>(
  ({...props}, ref) => {
    const buttonInner = () => {
      const children = props.children ? props.children : null
      if (props.iconRight) {
        return (
          <ContentAligner>
            {children}
            <IconAligner>
              <Icon rotate={props.rotateIcon} name={props.iconRight} />
            </IconAligner>
          </ContentAligner>
        )
      }
      if (props.iconLeft) {
        return (
          <ContentAligner>
            <IconAligner>
              <Icon
                rotate={props.rotateIcon}
                name={props.iconLeft}
                style={props.iconStyle}
              />
            </IconAligner>
            {children}
          </ContentAligner>
        )
      }
      return children
    }
    return (
      <StyledButton
        {...props}
        type={'type' in props ? props.type : 'button'}
        ref={ref as React.Ref<HTMLButtonElement>}
      >
        {buttonInner()}
      </StyledButton>
    )
  }
)

const StyledLink = styled(
  // @ts-ignore className prop is magically provided by styled-components
  ({to, children, className, style}: RouterLinkButtonProps) => (
    <Link to={to} className={className} style={style}>
      {children}
    </Link>
  )
)`
  ${buttonStyles}
`

const StyledA = styled.a<StyledButtonProps>`
  ${buttonStyles}
`

interface AnchorButtonProps extends BaseButtonProps {
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLAnchorElement>) => void
}

interface RouterLinkButtonProps extends AnchorButtonProps {
  to: string
}

interface AButtonProps extends AnchorButtonProps {
  href: string
  target?: string
}

type LinkButtonProps = RouterLinkButtonProps | AButtonProps

export const LinkButton = (props: LinkButtonProps) => {
  const buttonInner = () => {
    const children = props.children ? props.children : null
    if (props.iconRight) {
      return (
        <ContentAligner>
          {children}
          <IconAligner>
            <Icon rotate={props.rotateIcon} name={props.iconRight} />
          </IconAligner>
        </ContentAligner>
      )
    }
    if (props.iconLeft) {
      return (
        <ContentAligner>
          <IconAligner>
            <Icon rotate={props.rotateIcon} name={props.iconLeft} />
          </IconAligner>
          {children}
        </ContentAligner>
      )
    }
    return children
  }
  return 'to' in props ? (
    <StyledLink {...props}>{buttonInner()}</StyledLink>
  ) : (
    <StyledA {...props}>{buttonInner()}</StyledA>
  )
}

type ButtonSegmentProps = {
  children: React.ReactNode
  appearance: Appearance
}

export const ButtonSegment = ({children, appearance}: ButtonSegmentProps) => {
  return <StyledSegment appearance={appearance}>{children}</StyledSegment>
}

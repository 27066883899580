import React, {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState
} from 'react'

export type Crumb = string | [string, string]

const CrumbContext = createContext<
  | {
      crumbs?: Crumb[]
      setCrumbs: (crumbs?: Crumb[]) => void
    }
  | undefined
>(undefined)

export const useCrumbs = () => {
  const context = useContext(CrumbContext)
  if (!context) {
    throw new Error('Calling useCrumbs outside CrumbProvider')
  }
  return context
}

export const CrumbProvider = ({children}: {children: ReactNode}) => {
  const [crumbs, setCrumbs] = useState<Crumb[] | undefined>()
  const context = useMemo(
    () => ({
      crumbs,
      setCrumbs
    }),
    [crumbs, setCrumbs]
  )

  return (
    <CrumbContext.Provider value={context}>{children}</CrumbContext.Provider>
  )
}

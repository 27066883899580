import {isRight} from 'fp-ts/lib/Either'
import * as t from 'io-ts'
import {nanoid} from 'nanoid'
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef
} from 'react'
import {
  getWorkbenchAnalyticsSharedSecret,
  NonOrgScopedEvent,
  OrgScopedEvent,
  parseAccountId,
  WorkbenchEvent,
  WorkbenchEventCodec,
  WorkbenchIdentifyEvent
} from '../../common/types/workbench-analytics'
import {ENVIRONMENT_NAME, ROUTING_API_URL} from '../../lib/env'
import {getAuth} from '../api'
import {notify} from '../bugsnag'
import {getApi} from '../fetch'
import {useOrganisations} from '../organisations'
import {getCookie} from '../cookies'
import {debounce} from '../helpers'

export type TrackEvent = {
  event: OrgScopedEvent['event']
  messageId?: string
  properties: Omit<OrgScopedEvent['properties'], 'email'>
}

type Identify = {
  userId?: WorkbenchIdentifyEvent['userId']
  messageId?: string
  anonymousIds?: WorkbenchIdentifyEvent['anonymousIds']
  traits: Omit<WorkbenchIdentifyEvent['traits'], 'zone' | 'account_id'>
}

export type Analytics = {
  identify: (event: Identify, immediate?: boolean) => Promise<void>
  track: (event: TrackEvent, immediate?: boolean) => Promise<void>
  trackNoOrg: (
    event: Omit<NonOrgScopedEvent, 'type' | 'userId'> & {messageId?: string},
    immediate?: boolean
  ) => Promise<void>
}

const ResponseCodec = t.type({
  data: t.type({ids: t.array(t.string)})
})

const WorkbenchEventsCodec = t.array(WorkbenchEventCodec)

const restoreLocalStorage = (key: string) => {
  const raw = localStorage.getItem(key)

  if (raw) {
    try {
      const json = JSON.parse(raw)
      const result = WorkbenchEventsCodec.decode(json)

      if (isRight(result)) {
        return result.right
      }
    } catch (e) {
      console.warn(`invalid json for ${key}`)
    }
    localStorage.removeItem(key)
  }
  return []
}

const saveLocalStorage = (key: string, events: WorkbenchEvent[]) => {
  localStorage.setItem(key, JSON.stringify(events))
}

type AnalyticsHelper = [instance: Analytics, cleanUp: () => void]

const init = ({
  getZone,
  batch
}: {
  getZone: (orgId: string) => Promise<string | undefined>
  batch: {size: number; interval: number; dedupeInterval?: number}
}): AnalyticsHelper => {
  const storageKey = 'workbench-analytics-events'
  let _batch: WorkbenchEvent[] = restoreLocalStorage(storageKey)
  let sendTimeout: ReturnType<typeof setTimeout>
  const maxFails = 5
  let failCount = 0

  const api = getApi()

  const _addToBatch = (event: WorkbenchEvent) => {
    _batch.push(event)
    saveLocalStorage(storageKey, _batch)
  }

  // record event in batch queue
  const debouncedBatchWrites = new Map<string, ReturnType<typeof debounce>>()
  const record = (event: WorkbenchEvent, immediate?: boolean) => {
    // fire it off straight away
    if (immediate) {
      _addToBatch(event)
      clearTimeout(sendTimeout)

      return send()
    }

    // else check if we need to queue up, checking for duplicates
    const eventKey = event.type === 'identify' ? 'identify' : event.event

    let debouncedAdd = debouncedBatchWrites.get(eventKey)
    if (!debouncedAdd) {
      // Ignore the same event already in the batch queue if seen within the debounce time
      debouncedAdd = debounce(_addToBatch, batch.dedupeInterval ?? 500)
      debouncedBatchWrites.set(eventKey, debouncedAdd)
    }
    debouncedAdd(event)

    return
  }

  // Call API here, calls to this function are queued via interval callback
  const send = async () => {
    if (_batch.length) {
      const events = [..._batch]
      const token = await getAuth().getIdToken()
      await api(
        'post',
        `${ROUTING_API_URL}/analytics`,
        {
          events
        },
        {
          ['x-atomic-token']:
            getWorkbenchAnalyticsSharedSecret(ENVIRONMENT_NAME),
          authorization: token ? `Bearer ${token}` : ''
        }
      )
        .then(res => {
          if (res.ok) {
            failCount = 0

            const result = ResponseCodec.decode(res.json)
            const sentIds = isRight(result)
              ? result.right.data.ids // new API response, returns valid event ids
              : events.map(e => e.messageId) // old API response returns a 200, assume all valid ( safe to remove in a future release)

            _batch = _batch.filter(
              a =>
                !(a.messageId
                  ? sentIds.includes(a.messageId)
                  : events.includes(a))
            )
            saveLocalStorage(storageKey, _batch)
          } else {
            failCount += 1
            notify('Error sending analytics', {
              body: res.body,
              status: res.status,
              events
            })
          }
        })
        .catch(e => {
          failCount += 1
          notify(e, {events})
        })
    }
    const interval =
      failCount > maxFails
        ? batch.interval * 10 // wait longer if we've failed multiple times
        : batch.interval

    sendTimeout = setTimeout(send, interval)
  }

  // helper to get user information auth state
  const getUser = () => {
    const auth = getAuth().getAuthState()
    if (auth.authenticated) {
      return {
        userId: auth.sub,
        properties: {email: auth.email, sub: auth.sub}
      }
    }
    notify('Analytics: User not authenticated')
    return {userId: undefined, properties: {email: ''}}
  }

  // helper to get current page context
  const getCurrentContext = () => {
    return {
      locale: navigator.language,
      page: {
        path: location.pathname,
        search: location.search,
        title: document.title,
        url: location.href
      },
      groupId: undefined,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  }

  const cleanUp = async () => {
    await send()
    clearTimeout(sendTimeout)
  }

  const identify = async (event: Identify, immediate?: boolean) => {
    const user = getUser()
    const zone = await getZone(event.traits.org_id)
    record(
      {
        // @ts-ignore, if this is undefined ( it shouldn't be )
        // send and let the API report
        userId: user.userId,
        ...event,
        messageId: event.messageId ?? nanoid(),
        traits: {
          ...event.traits,
          account_id: parseAccountId(event.traits.org_id) ?? '',
          zone,
          sub: user.properties.sub
        },
        ...getAnonymousIds(),
        context: getCurrentContext(),
        type: 'identify'
      },
      immediate
    )
  }

  const analytics: Analytics = {
    identify,
    track: async (event, immediate) => {
      const user = getUser()
      const accountId = parseAccountId(event.properties.org_id)
      const zone = await getZone(event.properties.org_id)

      // we want to 'identify' users who have logged into the workbench against their anonymous ids
      // this is just a convenient oft-called place to do so where we know we'll have all the user info we need
      identifyAnonymousUser(event.properties.org_id, identify)
      await record(
        {
          type: 'track',
          messageId: event.messageId ?? nanoid(),
          // @ts-ignore, if this is undefined ( it shouldn't be )
          // send and let the API report
          userId: user.userId,
          context: {
            ...getCurrentContext(),
            groupId: accountId
          },
          event: event.event,
          properties: {
            ...user.properties,
            ...event.properties,
            account_id: accountId,
            zone
          }
        },
        immediate
      )
    },
    trackNoOrg: async (event, immediate) => {
      const user = getUser()
      await record(
        {
          ...event,
          messageId: event.messageId ?? nanoid(),
          context: getCurrentContext(),
          type: 'track',
          // @ts-ignore, if this is undefined ( it shouldn't be )
          // send and let the API report
          userId: user.userId
        },
        immediate
      )
    }
  }

  sendTimeout = setTimeout(send, batch.interval)

  return [analytics, cleanUp]
}

const ANON_SEGMENT_COOKIE_NAME = 'ajs_anonymous_id'
const ANON_HUBSPOT_COOKIE = '__hstc'

const getAnonymousIds = (): {anonymousIds: Identify['anonymousIds']} => {
  let anonIds: Identify['anonymousIds'] = []
  const segmentCookie = getCookie(ANON_SEGMENT_COOKIE_NAME)
  if (segmentCookie)
    anonIds = [...anonIds, {service: 'segment', id: segmentCookie}]
  // Note currently we don't 'identify' users against hubspot from these calls
  // this is just in place for when we do
  const hubspotCookie = getCookie(ANON_HUBSPOT_COOKIE)
  if (hubspotCookie)
    anonIds = [...anonIds, {service: 'hubspot', id: hubspotCookie}]
  return {anonymousIds: anonIds}
}

const anonymousIdWasTracked = (cookieId: string) =>
  !!sessionStorage.getItem(`anon_id_${cookieId}`)

const markAnonymousIdAsTracked = (cookieId: string) =>
  // using session storage since there doesn't seem to be any reason not to and we should get
  // a higher hit rate as a result where any network issues occur
  sessionStorage.setItem(`anon_id_${cookieId}`, new Date().toISOString())

/** identify users to both hubspot & segment */
const identifyAnonymousUser = (
  orgId: string,
  identifyToSegment: Analytics['identify']
) => {
  if (!orgId) return
  // if we haven't identified the user against their anonymous token before
  const segmentCookie = getCookie(ANON_SEGMENT_COOKIE_NAME)
  const hubspotCookie = getCookie(ANON_HUBSPOT_COOKIE)
  if (
    (segmentCookie && !anonymousIdWasTracked(segmentCookie)) ||
    (hubspotCookie && !anonymousIdWasTracked(hubspotCookie))
  ) {
    // make the call to the segment identify function
    identifyToSegment({
      // Note: that the anonymous ids will be inserted in the 'identify' call
      traits: {
        org_id: orgId
      }
    })
    if (segmentCookie) markAnonymousIdAsTracked(segmentCookie)
    if (hubspotCookie) markAnonymousIdAsTracked(hubspotCookie)
  }
}

const AnalyticsContext = React.createContext<Analytics | undefined>(undefined)

export const AnalyticsProvider = ({children}: {children: ReactNode}) => {
  const [organisations] = useOrganisations()

  const organisationsRef = useRef(organisations)

  // update the organisations ref whenever organisations is updated
  // this ensures the getZone function can see the updated list
  useEffect(() => {
    organisationsRef.current = organisations
  }, [organisations])

  const analyticsRef = useRef<AnalyticsHelper>()

  // Initiate the Analytics helper
  // We only want to initiate this once, hence the useMemo
  analyticsRef.current = useMemo(() => {
    // getZone will find the zone name
    // within the organisations array reference
    const getZone = async (orgId: string, retry = 0) =>
      new Promise<string | undefined>(resolve => {
        const org = organisationsRef.current.find(o => o.id === orgId)
        if (org) {
          const zoneName = org?.attributes?.zoneName ?? undefined
          if (!zoneName) {
            notify(
              'Analytics: ZoneName is missing from organisation attributes',
              {orgId, attributes: org.attributes}
            )
          }
          resolve(zoneName)
        } else if (retry < 5) {
          // try again in a bit, the organisationsRef may have not updated yet
          setTimeout(() => {
            getZone(orgId, retry + 1).then(resolve)
          }, 100)
          return
        } else {
          notify('Analytics: Failed to find organisation id', {orgId})
          resolve(undefined)
        }
      })

    // Return the analytics helper now
    return init({
      getZone,
      batch: {size: 10, interval: 1000}
    })
  }, []) // <- No deps here as I don't want organisations changing to re-init analytics. hence the organisationsRef

  const [analytics, cleanUp] = analyticsRef.current

  useEffect(() => {
    return () => {
      // clean up analytics object when this component unmounts
      // this actually is unlikely to happen as we are a top level component
      cleanUp()
    }
  }, [cleanUp])

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export function useAnalytics() {
  const ctx = useContext(AnalyticsContext)

  if (!ctx) {
    throw new Error('Calling useAnalytics outside of AnalyticsProvider')
  }
  return ctx
}

export const useUserIdentifiedLink = () => {
  // TODO we want to use the derived user id from 'createWorkbenchUserId'
  // we're just omitting the id for now which means workbench users aren't 'identified' on the docs site

  const linkify = useCallback(
    (originalLink: string) => {
      // const sub = auth.authState.authenticated ? auth.authState.sub : undefined
      // const orgId = env.organisation?.id

      // if (!sub || !orgId || orgIsUnTrackable(orgId)) return originalLink
      // try {
      //   const url = new URL(originalLink)
      //   url.searchParams.append('userId', sub)
      //   return url.toString()
      // } catch (e) {
      //   console.error(e)
      //   return originalLink
      // }
      return originalLink
    },
    // [auth, env.organisation?.id]
    []
  )
  return linkify
}

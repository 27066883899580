import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import SVGCheck from '../../assets/icons/check-small.svg'
import {RoutingOrganisation} from '../../common/types'
import {useGetOrganisationAccessQuery} from '../../data/workbench-api/generated/organisation'
import {EnvironmentNode} from '../../environment-provider'
import {isMFAError} from '../../is-mfa-error'
import {getClient} from '../../lib/api'
import {rewritePath} from '../../lib/rewrite-path'
import {Button} from '../library/buttons'
import {
  DropdownBody,
  DropdownEmptyMessage,
  DropdownItem
} from '../library/dropdown/legacy'
import {Dropdown} from '../library/dropdown/dropdown'
import {Loader} from '../loader'
import {CrumbSwitcher} from './crumb'

type EnvironmentSwitcherProps = {
  organisations: RoutingOrganisation[]
  environment?: EnvironmentNode
}

export const OrganisationSwitcher = ({
  organisations,
  environment
}: EnvironmentSwitcherProps) => {
  const currentOrgName = organisations.find(
    org => org.id === environment?.organisationId
  )?.attributes.name
  return (
    <>
      <Dropdown
        disabled={!organisations.length}
        trigger={(handleClick, ref) => (
          <a onClick={handleClick} ref={ref as React.Ref<HTMLAnchorElement>}>
            <CrumbSwitcher>
              {currentOrgName ? (
                <strong>{currentOrgName}</strong>
              ) : (
                'Choose organisation'
              )}
            </CrumbSwitcher>
          </a>
        )}
      >
        {closeDropdown => (
          <>
            <DropdownBody style={{minWidth: '300px'}}>
              {organisations.map(org => (
                <OrganisationSwitcherList
                  key={org.id}
                  organisation={org}
                  currentOrgId={environment?.organisationId}
                  closeDropdown={closeDropdown}
                />
              ))}
            </DropdownBody>
          </>
        )}
      </Dropdown>
    </>
  )
}

const OrganisationTitle = styled.div`
  padding: 7px 16px;
  color: ${({theme}) => theme.colors.grey12};
  font-size: 13px;
  a {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &:hover {
    background: ${({theme}) => theme.colors.grey3};
    color: ${({theme}) => theme.colors.grey12};
  }
  &.active {
    color: ${({theme}) => theme.colors.blue11};
  }
`

type OrganisationSwitcherListProps = {
  organisation: RoutingOrganisation
  closeDropdown: () => void
  currentOrgId?: string
}

const OrganisationSwitcherList = ({
  organisation,
  closeDropdown,
  currentOrgId
}: OrganisationSwitcherListProps) => {
  const [mfaRequired, setMfaRequired] = useState(false)
  const navigate = useNavigate()

  // TODO - remove this once this information is available either via the
  // routing api, or via top level queries
  const {loading} = useGetOrganisationAccessQuery({
    variables: {id: organisation.id},
    client: getClient(organisation.id),
    onCompleted: () => setMfaRequired(false),
    onError: error => {
      if (isMFAError(error)) {
        setMfaRequired(true)
      }
    }
  })

  const switchLink = getSwitchLink(window.location.pathname, {
    orgId: organisation.id
  })

  if (loading) {
    return <Loader variant="inline" />
  }

  if (mfaRequired) {
    return (
      <DropdownItem padded={true} style={{maxWidth: '220px'}}>
        <DropdownEmptyMessage>
          {organisation.attributes.name} requires multi-factor authentication to
          access.
        </DropdownEmptyMessage>
        <Button // todo should be a link button but I can't get it to be small in that case
          style={{marginTop: '10px'}}
          iconLeft="key"
          size="small"
          appearance="secondary"
          stretch="center"
          onClick={() => {
            navigate('/mfa-setup')
            closeDropdown()
          }}
        >
          Enable MFA
        </Button>
      </DropdownItem>
    )
  }

  const active = organisation.id === currentOrgId

  return (
    <OrganisationTitle key={organisation.id} className={active ? 'active' : ''}>
      {
        // if we're on a different org then we can just redirect entirely with <a>
      }
      <a href={switchLink}>
        {organisation.attributes.name}
        {active && <SVGCheck />}
      </a>
    </OrganisationTitle>
  )
}

export const getSwitchLink = (
  path: string,
  replace: {
    orgId?: string
    envId?: string
  }
) => {
  const splitPath = window.location.pathname.split('/')
  const pathWithoutOrg = splitPath.splice(2).join('/')

  return rewritePath(
    [
      ['/:orgId/permissions/role/*', '/:orgId/permissions/roles'],
      ['/:orgId/permissions/group/*', '/:orgId/permissions/groups']
    ],
    replace,
    `/${replace.orgId}/${pathWithoutOrg}`
  )(path)
}

import QRCode from 'qrcode.react'
import React, {useEffect, useState} from 'react'
import {Button, LinkButton} from '../../../components/library/buttons'
import {CopyInput} from '../../../components/library/copy'
import {Loader} from '../../../components/loader'
import {Authenticator, AuthStateAuth} from '../../../lib/cognito'
import {BU_COGNITO_ENVIRONMENT_NAME} from '../../../lib/env'
import {
  StepperTitle,
  StepperMessage,
  StepperActions,
  StepperContent,
  StepperContain,
  StepperBody
} from '../../../components/library/stepper/stepper'
import styled from 'styled-components'
import {useSearchParams} from 'react-router-dom'

const poolName =
  BU_COGNITO_ENVIRONMENT_NAME === 'master' ? '' : BU_COGNITO_ENVIRONMENT_NAME

const issuer = `Atomic Workbench${poolName ? ` (${poolName})` : ''}`

const getAuthCode = (code: string, name: string) =>
  `otpauth://totp/${encodeURIComponent(
    name
  )}?secret=${code}&issuer=${encodeURIComponent(issuer)}`

export const Code = ({
  auth,
  authState
}: {
  auth: Authenticator
  authState: AuthStateAuth
}) => {
  const [searchParams] = useSearchParams()
  const searchString = searchParams.toString()

  const [code, setCode] = useState<string>()
  const [error, setError] = useState<string>()
  const [mode, setMode] = useState<'qr-code' | 'text'>('qr-code')

  useEffect(() => {
    const user = auth.pool.getCurrentUser()
    if (user && !code) {
      user.getSession(() => {
        user.associateSoftwareToken({
          associateSecretCode: secretCode => {
            setCode(secretCode)
          },
          onFailure: err => {
            setError(err.message)
          }
        })
      })
    }
  }, [code, auth.pool])

  return (
    <StepperBody>
      {code && mode === 'qr-code' && (
        <StepperContent>
          <StepperTitle>Scan QR code</StepperTitle>
          <StepperMessage>
            Scan the following QR code with the authentication app on your
            phone. Once complete, you’ll receive a 6-digit code which you’ll
            need for the following step.
          </StepperMessage>
          <StepperContain>
            <QRWrap>
              <QRContent>
                <QRCode value={getAuthCode(code, authState.email)} />
              </QRContent>
            </QRWrap>
            <StepperActions>
              <LinkButton
                stretch="center"
                size="large"
                to={`../verify${searchString.length ? `?${searchString}` : ''}`}
              >
                Code received
              </LinkButton>
              <Button
                size="large"
                appearance="transparent"
                onClick={() => setMode('text')}
              >
                Enter text key instead
              </Button>
            </StepperActions>
          </StepperContain>
        </StepperContent>
      )}
      {code && mode === 'text' && (
        <StepperContent>
          <StepperTitle>Enter text key</StepperTitle>
          <StepperMessage>
            Enter the following text key into the authentication app on your
            phone. Once complete, you’ll receive a 6-digit code which you’ll
            need for the following step.
          </StepperMessage>

          <CopyWrap>
            <CopyInput value={code} />
          </CopyWrap>
          <StepperContain>
            <StepperActions>
              <LinkButton stretch="center" size="large" to="../verify">
                Code received
              </LinkButton>
              <Button
                size="large"
                appearance="transparent"
                onClick={() => setMode('qr-code')}
              >
                Scan QR code instead
              </Button>
            </StepperActions>
          </StepperContain>
        </StepperContent>
      )}
      {!code && <Loader variant="overlay" />}
      {error && (
        <div>
          <p>{error}</p>
        </div>
      )}
    </StepperBody>
  )
}

const QRWrap = styled.div`
  width: 100%;
  max-width: 400px;
  height: 400px;
  background: ${({theme}) => theme.grey05};
  border-radius: ${({theme}) => theme.borderRadius};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  @media (max-height: 800px) {
    height: 200px;
    max-width: 200px;
  }
`

const QRContent = styled.div`
  width: 200px;
  height: 200px;
  mix-blend-mode: multiply;
  canvas {
    width: 100% !important;
    height: 100% !important;
  }

  @media (max-height: 800px) {
    height: 160px;
    max-width: 160px;
  }
`

const CopyWrap = styled.div`
  width: 100%;
  max-width: 400px;
  margin-bottom: 30px;
`

import * as t from 'io-ts'
import {validate} from '../common/types/validate'

const WorkbenchConfigCodec = t.type({
  ROUTING_API_URL: t.string,
  CUSTOMER_API_URL: t.string,
  CLIENT_API_URL: t.string,
  BU_COGNITO_POOL_ID: t.string,
  BU_COGNITO_APP_ID: t.string,
  BU_COGNITO_REGION: t.string,
  ENVIRONMENT_NAME: t.union([
    t.literal('development'),
    t.literal('local'),
    t.literal('pollution'),
    t.literal('staging'),
    t.literal('master')
  ]),
  BU_COGNITO_ENVIRONMENT_NAME: t.union([
    t.literal('development'),
    t.literal('pollution'),
    t.literal('master')
  ]),
  APP_VERSION: t.string,
  WORKBENCH_CLIENT_URL: t.string,
  SFMC_PLUGIN_URL: t.string,
  APP_FLAVOUR: t.union([t.literal('workbench'), t.literal('sfmc-plugin')])
})

if (!('atomic_config' in window)) {
  throw new Error('window.atomic_config undefined')
}

const buildConfig = {
  APP_FLAVOUR: process.env.APP_FLAVOUR
}

const configValidated = validate(
  {...buildConfig, ...window.atomic_config},
  WorkbenchConfigCodec
)
if (!configValidated.valid) {
  throw new Error(
    `Invalid config in window.atomic_config: ${configValidated.errors.join(
      ', '
    )}`
  )
}

const {
  ROUTING_API_URL,
  CUSTOMER_API_URL,
  CLIENT_API_URL,
  BU_COGNITO_POOL_ID,
  BU_COGNITO_APP_ID,
  BU_COGNITO_REGION,
  BU_COGNITO_ENVIRONMENT_NAME,
  ENVIRONMENT_NAME,
  WORKBENCH_CLIENT_URL,
  SFMC_PLUGIN_URL,
  APP_VERSION,
  APP_FLAVOUR
} = configValidated.data

export {
  ROUTING_API_URL,
  CUSTOMER_API_URL,
  CLIENT_API_URL,
  BU_COGNITO_POOL_ID,
  BU_COGNITO_APP_ID,
  BU_COGNITO_REGION,
  BU_COGNITO_ENVIRONMENT_NAME,
  ENVIRONMENT_NAME,
  WORKBENCH_CLIENT_URL,
  SFMC_PLUGIN_URL,
  APP_VERSION,
  APP_FLAVOUR
}

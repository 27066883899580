import {gql} from '@apollo/client'
import {GraphQLFormattedError} from 'graphql'
import QRCode from 'qrcode.react'
import React, {useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useTestUserListQuery} from '../data/workbench-api/generated/end-user'
import {WebAppFlavour} from '../demo-login/app'
import {EnvironmentNode} from '../environment-provider'
import {useContainers} from '../hooks/use-containers'
import {useEnvPreferences} from '../hooks/use-env-preferences'
import {useFlags} from '../hooks/use-flags'
import {useAnalytics} from '../lib/analytics/analytics'
import {ENVIRONMENT_NAME} from '../lib/env'
import {useUpsertMagicLinkMutation} from './generated/magic-link-composer'
import {IconButton, LinkButton} from './library/buttons'
import {CopyInput} from './library/copy'
import {Select} from './library/form'
import {Toggle} from './library/toggles/toggle'
import {Loader} from './loader'
import {createAuthenticatedMagicLink, createMagicLink} from './magic-links'
import {SelectContainer} from './select-container'

export type MagicLinkModalTypes = 'container' | 'user' | 'new' | 'profile'

type AuthdMagicLinkParts = {
  environmentId: string
  streamContainerId: string
  organisationId: string
  endUserId?: string
  appFlavour: WebAppFlavour
}

type RegularMagicLinkParts = {
  environmentId: string
  streamContainerId: string
  organisationId: string
  authSecret: string
  endUserId: string
  appFlavour: WebAppFlavour
}

type MagicLinkParts = AuthdMagicLinkParts | RegularMagicLinkParts

const Spacer = styled.div`
  height: 20px;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: ${p => p.theme.shadowDepth2};
  border: 1px solid ${p => p.theme.grey10};
  border-radius: ${p => p.theme.borderRadius};
`
const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 30px;
  font-size: ${p => p.theme.fontSize};
  line-height: ${p => p.theme.lineHeightL};
  h4 {
    color: ${p => p.theme.grey100};
    font-size: ${p => p.theme.fontSizeL};
    line-height: ${p => p.theme.lineHeightXL};
    margin: 0px 0px 20px 0px;
  }
  p {
    color: ${p => p.theme.grey50};
    margin: 0px;
  }
  button {
    margin: -5px -10px -5px 5px;
    flex: none;
  }
`

const MagicLinkSection = styled(Section)`
  background: ${p => p.theme.grey05};
`

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const QRWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  background: ${({theme}) => theme.white};
  align-items: center;
  justify-content: center;
  font-weight: bold;
`

type MagicLinkComposerProps = {
  handleClose?: () => void
  organisationId: string
  environmentId: string
  containerId?: string
  defaultUserId?: string
  userName?: string
  context: MagicLinkModalTypes
  requiresAuthentication: boolean
  isModal?: boolean
  environment?: EnvironmentNode
}

const useMakeMagicLink = (
  requiresAuthentication: boolean,
  mlConfig: MagicLinkParts | undefined,
  appFlavour: WebAppFlavour
) => {
  const [upsertAuthnMagicLinkMutation] = useUpsertMagicLinkMutation()

  const [magicLink, setMagicLink] = useState<{
    link?: string
    loading: boolean
    errors?: ReadonlyArray<GraphQLFormattedError>
  }>({link: undefined, loading: false, errors: undefined})

  useEffect(() => {
    /** effectively cancel the promise result */
    let ignoreResult = false

    if (!mlConfig) {
      setMagicLink({link: undefined, loading: false})
      return
    }
    // we can return early based on the env config or the appFlavour
    if (!requiresAuthentication || appFlavour !== 'demo') {
      if ('authSecret' in mlConfig) {
        setMagicLink({
          link: createMagicLink(mlConfig),
          loading: false
        })
        return
      }
      setMagicLink({link: undefined, loading: false})
      return
    }

    setMagicLink({loading: true})
    upsertAuthnMagicLinkMutation({
      variables: {
        endUserId: mlConfig.endUserId,
        environmentId: mlConfig.environmentId,
        streamContainerId: mlConfig.streamContainerId
      }
    }).then(({data, errors}) => {
      if (ignoreResult) return
      if (errors || !data?.upsertMagicLink?.magicLink?.id) {
        setMagicLink({link: undefined, loading: false, errors})
        return
      }

      const link = createAuthenticatedMagicLink({
        environmentId: mlConfig.environmentId,
        organisationId: mlConfig.organisationId,
        magicLinkId: data.upsertMagicLink.magicLink.id
      })
      setMagicLink({link, loading: false})
    })
    return () => {
      // if we get another request before this one completes
      ignoreResult = true
    }
  }, [
    mlConfig,
    requiresAuthentication,
    appFlavour,
    upsertAuthnMagicLinkMutation,
    setMagicLink
  ])

  return magicLink
}

useMakeMagicLink.fragments = {
  magicLink: gql`
    fragment UseMakeMagicLink on MagicLink {
      id
      environmentId
    }
  `
}

gql`
  mutation UpsertMagicLink(
    $streamContainerId: String!
    $endUserId: String
    $environmentId: String!
  ) {
    upsertMagicLink(
      input: {
        streamContainerId: $streamContainerId
        endUserId: $endUserId
        environmentId: $environmentId
      }
    ) {
      magicLink {
        ...UseMakeMagicLink
      }
    }
  }

  ${useMakeMagicLink.fragments.magicLink}
`

export const MagicLinkComposer = (
  props: Omit<MagicLinkComposerProps, 'requiresAuthentication'>
) => {
  // If the environment 'requires authentication' then we need to
  // persist the magic link via graphql and use the id rather than exposing
  // the authSecret, streamContainer & endUserId directly in the link
  const {loading, preferences} = useEnvPreferences(props.environmentId)
  const requiresAuthentication =
    preferences?.magicLinkConfiguration?.requiresAuthentication

  if (loading) return null
  return (
    <MagicLinkInner
      {...props}
      requiresAuthentication={!!requiresAuthentication}
    />
  )
}

/** When selecting the user for a magic link we allow an empty option of 'any workbench member' */
const PLACEHOLDER_ANY_WORKBENCH_MEMBER = 'ATOMIC__ANY_WORKBENCH_USER'

const MagicLinkInner = ({
  handleClose,
  environmentId,
  organisationId,
  containerId,
  defaultUserId: userId,
  userName,
  context,
  requiresAuthentication,
  isModal = true,
  environment
}: MagicLinkComposerProps) => {
  const analytics = useAnalytics()
  const {data: userData, loading: loadingUsers} = useTestUserListQuery({
    variables: {environmentId}
  })

  // the demo app only works in production, so default to shell for non-prod
  const [appFlavour, setAppFlavour] = useState<WebAppFlavour>(
    ENVIRONMENT_NAME !== 'master' ? 'shell' : 'demo'
  )

  const isAuthenticatedLink = appFlavour === 'demo' && requiresAuthentication

  const users = (userData?.endUsers.nodes ?? []).filter(
    // hide the option for the logged in workbench member if the link is authenticated
    // they can use the 'any workbench member' option (added below)
    u => !!u.authSecret && (!isAuthenticatedLink || !u.businessUserAccountId)
  )

  const [containerIdState, setContainerIdState] = useState<string | undefined>(
    containerId
  )

  const [endUserId, setEndUserId] = useState<string | undefined>(
    isAuthenticatedLink && context !== 'user'
      ? PLACEHOLDER_ANY_WORKBENCH_MEMBER
      : userId
  )

  // update the state to the 'default option' when the conditions change (probably app_flavour changed)
  // EXCEPT when the context is 'user' which should constrain the userId to what was passed in
  useEffect(() => {
    if (
      context !== 'user' &&
      (endUserId === PLACEHOLDER_ANY_WORKBENCH_MEMBER || endUserId === userId)
    )
      setEndUserId(
        isAuthenticatedLink ? PLACEHOLDER_ANY_WORKBENCH_MEMBER : userId
      )
  }, [isAuthenticatedLink, userId, endUserId, context])

  const [streamContainers] = useContainers(
    organisationId,
    environmentId,
    data => {
      if (!containerId) {
        setContainerIdState(
          data.environment?.streamContainers.nodes.at(0)?.id as
            | string
            | undefined
        )
      }
    }
  )
  const flags = useFlags(organisationId)

  const userOptions = users
    .map(c => ({
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      text: c.nickname || c.organisationBusinessUserAccount?.email || c.id,
      value: c.id
    }))
    .concat(
      isAuthenticatedLink
        ? [
            {
              text: 'Any workbench member',
              value: PLACEHOLDER_ANY_WORKBENCH_MEMBER
            }
          ]
        : []
    )

  const authSecret = users.find(u => u.id === endUserId)?.authSecret

  const userIsPresent = !!endUserId

  // 'validate' link parts by making sure the necessary fields are present
  // for either the Authenticated or Regular link
  // means we get a stable identity for the useMagicLink hook
  const linkParts: MagicLinkParts | undefined = useMemo(() => {
    if (!containerIdState || !endUserId) return undefined // note endUserId may be the placeholder here
    if (isAuthenticatedLink)
      return {
        appFlavour,
        environmentId,
        organisationId,
        streamContainerId: containerIdState,
        // 'any workbench member' represents no user id, replace it with undefined for persistence
        endUserId:
          endUserId === PLACEHOLDER_ANY_WORKBENCH_MEMBER ? undefined : endUserId
      }
    if (!authSecret) return undefined
    return {
      appFlavour,
      environmentId,
      organisationId,
      authSecret,
      streamContainerId: containerIdState,
      endUserId
    }
  }, [
    appFlavour,
    authSecret,
    containerIdState,
    endUserId,
    environmentId,
    organisationId,
    isAuthenticatedLink
  ])

  const {link, loading} = useMakeMagicLink(
    requiresAuthentication,
    linkParts,
    appFlavour
  )

  const containerName =
    containerIdState && streamContainers
      ? streamContainers.find(c => c.id === containerIdState)?.name
      : undefined

  const title = isModal
    ? context === 'user'
      ? 'Magic auth link for ' + userName
      : context === 'container'
        ? 'Magic auth link for container ' + containerName
        : 'Magic auth link'
    : `Atomic Connect`
  const description = isModal
    ? `To log into the demo app,
    ${context === 'user' ? 'select a container' : ''}
    ${context === 'new' ? 'select a container and test account' : ''}
    ${context === 'container' ? 'select a test account' : ''}
    to generate a magic auth link.`
    : `Open Atomic Connect to receive and interact with test \
      cards sent to you from the ${environment?.organisationName} \
      ${environment?.name} environment.`

  return (
    <Wrapper>
      <Section>
        <HeadingWrapper>
          <h4>{title}</h4>
          {handleClose && (
            <IconButton icon={'close'} size={'large'} onClick={handleClose} />
          )}
        </HeadingWrapper>
        <p>{description}</p>
        {flags.isInternal && (
          <>
            <Spacer />
            <Toggle
              style={{width: '100%'}}
              value={appFlavour}
              options={[
                {value: 'alpha', text: 'Alpha app'},
                {value: 'shell', text: 'Shell app'},
                {value: 'demo', text: 'Atomic Connect'}
              ]}
              onChange={val => {
                if (
                  // make sure we don't have an invalid state set for the combo of app flavour and end user
                  // note this should probably use a reducer or some kind of shared state
                  val === 'demo' &&
                  requiresAuthentication &&
                  endUserId === userId &&
                  context !== 'user'
                ) {
                  setEndUserId(PLACEHOLDER_ANY_WORKBENCH_MEMBER)
                }
                setAppFlavour(val)
              }}
              legacyMode={true}
            />
          </>
        )}
        {context !== 'container' && (
          <>
            <Spacer />
            <SelectContainer
              streamContainers={streamContainers ?? undefined}
              onChange={setContainerIdState}
              containerId={containerIdState}
            />
          </>
        )}
        {context !== 'user' && (
          <>
            <Spacer />
            <Select
              iconLeft="profile"
              label={loadingUsers ? 'Loading...' : 'Select test account...'}
              upLabel="Test account"
              options={userOptions}
              value={endUserId}
              onChange={setEndUserId}
              disabled={loadingUsers}
            />
          </>
        )}
      </Section>

      {userIsPresent && containerIdState && (
        <MagicLinkSection>
          {link && !loading ? (
            <>
              <div>
                <LinkButton
                  style={{width: '100%'}}
                  size="large"
                  href={link}
                  onClick={() => {
                    analytics.track({
                      event: 'Opened atomic connect link',
                      properties: {
                        category: 'general',
                        org_id: organisationId,
                        environment_id: environmentId
                      }
                    })
                  }}
                  target="_blank"
                  iconRight="external"
                  textAlign="left"
                >
                  Open in browser
                </LinkButton>
              </div>
              <Spacer />
              <QRWrapper>
                Scan to open on mobile
                <Spacer />
                <QRCode renderAs="svg" size={200} value={link} />
              </QRWrapper>
              <Spacer />
              <CopyInput
                iconLeft={isAuthenticatedLink ? 'padlock' : undefined}
                value={link}
                onCopy={() => {
                  analytics.track({
                    event: 'Copied atomic connect link',
                    properties: {
                      category: 'general',
                      org_id: organisationId,
                      environment_id: environmentId
                    }
                  })
                }}
                copyText="Copy link"
              ></CopyInput>
            </>
          ) : (
            <Loader />
          )}
        </MagicLinkSection>
      )}
    </Wrapper>
  )
}

import {
  ActionId,
  ActionImpl,
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarProvider,
  KBarResults,
  KBarSearch,
  useMatches
} from 'kbar'
import React, {ReactNode} from 'react'
import styled from 'styled-components'
import {RoutingOrganisation} from '../common/types'
import {EnvironmentNode} from '../environment-provider'
import {useFrecency} from '../providers/frecency'
import {defaultActions, useEnvironmentActions} from './provider'

const ThemedKBarAnimator = styled(KBarAnimator)`
  background: white; // theme colors are transparent so give them a background
  border-radius: ${({theme}) => theme.borderRadius};
  box-shadow: ${({theme}) => theme.shadowDepth2};
  overflow: hidden;
`

const ThemedKBarSearch = styled(KBarSearch)`
  padding: 12px 16px;
  font-size: ${props => props.theme.fontSize};
  width: 90vw;
  max-width: 600px;
  box-sizing: border-box;
  outline: none;
  border: none;
  background: ${props => props.theme.grey10};
`

const ThemedKBarPositioner = styled(KBarPositioner)`
  z-index: 20;
  background: ${({theme}) => theme.grey20};
`

export const KBarProviderWithFrecency = ({children}: {children: ReactNode}) => {
  const frecency = useFrecency()

  return (
    <KBarProvider
      actions={defaultActions}
      options={{
        callbacks: {
          onSelectAction: action => {
            frecency.visit(action.id)
          }
        }
      }}
    >
      {children}
    </KBarProvider>
  )
}

type AtomicKBarProps = {
  organisationId: string
  environment?: EnvironmentNode
  signOut: () => void
  organisations: RoutingOrganisation[]
}
export const AtomicKBar = ({
  organisationId,
  environment,
  signOut,
  organisations
}: AtomicKBarProps) => {
  useEnvironmentActions(organisations, organisationId, environment, signOut)

  return (
    <KBarPortal>
      <ThemedKBarPositioner>
        <ThemedKBarAnimator>
          <ThemedKBarSearch />
          <RenderResults />
        </ThemedKBarAnimator>
      </ThemedKBarPositioner>
    </KBarPortal>

    // TODO add the MagicLink Modal in
    // (will need to iron out some rendering issues)
    // <MagicLinkModal />
  )
}

const RenderResults = () => {
  const {results, rootActionId} = useMatches()

  return (
    <KBarResults
      items={results}
      onRender={({item, active}) =>
        typeof item === 'string' ? (
          <div
            style={{
              padding: '8px 16px',
              fontSize: '10px',
              textTransform: 'uppercase',
              opacity: 0.5
            }}
          >
            {item}
          </div>
        ) : (
          <ResultItem
            action={item}
            active={active}
            currentRootActionId={rootActionId}
          />
        )
      }
    />
  )
}

// ------- Result Item ---------
const ResultItemWrapper = styled.div<{active: boolean}>`
  padding: 12px 16px;
  background: ${({active, theme}) => (active ? theme.grey20 : theme.white)};
  border-left: 2px solid
    ${({active, theme}) => (active ? theme.blue : theme.white)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: ${({active, theme}) => (active ? theme.blue : theme.grey80)};
`
const ResultItemContent = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14;
`

const ItemSubtitle = styled.span`
  font-size: ${({theme}) => theme.fontSizeXS};
`

const ResultItem = React.forwardRef(
  (
    {
      action,
      active,
      currentRootActionId
    }: {
      action: ActionImpl
      active: boolean
      currentRootActionId: ActionId | null | undefined
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const ancestors = React.useMemo(() => {
      if (!currentRootActionId) return action.ancestors
      const index = action.ancestors.findIndex(
        ancestor => ancestor.id === currentRootActionId
      )
      // +1 removes the currentRootAction; e.g.
      // if we are on the "Set theme" parent action,
      // the UI should not display "Set theme… > Dark"
      // but rather just "Dark"
      return action.ancestors.slice(index + 1)
    }, [action.ancestors, currentRootActionId])

    // find the parent icon so we can fallback if necessary
    const parentIcon = React.useMemo(
      () => [...ancestors].reverse().find(a => a.icon)?.icon,
      [ancestors]
    )

    return (
      <ResultItemWrapper ref={ref} active={active}>
        <ResultItemContent>
          {(action.icon && action.icon) || parentIcon || null}
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div>
              {ancestors.length > 0 &&
                ancestors.map(ancestor => (
                  <React.Fragment key={ancestor.id}>
                    <span style={{opacity: '0.5px', marginRight: '8px'}}>
                      {ancestor.name}
                    </span>
                    <span style={{marginRight: '8px'}}>&rsaquo;</span>
                  </React.Fragment>
                ))}
              <span>{action.name}</span>
            </div>
            {action.subtitle && (
              <ItemSubtitle> {action.subtitle} </ItemSubtitle>
            )}
          </div>
        </ResultItemContent>
        {action.shortcut?.length ? (
          <Shortcut aria-hidden>
            {action.shortcut.map(sc => (
              <Kbd key={sc}>{sc}</Kbd>
            ))}
          </Shortcut>
        ) : null}
      </ResultItemWrapper>
    )
  }
)

const Shortcut = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 4px;
`

const Kbd = styled.kbd`
  padding: 4px 6px;
  background: rgba(0 0 0 / 0.1);
  border-radius: 4px;
  font-size: 14px;
`

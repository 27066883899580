import React from 'react'
import styled from 'styled-components'
import Logo from '../../assets/icons/logo.svg'
import {Icon, IconName} from '../../components/library/icon/icon'

export const AuthButtonGroup = styled.div`
  margin: 10px 0 20px;
`

const StyledMedia = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({theme}) => theme.grey05};
  border-radius: ${({theme}) => theme.borderRadiusPill};
  margin-bottom: 30px;
  align-self: center;
`

export const AuthLargeHeader = styled.h1`
  font-size: ${p => p.theme.fontSizeXXL};
  text-align: center;
  margin: 20px 0 0;
`

export const AuthSmallHeader = styled.h3`
  font-size: ${p => p.theme.fontSizeL};
  text-align: center;
  margin: 0;
`
export const AuthButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const AuthMessage = styled.p`
  font-size: ${p => p.theme.fontSize};
  text-align: center;
  color: ${p => p.theme.grey50};
  margin: 20px 0;
`

export const P = styled.p`
  text-align: center;
  margin-bottom: 20px;
  color: ${p => p.theme.grey50};
`

export const Error = styled.div<{margin?: string}>`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.red};
  min-height: 60px;
  border-radius: ${p => p.theme.borderRadius};
  padding: 10px 20px;
  align-self: stretch;
  margin: ${p => (p.margin ? p.margin : '0 0 10px')};
  background: ${p => p.theme.redLightest};
`

interface AuthMediaProps {
  icon: IconName
}

export const AuthMedia = ({icon}: AuthMediaProps) => (
  <StyledMedia>
    <Icon name={icon} />
  </StyledMedia>
)

export const AuthLogo = () => <Logo />

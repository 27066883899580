import React from 'react'
import * as t from 'io-ts'
import {isRight} from 'fp-ts/lib/Either'
import reporter from 'io-ts-reporters'
import styled from 'styled-components'

import {ModalContainer} from '../components/library/modal/modal'
import loader from '../assets/loader.gif'

const AtomicSDKOnButtonPressedCodec = t.type({
  action: t.literal('iframe'),
  properties: t.type({
    url: t.string,
    type: t.union([t.literal('vimeo'), t.literal('page')]),
    title: t.string,
    width: t.string,
    aspectRatio: t.string,
    backgroundColor: t.string
  })
})

export type AtomicSDKOnButtonPressed = t.TypeOf<
  typeof AtomicSDKOnButtonPressedCodec
>

const Container = styled.div<{
  width: string
  aspectRatio: string
  backgroundColor: string
}>`
  width: ${p => p.width};
  aspect-ratio: ${p => p.aspectRatio};
  background-color: ${p => p.backgroundColor};
  border-radius: 8px;
`

const VimeoWrapper = styled.div`
  padding: 56.25% 0 0 0;
  position: relative;
`

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: none;
  background: url(${loader}) center/60px no-repeat;
`

export const onButtonPressed =
  (setModalData: (d: AtomicSDKOnButtonPressed | undefined) => void) =>
  (data: unknown) => {
    if (!data) return
    if (!(typeof data === 'object')) return
    if (!('actionPayload' in data)) return

    const decoded = AtomicSDKOnButtonPressedCodec.decode(data.actionPayload)
    if (!isRight(decoded)) {
      console.warn('onButtonPressed: invalid data', {
        data,
        errors: reporter.report(decoded)
      })
      return
    }
    setModalData(decoded.right)
  }

export const AtomicSourcedModal = ({
  data,
  setModalData
}: {
  data: AtomicSDKOnButtonPressed
  setModalData: (d: AtomicSDKOnButtonPressed | undefined) => void
}) => {
  return (
    <ModalContainer
      handleClose={() => {
        setModalData(undefined)
      }}
    >
      {() =>
        data.properties.type === 'vimeo' ? (
          <>
            <Container
              width={data.properties.width}
              aspectRatio={data.properties.aspectRatio}
              backgroundColor={data.properties.backgroundColor}
            >
              <VimeoWrapper>
                <Iframe
                  src={data.properties.url}
                  title={data.properties.title}
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                ></Iframe>
              </VimeoWrapper>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </Container>
          </>
        ) : (
          <Container
            width={data.properties.width}
            aspectRatio={data.properties.aspectRatio}
            backgroundColor={data.properties.backgroundColor}
          >
            <Iframe
              src={data.properties.url}
              title={data.properties.title}
            ></Iframe>
          </Container>
        )
      }
    </ModalContainer>
  )
}

import type {CmdkActionGetter} from '../../cmdk/types'
import type {IconName} from '../../components/library/icon/icon'
import type {Flags} from '../../hooks/use-flags'

export const flagFilter = (flags?: Flags) => (element: CategoryTool) =>
  !!flags && (!element.preview || flags.featurePreview)

type CategoryTool = {
  title: string
  description: string
  link: string
  docLink?: string
  preview?: boolean
  beta?: boolean
}
type Category = {
  icon: IconName
  title: string
  elements: CategoryTool[]
}

export const getActionsForToolbar: CmdkActionGetter = flags =>
  categories.flatMap(category =>
    category.elements.filter(flagFilter(flags)).map(element => ({
      name: element.title,
      to: element.link.replace('../', ''),
      parent: 'tools'
    }))
  )

export const categories: Category[] = [
  {
    icon: 'settings_nav',
    title: 'Configuration',
    elements: [
      {
        title: 'SDK request debugger',
        description:
          'View recent client API requests, to help with debugging your SDK integration.',
        link: '../../activity-log'
      },
      {
        title: 'Analytics debugger',
        description:
          'View and download the last 1,000 analytics events received.',
        link: '../analytics',
        docLink:
          'https://documentation.atomic.io/guide/tools/analytics-debugger'
      }
    ]
  },
  {
    icon: 'configure',
    title: 'Testing',
    elements: [
      {
        title: 'Test Action Flow sender',
        description: 'Batch and send test Action Flows.',
        link: '../testing-tools/batch-send',
        docLink:
          'https://documentation.atomic.io/guide/tools/test-action-flow-sender'
      },
      {
        title: 'Validate auth token',
        description: 'Validate an auth token against an API key.',
        link: '../testing-tools/validate-auth-token',
        docLink:
          'https://documentation.atomic.io/guide/tools/validate-auth-token'
      }
    ]
  },
  {
    icon: 'configure',
    title: 'Operations',
    elements: [
      {
        title: 'Analytics exporter',
        description: 'Batched analytics files available to download.',
        link: '../analytics-exporter',
        docLink:
          'https://documentation.atomic.io/guide/tools/analytics-exporter'
      },
      {
        title: 'Customer data importer',
        description:
          'Add or update user profile records by uploading a CSV file. See import history.',
        link: '../customer-csv-importer',
        // TBA
        docLink:
          'https://documentation.atomic.io/guide/tools/customer-data-importer'
      },
      {
        title: 'Cancel cards',
        description: 'Find and cancel active card instances in bulk.',
        link: '../cancel-cards',
        docLink: 'https://documentation.atomic.io/guide/tools/cancel-cards'
      },
      {
        title: 'Action Flow bulk send',
        description: 'Upload a CSV file to send Action Flows in bulk.',
        link: '../af-bulk-send',
        docLink:
          'https://documentation.atomic.io/guide/tools/action-flow-bulk-send'
      }
    ]
  },
  {
    icon: 'configure',
    title: 'Security',
    elements: [
      {
        beta: true,
        title: 'Audit log',
        description: 'Log of user actions within your organisation.',
        link: '../../audit-log'
      }
    ]
  }
]

import * as t from 'io-ts'
import React, {useEffect, useState} from 'react'
import {useIdleTimer} from 'react-idle-timer'
import {toRelativeURL} from '../lib/helpers'
import {PreviewFlagMarker} from './feature-flags'
import {ButtonGroup} from './form'
import {Button} from './library/buttons'
import {Modal} from './library/modal/modal'
import {isRight} from 'fp-ts/lib/Either'

const LAST_ACTIVITY_KEY = 'last-activity'
const AUTO_LOGOUT_LAST_PATH_KEY = 'last-activity-sign-out-url'
const AUTO_LOGOUT_LAST_PATH_EXPIRY = 5 * 60 * 1000 // 5 minutes
const PROMPT_TIMEOUT_MS = 60000

const AutoLogoutLastPathCodec = t.type({
  url: t.string,
  created: t.string
})
type AutoLogoutLastPath = t.TypeOf<typeof AutoLogoutLastPathCodec>

const idleCheck = (totalTimeout: number) => {
  const lastAction = localStorage.getItem(LAST_ACTIVITY_KEY)

  if (lastAction && !isNaN(Number(new Date(lastAction)))) {
    const nowMillis = Number(new Date())
    const thenMillis = Number(new Date(lastAction))
    const diff = nowMillis - thenMillis
    if (diff > totalTimeout) {
      return true
    }
  }

  return false
}

const storeLastPathBeforeInactivity = () => {
  const lastActiveUrl: AutoLogoutLastPath = {
    url: toRelativeURL(window.location.href),
    created: new Date().toISOString()
  }
  localStorage.setItem(AUTO_LOGOUT_LAST_PATH_KEY, JSON.stringify(lastActiveUrl))
}

/**
 *
 * @returns the last path before the user was signed out due to inactivity
 */
export const getLastPathBeforeInactivity = () => {
  const raw = localStorage.getItem(AUTO_LOGOUT_LAST_PATH_KEY)
  if (raw) {
    try {
      const json = JSON.parse(raw)
      const result = AutoLogoutLastPathCodec.decode(json)

      if (isRight(result)) {
        const createdMillis = Number(new Date(result.right.created))
        const nowMillis = Number(new Date())
        const diff = nowMillis - createdMillis
        if (diff > AUTO_LOGOUT_LAST_PATH_EXPIRY) {
          console.warn(`Last URL found "${result.right.url}", but has expired`)
          clearLastPathBeforeInactivity()
          return undefined
        }
        return result.right.url
      }

      console.warn(`invalid JSON format for ${AUTO_LOGOUT_LAST_PATH_KEY}`, json)
      return undefined
    } catch (e) {
      console.warn(`invalid JSON for ${AUTO_LOGOUT_LAST_PATH_KEY}: ${raw}`)
      return undefined
    }
  }
  return undefined
}

export const clearLastPathBeforeInactivity = () => {
  localStorage.removeItem(AUTO_LOGOUT_LAST_PATH_KEY)
}

const trackActivity = () =>
  localStorage.setItem(LAST_ACTIVITY_KEY, new Date().toISOString())

export const resetActivity = () => localStorage.setItem(LAST_ACTIVITY_KEY, '')

type SessionTimeoutProps = {
  signOut: () => void
  timeout: number // seconds
}

export const SessionTimeout = ({
  signOut,
  timeout: timeoutSeconds
}: SessionTimeoutProps) => {
  const [prompted, setPrompted] = useState(false)

  const promptTimeout = PROMPT_TIMEOUT_MS
  const timeout = timeoutSeconds * 1000

  const {activate, getRemainingTime} = useIdleTimer({
    onPrompt() {
      setPrompted(true)
    },
    onIdle() {
      signOut()
    },
    onAction() {
      // check for time between actions. If it exceeds the limit, log out
      // immediately

      if (idleCheck(promptTimeout + timeout)) {
        console.log('Signing out due to excessive idleness')
        signOut()
      }

      trackActivity()
    },
    onActive() {
      setPrompted(false)
    },

    timeout,
    promptTimeout,
    stopOnIdle: true,
    crossTab: true,
    syncTimers: 1000
  })

  useEffect(() => {
    if (idleCheck(promptTimeout + timeout)) {
      console.log('Signing out due to excessive idleness')
      if (!prompted) {
        // if it has already timed out without being prompted, user is likely going to a specific page
        // store the current url to redirect back to it after sign in
        storeLastPathBeforeInactivity()
      }
      signOut()
    }
  }, [signOut, prompted, promptTimeout, timeout])

  return prompted ? (
    <SessionTimeoutPrompt
      getRemainingTime={getRemainingTime}
      continueSession={() => {
        activate()
      }}
      endSession={() => {
        signOut()
      }}
    />
  ) : null
}

type SessionTimeoutPromptProps = {
  getRemainingTime: () => number
  continueSession: () => void
  endSession: () => void
}
const SessionTimeoutPrompt = ({
  getRemainingTime,
  continueSession,
  endSession
}: SessionTimeoutPromptProps) => {
  const [remainingTime, setRemainingTime] = useState<number>(getRemainingTime())
  const seconds = remainingTime ? Math.round(remainingTime / 1000) : undefined
  const formattedTime = seconds
    ? seconds > 60
      ? `in ${Math.round(seconds / 60)} minute${seconds >= 90 ? 's' : ''}`
      : `in ${seconds} second${seconds > 1 ? 's' : ''}`
    : 'now'

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(getRemainingTime())
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [setRemainingTime, getRemainingTime])

  return (
    <Modal
      handleClose={continueSession}
      title={
        <>
          Are you still here? <PreviewFlagMarker />
        </>
      }
      style={{width: '440px'}}
    >
      <form
        onSubmit={e => {
          e.preventDefault()
          endSession()
        }}
      >
        <p>You will be logged out automatically {formattedTime}</p>
        <ButtonGroup>
          <Button type="submit" size="large">
            Log out
          </Button>
          <Button onClick={continueSession} appearance="tint" size="large">
            I'm still here
          </Button>
        </ButtonGroup>
      </form>
    </Modal>
  )
}

import React from 'react'
import styled, {css} from 'styled-components'
import {Icon, IconName} from '../icon/icon'
import {Tooltip, TooltipProps} from '../tooltip/tooltip'

export type Size = 'small' | 'medium' | 'large' | 'xlarge'
type Color = 'blue' | 'green' | 'red' | 'orange' | 'yellow'

const colors: Color[] = ['blue', 'green', 'red', 'orange', 'yellow']

export interface AvatarProps {
  size?: Size
  active?: boolean
  isOrg?: boolean
  color?: Color | 'grey'
  name?: string | null
  image?: string
  tooltipContent?: string
  hasTooltip?: boolean
  tooltipPosition?: TooltipProps['position']
  style?: React.CSSProperties
}

const blue = css`
  background-image: linear-gradient(
    ${({theme}) => theme.blueLight},
    ${({theme}) => theme.blueLight}
  );
  color: ${({theme}) => theme.blueDarkest};
`

const green = css`
  background-image: linear-gradient(
    ${({theme}) => theme.greenLight},
    ${({theme}) => theme.greenLight}
  );
  color: ${({theme}) => theme.greenDarkest};
`

const red = css`
  background-image: linear-gradient(
    ${({theme}) => theme.redLight},
    ${({theme}) => theme.redLight}
  );
  color: ${({theme}) => theme.redDarkest};
`

const orange = css`
  background-image: linear-gradient(
    ${({theme}) => theme.orangeLight},
    ${({theme}) => theme.orangeLight}
  );
  color: ${({theme}) => theme.orangeDarkest};
`

const yellow = css`
  background-image: linear-gradient(
    ${({theme}) => theme.yellowLight},
    ${({theme}) => theme.yellowLight}
  );
  color: ${({theme}) => theme.yellowDarkest};
`

const grey = css`
  background: transparent;
  color: ${({theme}) => theme.grey100};
`
const IconWrapper = styled.div<AvatarProps>`
  display: flex;
  svg {
    opacity: 0.5;
  }
`

const StyledAvatar = styled.div<{
  color?: Color | 'grey'
  size?: Size
  isOrg?: boolean
}>`
  position: relative;
  background-color: ${({theme}) => theme.white};
  border-radius: ${({theme}) => theme.borderRadiusPill};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  flex: none;
  ${props => {
    switch (props.size) {
      case 'small':
        return `
          width: 20px;
          height: 20px;
        `
      case 'large':
        return `
          width: 40px;
          height: 40px;
        `
      case 'xlarge':
        return `
            width: 100px;
            height: 100px;
          `
      default:
        return `
        width: 30px;
        height: 30px;
      `
    }
  }}
  ${props => {
    if (props.isOrg) {
      return `border-radius: ${props.theme.borderRadius};`
    } else {
      return null
    }
  }}
  ${props =>
    (props.color === 'grey' && grey) ||
    (props.color === 'red' && red) ||
    (props.color === 'green' && green) ||
    (props.color === 'blue' && blue) ||
    (props.color === 'yellow' && yellow) ||
    (props.color === 'orange' && orange)};
`

const StyledIndicator = styled.div<AvatarProps>`
  background-color: ${({theme}) => theme.green};
  border-radius: ${({theme}) => theme.borderRadiusPill};
  border: 2px solid ${({theme}) => theme.white};
  position: absolute;
  right: 100%;
  top: 100%;
  box-shadow: ${({theme}) => theme.shadowDepth1};
  ${props => {
    switch (props.size) {
      case 'small':
        return `
          width: 8px;
          height: 8px;
          transform: translate(6px,-6px);
        `
      case 'large':
        return `
          width: 12px;
          height: 12px;
          transform: translate(12px,-12px);
        `
      case 'xlarge':
        return `
            width: 16px;
            height: 16px;
            transform: translate(22px,-22px);
          `
      default:
        return `
          width: 10px;
          height: 10px;
          transform: translate(9px,-9px);
        `
    }
  }}
`

const ImageContent = styled.div<{image: string}>`
  border-radius: ${({theme}) => theme.borderRadiusPill};
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  width: 100%;
  height: 100%;
  background-image: url('${props => props.image}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 1px ${({theme}) => theme.grey10};
`

const AcronymWrapper = styled.div<{size: Size}>`
  ${props => {
    switch (props.size) {
      case 'small':
        return `
          font-size: ${props.theme.fontSizeXS};
          line-height: ${props.theme.lineHeightXS};
        `
      case 'large':
        return `
          font-size: ${props.theme.fontSize};
          line-height: ${props.theme.lineHeight};
        `
      case 'xlarge':
        return `
            font-size: ${props.theme.fontSizeXXL};
            line-height: ${props.theme.lineHeightXXL};
          `
      default:
        return `
        font-size: ${props.theme.fontSizeS};
        line-height: ${props.theme.lineHeightS};
      `
    }
  }}
`

const getFixedColor = (name: string) => {
  let hash = 0
  const nameLength = name.length
  if (nameLength > 0) {
    const colorsLength = colors.length
    for (let i = 0; i < nameLength; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash)
      hash = hash & hash
    }
    hash = ((hash % colorsLength) + colorsLength) % colorsLength
  }
  return colors[hash]
}

const getRandomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)]
}

export const Avatar = React.forwardRef(
  (
    {
      size = 'medium',
      active = false,
      color,
      image,
      name,
      hasTooltip,
      tooltipContent,
      tooltipPosition = 'bottom',
      isOrg = false,
      style
    }: AvatarProps,
    ref: React.Ref<HTMLElement>
  ) => {
    const trimmedName = name ? name.trim() : undefined
    const avatar = (
      <StyledAvatar
        isOrg={isOrg}
        className={'avatar'}
        size={size}
        ref={ref as React.Ref<HTMLDivElement>}
        color={
          color
            ? color
            : trimmedName
              ? getFixedColor(trimmedName)
              : getRandomColor()
        }
        style={style}
      >
        {image ? (
          <ImageContent image={image} />
        ) : (
          <>
            {trimmedName ? (
              <AcronymWrapper size={size}>
                {trimmedName.charAt(0).toUpperCase()}
              </AcronymWrapper>
            ) : (
              <IconWrapper>
                <Icon
                  name={
                    isOrg
                      ? (`avatar_business_${size}` as IconName)
                      : (`avatar_${size}` as IconName)
                  }
                />
              </IconWrapper>
            )}
          </>
        )}
        {active && <StyledIndicator size={size} />}
      </StyledAvatar>
    )

    // The wrapping div helps with the Avatar Group CSS
    return hasTooltip ? (
      <div>
        <Tooltip
          position={tooltipPosition}
          isLight={false}
          allowFocus={true}
          content={
            tooltipContent || name
              ? tooltipContent
                ? tooltipContent
                : name
              : 'Anonymous'
          }
        >
          {avatar}
        </Tooltip>
      </div>
    ) : (
      avatar
    )
  }
)

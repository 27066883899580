import memoizeOne from 'memoize-one'
import {CmdkActionGetter} from '../../cmdk/types'
import {Flags} from '../../hooks/use-flags'

export const getLinkGroups = memoizeOne((flags?: Flags) => [
  {
    group: 'SDK',
    links: [
      {to: 'streams', label: 'Streams'},
      {to: 'stream-containers', label: 'Stream containers'},
      {to: 'notifications', label: 'Notifications'},
      {
        to: 'container-themes',
        label: 'Container themes'
      },
      {to: 'api-host', label: 'SDK API host'},
      {to: 'api-keys', label: 'SDK API keys'},
      {to: 'sdk-privacy', label: 'Privacy'}
    ]
  },
  {
    group: 'API',
    links: [
      {to: 'api-endpoint', label: 'Base URL endpoint'},
      {to: 'authentication-controls', label: 'API keys'}
    ]
  },
  {
    group: 'Card templates',
    links: [
      {to: 'card-approvals', label: 'Approvals'},
      ...(flags?.featurePreview || flags?.cardActions
        ? [{to: 'card-actions', label: 'Card actions', flag: 'preview'}]
        : []),
      {to: 'share-links', label: 'Share links'}
    ]
  },
  {
    group: 'Customer profiles',
    links: [
      {to: 'custom-fields', label: 'Custom fields'},
      {to: 'tags', label: 'Tags'}
    ]
  },
  ...(flags?.featurePreview || flags?.integrations
    ? [
        {
          group: 'Integrations',
          links: [
            {
              to: 'integrations/salesforce',
              label: 'Salesforce',
              flag: 'preview'
            },
            ...(flags?.cardMarketoConnector
              ? [
                  {
                    to: 'integrations/marketo',
                    label: 'Marketo',
                    flag: 'preview'
                  }
                ]
              : []),
            ...(flags?.featurePreview
              ? [
                  {
                    to: 'aws',
                    label: 'AWS',
                    flag: 'preview'
                  }
                ]
              : [])
          ]
        }
      ]
    : []),
  {
    group: 'Analytics',
    links: [
      {to: 'webhook/subscriptions', label: 'Webhook subscriptions'},
      {to: 'webhook/credentials', label: 'Webhook credentials'},
      {to: 'webhook/custom-events', label: 'Custom events'},
      {to: 'analytics-export-settings', label: 'Analytics export settings'}
    ]
  },
  {
    group: 'Testing',
    links: [
      {to: 'test-accounts', label: 'Test accounts'},
      ...(flags?.featurePreview || flags?.authenticatedMagicLinks
        ? [{to: 'magic-link-settings', label: 'Magic links', flag: 'preview'}]
        : [])
    ]
  }
])

export const getActionsForConfiguration: CmdkActionGetter = flags =>
  getLinkGroups(flags).flatMap(group =>
    group.links.map(link => ({
      name: link.label,
      to: `configuration/${link.to}`,
      parent: 'configuration'
    }))
  )

import React, {useContext, useRef, useState} from 'react'
import {
  ToastConsumer as AwesomeToastConsumer,
  ToastProvider as AwesomeToastProvider
} from 'react-awesome-toasts'
import {ToastContext as ToastContextShape} from 'react-awesome-toasts/dist/ToastContext/ToastContext.types'
import {Icon} from '../components/library/icon/icon'
import {Button} from './../components/library/buttons/button'
import styled from 'styled-components'

type ToastVariant = 'success' | 'info' | 'error' // | 'warn'

export type MakeToast = (
  text: string,
  /** info is default */
  variant?: ToastVariant,
  customAction?: () => void,
  actionText?: string,
  timeout?: number
) => void

// Awesome toasts don't expose their wrapper container and override it's classnames if passed as props
const ToastContainerSibling = styled.div`
  // ensure this div is always full height (see Wrap -> main)
  flex: 1;
  display: flex;
  flex-direction: column;

  ~ .ToastContainer__toast-container___3cSlj {
    padding: 30px;
    padding-bottom: 30px;
    width: auto;
    z-index: 999;
  }

  ~ .ToastContainer__toast-container--bottom-right___30aMN {
    right: 0;
    bottom: 0;
  }

  ~ .ToastContainer__toast-container--bottom-right___30aMN.ToastContainer__toast-container--entering___PlBi2 {
    transform: translateY(100%);
  }

  ~ .ToastContainer__toast-container--bottom-right___30aMN.ToastContainer__toast-container--exited___2U_YA {
    transform: translateY(-50%);
    opacity: 0;
  }

  @media (max-width: 600px) {
    ~ .ToastContainer__toast-container___3cSlj {
      width: 100%;
      max-width: none;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
`

const StyledToast = styled.div`
  background: ${({theme}) => theme.black};
  border-radius: ${({theme}) => theme.borderRadius};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 15px 20px;
  box-shadow: ${({theme}) => theme.shadowDepth1};

  button {
    margin: 0 -10px 0 10px;
    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background: ${({theme}) => theme.white10};
    }
    &:not([disabled]):active {
      background: ${({theme}) => theme.white20};
    }
  }

  &.root--error button {
    color: ${({theme}) => theme.white};
  }

  .alert {
    opacity: 0;
    position: absolute;
  }

  &.root--error {
    background: ${({theme}) => theme.red};
  }
`

const StyledMessage = styled.div`
  color: ${({theme}) => theme.white};
  font-size: ${({theme}) => theme.fontSizeS};
  line-height: ${({theme}) => theme.lineHeightCompact};
  margin-right: 50px;
`

const StyledAria = styled.div`
  opacity: 0;
  position: absolute;
`

const ActionWrapper = styled.div<{toastVariant: ToastVariant}>`
  margin-left: 0;
  margin-right: 20px;
  & > button {
    color: ${({toastVariant}) => variantColor(toastVariant)};
  }
`

const IconWrap = styled.div`
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`

const VariantIcon = styled(Icon)<{toastVariant: ToastVariant}>`
  [fill] {
    fill: ${({toastVariant}) => variantColor(toastVariant)};
  }
  [stroke] {
    stroke: ${({toastVariant}) => variantColor(toastVariant)};
  }
`
// have to use a different one here to account for the
// fact that colouring the info icon is different to success & error (and already used elsewhere)
const InfoIcon = styled(Icon)<{toastVariant: ToastVariant}>`
  color: ${({toastVariant}) => variantColor(toastVariant)};
`

// TODO: replace this with theme colours once they are available
// https://linear.app/atomic-app/issue/PLA-864/improve-workbench-theme-colours
const variantColor = (variant?: ToastVariant): string => {
  switch (variant) {
    case 'success':
      return '#28CD78'
    case 'error':
      return '#FF5C6F'
    case 'info':
    default:
      return '#57AEFF'
  }
}

const variantIcon = (variant?: ToastVariant) => {
  switch (variant) {
    case 'success':
      return 'success' as const
    case 'error':
      return 'error' as const
    case 'info':
    default:
      return 'info' as const
  }
}

export type CustomToastProps = {
  text: string
  actionText?: string
  ariaLabel?: string
  onDismissClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onActionClick?: () => void
  variant?: ToastVariant
}

export const ToastContext = React.createContext<MakeToast | undefined>(
  undefined
)

// To trigger a toast, use the useToast hook:
// const toaster = useToast()
// toaster('Toast message')
export const useToast = () => {
  const ctx = useContext(ToastContext)
  if (!ctx) {
    throw new Error('useToast can only be used inside a ToastProvider')
  }
  return ctx
}

const CustomToast = ({
  text,
  actionText,
  ariaLabel,
  variant = 'info',
  onDismissClick,
  onActionClick
}: CustomToastProps) => {
  const actionRef = React.createRef<HTMLButtonElement>()

  const handleDismissClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onDismissClick) onDismissClick(e)
  }

  const handleActionClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onActionClick) {
      handleDismissClick(e)
      onActionClick()
    }
  }

  const icon = variantIcon(variant)
  return (
    <StyledToast className={`root`}>
      <IconWrap>
        {icon === 'info' ? ( // hack to deal with info icon needing different treatment
          <InfoIcon name={icon} toastVariant={variant} />
        ) : (
          <VariantIcon name={icon} toastVariant={variant} />
        )}
      </IconWrap>
      <StyledAria role="alert" aria-label={ariaLabel ?? text}></StyledAria>
      <StyledMessage className={'text'}>{text}</StyledMessage>
      {actionText ? (
        <ActionWrapper toastVariant={variant}>
          <Button
            appearance={'transparent'}
            size={'small'}
            onClick={handleActionClick}
            ref={actionRef}
            style={{whiteSpace: 'nowrap'}}
          >
            {actionText}
          </Button>
        </ActionWrapper>
      ) : null}
      <Button
        data-testid="dismiss-toast"
        appearance={'transparent'}
        size={'small'}
        onClick={handleDismissClick}
        style={{margin: 0}}
      >
        <Icon name="clear" style={{color: 'white'}} />
      </Button>
    </StyledToast>
  )
}

// this nested monstrosity exists so we can trigger toasts via a simple typed
// context, and the useContext hook, rather than via the provided HOC
export const ToastProvider: React.FunctionComponent<{
  children: React.ReactNode
}> = ({children}) => {
  // Without using a ref here toaster is redefined on each render, this causes issues
  // if its used as part of a useEffect
  const toaster = useRef<MakeToast | undefined>()
  const [toastTimeout, setToastTimeout] = useState<number>(4500)

  return (
    <AwesomeToastProvider
      position="bottom-center"
      // @ts-ignore Toast types specify a React class but we are passing a functional component
      component={CustomToast}
      timeout={toastTimeout}
    >
      <AwesomeToastConsumer>
        {(toast: ToastContextShape) => {
          if (!toaster.current) {
            toaster.current = (
              text: string,
              variant?: ToastVariant,
              customAction?: () => void,
              actionText?: string,
              timeout?: number
            ) => {
              if (timeout && toastTimeout !== timeout) {
                setToastTimeout(timeout)
              }
              toast.show({
                text,
                actionText,
                ariaLabel: `${text} - click to dismiss`,
                onDismissClick: toast.hide,
                onActionClick: customAction,
                variant,
                timeout
              })
            }
          }
          return (
            <ToastContext.Provider value={toaster.current}>
              <ToastContainerSibling>{children}</ToastContainerSibling>
            </ToastContext.Provider>
          )
        }}
      </AwesomeToastConsumer>
    </AwesomeToastProvider>
  )
}

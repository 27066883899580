import AtomicSDK, {UserUpdate} from '@atomic.io/action-cards-web-sdk'
import {get} from 'object-path-immutable'
import {useCallback, useEffect, useRef, useState} from 'react'
import {useThrottledCallback} from 'use-debounce'
import {getToken} from '../lib/api'
import {CLIENT_API_URL, ENVIRONMENT_NAME, ROUTING_API_URL} from '../lib/env'
import {getApi} from '../lib/fetch'

const ATOMIC_API_HOST = 'https://50-9.client-api.atomic.io'
const useLocal = true

export const atomicConfig = {
  local: {
    // note ids and keys are the same in dev env and local fixture
    ATOMIC_API_HOST: useLocal
      ? CLIENT_API_URL
      : 'https://04.client-api.development.unobtainium.atomic.io',
    ATOMIC_ENVIRONMENT_ID: 'QWE3DBOn',
    ATOMIC_API_KEY: 'defend-marry-thief-arch',
    ATOMIC_CONTAINER_ID: '5dEDzgag',
    ATOMIC_HIGH_PRIORITY_CONTAINER_ID: 'yBw2KlEQ',
    ATOMIC_ACTION_FLOW_PUBLISHED_CONTAINER_ID: 'zBMh8GsGB'
  },
  pollution: {
    ATOMIC_API_HOST,
    ATOMIC_ENVIRONMENT_ID: 'MPaBKDnw',
    ATOMIC_API_KEY: 'defeat-situation-rain-scale',
    ATOMIC_CONTAINER_ID: 'w1mGmK7N',
    ATOMIC_HIGH_PRIORITY_CONTAINER_ID: 'kRn25aRo',
    ATOMIC_ACTION_FLOW_PUBLISHED_CONTAINER_ID: 'oVxNdwRw'
  },
  development: {
    ATOMIC_API_HOST,
    ATOMIC_ENVIRONMENT_ID: 'ern95kn0',
    ATOMIC_API_KEY: 'sail-sacrifice-cattle-already',
    ATOMIC_CONTAINER_ID: 'JXz0zoLr',
    ATOMIC_HIGH_PRIORITY_CONTAINER_ID: 'BZNM7jX4',
    ATOMIC_ACTION_FLOW_PUBLISHED_CONTAINER_ID: 'kXbrdkXQ'
  },
  staging: {
    ATOMIC_API_HOST,
    ATOMIC_ENVIRONMENT_ID: 'wZXN44nM',
    ATOMIC_API_KEY: 'fold-wrong-struggle-silk',
    ATOMIC_CONTAINER_ID: 'WDpyXW40',
    ATOMIC_HIGH_PRIORITY_CONTAINER_ID: '2VvEgaQ3',
    ATOMIC_ACTION_FLOW_PUBLISHED_CONTAINER_ID: '2Vvj3YV3'
  },
  master: {
    ATOMIC_API_HOST,
    ATOMIC_ENVIRONMENT_ID: 'vLXPzrnp',
    ATOMIC_API_KEY: 'beyond-do-nephew-obedient',
    ATOMIC_CONTAINER_ID: 'ygjodNlP',
    ATOMIC_HIGH_PRIORITY_CONTAINER_ID: 'vl9VE54J',
    ATOMIC_ACTION_FLOW_PUBLISHED_CONTAINER_ID: '6ln7Y54a'
  }
}[ENVIRONMENT_NAME]

const fetchAuthToken =
  (appClientId: string) => async (): Promise<string | undefined> => {
    const resp = await getApi({getToken})(
      'get',
      `${ROUTING_API_URL}/atomic-token`,
      undefined,
      {
        'app-client-id': appClientId
      }
    )
    const token = get(resp.json, 'token')
    return token
  }

export type UseAtomicSDK = {
  initialised: boolean
  cardCount: number | undefined
  updateCardCount: () => void
  updateUserProfile: (profile: UserUpdate['profile']) => void
}

export const useAtomicSDK = (
  authenticated: boolean,
  appClientId: string
): UseAtomicSDK => {
  const [initialised, setInitialised] = useState(false)

  useEffect(() => {
    if (useLocal) {
      AtomicSDK.setTestHostHeader('03')
    }

    if (
      // initialise if authenticated, and there's a current org
      // the return callback should log out the SDK if that becomes untrue
      // TODO move auth endpoint to routing so it doesn't need org id, then we
      // don't need to log out - org-specific card delivery will be controlled
      // by SDK container filtering
      authenticated &&
      appClientId
    ) {
      AtomicSDK.initialise(
        atomicConfig.ATOMIC_API_HOST,
        atomicConfig.ATOMIC_API_KEY,
        atomicConfig.ATOMIC_ENVIRONMENT_ID
      )

      if (ENVIRONMENT_NAME !== 'master') {
        AtomicSDK.enableDebugMode(3)
      }

      AtomicSDK.setSessionDelegate(fetchAuthToken(appClientId))
      setInitialised(true)

      return () => {
        AtomicSDK.logout()
        setInitialised(false)
      }
    } else {
      return undefined
    }
  }, [authenticated, appClientId])

  const [cardCount, setCardCount] = useState<number | undefined>()
  const timerRef = useRef<NodeJS.Timeout | undefined>()
  const updateCardCount = useThrottledCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    const hasEmbeds = Object.keys(AtomicSDK.streamContainers).length > 0

    if (hasEmbeds)
      // if we can't instantiate the stream containers for some reason then don't get a card count
      AtomicSDK.requestCardCount(atomicConfig.ATOMIC_CONTAINER_ID).then(v => {
        setCardCount(v.totalCards)
      })
    else if (cardCount !== undefined) setCardCount(undefined)

    // schedule the next check. If no embeds initialised yet - check a bit sooner
    const pollInterval = hasEmbeds ? 30_000 : 5_000
    timerRef.current = setTimeout(updateCardCount, pollInterval)
  }, 1000)

  useEffect(() => {
    if (!initialised) {
      return () => null
    }

    updateCardCount()

    // clear any pending polls on unmount
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [updateCardCount, initialised])

  const updateUserProfile = useCallback((profile: UserUpdate['profile']) => {
    // let errors bubble up to bugsnag
    AtomicSDK.updateUser({profile})
  }, [])

  return {
    initialised,
    cardCount,
    updateCardCount,
    updateUserProfile
  }
}

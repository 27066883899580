import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import {createPortal} from 'react-dom'
import ResizeObserver from 'resize-observer-polyfill'
import styled, {css} from 'styled-components'
import {BetaFlagMarker} from '../../feature-flags'
import {Loader} from '../../loader'
import {IconButton} from '../buttons/icon-button'

const ANIMATION_MS = 100
const ANIMATION_SEC = ANIMATION_MS / 1000

export type ModalProps = {
  title?: React.ReactNode
  children: React.ReactNode
  isOpen?: boolean
  handleClose: () => void
  style?: React.CSSProperties
  order?: 'up' | 'down'
  isFullScreen?: boolean
  stopPropagation?: boolean
  overflow?: string
  isBeta?: boolean
  loading?: boolean
  className?: string
  closeOnChildModalClicked?: boolean
  closeOnOverlayClick?: boolean
}

export const Modal: FC<ModalProps> = ({
  loading,
  className,
  overflow,
  isFullScreen,
  isBeta,
  title,
  children,
  style,
  ...props
}) => {
  return (
    <ModalContainer {...props}>
      {({closeModal}) => (
        <>
          {loading ? (
            <Loader variant="overlay" />
          ) : (
            <ModalContent
              className={`modal-contents ${className ? className : ''}`}
              overflow={overflow}
              isFullScreen={isFullScreen}
              style={style}
            >
              {title && (
                <ModalHeader isFullScreen={isFullScreen}>
                  <h4>
                    {title}
                    {isBeta && <BetaFlagMarker />}
                  </h4>
                  <IconButton
                    icon={'close'}
                    size={'large'}
                    onClick={e => {
                      e.stopPropagation()
                      closeModal()
                    }}
                  />
                </ModalHeader>
              )}

              {isFullScreen ? (
                <FullScreenScroller>{children}</FullScreenScroller>
              ) : (
                children
              )}
            </ModalContent>
          )}
        </>
      )}
    </ModalContainer>
  )
}

type ModalContainerProps = {
  children: ({closeModal}: {closeModal: () => void}) => React.ReactNode
  isOpen?: boolean
  handleClose: () => void
  style?: React.CSSProperties
  order?: 'up' | 'down'
  isFullScreen?: boolean
  stopPropagation?: boolean
  closeOnChildModalClicked?: boolean
  closeOnOverlayClick?: boolean
}

export const ModalContainer = ({
  children,
  isOpen = true,
  handleClose,
  order,
  isFullScreen,
  stopPropagation,
  closeOnChildModalClicked = true,
  closeOnOverlayClick = true
}: ModalContainerProps) => {
  const elRef = useRef<HTMLDivElement>(document.createElement('div'))
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [offsetTop, setOffsetTop] = useState<number>(0)
  const [downTarget, setDownTarget] = useState<HTMLElement>()
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight)
  const [blurDisabled, setBlurDisabled] = useState<boolean>(false)

  // we need to keep the component mounted in order to render the animate-out
  // showModal is used to control the visibility of the modal
  const [showModal, setShowModal] = useState<boolean>(false)
  const [animateOut, setAnimateOut] = useState<boolean>(true)

  const closeModal = useCallback(() => {
    setAnimateOut(true)
    setTimeout(() => {
      setShowModal(false)
      handleClose()
    }, ANIMATION_MS)
  }, [handleClose])

  // respond delayed to 'isOpen' prop
  useEffect(() => {
    if (isOpen) {
      setShowModal(true)
      // wait for the modal to be mounted
      setTimeout(() => setAnimateOut(false), 0)
    } else {
      setAnimateOut(true)
      setTimeout(() => {
        setShowModal(false)
      }, ANIMATION_MS)
    }
  }, [isOpen])

  useLayoutEffect(() => {
    const handleWindowResize = () => {
      setWindowHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleWindowResize)

    const el = elRef.current
    document.body.appendChild(el)
    el.classList.add('modal')
    el.setAttribute('data-testid', `modal`)

    const content = contentRef.current

    const doOffsetCalc = () => {
      if (content) {
        const offset = (windowHeight - content.offsetHeight) / 2
        setOffsetTop(offset)
      }
    }

    // Recenter the modal if its size changes
    let resizeObserver: ResizeObserver | undefined | void
    if (content && el.classList.contains('modal')) {
      resizeObserver = new ResizeObserver(doOffsetCalc)
      resizeObserver.observe(content)
    }

    // Center the modal based on its size
    doOffsetCalc()

    return () => {
      document.body.removeChild(el)
      window.removeEventListener('resize', handleWindowResize)
      if (resizeObserver && content) {
        resizeObserver.unobserve(content)
      }
    }
  }, [showModal, windowHeight])

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (isOpen && e.key === 'Escape') {
        if (stopPropagation) {
          // only close the topmost modal
          const allModals = document.querySelectorAll('.modal')
          if (!allModals.length) return
          const topMostModal = allModals[allModals.length - 1]
          if (topMostModal !== elRef.current) {
            return
          }
        }
        e.preventDefault()
        closeModal()
      }
    }
    window.addEventListener('keydown', handleEsc, false)

    return () => {
      window.removeEventListener('keydown', handleEsc, false)
    }
  }, [closeModal, isOpen, stopPropagation])

  const handleOverlayClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (
        closeOnOverlayClick &&
        (!contentRef.current ||
          (contentRef.current &&
            e.target &&
            !contentRef.current.contains(e.target as Node))) &&
        !blurDisabled &&
        e.target === downTarget &&
        closeOnChildModalClicked
      ) {
        closeModal()
      }
    },
    [
      closeModal,
      downTarget,
      blurDisabled,
      closeOnChildModalClicked,
      closeOnOverlayClick
    ]
  )

  // Detect all dropdown portals that have children (are open)
  // If any are present, disable blur to close on modals, until all dropdowns are closed again
  const toggleBlurToClose = () => {
    const el = elRef.current
    const dropdowns = el.parentElement
      ? Array.from(el.parentElement.querySelectorAll('.dropdown-contents'))
      : []
    const haveUpDropdowns = dropdowns.some(d => !!d.firstElementChild)
    setBlurDisabled(haveUpDropdowns)
  }

  return showModal ? (
    <>
      {createPortal(
        <Wrapper
          order={order}
          offsetTop={offsetTop}
          onClick={event => {
            if (stopPropagation) {
              event.stopPropagation()
            }
            handleOverlayClick(event)
          }}
          isFullScreen={isFullScreen}
          onMouseDown={e => {
            setDownTarget(e.target as HTMLElement)
            toggleBlurToClose()
          }}
          animateOut={animateOut}
        >
          <ContentInner ref={contentRef} animateOut={animateOut}>
            {children({closeModal})}
          </ContentInner>
        </Wrapper>,
        elRef.current
      )}
    </>
  ) : null
}

const FullScreenScroller = styled.div`
  margin-top: 70px;
  overflow: auto;
  height: calc(100% - 70px);
`

const Wrapper = styled.div<{
  isFullScreen?: boolean
  offsetTop: number
  order?: 'up' | 'down'
  animateOut?: boolean
}>`
  position: fixed;
  z-index: 9;
  background: ${p => p.theme.grey20};
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  overflow: auto;
  padding: 20px;
  padding-top: ${p => `${p.offsetTop}px`};
  padding-bottom: ${p => `${p.offsetTop}px`};

  opacity: 1;
  transition: opacity ${ANIMATION_SEC}s ease; // this should match the setTimout in the main component
  ${({animateOut}) => animateOut && 'opacity: 0;'}

  ${p => {
    switch (p.order) {
      case 'up':
        return `
        z-index: 10;
      `
      case 'down':
        return `
        z-index: 8;
        ${ModalContent} {
          transform: scale(0.9);
        }
      `
      default:
        return `
        z-index: 9;
      `
    }
  }}
`

const ContentInner = styled.div<{
  animateOut?: boolean
}>`
  max-width: 100%;
  scale: 1;
  transition: scale ${ANIMATION_SEC}s ease;
  ${({animateOut}) => animateOut && 'scale: 0.8;'}
`

const ModalContent = styled.div<{
  isFullScreen?: boolean
  overflow?: string
}>`
  background: ${p => p.theme.white};
  padding: 25px 30px 30px;
  box-shadow: ${p => p.theme.shadowDepth2};
  border-radius: ${p => p.theme.borderRadius};
  min-width: 400px;
  overflow: ${p => (p.overflow ? p.overflow : 'auto')};
  display: flex;
  flex-direction: column;

  ${p =>
    p.isFullScreen &&
    css`
      padding: 0;
      width: 100vw;
      height: 100vh;
      border-radius: 0;
      position: relative;
    `}
`
const ModalHeader = styled.div<{isFullScreen?: boolean}>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-self: stretch;
  ${p =>
    p.isFullScreen &&
    css`
      box-shadow: 0 1px 0 0 ${p.theme.grey10};
      top: 0;
      left: 0;
      width: 100%;
      position: fixed;
      padding: 20px 30px;
      background: ${p.theme.white};
    `}

  h4 {
    font-size: ${p => p.theme.fontSizeL};
    line-height: ${p => p.theme.lineHeightXL};
    margin: 0;
    width: 100%;
  }
  button {
    margin: -5px -10px -5px 5px;
    flex: none;
  }
`

export const ModalDescription = styled.div`
  font-size: ${p => p.theme.fontSize};
  line-height: ${p => p.theme.lineHeightL};
  color: ${p => p.theme.grey50};
  margin-bottom: 20px;
`

const FullScreenContainer = styled.div`
  position: fixed;
  z-index: 19;
  background: ${p => p.theme.white};
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  overflow: auto;
  padding: 20px;
`

export const FullScreenOverlay = ({
  children,
  ...props
}: {
  children: React.ReactNode
}) => {
  const elRef = useRef<HTMLDivElement>(document.createElement('div'))
  useEffect(() => {
    const el = elRef.current
    document.body.appendChild(el)
    el.classList.add('modal')
    return () => {
      document.body.removeChild(el)
    }
  })
  return (
    <>
      {createPortal(
        <FullScreenContainer {...props}>{children}</FullScreenContainer>,
        elRef.current
      )}
    </>
  )
}

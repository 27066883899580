import React from 'react'
import styled from 'styled-components'
import {useAtomicSDKContext} from '../providers/atomic-sdk'

export const sidebarTransition = '200ms ease'
const width = 48
export const headerHeight = 48
export const streamWidth = 340
export const streamVisibleClass = 'stream-visible'

const Sidebar = styled.aside`
  width: ${width}px;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  min-height: 530px;
  height: 100%;
  z-index: 3;
  justify-content: center;
  background: ${p => p.theme.colors.navy12};
`

const Header = styled.header`
  height: ${headerHeight}px;
  z-index: 2;
  border-bottom: 1px solid ${p => p.theme.grey10};
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 19px;
`

const Main = styled.main`
  flex: 1;
  display: flex; // make sure children are full-height (see ToastContainerSibling)
  flex-direction: column;
  z-index: 1;
  overflow-x: auto;
`

const Wrap = styled.div`
  min-height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%;
  > #stream {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: ${streamWidth}px;
    transform: translateX(${streamWidth}px);
    transition: ${sidebarTransition} transform;
    display: flex;

    > button {
      position: absolute;
      top: 0;
      right: 0;
      margin: 11px;
    }
  }

  // should sit under modals but above everything else, including dropdowns
  // currently a known issue where dropdowns sit above the single card subview
  // TODO fix this by closing dropdowns when the single card subview is opened
  #single-card {
    z-index: 4;
  }

  > main,
  > header {
    position: relative; // important for z-index to work as expected
  }
  > #sidebar ~ main,
  > #sidebar ~ header {
    margin-left: ${width}px;
  }

  &.${streamVisibleClass}.authenticated {
    > main,
    > header {
      margin-right: ${streamWidth}px;
    }
    #stream {
      transform: translateX(0);
    }
  }
`

interface LayoutProps {
  children: React.ReactNode
  stream: React.ReactNode
  singleCard: React.ReactNode
  sidebar?: React.ReactNode
  header?: React.ReactNode
  authenticated: boolean
}
export const Layout = ({
  children,
  sidebar,
  header,
  authenticated,
  stream,
  singleCard
}: LayoutProps) => {
  const {streamVisible} = useAtomicSDKContext()
  return (
    <Wrap
      className={`${streamVisible ? streamVisibleClass : ''} ${
        authenticated ? 'authenticated' : ''
      }`}
    >
      {sidebar ? <Sidebar id="sidebar">{sidebar}</Sidebar> : null}
      {header ? <Header id="header">{header}</Header> : null}
      <Main>{children}</Main>
      <div id="stream">{stream}</div>
      <div id="single-card">{singleCard}</div>
    </Wrap>
  )
}

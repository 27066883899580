import React, {useCallback, useEffect, useState} from 'react'
import {InviteData} from '../../common/types'
import {Authenticator, CognitoError} from '../../lib/cognito'
import {FormRow} from '../form'
import {Button} from '../library/buttons/index'
import {PasswordInput} from '../library/form/password-input'
import {TextInput} from '../library/form/text'
import {
  AuthButtonGroup,
  AuthLargeHeader,
  AuthLogo,
  AuthMessage,
  Error
} from './layout'
import {AuthComplete} from './types'

interface SignUpProps {
  auth: Authenticator
  organisation?: string
  email?: string
  onComplete: AuthComplete
  formOnly?: boolean
  inviteData?: InviteData
}

export const SignUpForm = ({
  auth,
  onComplete,
  formOnly = false,
  inviteData,
  ...props
}: SignUpProps) => {
  const [formData, setFormData] = useState(() => ({
    password: '',
    repeatPassword: '',
    firstName: '',
    lastName: '',
    ...(inviteData ? inviteData : {}),
    email: props.email?.toLowerCase() ?? ''
  }))
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    // reset client id to default, if showing the regular sign-in form
    auth.setClientId(null)
  }, [auth])

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setLoading(true)
      setError('')

      const {email, password, repeatPassword, ...userData} = formData

      if (password && repeatPassword && password !== repeatPassword) {
        setError('Passwords do not match')
        setLoading(false)
        return
      }

      try {
        const result = await auth.signUp(email, password)
        await onComplete(result.state, {username: email, password}, userData)
      } catch (err) {
        if (err instanceof CognitoError) {
          if (err.message.includes('greater than or equal to 6')) {
            setError('Password must contain at least 6 characters')
          } else if (err.message.includes('numeric')) {
            setError('Password must contain a number or symbol')
          } else if (err.message.includes('uppercase')) {
            setError('Password must contain an uppercase letter')
          } else if (err.message.includes('email already exists')) {
            setError('User already exists')
          } else if (
            err.message.includes(
              'Provided password cannot be used for security reasons'
            )
          ) {
            setError(
              'Chosen password is known to be compromised, please choose another'
            )
          } else {
            setError(err.message)
          }
        } else {
          throw err
        }
      } finally {
        setLoading(false)
      }
    },
    [formData, onComplete, auth]
  )

  return (
    <>
      {!formOnly && (
        <>
          <AuthLogo />
          <AuthLargeHeader>Sign up</AuthLargeHeader>
          <AuthMessage>To a new Atomic account</AuthMessage>
        </>
      )}
      <form onSubmit={handleSubmit}>
        {error && <Error>{error}</Error>}
        <FormRow>
          <TextInput
            type="email"
            name="email"
            label="Email address"
            required={true}
            onChange={email =>
              setFormData(s => ({...s, email: email.toLowerCase()}))
            }
            value={formData.email}
            readOnly={!!props.email}
            style={{maxWidth: 'none'}}
          />
        </FormRow>
        {inviteData && ( //legacy invites won't have inviteData
          <>
            <FormRow>
              <TextInput
                type="text"
                label="First name"
                required={true}
                onChange={firstName => {
                  setFormData(s => ({...s, firstName}))
                }}
                value={formData.firstName ?? ''}
                style={{maxWidth: 'none'}}
              />
            </FormRow>
            <FormRow>
              <TextInput
                type="text"
                label="Last name"
                required={true}
                onChange={lastName => {
                  setFormData(s => ({...s, lastName}))
                }}
                value={formData.lastName ?? ''}
                style={{maxWidth: 'none'}}
              />
            </FormRow>
          </>
        )}
        <FormRow>
          <PasswordInput
            name="password"
            upLabel="Password"
            label="Create password"
            required={true}
            onChange={password => {
              setFormData(s => ({...s, password}))
            }}
            value={formData.password}
            style={{maxWidth: 'none'}}
          />
        </FormRow>
        <FormRow>
          <PasswordInput
            name="repeatPassword"
            upLabel="Confirm password"
            label="Confirm password"
            required={true}
            onChange={repeatPassword => {
              setFormData(s => ({...s, repeatPassword}))
            }}
            value={formData.repeatPassword}
            style={{maxWidth: 'none'}}
          />
        </FormRow>

        <AuthButtonGroup>
          <Button
            type="submit"
            appearance="primary"
            size="large"
            disabled={loading}
            style={{
              width: '100%'
            }}
          >
            {loading ? 'Loading...' : 'Sign up'}
          </Button>
        </AuthButtonGroup>
      </form>
    </>
  )
}

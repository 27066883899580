import {gql} from '@apollo/client'
import {useEffect, useMemo, useState} from 'react'
import {getClient} from '../lib/api'
import {useAuth} from '../lib/auth'
import {FeatureFlagsQuery} from './generated/use-flags'
export type Flags = {
  featurePreview?: boolean | null
  isInternal?: boolean | null
  experimentalWebhooks?: boolean | null
  cardConnectors?: boolean | null
  integrations?: boolean | null
  cardActions?: boolean | null
  actionFlows?: boolean | null
  authenticatedMagicLinks?: boolean | null
  cardMarketoConnector?: boolean | null
  actionFlowConditionalSteps?: boolean | null
  insightsExclusionFilter?: boolean | null
}

export const useFlags = (organisationId: string | undefined): Flags => {
  const {authState} = useAuth()
  const email = authState.authenticated && authState.email

  const [featureFlags, setFeatureFlags] = useState<Flags>({})

  useEffect(() => {
    if (email && organisationId) {
      getClient(organisationId)
        .query<FeatureFlagsQuery>({
          query: FEATURE_FLAGS_QUERY,
          variables: {email, organisationId},
          fetchPolicy: 'cache-first'
        })
        .then(d => {
          setFeatureFlags(d.data.featureFlags ?? {})
        })
    }
  }, [organisationId, email, setFeatureFlags])

  // Note we've got overrides in the feature-flag-plugin on the api as well. TODO cleanup
  const experimentalWebhooks = organisationId
    ? !!featureFlags.experimentalWebhooks ||
      ['57-1', '57-2'].includes(organisationId)
    : false

  const cardConnectors = organisationId
    ? organisationId.startsWith('59-') ||
      organisationId.startsWith('53-') ||
      organisationId.startsWith('241-') ||
      organisationId.startsWith('215-') ||
      organisationId.startsWith('415-1') ||
      organisationId.startsWith('414-1') ||
      organisationId.startsWith('446-1') ||
      organisationId.startsWith('120-') ||
      organisationId.startsWith('492-1') ||
      organisationId.startsWith('493-1')
    : false

  const integrations = organisationId
    ? organisationId.startsWith('53-') ||
      organisationId.startsWith('59-') ||
      organisationId.startsWith('241-') ||
      organisationId.startsWith('215-') ||
      organisationId.startsWith('415-1') ||
      organisationId.startsWith('414-1') ||
      organisationId.startsWith('446-1') ||
      organisationId.startsWith('120-') ||
      organisationId.startsWith('492-1') ||
      organisationId.startsWith('493-1') ||
      organisationId.startsWith('496-')
    : false

  // Enable Card actions menu in configuration screen
  const cardActions = organisationId
    ? !!organisationId.startsWith('62-') || !!organisationId.startsWith('147-')
    : false

  // Enable for ANZ
  const authenticatedMagicLinks =
    !!organisationId && organisationId.startsWith('62-')

  const enableMarketoSSFSConnector =
    !!organisationId && organisationId.startsWith('59-')

  const actionFlows = organisationId
    ? organisationId === '59-2' ||
      organisationId === '241-1' ||
      organisationId === '492-1' ||
      organisationId === '53-2' ||
      organisationId.startsWith('147-') ||
      organisationId.startsWith('493-') ||
      organisationId.startsWith('61-') ||
      organisationId.startsWith('67-') ||
      organisationId.startsWith('62-')
    : false

  const actionFlowConditionalSteps = organisationId
    ? organisationId === '241-1' ||
      organisationId === '494-1' ||
      organisationId.startsWith('147-') ||
      organisationId.startsWith('67-') ||
      organisationId === '59-2'
    : false

  const insightsExclusionFilter = organisationId
    ? organisationId.startsWith('147-')
    : false

  // cache output in the event it is used in effects
  const flags = useMemo(
    () => ({
      ...featureFlags,
      cardConnectors,
      experimentalWebhooks,
      cardActions,
      actionFlows: actionFlows || !!featureFlags.featurePreview,
      authenticatedMagicLinks,
      integrations,
      cardMarketoConnector:
        !!featureFlags.featurePreview || enableMarketoSSFSConnector,
      actionFlowConditionalSteps,
      insightsExclusionFilter
    }),
    [
      featureFlags,
      cardConnectors,
      experimentalWebhooks,
      cardActions,
      authenticatedMagicLinks,
      integrations,
      enableMarketoSSFSConnector,
      actionFlows,
      actionFlowConditionalSteps,
      insightsExclusionFilter
    ]
  )

  return flags
}

export const FEATURE_FLAGS_QUERY = gql`
  query FeatureFlags($email: String, $organisationId: String) {
    featureFlags(email: $email, organisationId: $organisationId) {
      featurePreview
      isInternal
      experimentalWebhooks
    }
  }
`

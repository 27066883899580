import React, {ReactNode, useState} from 'react'
import {Link, matchPath, useNavigate} from 'react-router-dom'
import SVGCheck from '../../assets/icons/check-small.svg'
import SVGOrganisation from '../../assets/icons/organisation-small.svg'
import {RoutingOrganisation} from '../../common/types'
import {useGetOrganisationAccessQuery} from '../../data/workbench-api/generated/organisation'
import {OrganisationAccessEnvironment} from '../../data/workbench-api/generated/types'
import {EnvironmentNode} from '../../environment-provider'
import {useEnvironment} from '../../environment-provider'
import {isMFAError} from '../../is-mfa-error'
import {getClient} from '../../lib/api'
import {rewritePath} from '../../lib/rewrite-path'
import {Button} from '../library/buttons'
import {
  DropdownBody,
  DropdownEmptyMessage,
  DropdownItem,
  DropdownSection
} from '../library/dropdown/legacy'
import {Dropdown} from '../library/dropdown/dropdown'
import styled from 'styled-components'
import {Loader} from '../loader'
import {CrumbSwitcher} from './crumb'
import {EnvironmentFilter} from './environment-filter'
import {useSearchRegex} from '../../hooks/use-search-regex'

type EnvironmentSwitcherProps = {
  organisations: RoutingOrganisation[]
  environment?: EnvironmentNode
}

export const EnvironmentSwitcher = ({
  organisations,
  environment
}: EnvironmentSwitcherProps) => {
  const [search, setSearch] = useState('')

  const currentOrgName = organisations.find(
    org => org.id === environment?.organisationId
  )?.attributes.name

  const buttonText =
    currentOrgName && environment?.name ? (
      <>
        {currentOrgName}
        <strong>{environment.name}</strong>
      </>
    ) : (
      'Choose environment'
    )

  return (
    <Dropdown
      disabled={!organisations.length}
      trigger={(handleClick, ref) => (
        <a onClick={handleClick} ref={ref as React.Ref<HTMLAnchorElement>}>
          <CrumbSwitcher>{buttonText}</CrumbSwitcher>
        </a>
      )}
    >
      {closeDropdown => (
        <>
          <DropdownBody style={{minWidth: '220px'}}>
            <EnvironmentFilter value={search} onChange={setSearch} />
            {organisations.map(org => (
              <EnvironmentSwitcherList
                key={`${org.id}-${search}`}
                organisation={org}
                search={search}
                currentEnvironmentId={environment?.id}
                currentOrgId={environment?.organisationId}
                closeDropdown={() => {
                  closeDropdown()
                  setSearch('')
                }}
              />
            ))}
          </DropdownBody>
        </>
      )}
    </Dropdown>
  )
}

export const OrganisationTitle = styled.div`
  padding: 5px 16px;
  display: flex;
  gap: 8px;
  align-content: center;
  align-items: center;
  color: ${({theme}) => theme.colors.grey12};
  font-size: 13px;
  font-weight: 600;
  svg {
    width: 16px;
    color: ${({theme}) => theme.colors.grey10};
  }
`

export const EnvironmentTitle = styled.div`
  padding: 7px 16px 7px 40px;
  color: ${({theme}) => theme.colors.grey12};
  font-size: 13px;
  a {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:hover {
    background: ${({theme}) => theme.colors.grey3};
    color: ${({theme}) => theme.colors.grey12};
  }
  &.active {
    color: ${({theme}) => theme.colors.blue11};
  }
`

type EnvironmentSwitcherListProps = {
  organisation: RoutingOrganisation
  closeDropdown: () => void
  currentEnvironmentId?: string
  currentOrgId?: string
  search?: string
}

const EnvironmentSwitcherList = ({
  organisation,
  closeDropdown,
  currentEnvironmentId,
  currentOrgId,
  search
}: EnvironmentSwitcherListProps) => {
  const [mfaRequired, setMfaRequired] = useState(false)
  const navigate = useNavigate()

  // TODO - remove this once this information is available either via the
  // routing api, or via top level queries
  const {data, loading} = useGetOrganisationAccessQuery({
    variables: {id: organisation.id},
    client: getClient(organisation.id),
    onCompleted: () => setMfaRequired(false),
    onError: error => {
      if (isMFAError(error)) {
        setMfaRequired(true)
      }
    }
  })

  const environments = data?.getOrganisationAccess?.environments?.filter(
    (env): env is OrganisationAccessEnvironment => !!env
  )

  // if we're in an 'org route' then we can't change the env by using a link
  const onOrgRoute = matchPath('/:orgId/:orgConfig', window.location.pathname)
  const {setEnvironmentId} = useEnvironment()
  const isCurrentOrg = organisation.id === currentOrgId
  const searchRegex = useSearchRegex(search)
  const filteredEnvironments = searchRegex
    ? environments?.filter(env => searchRegex.test(env.name))
    : environments

  return search && !filteredEnvironments?.length ? null : (
    // If we are searching and don't have any results then hide the header 👆
    <DropdownSection>
      <OrganisationTitle>
        <SVGOrganisation />
        {organisation.attributes.name}
      </OrganisationTitle>
      {loading && !environments ? (
        <Loader variant="inline" />
      ) : filteredEnvironments?.length ? (
        filteredEnvironments.map(env => {
          const active = env.id === currentEnvironmentId
          let display: ReactNode = env.name
          if (search && searchRegex) {
            // Split the name into parts, and wrap the parts that match the search text <strong>
            const parts = env.name.split(searchRegex)

            display = parts.map((part, index) =>
              searchRegex.test(part) ? (
                <strong key={index}>{part}</strong>
              ) : (
                part
              )
            )
          }
          return (
            <EnvironmentTitle
              key={`${env.id}-${search}`}
              className={active ? 'active' : ''}
            >
              {onOrgRoute ? (
                <a
                  href={
                    // if we're on a different org then we can just redirect entirely
                    !isCurrentOrg
                      ? `/${organisation.id}/${onOrgRoute.params.orgConfig}`
                      : undefined
                  }
                  onClick={() => {
                    // otherwise we have to change the env menu using the setter
                    if (isCurrentOrg) {
                      setEnvironmentId(env.id)
                      closeDropdown()
                    }
                  }}
                >
                  <span>{display}</span>
                  {active && <SVGCheck />}
                </a>
              ) : isCurrentOrg ? (
                // This is a temporary fix to reload the page to reload the organisation id cache
                // in apollo provider/client when switching environment from different organisation.
                <Link
                  to={getSwitchLink(window.location.pathname, {
                    organisationId: organisation.id,
                    id: env.id
                  })}
                  onClick={closeDropdown}
                >
                  <span>{display}</span>
                  {active && <SVGCheck />}
                </Link>
              ) : (
                <a
                  href={getSwitchLink(window.location.pathname, {
                    organisationId: organisation.id,
                    id: env.id
                  })}
                  onClick={closeDropdown}
                >
                  <span>{display}</span>
                  {active && <SVGCheck />}
                </a>
              )}
            </EnvironmentTitle>
          )
        })
      ) : mfaRequired ? (
        <DropdownItem padded={true} style={{maxWidth: '300px'}}>
          <DropdownEmptyMessage style={{fontSize: '13px'}}>
            This organisation requires multi-factor authentication to access.
          </DropdownEmptyMessage>
          <Button // todo should be a link button but I can't get it to be small in that case
            style={{marginTop: '10px'}}
            iconLeft="key"
            size="small"
            appearance="secondary"
            stretch="center"
            onClick={() => {
              navigate('/mfa-setup')
              closeDropdown()
            }}
          >
            Enable MFA
          </Button>
        </DropdownItem>
      ) : (
        <DropdownItem
          padded={true}
          style={{maxWidth: '300px', fontSize: '13px'}}
        >
          No environments found
        </DropdownItem>
      )}
    </DropdownSection>
  )
}

const getSwitchLink = (
  path: string,
  environment: {
    organisationId: string
    id: string
  }
) => {
  return rewritePath(
    [
      ['/:org/:orgSetting', '/:org/:orgSetting'],
      [
        '/:org/:env/configuration/:config*',
        '/:org/:env/configuration/:config*'
      ],
      ['/:org/permissions/*', '/:org/permissions'],
      ['/:org/:env/action-flow/*', '/:org/:env/action-flows'],
      ['/:org/:env/:somepath/*', '/:org/:env/:somepath']
    ],
    {org: environment.organisationId, env: environment.id},
    `/${environment.organisationId}/${environment.id}`
  )(path)
}

import React, {useCallback} from 'react'
import {LinkButton} from '../../../components/library/buttons'
import {Icon} from '../../../components/library/icon/icon'
import {
  StepperTitle,
  StepperMessage,
  StepperActions,
  StepperContent,
  StepperContain,
  StepperBody
} from '../../../components/library/stepper/stepper'
import styled from 'styled-components'
import {useSearchParams} from 'react-router-dom'
import {useAnalytics} from '../../../lib/analytics/analytics'
import {useEnvironment} from '../../../environment-provider'

export const Start = ({title}: {title?: string}) => {
  const [searchParams] = useSearchParams()
  const searchString = searchParams.toString()
  const {environment} = useEnvironment()
  const analytics = useAnalytics()

  const track = useCallback(
    (url: string) => {
      if (environment) {
        analytics.track({
          event: 'Redirected to external URL',
          properties: {
            url,
            environment_id: environment.id,
            org_id: environment.organisationId,
            category: 'general'
          }
        })
      }
    },
    [environment, analytics]
  )

  return (
    <StepperBody>
      <StepperContent>
        <StepperTitle>
          {title ?? 'Install an authentication app on your phone'}
        </StepperTitle>
        <StepperMessage>
          To enable multi-factor authentication, you’ll need to use an
          authentication app such as{' '}
          <a
            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              track(
                'https://apps.apple.com/us/app/google-authenticator/id388497605'
              )
            }}
          >
            <AnchorStyle>Google Authenticator</AnchorStyle>
          </a>
          ,{' '}
          <a
            href="https://authy.com/"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              track('https://authy.com/')
            }}
          >
            <AnchorStyle>Authy</AnchorStyle>
          </a>
          , or{' '}
          <a
            href="https://duo.com/"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              track('https://duo.com/')
            }}
          >
            <AnchorStyle>Duo</AnchorStyle>
          </a>
          . Install one from the App Store on iOS, or the Google Play Store on
          Android.
        </StepperMessage>
        <StepperContain>
          <DeviceWrap>
            <Device>
              <Icon name="download" />
            </Device>
          </DeviceWrap>
          <StepperActions>
            <LinkButton
              size="large"
              to={`code${searchString.length ? `?${searchString}` : ''}`}
            >
              Continue
            </LinkButton>
          </StepperActions>
        </StepperContain>
      </StepperContent>
    </StepperBody>
  )
}

const DeviceWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 50px;
`

const Device = styled.div`
  width: 100%;
  max-width: 185px;
  height: 400px;
  background: ${({theme}) => theme.grey05};
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-height: 800px) {
    height: 200px;
    max-width: 92px;
  }
`
const AnchorStyle = styled.span`
  color: ${({theme}) => theme.blue};
  &:focus,
  &:hover {
    color: ${({theme}) => theme.blueDark};
  }
  &:active {
    color: ${({theme}) => theme.blueDarkest};
  }
`

import {CognitoUser} from 'amazon-cognito-identity-js'
import React, {useState} from 'react'
import {Error as ErrorLayout} from '../../../components/auth/layout'
import {Button} from '../../../components/library/buttons'
import {TextInput} from '../../../components/library/form'
import {
  StepperActions,
  StepperBody,
  StepperContain,
  StepperContent,
  StepperMessage,
  StepperTitle
} from '../../../components/library/stepper/stepper'
import {Authenticator} from '../../../lib/cognito'

const verifyToken = (
  user: CognitoUser,
  verificationCode: string
): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    user.getSession(() => {
      user.verifySoftwareToken(verificationCode, 'Atomic Workbench', {
        onSuccess: () => {
          user.setUserMfaPreference(
            null, // SMS
            {
              // Software MFA
              Enabled: true,
              PreferredMfa: true
            },
            (error, data) => (error ? reject(error) : resolve(data))
          )
        },
        onFailure: reject
      })
    })
  })
}

export const Verify = ({
  auth,
  onComplete
}: {
  auth: Authenticator
  onComplete: () => void
}) => {
  const [code, setCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>('')

  return (
    <StepperBody>
      <StepperContent>
        <StepperTitle>Enter authentication code</StepperTitle>
        <StepperMessage>
          Enter the 6-digit code you received in your authentication app.
        </StepperMessage>
        <StepperContain>
          <form
            style={{width: '100%'}}
            onSubmit={async e => {
              e.preventDefault()

              setLoading(true)
              const user = auth.pool.getCurrentUser()
              if (user) {
                let data: string | undefined = undefined
                try {
                  data = await verifyToken(user, code)
                } catch (err) {
                  if (!(err instanceof Error)) throw new Error(String(err))
                  if (err.message.includes('[0-9]+')) {
                    setError('All characters must be numeric')
                  } else if (err.message.includes('Code mismatch')) {
                    setError('Invalid code. Please try again.')
                  } else {
                    setError(err.message)
                  }
                }
                if (data) {
                  onComplete()
                }
              } else {
                setError('Something went wrong. Please try again.')
              }
              setLoading(false)
            }}
          >
            {error !== '' && (
              <ErrorLayout margin="0 0 20px">{error}</ErrorLayout>
            )}
            <TextInput
              style={{marginBottom: '30px'}}
              label="Enter code..."
              upLabel="Code"
              value={code}
              onChange={setCode}
            />
            <StepperActions>
              <Button
                type="submit"
                stretch="center"
                size="large"
                disabled={code.length !== 6 || loading}
              >
                {loading ? 'Loading...' : 'Enable two-step authentication'}
              </Button>
            </StepperActions>
          </form>
        </StepperContain>
      </StepperContent>
    </StepperBody>
  )
}

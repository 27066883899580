import React, {useEffect, useRef, useState} from 'react'
import {createPortal} from 'react-dom'
import styled, {css} from 'styled-components'

export type TooltipPosition =
  | 'top'
  | 'top-left'
  | 'top-right'
  | 'bottom'
  | 'bottom-left'
  | 'bottom-right'
  | 'left'
  | 'right'

export interface TooltipProps {
  content: string | React.ReactNode
  children: React.ReactNode
  isLight?: boolean
  offsetTop?: string
  offsetRight?: string
  offsetBottom?: string
  offsetLeft?: string
  wrapContent?: boolean
  position: TooltipPosition
  disabled?: boolean
  allowFocus?: boolean
  active?: boolean
  className?: string
  asPortal?: boolean
}

interface StyledContentProps {
  isLight?: boolean
  offsetTop?: string
  offsetRight?: string
  offsetBottom?: string
  offsetLeft?: string
  wrapContent?: boolean
  position?: TooltipPosition
  triggerRect?: DOMRect
}

const ToolTipWrapper = styled.div<StyledContentProps>`
  position: relative;
  min-height: 30px;
  white-space: nowrap;
  font-size: ${({theme}) => theme.fontSizeS};
  line-height: ${({theme}) => theme.lineHeightCompact};
  text-align: center;
  border-radius: ${({theme}) => theme.borderRadius};
  padding: 5px 10px;
  box-shadow: ${({theme}) => theme.shadowDepth2};
  z-index: 999;

  ${props =>
    (props.isLight &&
      css`
        background-color: ${({theme}) => theme.white};
        color: ${({theme}) => theme.black};
      `) ||
    (!props.isLight &&
      css`
        background-color: ${({theme}) => theme.black};
        color: ${({theme}) => theme.white};
      `)};

  ${props =>
    props.offsetLeft
      ? css`
          margin-left: ${props.offsetLeft};
        `
      : null};
  ${props =>
    props.offsetRight
      ? css`
          margin-right: ${props.offsetRight};
        `
      : null};
  ${props =>
    props.offsetTop
      ? css`
          margin-top: ${props.offsetTop};
        `
      : null};
  ${props =>
    props.offsetBottom
      ? css`
          margin-bottom: ${props.offsetBottom};
        `
      : null};
  ${props =>
    props.wrapContent
      ? css`
          white-space: normal;
        `
      : null};
`

// TODO this is a WIP. introduced during AF card builder work to support
// tooltips that were otherwise hidden
// Needs to support all positions
// calc(${props.triggerRect?.height}px + 45%)
const PortalTooltip = styled(ToolTipWrapper)`
  ${props =>
    props.position === 'bottom'
      ? css`
          transform: translate(
            calc(${props.triggerRect?.width}px - 50%),
            calc(${props.triggerRect?.height}px + 10px)
          );
        `
      : props.position === 'right'
        ? css`
            transform: translate(
              calc(${props.triggerRect?.width}px + 10px),
              -5px
            );
          `
        : props.position === 'bottom-right'
          ? css`
              transform: translate(
                0,
                calc(${props.triggerRect?.height}px + 15px)
              );
            `
          : props.position === 'left'
            ? css`
                transform: translate(-200px, -5px);
              ` // 200px is a bit of a magic number, but it's doing the trick for now
            : null}
`

const StyledTooltip = styled(ToolTipWrapper)`
  position: absolute;
  ${props =>
    (props.position === 'top' &&
      css`
        left: 50%;
        top: -10px;
        transform: translate(-50%, -100%);
      `) ||
    (props.position === 'top-left' &&
      css`
        left: 0;
        top: -10px;
        transform: translate(0, -100%);
      `) ||
    (props.position === 'top-right' &&
      css`
        right: 0;
        top: -10px;
        transform: translate(0, -100%);
      `) ||
    (props.position === 'bottom' &&
      css`
        left: 50%;
        bottom: -10px;
        transform: translate(-50%, 100%);
      `) ||
    (props.position === 'bottom-left' &&
      css`
        left: 0;
        bottom: -10px;
        transform: translate(0, 100%);
      `) ||
    (props.position === 'bottom-right' &&
      css`
        right: 0;
        bottom: -10px;
        transform: translate(0, 100%);
      `) ||
    (props.position === 'left' &&
      css`
        left: -10px;
        top: 50%;
        transform: translate(-100%, -50%);
      `) ||
    (props.position === 'right' &&
      css`
        right: -10px;
        top: 50%;
        transform: translate(100%, -50%);
      `)};
`

const StyledSpan = styled.span`
  position: relative;
  outline: none;
`

export const Tooltip = ({
  children,
  content,
  position,
  isLight,
  offsetTop,
  offsetRight,
  offsetBottom,
  offsetLeft,
  wrapContent,
  allowFocus,
  disabled,
  active,
  className,
  asPortal
}: TooltipProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const elementRef = useRef<HTMLElement>(null)
  useEffect(() => {
    if (disabled) {
      setIsHovered(false)
      setIsFocused(false)
    }
  }, [disabled])

  let triggerRect: DOMRect | undefined = undefined

  if (asPortal) {
    triggerRect = elementRef.current?.getBoundingClientRect()
  }

  return content ? (
    <StyledSpan
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false)
        setIsFocused(false)
      }}
      onClick={() => setIsFocused(false)}
      tabIndex={allowFocus ? 0 : -1}
      className={`${className ?? ''} tooltip`}
      ref={elementRef}
    >
      <>
        {children}
        {(active !== undefined ? active : isHovered || isFocused) &&
        !disabled ? (
          asPortal ? (
            createPortal(
              <div
                style={{
                  position: 'fixed',
                  zIndex: 99,
                  pointerEvents: 'none',
                  top: triggerRect?.top,
                  left: triggerRect?.left
                }}
              >
                <PortalTooltip
                  position={position}
                  isLight={isLight}
                  triggerRect={triggerRect}
                  offsetTop={offsetTop}
                  offsetRight={offsetRight}
                  offsetBottom={offsetBottom}
                  offsetLeft={offsetLeft}
                  wrapContent={wrapContent}
                >
                  {content}
                </PortalTooltip>
              </div>,
              document.body
            )
          ) : (
            <StyledTooltip
              position={position}
              isLight={isLight}
              offsetTop={offsetTop}
              offsetRight={offsetRight}
              offsetBottom={offsetBottom}
              offsetLeft={offsetLeft}
              wrapContent={wrapContent}
            >
              {content}
            </StyledTooltip>
          )
        ) : null}
      </>
    </StyledSpan>
  ) : (
    <>{children}</>
  )
}

import React, {ReactNode, useContext, useEffect, useMemo, useState} from 'react'
import {useInterval} from 'react-use'
import {ResponseError, RoutingOrganisation} from '../common/types'
import {useAuth} from '../lib/auth'
import {ROUTING_API_URL} from '../lib/env'
import {useListLazy} from '../lib/jsonAPI'

type UseOrganisations = readonly [
  RoutingOrganisation[],
  {
    errors: ResponseError[] | undefined
    loading: boolean
    refetch: () => Promise<void>
  }
]

export const OrganisationsContext = React.createContext<
  UseOrganisations | undefined
>(undefined)

export const OrganisationsProvider = ({children}: {children: ReactNode}) => {
  const {auth, authState} = useAuth()
  const appClientId = auth.getClientId()
  const [organisations, setOrganisations] = useState<RoutingOrganisation[]>([])
  const requestOptions = useMemo(
    () => ({
      headers: {
        'app-client-id': appClientId
      },
      authIsOptional: true // allow unauthenticated requests
    }),
    [appClientId]
  )

  const [query, data] = useListLazy<RoutingOrganisation>(
    `${ROUTING_API_URL}/organisations`,
    undefined,
    requestOptions
  )

  const [firstQueryMs, setFirstQuery] = useState<number | undefined>(undefined)
  useEffect(() => {
    if (authState.authenticated) {
      query()
      setFirstQuery(prev => prev ?? Date.now()) // only set once
    }
  }, [authState.authenticated, query])

  /** Poll for 20s or until we have some organisations.
   * In some cases (like SSO) the user accounts need to first sync to the routing_db.
   * The user may not have access to them immediately */
  useInterval(
    query,
    authState.authenticated &&
      !data.list.length &&
      firstQueryMs &&
      // try for 20s (this will update when the new list comes back from 'query')
      Date.now() < firstQueryMs + 21_000
      ? 4000
      : null // this cancels the interval
  )

  // Ensure we don't show organisations after logout
  useEffect(() => {
    if (!authState.authenticated) {
      setOrganisations([])
    } else {
      setOrganisations(data.list)
    }
  }, [data.list, authState.authenticated])

  const value = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {list: _, ...meta} = data
    // send organisations in state, this ensures an empty list on logout
    return [organisations, {...meta, refetch: query}] as const
  }, [organisations, data, query])

  return (
    <OrganisationsContext.Provider value={value}>
      {children}
    </OrganisationsContext.Provider>
  )
}

export const useOrganisations = () => {
  const ctx = useContext(OrganisationsContext)
  if (!ctx) {
    throw new Error('Calling useOrganisations outside of OrganisationsProvider')
  }
  return ctx
}

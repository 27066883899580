import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EnvironmentDetailFragment = { __typename?: 'Environment', id: string, name: string, created: string, updated: string, active: boolean, organisationId: string, preferences: unknown, organisation?: { __typename?: 'Organisation', name: string } | null };

export type GetEnvironmentQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetEnvironmentQuery = { __typename?: 'Query', environment?: { __typename?: 'Environment', id: string, name: string, created: string, updated: string, active: boolean, organisationId: string, preferences: unknown, organisation?: { __typename?: 'Organisation', name: string } | null } | null };

export type EnvironmentListQueryVariables = Types.Exact<{
  organisationId: Types.Scalars['String']['input'];
  orderBy?: Array<Types.EnvironmentsOrderBy> | Types.EnvironmentsOrderBy;
}>;


export type EnvironmentListQuery = { __typename?: 'Query', environments?: { __typename?: 'EnvironmentsConnection', nodes: Array<{ __typename?: 'Environment', id: string, name: string, created: string, updated: string, active: boolean, organisationId: string, preferences: unknown, organisation?: { __typename?: 'Organisation', name: string } | null }> } | null };

export type AddEnvironmentMutationVariables = Types.Exact<{
  organisationId: Types.Scalars['String']['input'];
  name: Types.Scalars['String']['input'];
  active?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type AddEnvironmentMutation = { __typename?: 'Mutation', createEnvironment?: { __typename?: 'CreateEnvironmentPayload', environment?: { __typename?: 'Environment', id: string, name: string, created: string, updated: string, active: boolean, organisationId: string, preferences: unknown, organisation?: { __typename?: 'Organisation', name: string } | null } | null } | null };

export type UpdateEnvironmentMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  active?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  preferences?: Types.InputMaybe<Types.Scalars['JSON']['input']>;
}>;


export type UpdateEnvironmentMutation = { __typename?: 'Mutation', updateEnvironment?: { __typename?: 'UpdateEnvironmentPayload', environment?: { __typename?: 'Environment', id: string, name: string, created: string, updated: string, active: boolean, organisationId: string, preferences: unknown, organisation?: { __typename?: 'Organisation', name: string } | null } | null } | null };

export const EnvironmentDetailFragmentDoc = gql`
    fragment EnvironmentDetail on Environment {
  id
  name
  created
  updated
  active
  organisationId
  preferences
  organisation {
    name
  }
}
    `;
export const GetEnvironmentDocument = gql`
    query GetEnvironment($id: String!) {
  environment(id: $id) {
    ...EnvironmentDetail
  }
}
    ${EnvironmentDetailFragmentDoc}`;

/**
 * __useGetEnvironmentQuery__
 *
 * To run a query within a React component, call `useGetEnvironmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnvironmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnvironmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEnvironmentQuery(baseOptions: Apollo.QueryHookOptions<GetEnvironmentQuery, GetEnvironmentQueryVariables> & ({ variables: GetEnvironmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEnvironmentQuery, GetEnvironmentQueryVariables>(GetEnvironmentDocument, options);
      }
export function useGetEnvironmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnvironmentQuery, GetEnvironmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEnvironmentQuery, GetEnvironmentQueryVariables>(GetEnvironmentDocument, options);
        }
export function useGetEnvironmentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEnvironmentQuery, GetEnvironmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEnvironmentQuery, GetEnvironmentQueryVariables>(GetEnvironmentDocument, options);
        }
export type GetEnvironmentQueryHookResult = ReturnType<typeof useGetEnvironmentQuery>;
export type GetEnvironmentLazyQueryHookResult = ReturnType<typeof useGetEnvironmentLazyQuery>;
export type GetEnvironmentSuspenseQueryHookResult = ReturnType<typeof useGetEnvironmentSuspenseQuery>;
export type GetEnvironmentQueryResult = Apollo.QueryResult<GetEnvironmentQuery, GetEnvironmentQueryVariables>;
export const EnvironmentListDocument = gql`
    query EnvironmentList($organisationId: String!, $orderBy: [EnvironmentsOrderBy!]! = [NATURAL]) {
  environments(orderBy: $orderBy, condition: {organisationId: $organisationId}) {
    nodes {
      ...EnvironmentDetail
    }
  }
}
    ${EnvironmentDetailFragmentDoc}`;

/**
 * __useEnvironmentListQuery__
 *
 * To run a query within a React component, call `useEnvironmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentListQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useEnvironmentListQuery(baseOptions: Apollo.QueryHookOptions<EnvironmentListQuery, EnvironmentListQueryVariables> & ({ variables: EnvironmentListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnvironmentListQuery, EnvironmentListQueryVariables>(EnvironmentListDocument, options);
      }
export function useEnvironmentListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnvironmentListQuery, EnvironmentListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnvironmentListQuery, EnvironmentListQueryVariables>(EnvironmentListDocument, options);
        }
export function useEnvironmentListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EnvironmentListQuery, EnvironmentListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EnvironmentListQuery, EnvironmentListQueryVariables>(EnvironmentListDocument, options);
        }
export type EnvironmentListQueryHookResult = ReturnType<typeof useEnvironmentListQuery>;
export type EnvironmentListLazyQueryHookResult = ReturnType<typeof useEnvironmentListLazyQuery>;
export type EnvironmentListSuspenseQueryHookResult = ReturnType<typeof useEnvironmentListSuspenseQuery>;
export type EnvironmentListQueryResult = Apollo.QueryResult<EnvironmentListQuery, EnvironmentListQueryVariables>;
export const AddEnvironmentDocument = gql`
    mutation AddEnvironment($organisationId: String!, $name: String!, $active: Boolean) {
  createEnvironment(
    input: {organisationId: $organisationId, name: $name, active: $active}
  ) {
    environment {
      ...EnvironmentDetail
    }
  }
}
    ${EnvironmentDetailFragmentDoc}`;

/**
 * __useAddEnvironmentMutation__
 *
 * To run a mutation, you first call `useAddEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEnvironmentMutation, { data, loading, error }] = useAddEnvironmentMutation({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      name: // value for 'name'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useAddEnvironmentMutation(baseOptions?: Apollo.MutationHookOptions<AddEnvironmentMutation, AddEnvironmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEnvironmentMutation, AddEnvironmentMutationVariables>(AddEnvironmentDocument, options);
      }
export type AddEnvironmentMutationHookResult = ReturnType<typeof useAddEnvironmentMutation>;
export type AddEnvironmentMutationResult = Apollo.MutationResult<AddEnvironmentMutation>;
export type AddEnvironmentMutationOptions = Apollo.BaseMutationOptions<AddEnvironmentMutation, AddEnvironmentMutationVariables>;
export const UpdateEnvironmentDocument = gql`
    mutation UpdateEnvironment($id: String!, $name: String, $active: Boolean, $preferences: JSON) {
  updateEnvironment(
    input: {id: $id, patch: {name: $name, active: $active, preferences: $preferences}}
  ) {
    environment {
      ...EnvironmentDetail
    }
  }
}
    ${EnvironmentDetailFragmentDoc}`;

/**
 * __useUpdateEnvironmentMutation__
 *
 * To run a mutation, you first call `useUpdateEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnvironmentMutation, { data, loading, error }] = useUpdateEnvironmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      active: // value for 'active'
 *      preferences: // value for 'preferences'
 *   },
 * });
 */
export function useUpdateEnvironmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEnvironmentMutation, UpdateEnvironmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEnvironmentMutation, UpdateEnvironmentMutationVariables>(UpdateEnvironmentDocument, options);
      }
export type UpdateEnvironmentMutationHookResult = ReturnType<typeof useUpdateEnvironmentMutation>;
export type UpdateEnvironmentMutationResult = Apollo.MutationResult<UpdateEnvironmentMutation>;
export type UpdateEnvironmentMutationOptions = Apollo.BaseMutationOptions<UpdateEnvironmentMutation, UpdateEnvironmentMutationVariables>;
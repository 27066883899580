import styled from 'styled-components'

export const PageTitle = styled.h2``

export const HeaderAvatars = styled.div`
  margin: 0 5px 0 10px;
`

export const SavingStatus = styled.div`
  font-size: ${p => p.theme.fontSizeS};
  line-height: ${p => p.theme.lineHeightCompact};
  color: ${p => p.theme.grey50};
  margin: 0 10px 0 15px;
  white-space: nowrap;
`

export const PageStatusText = styled.div`
  font-size: ${p => p.theme.fontSizeS};
  line-height: ${p => p.theme.lineHeightCompact};
  color: ${p => p.theme.grey50};
  margin: 0 10px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const PageActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  max-width: 100%;
  button,
  a {
    font-size: 13px;
    line-height: 16px;
    padding: 2px 8px;
    min-height: 24px;
  }
  > ${PageStatusText} {
    margin-left: 10px;
  }
  > button:not(:last-of-type) {
    margin-left: 10px; /* For consecutive buttons */
  }
  > button + button:last-of-type {
    flex: none;
    margin-left: 5px;
  }
`

export const EditableTitle = styled(PageTitle)`
  input {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    padding: 0;
    border: none;
    ::placeholder {
      color: ${({theme}) => theme.grey35};
    }
  }
`

export const PageHeaderContent = styled.div``

const crumbtrailHeight = 20

const Crumbtrail = styled.div`
  font-size: ${p => p.theme.fontSizeXS};
  color: ${p => p.theme.grey50};
  height: ${crumbtrailHeight}px;
  line-height: ${crumbtrailHeight}px;

  a {
    color: ${p => p.theme.blue};
  }
`

export const PageHeader = styled.header`
  position: relative;
  margin: 0px;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border-bottom: solid 1px ${({theme}) => theme.grey10};

  ${PageHeaderContent} {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    > h2 {
      font-size: 16px;
      line-height: 20px;
      margin: 0;
    }
  }
  ${PageActions} {
  }

  ${Crumbtrail} {
    position: absolute;
    top: 0;
    left: 0;
  }

  ${Crumbtrail} ~ ${PageHeaderContent},
  ${Crumbtrail} ~ ${PageActions} {
    margin-top: ${crumbtrailHeight + 5}px;
  }
`

export const PageDescription = styled.div`
  padding: 20px 0 0 20px;
`

export const PageBody = styled.div`
  padding: 20px;
`

export const PageBodyActions = styled.div`
  margin: 30px 0 0;
  button {
    width: 100%;
  }
`

import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ErrorComponent, NoAccess} from './components/errors'
import {NoResults} from './components/library/list/no-results'
import {Loader} from './components/loader'
import {useAuth} from './lib/auth'
import {notify} from './lib/bugsnag'
import {getOrganisationId} from './lib/get-organisation-id'
import {ERR_NO_ORGANISATIONS} from './lib/api'

export const OrganisationRedirect = ({subPath}: {subPath?: string}) => {
  const {auth, authState} = useAuth()
  const navigate = useNavigate()
  const [error, setError] = useState<Error>()
  const [loaded, setLoaded] = useState<boolean>(false)
  const location = useLocation()

  useEffect(() => {
    const appClientId = auth.getClientId()

    getOrganisationId(appClientId)
      .then(id => {
        const to = subPath ? `/${id}/${subPath}${location.search}` : id
        navigate(to, {replace: true})
      })
      .catch(e => {
        if (e.message !== ERR_NO_ORGANISATIONS) {
          setError(e)
        }
        // otherwise user has created an account but has
        // not selected an organisation yet, this is
        // not an error, our state will transition to
        // loading false, and organisation undefined
        setLoaded(true)
      })
  }, [auth, location.search, navigate, subPath])

  if (error) {
    return <ErrorComponent error={error} />
  }
  if (loaded) {
    // Not something that should happen in production
    // I see this when reseting the dev database after inviting a user
    // this will happen in production if a user goes direct to workbench.atomic.io
    // after logging in via a new auth provider, i.e. SSO
    notify(new Error('No organisations available for authenticated user'), {
      authState
    })
    return (
      <NoAccess>
        <NoResults heading="No active organisations" icon="plug" message={''}>
          <p>Please contact your organisation administrator</p>
        </NoResults>
      </NoAccess>
    )
  }
  return <Loader />
}

import * as t from 'io-ts'

const FillRuleCodec = t.union([
  t.literal('nonzero'),
  t.literal('evenodd'),
  t.literal('inherit'),
  t.literal('')
])

const PathCodec = t.array(
  t.union([
    t.string,
    t.intersection([
      t.type({
        d: t.string
      }),
      t.partial({
        strokeLinecap: t.string,
        strokeLinejoin: t.string,
        strokeWidth: t.string,
        stroke: t.string,
        fill: t.string,
        fillRule: FillRuleCodec,
        clipRule: t.string
      })
    ])
  ])
)

export const IconCodec = t.intersection([
  t.type({
    height: t.union([t.number, t.string]),
    width: t.union([t.number, t.string]),
    viewbox: t.string,
    path: PathCodec
  }),
  t.partial({
    fill: t.string,
    fillRule: FillRuleCodec,
    colors: t.array(t.string),
    rects: t.array(
      t.type({
        height: t.string,
        rx: t.string,
        width: t.string,
        x: t.string,
        y: t.string
      })
    )
  })
])

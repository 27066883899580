import React from 'react'
import styled from 'styled-components'
import {Tag} from './library/tag/tag'

export const StyledFlag = styled.div`
  margin: -1px 0 -1px 5px;
  display: inline-block;
  font-weight: normal;
`

export const PreviewFlagMarker = ({className}: {className?: string}) => (
  <StyledFlag className={className}>
    <Tag appearance="blue">Preview</Tag>
  </StyledFlag>
)

export const DeprecatedFlagMarker = ({className}: {className?: string}) => (
  <StyledFlag className={className}>
    <Tag appearance="red">Deprecated</Tag>
  </StyledFlag>
)

/**
 * @deprecated Use PreviewFlagMarker
 */
export const FeatureFlagMarker = PreviewFlagMarker

export const BetaFlagMarker = ({className}: {className?: string}) => (
  <StyledFlag className={className}>
    <Tag appearance="blue">Beta</Tag>
  </StyledFlag>
)

import {gql} from '@apollo/client'
import {Action, KBarProviderProps, useRegisterActions} from 'kbar'
import memoizeOne from 'memoize-one'
import React, {useEffect, useMemo} from 'react'
import {NavigateFunction, useNavigate} from 'react-router'
import {RoutingOrganisation} from '../common/types'
import {isDefined} from '../common/types/helpers'
import {Icon} from '../components/library/icon/icon'
import {getActionsForSidebar} from '../components/sidebar'
import {EnvironmentNode} from '../environment-provider'
import {Flags, useFlags} from '../hooks/use-flags'
import {upperCaseFirst} from '../lib/helpers'
import {useFrecency} from '../providers/frecency'
import {cardTemplateVersionName} from '../views/card-builder/helpers'
import {getActionsForConfiguration} from '../views/configuration/configuration-actions'
import {getActionsForToolbar} from '../views/tools/actions'
import {useGetCommandItemsLazyQuery} from './generated/provider'
import {CmdkActionGetter} from './types'
import {UNTITLED_ACTION_FLOW_NAME} from '../common/types/constants'

export const defaultActions: KBarProviderProps['actions'] = []

const actionGetters: CmdkActionGetter[] = [
  getActionsForSidebar,
  getActionsForToolbar,
  getActionsForConfiguration

  /* TODO
  ✔︎ sidebar items
    ✔︎ subviews e.g. "published cards"
    ✔︎ tools
    ✔︎ configuration
  ✔︎ profile dropdown
  ✔︎ all cards
  ✔︎ all action flows
  ✔︎ all themes

  show stream
  ?? open connect modal (sort of done but errors)
  ✔︎ copy org, env id etc to clipboard?
  ✔︎ switch org
  ︎✔︎ switch env
  send test card
    second-level choose from list
    default target users, stream, payload etc

  */
]

const getEnvironmentActions = memoizeOne(
  (
    navigate: NavigateFunction,
    environment: EnvironmentNode | undefined,
    flags: Flags | undefined
  ): Action[] =>
    environment
      ? actionGetters
          .flatMap(getter => getter(flags))
          .map(action =>
            'to' in action
              ? {
                  id: `url:${action.to}`,
                  perform: () =>
                    navigate(
                      action.scope === 'root'
                        ? action.to
                        : action.scope === 'organisation'
                          ? `/${environment.organisationId}/${action.to}`
                          : `/${environment.organisationId}/${environment.id}/${action.to}`
                    ),
                  ...action
                }
              : action
          )
      : []
)

export const useEnvironmentActions = (
  organisations: RoutingOrganisation[],
  organisationId: string,
  environment: EnvironmentNode | undefined,
  signOut: () => void
) => {
  const flags = useFlags(environment?.organisationId)
  const navigate = useNavigate()
  const actions = getEnvironmentActions(navigate, environment, flags)
  const items = useCommandItems(environment)
  const legacyCardsEnabled = !!items?.getOrganisationAccess?.legacyCardsEnabled

  useRegisterActionsWithFrecency(
    environment && actions.length // need the parent actions first
      ? actions
          .concat([
            {
              id: 'switch-org',
              name: `Switch organisation`
            }
          ])
          .concat(
            organisations
              .filter(org => org.id !== organisationId)
              .map(org => ({
                id: `switch-org-${org.id}`,
                parent: 'switch-org',
                name: org.attributes.name,
                perform: () => navigate(`/${org.id}`)
              }))
          )
          .concat([
            {
              id: 'switch-env',
              name: `Switch environment`
            }
          ])
          .concat(
            items?.getOrganisationAccess?.environments
              ?.filter(isDefined)
              .map(env => ({
                id: `switch-env-${env.id}`,
                parent: 'switch-env',
                name: env.name,
                perform: () => navigate(`/${organisationId}/${env.id}`)
              })) ?? []
          )

          .concat([
            {id: 'logout', name: 'Log out', perform: () => signOut()},
            {
              id: 'copy-org-id',
              name: 'Copy organisation ID',
              perform: () => navigator.clipboard.writeText(organisationId)
            },
            {
              id: 'copy-env-id',
              name: 'Copy environment ID',
              perform: () => navigator.clipboard.writeText(environment.id)
            }
          ])

          // cards
          .concat(
            legacyCardsEnabled
              ? [
                  {
                    id: 'cards',
                    name: 'Cards',
                    icon: <Icon name="cards_nav" />,
                    perform: () =>
                      navigate(
                        `/${environment.organisationId}/${environment.id}/cards`
                      )
                  }
                ]
              : []
          )
          .concat([
            {
              id: 'media-library',
              name: 'Media Library',
              keywords: 'media images files photos videos png jpg',
              icon: <Icon name="image_add" />,
              perform: () =>
                navigate(
                  `/${environment.organisationId}/${environment.id}/media`
                )
            }
          ])
          .concat(
            legacyCardsEnabled
              ? ['draft', 'published', 'archived'].map(status => ({
                  id: `cards:${status}`,
                  name: `${upperCaseFirst(status)} cards`,
                  parent: 'cards',
                  perform: () =>
                    navigate(
                      `/${environment.organisationId}/${environment.id}/cards?tab=${status}`
                    )
                }))
              : []
          )
          .concat(
            legacyCardsEnabled
              ? items?.cardTemplates?.nodes.map(item => ({
                  id: `url:cards:${item.id}`,
                  name: cardTemplateVersionName(item.currentVersionName),
                  parent: `cards:${item.currentStatus?.toLowerCase()}`,
                  perform: () =>
                    navigate(
                      `/${environment.organisationId}/${environment.id}/cards/${item.id}`
                    )
                })) ?? []
              : []
          )

          // action flows
          .concat(
            flags.actionFlows || !legacyCardsEnabled
              ? (
                  [
                    {
                      id: 'action-flows',
                      name: 'Action Flows',
                      icon: <Icon name="trigger_add" />,
                      perform: () =>
                        navigate(
                          `/${environment.organisationId}/${environment.id}/action-flows`
                        )
                    }
                  ] as Action[]
                ).concat(
                  items?.actionFlowConfigs?.nodes.map(item => ({
                    id: `url:action-flows:${item.id}`,
                    name: item.name || UNTITLED_ACTION_FLOW_NAME,
                    parent: 'action-flows',
                    perform: () =>
                      navigate(
                        `/${environment.organisationId}/${environment.id}/action-flow/${item.id}/c/edit`
                      )
                  })) ?? []
                )
              : []
          )

          // themes
          .concat(
            items?.themes?.nodes.map(theme => ({
              id: `url:themes:${theme.id}`,
              name: theme.name || 'Untitled theme',
              parent: 'url:configuration/container-themes',
              perform: () =>
                navigate(
                  `/${environment.organisationId}/${environment.id}/themes/${theme.id}`
                )
            })) ?? []
          )
          .concat({
            id: `url:documentation`,
            name: 'Documentation',
            // shortcut: ['?'],
            icon: <Icon name="info" />,
            perform: () =>
              window.open('https://documentation.atomic.io', '_blank')
          })
      : [],
    [actions, items, organisationId]
  )
}

// Note that you need the FrecencyProvider
export const useRegisterActionsWithFrecency = (
  actions: Action[],
  dependencies: React.DependencyList = []
) => {
  const frecency = useFrecency()
  const withPriority = useMemo(
    () =>
      actions.map(action => ({
        ...action,
        priority: action.priority // keep any specified priorities
          ? action.priority
          : frecency.getWeight(action.id)
      })),

    /**
     * useRegisterActions takes a list of dependencies which determine
     * whether or not to update the actions. Since we're just wrapping the hook
     * we take the dependencies we're passed and add the frecency to the list.
     * To save recomputing the priority for actions unless necessary.
     * See https://github.com/timc1/kbar/blob/main/src/useRegisterActions.tsx
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [frecency, ...dependencies]
  )
  // Could just pass 'withPriority' dep. but passing all dependencies again to be explicit.
  useRegisterActions(withPriority, [withPriority, ...dependencies])
}

const useCommandItems = (environment?: EnvironmentNode) => {
  const [doItemsQuery, {data}] = useGetCommandItemsLazyQuery()

  useEffect(() => {
    if (environment) {
      doItemsQuery({
        variables: {
          environmentId: environment.id,
          organisationId: environment.organisationId
        }
      })
    }
  }, [doItemsQuery, environment])

  return data
}

gql`
  query GetCommandItems($environmentId: String!, $organisationId: String!) {
    cardTemplates(
      condition: {environmentId: $environmentId}
      filter: {currentStatus: {in: [DRAFT, PUBLISHED]}}
    ) {
      nodes {
        id
        name
        currentVersionName
        currentStatus
      }
    }
    actionFlowConfigs(condition: {environmentId: $environmentId}) {
      nodes {
        id
        name
      }
    }
    themes(condition: {environmentId: $environmentId}) {
      nodes {
        id
        name
      }
    }
    getOrganisationAccess(organisationId: $organisationId) {
      id
      name
      environments {
        id
        name
      }
      legacyCardsEnabled
    }
  }
`

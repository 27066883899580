import * as Types from '../../data/workbench-api/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ManageAccountMenuQueryVariables = Types.Exact<{
  organisationId: Types.Scalars['String']['input'];
}>;


export type ManageAccountMenuQuery = { __typename?: 'Query', query: { __typename?: 'Query', businessUsers?: { __typename?: 'BusinessUsersConnection', totalCount: number } | null } };


export const ManageAccountMenuDocument = gql`
    query ManageAccountMenu($organisationId: String!) {
  query {
    businessUsers(first: 0, condition: {organisationId: $organisationId}) {
      totalCount
    }
  }
}
    `;

/**
 * __useManageAccountMenuQuery__
 *
 * To run a query within a React component, call `useManageAccountMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageAccountMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageAccountMenuQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useManageAccountMenuQuery(baseOptions: Apollo.QueryHookOptions<ManageAccountMenuQuery, ManageAccountMenuQueryVariables> & ({ variables: ManageAccountMenuQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManageAccountMenuQuery, ManageAccountMenuQueryVariables>(ManageAccountMenuDocument, options);
      }
export function useManageAccountMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManageAccountMenuQuery, ManageAccountMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManageAccountMenuQuery, ManageAccountMenuQueryVariables>(ManageAccountMenuDocument, options);
        }
export function useManageAccountMenuSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ManageAccountMenuQuery, ManageAccountMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ManageAccountMenuQuery, ManageAccountMenuQueryVariables>(ManageAccountMenuDocument, options);
        }
export type ManageAccountMenuQueryHookResult = ReturnType<typeof useManageAccountMenuQuery>;
export type ManageAccountMenuLazyQueryHookResult = ReturnType<typeof useManageAccountMenuLazyQuery>;
export type ManageAccountMenuSuspenseQueryHookResult = ReturnType<typeof useManageAccountMenuSuspenseQuery>;
export type ManageAccountMenuQueryResult = Apollo.QueryResult<ManageAccountMenuQuery, ManageAccountMenuQueryVariables>;
import React from 'react'
import {WebAppFlavour} from '../demo-login/app'
import {ENVIRONMENT_NAME} from '../lib/env'
import {ButtonGroup} from './form'
import {Button, LinkButton} from './library/buttons'
import {Copyable} from './library/copy'

const translatedEnvironmentName =
  // @ts-ignore
  {
    master: 'production'
  }[ENVIRONMENT_NAME] ?? ENVIRONMENT_NAME

const appDomains = {
  alpha: 'alpha-atomic-io-1c2d2.firebaseapp.com',
  shell: 'shell-atomic-io.firebaseapp.com',
  demo: 'demo.atomic.io'
}

interface MagicLinkProps {
  destination?: string
  link?: string
  handleClose?: () => void
  isModal: boolean
  userIsPresent?: boolean
}

export const MagicLinkButtons = ({
  link,
  destination,
  handleClose,
  isModal,
  userIsPresent
}: MagicLinkProps) => (
  <>
    {isModal ? (
      <ButtonGroup>
        {link && userIsPresent && (
          <LinkButton
            size="large"
            href={link}
            target="blank"
            iconRight="external"
          >
            {`Open ${destination} app`}
          </LinkButton>
        )}
        <Button onClick={handleClose} appearance="secondary" size="large">
          Cancel
        </Button>
      </ButtonGroup>
    ) : (
      <>
        <LinkButton
          style={{marginTop: '20px'}}
          stretch="center"
          size="large"
          href={link ?? ''}
          disabled={link ? false : true}
        >
          Finish setup
        </LinkButton>
        {link && (
          <div style={{marginTop: '20px', alignSelf: 'center'}}>
            <Copyable value={link}>
              {copying => (
                <Button appearance="transparent" size="large">
                  {copying ? 'Copied' : 'Copy link'}
                </Button>
              )}
            </Copyable>
          </div>
        )}
      </>
    )}
  </>
)

export const createMagicLink = ({
  environmentId,
  streamContainerId,
  organisationId,
  authSecret,
  endUserId,
  appFlavour
}: {
  environmentId: string
  streamContainerId: string
  organisationId: string
  authSecret: string
  endUserId: string
  appFlavour: WebAppFlavour
}) => {
  const sp = new URLSearchParams({
    siteId: environmentId,
    containerId: streamContainerId,
    orgId: organisationId,
    authSecret: `${endUserId}:${authSecret}`,
    apiEnv: translatedEnvironmentName
  })

  return `https://${appDomains[appFlavour]}/configure?${sp}`
}

export const createAuthenticatedMagicLink = ({
  environmentId,
  organisationId,
  magicLinkId
}: {
  environmentId: string
  organisationId: string
  magicLinkId: string
}) => {
  const sp = new URLSearchParams({
    id: magicLinkId,
    envId: environmentId,
    orgId: organisationId
  })
  return `${window.location.origin}/magic-link.html?${sp}`
}

const ORGANISATION_ID_KEY = 'currentOrganisationId'
const ENVIRONMENT_ID_KEY = 'currentEnvironmentId'

export const getLocalOrganisationId = () =>
  localStorage.getItem(ORGANISATION_ID_KEY) ?? undefined

export const setLocalOrganisationId = (id: string) =>
  localStorage.setItem(ORGANISATION_ID_KEY, id)

const makeEnvKey = (orgId: string, envKey: string) => `${orgId}-${envKey}`

export const getLocalEnvironmentId = (orgId: string) => {
  const orgBasedEnv =
    localStorage.getItem(makeEnvKey(orgId, ENVIRONMENT_ID_KEY)) ?? undefined

  return orgBasedEnv
}

export const setLocalEnvironmentId = (orgId: string, id: string) =>
  localStorage.setItem(makeEnvKey(orgId, ENVIRONMENT_ID_KEY), id)

export const removeLocalEnvironmentId = (orgId: string) =>
  localStorage.removeItem(makeEnvKey(orgId, ENVIRONMENT_ID_KEY))

import {useCrumbs} from '../../providers/crumbs'
import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import Divider from '../../assets/icons/forward-slash.svg'
import {Crumb, CrumbButton} from './crumb'

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  svg {
    color: ${({theme}) => theme.grey10};
  }
`

export const Crumbs = ({className}: {className?: string}) => {
  const {crumbs} = useCrumbs()
  return crumbs ? (
    <Wrapper className={className}>
      {crumbs.map((crumb, i) => {
        return (
          <React.Fragment
            key={`${typeof crumb === 'string' ? crumb : crumb[0]}-${i}`}
          >
            <Divider />
            {typeof crumb === 'string' ? (
              <Crumb>{crumb}</Crumb>
            ) : (
              <CrumbButton>
                <Link
                  data-testid={`link-crumb-${crumb[1].toLowerCase()}`}
                  to={crumb[0]}
                >
                  {crumb[1]}
                </Link>
              </CrumbButton>
            )}
          </React.Fragment>
        )
      })}
    </Wrapper>
  ) : null
}

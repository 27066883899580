import './polyfills'

import 'normalize.css'
import React, {StrictMode} from 'react'
import {createRoot} from 'react-dom/client'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {BrowserRouter} from 'react-router-dom'
import {StyleSheetManager, ThemeProvider} from 'styled-components'
import {App} from './app'
import './assets/style.css'
import {defaultTheme} from './components/library/theme'
import {AnalyticsProvider} from './lib/analytics/analytics'
import {getAuth} from './lib/api'
import {AuthProvider} from './lib/auth'
import {BugsnagErrorBoundary, init as initBugsnag, notify} from './lib/bugsnag'
import {OrganisationsProvider} from './lib/organisations'
import {shouldForwardProp} from './lib/should-forward-prop'

const RootComponent = () => (
  <StrictMode>
    <BugsnagErrorBoundary>
      <AuthProvider auth={getAuth()}>
        <OrganisationsProvider>
          <AnalyticsProvider>
            <ThemeProvider theme={defaultTheme}>
              <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                {/* this enables multiple Routes Collections within the application */}
                <BrowserRouter>
                  <HelmetProvider>
                    <Helmet>
                      <title>Atomic Workbench</title>
                    </Helmet>
                    <App />
                  </HelmetProvider>
                </BrowserRouter>
              </StyleSheetManager>
            </ThemeProvider>
          </AnalyticsProvider>
        </OrganisationsProvider>
      </AuthProvider>
    </BugsnagErrorBoundary>
  </StrictMode>
)

initBugsnag()

const container = document.getElementById('root')

document.addEventListener('securitypolicyviolation', e => {
  // notify bugsnag as we don't have a csp-report endpoint
  notify(new Error('CSP Violation'), {
    blockedUri: e.blockedURI,
    columnNumber: e.columnNumber,
    disposition: e.disposition,
    documentUri: e.documentURI,
    effectiveDirective: e.effectiveDirective,
    lineNumber: e.lineNumber,
    originalPolicy: e.originalPolicy,
    referrer: e.referrer,
    sourceFile: e.sourceFile,
    statusCode: e.statusCode,
    violatedDirective: e.violatedDirective
  })
})

if (container) {
  const root = createRoot(container)
  root.render(<RootComponent />)
} else {
  throw new Error('Could not mount app at root')
}

import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js'
import {isLeft} from 'fp-ts/lib/These'
import * as t from 'io-ts'
import {createNanoEvents} from 'nanoevents'
import {
  BU_COGNITO_APP_ID,
  BU_COGNITO_ENVIRONMENT_NAME,
  BU_COGNITO_POOL_ID,
  BU_COGNITO_REGION,
  WORKBENCH_CLIENT_URL,
  SFMC_PLUGIN_URL
} from './env'
import {notify} from './bugsnag'

const CLIENT_ID_KEY = 'authClientId'

const getLocalClientId = () => localStorage.getItem(CLIENT_ID_KEY)

const setLocalClientId = (clientId: string) =>
  localStorage.setItem(CLIENT_ID_KEY, clientId)

interface AuthStateAnon {
  authenticated: false
  loaded: boolean
  error?: string
}
interface UserInfo {
  sub: string
  username: string
  email: string
  mfaEnabled: boolean
  ssoLogin: boolean
  aud: string
}
export interface AuthStateAuth extends UserInfo {
  authenticated: true
  loaded: true
}

export type AuthState = AuthStateAnon | AuthStateAuth

type Event = 'stateChange' | 'signOut'

type Payload = {
  [key: string]: string | number | boolean
}
interface Events {
  stateChange: (authState: AuthState, payload?: Payload) => void
  signOut: () => void
}

export type SignInResult =
  | {state: 'success' | 'confirm'}
  | {
      state: 'totp'
      sendTOTPCode: (code: string) => Promise<void>
    }

type SignInFunc = (username: string, password: string) => Promise<SignInResult>

type SignUpFunc = (
  username: string,
  password: string
) => Promise<{state: 'success' | 'confirm'}>

type ConfirmFunc = (username: string, code: string) => Promise<boolean>

type ResendCodeFunc = (username: string) => Promise<boolean>

type HandleAuthFunc = (
  code: string
) => Promise<{success: true} | {success: false; error: string}>

type SetClientIdFunc = (
  clientId: string | null
) => Promise<{success: true} | {success: false; error: string}>

type GetAuthUrlFunc = (
  action: 'login' | 'forgotPassword',
  redirect?: string
) => string

// extract useful information from a CognitoUser into a simple object
const sanitiseCognitoUser = async (user: CognitoUser): Promise<UserInfo> => {
  return new Promise((resolve, reject) => {
    user.getSession((err: Error | null, session: CognitoUserSession) => {
      if (err) {
        reject(err)
      } else {
        const token = session.getIdToken()
        resolve({
          sub: token.payload.sub,
          username: token.payload['cognito:username'],
          email: token.payload.email,
          mfaEnabled: token.payload.mfa_enabled === 'true',
          ssoLogin: 'identities' in token.payload,
          aud: token.payload.aud
        })
      }
    })
  })
}

/** avoid case sensitivity issues in cognito (currently username = email) */
const normaliseUsername = (username: string): string =>
  username.trim().toLowerCase()

// these need to be allowed in the cognito app client (LogoutURLs)
// https://github.com/atomic-app/environments/blob/development/cfn-masters/cognitos/lambda/cfn-stack-cognitos-pre-package.yaml#L405
type KnownSignOutPaths = '/' | '/demo-login.html'

export interface Authenticator {
  on: (
    event: Event,
    handler: (state: AuthState, payload?: Payload) => void | Promise<void>,
    immediate?: boolean
  ) => () => void
  signIn: SignInFunc
  signUp: SignUpFunc
  signOut: (redirect?: KnownSignOutPaths) => void
  getAuthState: () => AuthState
  refreshAuthState: () => Promise<void>
  getIdToken: () => Promise<string>
  confirm: ConfirmFunc
  resendCode: ResendCodeFunc
  pool: CognitoUserPool
  handleAuthCode: HandleAuthFunc
  setClientId: SetClientIdFunc
  getClientId: () => string
  getAuthUrl: GetAuthUrlFunc
}

export class CognitoError extends Error {}

const AuthResponseCodec = t.type({
  id_token: t.string,
  access_token: t.string,
  refresh_token: t.string
})

export const makeAuthenticator = (
  appFlavour: 'workbench' | 'sfmc-plugin'
): Authenticator => {
  // use custom client app if found, to enable SSO, defaulting to primary
  const getClientId = () => getLocalClientId() || BU_COGNITO_APP_ID
  const makePool = () =>
    new CognitoUserPool({
      UserPoolId: BU_COGNITO_POOL_ID,
      ClientId: getClientId()
    })

  let pool: CognitoUserPool = makePool()

  const reinitialisePool = async () => {
    pool = makePool()
    await emit('stateChange')
  }

  let authState: AuthState = {authenticated: false, loaded: false}

  const emitter = createNanoEvents<Events>()

  const getAuthState = () => authState

  const setAuthState = async () => {
    const user = pool.getCurrentUser()
    let newAuthState: AuthState
    if (user) {
      try {
        const userDetails = await sanitiseCognitoUser(user)
        newAuthState = {authenticated: true, loaded: true, ...userDetails}
      } catch (e) {
        // TODO log this properly - may be caused by Cognito's localStorage
        // data getting out of sync with the user pool
        console.error(e) // tslint:disable-line
        newAuthState = {authenticated: false, loaded: true}
      }
    } else {
      newAuthState = {authenticated: false, loaded: true}
    }
    // Only create a new object if newAuthState has changed
    // This can prevent unnecessary renders via the AuthProvider
    if (JSON.stringify(newAuthState) !== JSON.stringify(authState)) {
      authState = newAuthState
    }
  }

  const emit = async (event: Event, payload?: Payload) => {
    await setAuthState()
    emitter.emit(event, authState, payload)
  }

  emit('stateChange')

  const handleAuthCode: HandleAuthFunc = async (code: string) => {
    // code adapted from
    // https://github.com/aws-amplify/amplify-js/blob/61f7478609fce7dd2f25c562aeb887d3f3db4a67/packages/auth/src/OAuth/OAuth.ts#L120
    // https://github.com/aws-amplify/amplify-js/blob/61f7478609fce7dd2f25c562aeb887d3f3db4a67/packages/auth/src/Auth.ts#L1921-L1929
    // https://github.com/aws-amplify/amplify-js/blob/61f7478609fce7dd2f25c562aeb887d3f3db4a67/packages/auth/src/Auth.ts#L1950-L1952

    const url = `${getBaseAuthUrl()}/oauth2/token`
    const body = `grant_type=authorization_code&client_id=${getClientId()}&code=${code}&redirect_uri=${getRedirectUri(
      getClientId(),
      appFlavour
    )}`
    const response = await fetch(url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body
    })

    if (response.status >= 300) {
      return {
        success: false,
        error: 'Invalid response from auth server'
      }
    }

    const raw = JSON.parse(await response.text())
    const result = AuthResponseCodec.decode(raw)
    if (isLeft(result)) {
      return {
        success: false,
        error: 'Invalid response from auth server ( codec failed )'
      }
    }

    const session = new CognitoUserSession({
      IdToken: new CognitoIdToken({IdToken: result.right.id_token}),
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: result.right.refresh_token
      }),
      AccessToken: new CognitoAccessToken({
        AccessToken: result.right.access_token
      })
    })

    const user = new CognitoUser({
      Pool: pool,
      Username: session.getIdToken().decodePayload()['cognito:username']
    })

    user.setSignInUserSession(session)

    await emit('stateChange')

    return {success: true}
  }

  const setClientId: SetClientIdFunc = async clientId => {
    if (getClientId() !== clientId) {
      setLocalClientId(clientId || '')
      await reinitialisePool()
    }
    return {success: true}
  }

  const signIn: SignInFunc = (email: string, password: string) => {
    const username = normaliseUsername(email)
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password
    })

    const user = new CognitoUser({Pool: pool, Username: username})

    return new Promise((resolve, reject) => {
      user.authenticateUser(authenticationDetails, {
        selectMFAType: function (challengeName, challengeParameters) {
          if (challengeName !== 'SELECT_MFA_TYPE') {
            const message = `Unexpected challenge "${challengeName}", contact support`
            notify(message)
            reject(new CognitoError(message))
          }
          const mfaOptions = JSON.parse(challengeParameters.MFAS_CAN_CHOOSE)
          // Try SMS in the first case, we are seeing selectMFAType where users don't
          // have MFA enabled, so using SOFTWARE_TOKEN_MFA isn't going to be very useful
          if (mfaOptions.includes('SMS_MFA')) {
            user.sendMFASelectionAnswer('SMS_MFA', this)
            return
          }
          if (mfaOptions.includes('SOFTWARE_TOKEN_MFA')) {
            user.sendMFASelectionAnswer('SOFTWARE_TOKEN_MFA', this)
            return
          }
          const message =
            'Unable to proceed with login due to MFA issue, contact support'
          notify(message)
          reject(new CognitoError(message))
        },
        onSuccess: async () => {
          // const accessToken = result.getAccessToken().getJwtToken()
          // user.associateSoftwareToken(this)

          await emit('stateChange')

          resolve({state: 'success'})
        },
        onFailure: err => {
          console.log(err)

          if (err.code === 'UserNotConfirmedException') {
            resolve({state: 'confirm'})
          } else {
            reject(new CognitoError(err.message))
          }
        },
        mfaRequired: async function codeDeliveryDetails() {
          user.sendMFACode(
            window.prompt('Please input verification code', '') || '',
            this
          )
          await emit('stateChange')
          resolve({state: 'success'})
        },
        totpRequired: () => {
          resolve({
            state: 'totp',
            // pass through promisified callback which can be used by the caller
            // to send the MFA code
            sendTOTPCode: (code: string) => {
              return new Promise((res, rej) => {
                user.sendMFACode(
                  code,
                  {
                    onFailure: error => {
                      rej(error)
                    },
                    onSuccess: () => {
                      emit('stateChange').then(() => res())
                    }
                  },
                  'SOFTWARE_TOKEN_MFA'
                )
              })
            }
          })
        }
      })
    })
  }

  const signUp: SignUpFunc = (email: string, password: string) => {
    const username = normaliseUsername(email)

    // user will log in with their email address
    const attributes = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: username
      })
    ]

    return new Promise((resolve, reject) => {
      pool.signUp(username, password, attributes, [], (signupError, result) => {
        if (signupError) {
          reject(new CognitoError('Signup error: ' + signupError.message))
        } else if (result) {
          if (result.userConfirmed) {
            resolve({state: 'success'})
          } else {
            resolve({state: 'confirm'})
          }
        } else {
          reject(new Error('An unknown error occurred'))
        }
      })
    })
  }

  const confirm: ConfirmFunc = (username: string, verificationCode: string) => {
    const normalisedUsername = normaliseUsername(username)
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({Pool: pool, Username: normalisedUsername})

      user.confirmRegistration(verificationCode, true, confirmError => {
        if (confirmError) {
          reject(new CognitoError(confirmError.message))
        } else {
          resolve(true)
        }
      })
    })
  }

  const resendCode: ResendCodeFunc = (username: string) => {
    const normalisedUsername = normaliseUsername(username)
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({Pool: pool, Username: normalisedUsername})

      user.resendConfirmationCode((err, result) => {
        if (err) {
          console.log(err)
          reject(new CognitoError(err.message))
        } else {
          console.log(result)
          resolve(true)
        }
      })
    })
  }

  const signOut = async (redirectPath: KnownSignOutPaths = '/') => {
    const user = pool.getCurrentUser()
    if (user) {
      user.signOut()
    }

    const params = new URLSearchParams({
      client_id: getClientId(),
      logout_uri: window.origin + redirectPath // redirect back to the workbench + path. Note: this needs to be a valid redirect uri in the cognito app client
    })
    const logoutUrl = `${getBaseAuthUrl()}/logout?${params}`

    setLocalClientId('')

    await emit('signOut')
    await emit('stateChange')

    // This redirect ensures that the existing cognito session is cleared for SSO users
    // if we don't do this then the user can log back in for a period even if they're logged out of their IDP (aka cognito wont check the IDP)
    // setTimeout gives time to send any analytics etc
    setTimeout(() => (window.location.href = logoutUrl), 500) // eslint-disable-line functional/immutable-data
  }

  const getIdToken = (forceRefresh = false): Promise<string> => {
    const user = pool.getCurrentUser()

    if (!user) {
      return Promise.reject('User not logged in')
    }

    return new Promise((resolve, reject) => {
      user.getSession((err: Error | null, session: CognitoUserSession) => {
        if (err) {
          return reject(err)
        }
        if (!forceRefresh && session.isValid()) {
          return resolve(session.getIdToken().getJwtToken())
        }
        const refreshToken = session.getRefreshToken()
        if (refreshToken) {
          user.refreshSession(
            refreshToken,
            (e: Error, refreshed: CognitoUserSession) => {
              if (e) {
                return reject(e)
              }
              if (refreshed.isValid()) {
                return resolve(refreshed.getIdToken().getJwtToken())
              }
              return reject('User not logged in, refresh token failed')
            }
          )
        } else {
          return reject('User not logged in, missing refresh token')
        }
      })
    })
  }

  const getAuthUrl: GetAuthUrlFunc = (action, redirect) => {
    const baseParams = {
      response_type: 'code',
      client_id: getClientId(),
      redirect_uri: redirect || getRedirectUri(getClientId(), appFlavour)
    }

    if (action === 'login') {
      const params = new URLSearchParams(baseParams)
      return `${getBaseAuthUrl()}/oauth2/authorize?${params}`
    }

    const params = new URLSearchParams(baseParams)
    return `${getBaseAuthUrl()}/${action}?${params}`
  }

  return {
    on: (event, handler, immediate = false) => {
      if (immediate) {
        handler(authState)
      }
      return emitter.on(event, handler)
    },
    signIn,
    signUp,
    signOut,
    getAuthState,
    refreshAuthState: async () => {
      await getIdToken(true)
    },
    getIdToken,
    confirm,
    resendCode,
    pool,
    handleAuthCode,
    setClientId,
    getClientId,
    getAuthUrl
  }
}

const getBaseAuthUrl = () =>
  BU_COGNITO_ENVIRONMENT_NAME === 'master'
    ? 'https://auth.atomic.io'
    : `https://${BU_COGNITO_ENVIRONMENT_NAME}-atomic-io.auth.${BU_COGNITO_REGION}.amazoncognito.com`

// This should match the "Allowed callback URLs" configured in the cognito application client
// including the token ID - no wildcards.
const getRedirectUri = (clientId: string, appFlavour: string) =>
  appFlavour === 'sfmc-plugin'
    ? `${SFMC_PLUGIN_URL}/auth.html?client_id=${clientId}`
    : `${WORKBENCH_CLIENT_URL}/auth?client_id=${clientId}`

import {
  ApolloClient,
  createHttpLink,
  defaultDataIdFromObject,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'
import {onError} from '@apollo/client/link/error'
import {init as initBugsnag, notify} from './bugsnag'
import {Authenticator, makeAuthenticator} from './cognito'
import {APP_FLAVOUR, CUSTOMER_API_URL, ENVIRONMENT_NAME} from './env'

let auth: Authenticator
const clients: {[orgId: string]: ApolloClient<NormalizedCacheObject>} = {}

export const ERR_NO_ORGANISATIONS = 'No organisations found'

export const getAuth = () => {
  if (!auth) {
    auth = makeAuthenticator(APP_FLAVOUR)

    auth.on('stateChange', authState => {
      const bugsnag = initBugsnag()
      if (bugsnag) {
        if (authState.authenticated) {
          bugsnag.setUser(authState.sub)
        } else {
          bugsnag.setUser()
        }
      }
    })

    auth.on('signOut', () => {
      for (const client of Object.values(clients)) {
        client.cache.reset()
      }
    })
  }
  return auth
}

export const getToken = () =>
  getAuth()
    .getIdToken()
    .catch(e => {
      if (
        (e instanceof Error && /not logged in/.test(e.message)) ||
        /not logged in/.test(String(e))
      ) {
        console.warn(e)
        return undefined
      } else {
        throw e
      }
    })

const authLink = setContext(async (_, {headers}) => {
  const token = getAuth().getAuthState().authenticated
    ? await getAuth().getIdToken()
    : undefined

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const uriLink = (organisationId: string) =>
  setContext(({operationName}, context) => {
    // If context.uri is explicity passed as an option, e.g. as in accept-invitation.tsx
    // then we want it to take precedence
    if (context.uri) {
      return context
    }
    try {
      const endpoint = organisationUri(organisationId)

      // Add the operation name so it's easier to identify graphql requests
      // in the browser devtools - this is ignored by the server
      const suffix = ['development', 'pollution', 'local'].includes(
        ENVIRONMENT_NAME
      )
        ? `?${operationName}`
        : ''

      return {
        uri: `${endpoint}/workbench/graphql${suffix}`
      }
    } catch (e) {
      if (e instanceof Error && e.message === ERR_NO_ORGANISATIONS) {
        return context
      }
      throw e
    }
  })

const errorLink = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({message, locations, path, ...meta}) => {
      const logMessage = `[Apollo GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      notify(logMessage, meta)
      console.error(logMessage)
    })
  }

  if (networkError) {
    const message =
      'bodyText' in networkError
        ? `[Apollo Network error]: ${networkError.bodyText} `
        : `[Apollo Network error]: ${networkError}`

    // If we don't override the message here when have bodyText ( see ServerParserError type, error without json )
    // the only message you get is a json parse error
    if ('bodyText' in networkError) {
      networkError.message = message
    }

    notify(message, networkError)
    console.error(message)
  }
})

const organisationIdHeader = (organisationId: string) =>
  setContext((_, {headers}) => {
    return {
      headers: {
        ...headers,
        'x-atomic-test-host': `${organisationId}.customer-api.atomic.io`
      }
    }
  })

const cache = new InMemoryCache({
  dataIdFromObject: object =>
    'nodeId' in object
      ? `${object.__typename}:${object.nodeId}`
      : defaultDataIdFromObject(object)
})

const timezoneHeader = () =>
  setContext((_, {headers}) => {
    return {
      headers: {
        ...headers,
        'x-atomic-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    }
  })

export const getClient = (organisationId: string) => {
  if (!clients[organisationId]) {
    clients[organisationId] = new ApolloClient({
      link: authLink
        .concat(errorLink)
        .concat(uriLink(organisationId))
        .concat(organisationIdHeader(organisationId))
        .concat(timezoneHeader())
        .concat(createHttpLink()),
      cache,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          nextFetchPolicy: 'cache-first'
        }
      }
    })
  }
  return clients[organisationId]
}

export const organisationUri = (organisationId: string) =>
  CUSTOMER_API_URL.includes('app.github.dev')
    ? CUSTOMER_API_URL
    : getApiUrl(CUSTOMER_API_URL)(organisationId) // prefix a url with organisation id

export const getApiUrl = (baseUrl: string) => (organisationId: string) =>
  baseUrl.replace(/^https:\/\//, `https://${organisationId}.`)

import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrganisationAccessQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetOrganisationAccessQuery = { __typename?: 'Query', getOrganisationAccess?: { __typename?: 'OrganisationAccess', id: string, name: string, preferences?: unknown | null, legacyCardsEnabled?: boolean | null, environments?: Array<{ __typename?: 'OrganisationAccessEnvironment', id: string, name: string } | null> | null, permissions?: Array<{ __typename?: 'OrganisationAccessPermission', action: Types.PermissionAction, resource: Types.PermissionResource, environmentId?: string | null } | null> | null, currentBusinessUser?: { __typename?: 'BusinessUser', firstName?: string | null, lastName?: string | null, linkedTestUserId?: string | null, preferences: unknown, accountId: number } | null } | null };

export type GetOrganisationQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetOrganisationQuery = { __typename?: 'Query', organisation?: { __typename?: 'Organisation', id: string, name: string, preferences: unknown, purgeDays?: number | null } | null };

export type UpdateOrganisationMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  preferences?: Types.InputMaybe<Types.Scalars['JSON']['input']>;
}>;


export type UpdateOrganisationMutation = { __typename?: 'Mutation', updateOrganisation?: { __typename?: 'UpdateOrganisationPayload', organisation?: { __typename?: 'Organisation', id: string, name: string, preferences: unknown } | null } | null };

export type UpdateOrganisationPurgeDaysMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  purgeDays?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type UpdateOrganisationPurgeDaysMutation = { __typename?: 'Mutation', updateOrganisationPurgeDays?: { __typename?: 'OrganisationPayload', organisation?: { __typename?: 'Organisation', id: string, purgeDays?: number | null } | null } | null };


export const GetOrganisationAccessDocument = gql`
    query GetOrganisationAccess($id: String!) {
  getOrganisationAccess(organisationId: $id) {
    id
    name
    environments {
      id
      name
    }
    permissions {
      action
      resource
      environmentId
    }
    currentBusinessUser {
      firstName
      lastName
      linkedTestUserId
      preferences
      accountId
    }
    preferences
    legacyCardsEnabled
  }
}
    `;

/**
 * __useGetOrganisationAccessQuery__
 *
 * To run a query within a React component, call `useGetOrganisationAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationAccessQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganisationAccessQuery(baseOptions: Apollo.QueryHookOptions<GetOrganisationAccessQuery, GetOrganisationAccessQueryVariables> & ({ variables: GetOrganisationAccessQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganisationAccessQuery, GetOrganisationAccessQueryVariables>(GetOrganisationAccessDocument, options);
      }
export function useGetOrganisationAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationAccessQuery, GetOrganisationAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganisationAccessQuery, GetOrganisationAccessQueryVariables>(GetOrganisationAccessDocument, options);
        }
export function useGetOrganisationAccessSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOrganisationAccessQuery, GetOrganisationAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganisationAccessQuery, GetOrganisationAccessQueryVariables>(GetOrganisationAccessDocument, options);
        }
export type GetOrganisationAccessQueryHookResult = ReturnType<typeof useGetOrganisationAccessQuery>;
export type GetOrganisationAccessLazyQueryHookResult = ReturnType<typeof useGetOrganisationAccessLazyQuery>;
export type GetOrganisationAccessSuspenseQueryHookResult = ReturnType<typeof useGetOrganisationAccessSuspenseQuery>;
export type GetOrganisationAccessQueryResult = Apollo.QueryResult<GetOrganisationAccessQuery, GetOrganisationAccessQueryVariables>;
export const GetOrganisationDocument = gql`
    query GetOrganisation($id: String!) {
  organisation(id: $id) {
    id
    name
    preferences
    purgeDays
  }
}
    `;

/**
 * __useGetOrganisationQuery__
 *
 * To run a query within a React component, call `useGetOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganisationQuery(baseOptions: Apollo.QueryHookOptions<GetOrganisationQuery, GetOrganisationQueryVariables> & ({ variables: GetOrganisationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganisationQuery, GetOrganisationQueryVariables>(GetOrganisationDocument, options);
      }
export function useGetOrganisationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationQuery, GetOrganisationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganisationQuery, GetOrganisationQueryVariables>(GetOrganisationDocument, options);
        }
export function useGetOrganisationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOrganisationQuery, GetOrganisationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganisationQuery, GetOrganisationQueryVariables>(GetOrganisationDocument, options);
        }
export type GetOrganisationQueryHookResult = ReturnType<typeof useGetOrganisationQuery>;
export type GetOrganisationLazyQueryHookResult = ReturnType<typeof useGetOrganisationLazyQuery>;
export type GetOrganisationSuspenseQueryHookResult = ReturnType<typeof useGetOrganisationSuspenseQuery>;
export type GetOrganisationQueryResult = Apollo.QueryResult<GetOrganisationQuery, GetOrganisationQueryVariables>;
export const UpdateOrganisationDocument = gql`
    mutation UpdateOrganisation($id: String!, $name: String, $preferences: JSON) {
  updateOrganisation(
    input: {id: $id, patch: {name: $name, preferences: $preferences}}
  ) {
    organisation {
      id
      name
      preferences
    }
  }
}
    `;

/**
 * __useUpdateOrganisationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationMutation, { data, loading, error }] = useUpdateOrganisationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      preferences: // value for 'preferences'
 *   },
 * });
 */
export function useUpdateOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>(UpdateOrganisationDocument, options);
      }
export type UpdateOrganisationMutationHookResult = ReturnType<typeof useUpdateOrganisationMutation>;
export type UpdateOrganisationMutationResult = Apollo.MutationResult<UpdateOrganisationMutation>;
export type UpdateOrganisationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>;
export const UpdateOrganisationPurgeDaysDocument = gql`
    mutation UpdateOrganisationPurgeDays($id: String!, $purgeDays: Int) {
  updateOrganisationPurgeDays(input: {id: $id, purgeDays: $purgeDays}) {
    organisation {
      id
      purgeDays
    }
  }
}
    `;

/**
 * __useUpdateOrganisationPurgeDaysMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationPurgeDaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationPurgeDaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationPurgeDaysMutation, { data, loading, error }] = useUpdateOrganisationPurgeDaysMutation({
 *   variables: {
 *      id: // value for 'id'
 *      purgeDays: // value for 'purgeDays'
 *   },
 * });
 */
export function useUpdateOrganisationPurgeDaysMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganisationPurgeDaysMutation, UpdateOrganisationPurgeDaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganisationPurgeDaysMutation, UpdateOrganisationPurgeDaysMutationVariables>(UpdateOrganisationPurgeDaysDocument, options);
      }
export type UpdateOrganisationPurgeDaysMutationHookResult = ReturnType<typeof useUpdateOrganisationPurgeDaysMutation>;
export type UpdateOrganisationPurgeDaysMutationResult = Apollo.MutationResult<UpdateOrganisationPurgeDaysMutation>;
export type UpdateOrganisationPurgeDaysMutationOptions = Apollo.BaseMutationOptions<UpdateOrganisationPurgeDaysMutation, UpdateOrganisationPurgeDaysMutationVariables>;
import {CognitoUserPool} from 'amazon-cognito-identity-js'
import React, {useState} from 'react'
import {Routes, useNavigate} from 'react-router-dom'
import {FullScreenOverlay} from '../../../components/library/modal/modal'
import {Stepper} from '../../../components/library/stepper/stepper'
import {useEnvironment} from '../../../environment-provider'
import {Authenticator, AuthStateAuth} from '../../../lib/cognito'
import {Route} from '../../../lib/router'
import {VerifyPassword} from '../verify-password'
import {Code} from './code'
import {Start} from './start'
import {Verify} from './verify'
import {useSearchParams} from 'react-router-dom'

export const setMfaPref = (
  pool: CognitoUserPool,
  enabled: boolean
): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const user = pool.getCurrentUser()
    if (user) {
      user.getSession(() => {
        user.setUserMfaPreference(
          null, // SMS
          {
            // Software
            Enabled: enabled,
            PreferredMfa: enabled
          },
          (err, result) => {
            if (err) {
              reject(err)
            } else {
              resolve(result)
            }
          }
        )
      })
    } else {
      reject(new Error('Unable to obtain cognito user'))
    }
  })
}

export const MfaSetup = ({
  title,
  authState,
  auth,
  checkPassword = true,
  onComplete,
  cancelLink = '..'
}: {
  title?: string
  authState: AuthStateAuth
  auth: Authenticator
  checkPassword?: boolean
  onComplete: (redirect?: string) => void
  cancelLink?: string | false
}) => {
  const [searchParams] = useSearchParams()
  const searchString = searchParams.toString()

  const [passwordChecked, setPasswordChecked] =
    useState<boolean>(!checkPassword)
  const navigate = useNavigate()
  const environment = useEnvironment()

  return (
    <div>
      {passwordChecked ? (
        <FullScreenOverlay>
          <Routes>
            <Route
              path="/"
              render={() => (
                <Stepper
                  step={1}
                  steps={3}
                  backLink={cancelLink === false ? undefined : cancelLink}
                >
                  <Start title={title} />
                </Stepper>
              )}
            />
            <Route
              path={`/code`}
              render={() => (
                <Stepper
                  step={2}
                  steps={3}
                  backLink={`..${
                    searchString.length ? `?${searchString}` : ''
                  }`}
                >
                  <Code auth={auth} authState={authState} />
                </Stepper>
              )}
            />
            <Route
              path="/verify"
              render={() => (
                <Stepper
                  step={3}
                  steps={3}
                  backLink={`..${
                    searchString.length ? `?${searchString}` : ''
                  }`}
                >
                  <Verify
                    auth={auth}
                    onComplete={() =>
                      onComplete(searchParams.get('redirect') ?? undefined)
                    }
                  />
                </Stepper>
              )}
            />
          </Routes>
        </FullScreenOverlay>
      ) : (
        <VerifyPassword
          title="Enable two-step authentication"
          auth={auth}
          authState={authState}
          onVerified={() => setPasswordChecked(true)}
          onClose={() => navigate(`/${environment.organisation?.id}/profile`)}
        >
          To begin enabling two-step authentication, please enter your password.
        </VerifyPassword>
      )}
    </div>
  )
}

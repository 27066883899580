import {OrganisationPreferences} from './common/types'
import {ENVIRONMENT_NAME} from './lib/env'

const DEFAULT_INACTIVITY_TIMEOUT = 1800

// returns value in seconds

export const getInactivityTimeout = (
  organisationId: string,
  organisationPreferences?: OrganisationPreferences
): number | undefined => {
  // allow testing via url param
  const override = new URLSearchParams(document.location.search).get(
    'inactivity_time'
  )
  if (override) {
    return parseInt(override)
  }

  // Use value in preferences if it exists
  if (organisationPreferences?.inactivityTimeout) {
    return Math.max(organisationPreferences.inactivityTimeout, 60) // Sane limit of 1 min
  }

  if (
    // all pre-prod
    ENVIRONMENT_NAME !== 'master' ||
    // plus atomic prod envs, excluding demo
    organisationId === '50-2' ||
    organisationId === '50-3'
  ) {
    return DEFAULT_INACTIVITY_TIMEOUT
  }

  if (organisationId.startsWith('67')) {
    return 600 // 10 min timeout for BNZ
  }

  return undefined
}

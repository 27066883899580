import React, {useEffect, useState, useMemo, useCallback} from 'react'
import styled, {css} from 'styled-components'
import TextareaAutosize from 'react-textarea-autosize'

import {Icon, IconName} from '../icon/icon'

interface StyledFieldsProps {
  up?: boolean
}

interface StyledMultilineInputProps {
  appearance: 'primary' | 'transparent'
}

const inputClass = css`
  box-sizing: border-box;
  font-family:
    atomic___Inter,
    -apple-system,
    BlinkMacSystemFont,
    Roboto,
    Arial,
    san-serif;
  font-size: ${props => props.theme.fontSize};

  height: auto;
  width: 100%;
  display: flex;
  border-radius: 5px;
  transition: all 0.1s ease;
  cursor: text;
  color: ${props => props.theme.grey50};
  padding: 0px;
  &:focus-within,
  &:hover {
    background: ${props => props.theme.grey10};
  }
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active {
    transition: 'color 9999s ease-out, background-color 9999s ease-out';
    transition-delay: 9999s;
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }
`

const Primary = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 15px;
  justify-content: center;
  overflow: hidden;
`
const iconShared = css`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledIconLeft = styled.div`
  order: -1;
  ${iconShared}
`

const StyledIconRight = styled.div`
  order: 1;
  ${iconShared}
`

const TextareaAutosizeOnload = ({value, ...props}: {value: string}) => {
  const [, forceUpdate] = useState<null>()
  useEffect(() => {
    // forcing a re-render here, otherwise the textarea doesn't auto-resize ¯\_(ツ)_/¯
    forceUpdate(null)
  }, [])
  return <TextareaAutosize {...props} value={value} />
}

const StyledTextarea = styled(TextareaAutosizeOnload)<
  StyledFieldsProps & React.TextareaHTMLAttributes<HTMLTextAreaElement>
>`
  width: 100%;
  font-size: inherit;
  border: transparent;
  color: ${props => props.theme.black};
  padding: 0px;
  border-radius: none;
  outline: none;
  background-color: inherit;
  //transition: all 0.1s ease;
  line-height: ${props => props.theme.lineHeight};
  resize: none;
  padding: 20px 0 10px 0;
  :not(:placeholder-shown) {
    padding-top: 30px;
  }
  &:focus {
    padding-top: 30px;
    &::placeholder {
      padding-top: 0px;
    }
  }
  min-height: 60px;
  &::placeholder {
    transition: all 0.1s ease;
    white-space: nowrap;
    height: 20px;
  }

  ${props =>
    props.up &&
    css`
      &::placeholder {
        padding-top: 5px;
      }
      opacity: 1;
      padding-top: 20px;
    `}
`

const StyledTextAreaWrapper = styled.label<StyledMultilineInputProps>`
  ${inputClass}

  ${StyledIconLeft} ~ div {
    margin-left: 0;
  }
  ${StyledIconRight} ~ div {
    margin-right: 0;
  }

  ${props =>
    (props.appearance === 'primary' &&
      css`
        background: ${props.theme.grey05};
        &:focus-within,
        &:hover {
          background: ${p => p.theme.grey10};
        }
        &:active {
          background: ${p => p.theme.grey15};
        }
      `) ||
    (props.appearance === 'transparent' &&
      css`
        background: none;
        &:focus-within,
        &:hover {
          background: ${p => p.theme.grey05};
        }
        &:active {
          background: ${p => p.theme.grey10};
        }
      `)}

  ${Primary} {
    margin: 0 20px;
  }

  ${StyledIconLeft},
  ${StyledIconRight} {
    width: 60px;
    height: 60px;
  }
`

const StyledInlineTextArea = styled.label<StyledMultilineInputProps>`
  ${inputClass}
  ${StyledTextarea} {
    :not(:placeholder-shown) {
      padding-top: 10px;
    }
    min-height: 40px;
    &::placeholder {
      padding: 0;
    }
    padding-top: 10px;
    &::placeholder {
      padding-top: 0px;
    }
  }
  ${StyledIconLeft} ~ div {
    margin-left: 0;
  }
  ${StyledIconRight} ~ div {
    margin-right: 0;
  }
  ${props =>
    (props.appearance === 'primary' &&
      css`
        background: ${props.theme.grey05};
        &:focus-within,
        &:hover {
          background: ${p => p.theme.grey10};
        }
        &:active {
          background: ${p => p.theme.grey15};
        }
      `) ||
    (props.appearance === 'transparent' &&
      css`
        background: none;
        &:focus-within,
        &:hover {
          background: ${p => p.theme.grey05};
        }
        &:active {
          background: ${ppops => ppops.theme.grey10};
        }
      `)}

  ${StyledIconLeft},
  ${StyledIconRight} {
    width: 40px;
    height: 40px;
  }
`

const StyledLabel = styled.div<StyledFieldsProps>`
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-size: ${props => props.theme.fontSize};
  line-height: ${props => props.theme.lineHeight};
  top: 0;

  padding-top: 20px;
  display: flex;
  align-items: center;
  transition: all 0.1s ease;
  position: absolute;
  pointer-events: none;

  ${props =>
    props.up &&
    css`
      padding-top: 10px;
      font-size: ${p => p.theme.fontSizeS};
    `}
`

const StyledInlineLabel = styled.div`
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-size: ${props => props.theme.fontSize};
  line-height: ${props => props.theme.lineHeight};
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 0.1s ease;
  position: absolute;
  pointer-events: none;
`

interface BaseTextAreaProps {
  name?: string
  appearance?: 'primary' | 'transparent'
  type?: string
  value: string
  label?: string
  upLabel?: string
  iconLeft?: IconName
  iconRight?: IconName
  disabled?: boolean
  required?: boolean
  readOnly?: boolean
  up?: boolean
  onChange: (value: string, name: string) => void
  inline?: boolean
  style?: React.CSSProperties
  inputStyle?: React.CSSProperties
}

interface TextAreaWithPlaceholderProps extends BaseTextAreaProps {
  label: string
}

interface TextAreaWithLabelProps extends BaseTextAreaProps {
  upLabel: string
}

export type TextAreaProps =
  | TextAreaWithPlaceholderProps
  | TextAreaWithLabelProps

export const MultilineInput = (props: TextAreaProps) => {
  const [isFocused, setIsFocused] = useState(false)
  const id = useMemo(() => String(Math.random()), [])

  const {
    name,
    appearance = 'primary',
    label,
    value,
    upLabel,
    iconLeft = '',
    iconRight = '',
    inline = false,
    style,
    inputStyle,
    onChange
  } = props

  const getIconLeft = useCallback((icon: IconName) => {
    return (
      <StyledIconLeft>
        <Icon name={icon} />
      </StyledIconLeft>
    )
  }, [])

  const getIconRight = useCallback((icon: IconName) => {
    return (
      <StyledIconRight>
        <Icon name={icon} />
      </StyledIconRight>
    )
  }, [])

  const handleChange = useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement>
  >(
    e => {
      // TODO: not sure if this is right. In theory change should only trigger on blur (docs) but is happening on keypress
      e.preventDefault()

      const target = e.target
      onChange(target.value, target.name)
    },
    [onChange]
  )

  return inline ? (
    <StyledInlineTextArea appearance={appearance} style={style}>
      {iconLeft ? getIconLeft(iconLeft) : null}
      {iconRight ? getIconRight(iconRight) : null}
      <Primary>
        <StyledInlineLabel>
          {value === ''
            ? isFocused
              ? upLabel
                ? upLabel
                : label
              : label
            : ''}
        </StyledInlineLabel>
        <StyledTextarea
          name={name}
          id={id}
          value={value}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          autoFocus={true}
          spellCheck={false}
          onChange={handleChange}
          style={inputStyle}
        />
      </Primary>
    </StyledInlineTextArea>
  ) : (
    <StyledTextAreaWrapper appearance={appearance} style={style}>
      {iconLeft ? getIconLeft(iconLeft) : null}
      {iconRight ? getIconRight(iconRight) : null}
      <Primary>
        <StyledLabel up={!!isFocused || value !== ''}>
          {!!isFocused || value !== '' ? (upLabel ? upLabel : label) : label}
        </StyledLabel>
        {/* @ts-ignore Types are wrong in textarea autosize */}
        <StyledTextarea
          name={name}
          id={id}
          value={value}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          spellCheck={false}
          onChange={handleChange}
          style={inputStyle}
        />
      </Primary>
    </StyledTextAreaWrapper>
  )
}

import React, {useState} from 'react'
import {TextInput, InputProps} from './text'
import {IconButton} from '../buttons/icon-button'

type PasswordInputProps = Omit<InputProps, 'type' | 'buttonRight'>

export const PasswordInput = (props: PasswordInputProps) => {
  const [visible, setVisible] = useState<boolean>(false)

  const passwordProps = {
    ...props,
    type: visible ? 'text' : 'password',
    buttonRight: (
      <IconButton
        tabIndex={-1}
        appearance="transparent"
        icon={visible ? 'hide' : 'show'}
        onClick={e => {
          e.preventDefault()
          setVisible(!visible)
        }}
        size="large"
      />
    )
  } as InputProps

  return <TextInput {...passwordProps} />
}

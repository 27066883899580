import {Client, Event, NotifiableError} from '@bugsnag/browser'
import bugsnag from '@bugsnag/js'
import BugsnagPluginReact, {
  BugsnagErrorBoundary as BugsnagErrorBoundaryType
} from '@bugsnag/plugin-react'
import React, {ReactElement} from 'react'
import {APP_VERSION, ENVIRONMENT_NAME} from './env'

let bugsnagClient: Client | undefined = undefined

const init = () => {
  if (!bugsnagClient) {
    if (
      ENVIRONMENT_NAME === 'local' ||
      window.location?.host.includes('localhost') // e.g. when running the workbench against development APIs
    ) {
      console.warn(
        `Not initializing bugsnag for environment: "${ENVIRONMENT_NAME}"`
      )
    } else {
      bugsnagClient = bugsnag.start({
        apiKey: 'd0385d3c9614ab96f8aa05c2346ee084', // workbench-client bugsnag project
        appType: 'workbench-client',
        releaseStage: ENVIRONMENT_NAME,
        appVersion: APP_VERSION,
        plugins: [new BugsnagPluginReact(React)],
        redactedKeys: [/^username$/i, /^email$/i, /^password$/i]
      })
    }
  }
  return bugsnagClient
}

let ErrorBoundary: BugsnagErrorBoundaryType

const BugsnagErrorBoundary = ({children}: {children: ReactElement}) => {
  if (!ErrorBoundary && bugsnagClient) {
    const bugsnagReact = bugsnagClient.getPlugin('react')
    if (bugsnagReact) {
      ErrorBoundary = bugsnagReact.createErrorBoundary(React)
    }
  }
  if (ErrorBoundary) {
    return <ErrorBoundary>{children}</ErrorBoundary>
  }
  return children
}

const notify = (err: NotifiableError, metaData?: {}): Promise<Event | void> => {
  return new Promise((resolve, reject) => {
    if (bugsnagClient) {
      bugsnagClient.notify(
        err,
        event => {
          if (metaData) {
            event.addMetadata('metaData', metaData)
          }
        },
        (e, event) => (e ? reject(e) : resolve(event))
      )
    } else {
      console.warn('bugsnag not active, ignoring this error', err)
      resolve()
    }
  })
}

export {notify, init, BugsnagErrorBoundary}

import {css} from 'styled-components'
import {ThemeType} from '../../../../../components/library/theme'

export type Sizes = keyof ThemeType['lineHeights']

export const fontCSS = css<{size?: Sizes}>`
  font-size: ${({theme, size = 'small'}) => theme.fontSizes[size]};
  line-height: ${({theme, size = 'small'}) => theme.lineHeights[size]};
  letter-spacing: ${({theme, size = 'small'}) => theme.letterSpacing[size]};
`

export const fontLarge = css`
  font-size: ${({theme}) => theme.fontSizes.large};
  line-height: ${({theme}) => theme.lineHeights.large};
  letter-spacing: ${({theme}) => theme.letterSpacing.large};
`

export const fontMedium = css`
  font-size: ${({theme}) => theme.fontSizes.medium};
  line-height: ${({theme}) => theme.lineHeights.medium};
  letter-spacing: ${({theme}) => theme.letterSpacing.medium};
`

export const fontSmall = css`
  font-size: ${({theme}) => theme.fontSizes.small};
  line-height: ${({theme}) => theme.lineHeights.small};
  letter-spacing: ${({theme}) => theme.letterSpacing.small};
`

export const fontXSmall = css`
  font-size: ${({theme}) => theme.fontSizes.xsmall};
  line-height: ${({theme}) => theme.lineHeights.xsmall};
  letter-spacing: ${({theme}) => theme.letterSpacing.xsmall};
`

export const inputHoverColors = css`
  background-color: ${({theme}) => theme.colors.grey3};
  &:hover {
    background-color: ${({theme}) => theme.colors.grey4};
  }
  &:focus-within {
    /* &:has(:focus-visible) { might replace the above with this, but this doesn't have FF support yet https://caniuse.com/css-has  */
    background-color: ${({theme}) => theme.colors.grey4};
    outline: solid 2px ${({theme}) => theme.colors.blue12};
  }
  &:active {
    background-color: ${({theme}) => theme.colors.grey5};
  }
`

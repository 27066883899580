import {useCallback, useEffect, useRef, useState} from 'react'

/**
 * Focuses the first form input element after render. Returns a ref, this should
 * be passed to the parent form element
 */
export const useFocusFirstFormInput = <
  T extends HTMLElement = HTMLFormElement
>({
  wait = 0,
  key
}: {
  wait?: number
  key?: unknown
  // 👆 re-run the effect when this changes, shouldn't be required in most cases
} = {}) => {
  const formRef = useRef<T>(null)
  useEffect(() => {
    const firstEl:
      | HTMLInputElement
      | HTMLTextAreaElement
      | HTMLSelectElement
      | null = formRef.current
      ? formRef.current.querySelector(':read-write, select, input') // < https://developer.mozilla.org/en-US/docs/Web/CSS/:read-write
      : null
    if (firstEl) {
      // need to run asynchronously or floating labels get a bit wacky, similar
      // to the issues when browsers auto-fill
      const timer = setTimeout(() => {
        firstEl.focus({preventScroll: true}) // stops chrome scrolling to the element which is often janky
        if ('select' in firstEl) {
          firstEl.select()
        }
      }, wait)
      return () => clearTimeout(timer)
    }
    return
  }, [wait, key])

  return formRef
}

/**
 * changes a boolean flag for a period of time, then changes it back
 * e.g. for showing a temporary message to the user
 * handles unmounted components gracefully
 */
export const useFlash: (time?: number) => [boolean, () => void] = (
  time = 2500
) => {
  const [flash, setFlash] = useState(false)
  const triggerFlash = useCallback(() => {
    setFlash(true)
  }, [])

  // whenever flash is set to true, set it back after a delay
  useEffect(() => {
    let timeout: number

    if (flash) {
      timeout = window.setTimeout(() => setFlash(false), time)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [time, flash])

  return [flash, triggerFlash]
}

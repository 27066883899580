import * as Types from '../../data/workbench-api/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UseMakeMagicLinkFragment = { __typename?: 'MagicLink', id: string, environmentId: string };

export type UpsertMagicLinkMutationVariables = Types.Exact<{
  streamContainerId: Types.Scalars['String']['input'];
  endUserId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  environmentId: Types.Scalars['String']['input'];
}>;


export type UpsertMagicLinkMutation = { __typename?: 'Mutation', upsertMagicLink?: { __typename?: 'MagicLinkResult', magicLink?: { __typename?: 'MagicLink', id: string, environmentId: string } | null } | null };

export const UseMakeMagicLinkFragmentDoc = gql`
    fragment UseMakeMagicLink on MagicLink {
  id
  environmentId
}
    `;
export const UpsertMagicLinkDocument = gql`
    mutation UpsertMagicLink($streamContainerId: String!, $endUserId: String, $environmentId: String!) {
  upsertMagicLink(
    input: {streamContainerId: $streamContainerId, endUserId: $endUserId, environmentId: $environmentId}
  ) {
    magicLink {
      ...UseMakeMagicLink
    }
  }
}
    ${UseMakeMagicLinkFragmentDoc}`;

/**
 * __useUpsertMagicLinkMutation__
 *
 * To run a mutation, you first call `useUpsertMagicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMagicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMagicLinkMutation, { data, loading, error }] = useUpsertMagicLinkMutation({
 *   variables: {
 *      streamContainerId: // value for 'streamContainerId'
 *      endUserId: // value for 'endUserId'
 *      environmentId: // value for 'environmentId'
 *   },
 * });
 */
export function useUpsertMagicLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpsertMagicLinkMutation, UpsertMagicLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertMagicLinkMutation, UpsertMagicLinkMutationVariables>(UpsertMagicLinkDocument, options);
      }
export type UpsertMagicLinkMutationHookResult = ReturnType<typeof useUpsertMagicLinkMutation>;
export type UpsertMagicLinkMutationResult = Apollo.MutationResult<UpsertMagicLinkMutation>;
export type UpsertMagicLinkMutationOptions = Apollo.BaseMutationOptions<UpsertMagicLinkMutation, UpsertMagicLinkMutationVariables>;
import React, {MouseEvent, useCallback, useRef, useState} from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import IconCopy from '../../../assets/icons/copy.svg'
import {useFlash} from '../../../lib/hooks'
import {Button, ButtonProps} from '../buttons/button'
import {TextInput} from '../form'
import {Icon, IconProps} from '../icon/icon'
import styled from 'styled-components'

type Size = 'small' | 'large'

const InnerDiv = styled.div<{inputSize?: Size}>`
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: ${p => p.theme.borderRadius};
  position: relative;
  padding: 0 ${p => (p.inputSize === 'large' ? '10px' : '5px')} 0
    ${p => (p.inputSize === 'large' ? '20px' : '15px')};
  background: ${p => p.theme.white};
  input {
    padding: 0px;
  }
`

const InputStyled = styled.input<{inputSize?: Size}>`
  vertical-align: bottom;
  border-radius: ${p => p.theme.borderRadius};
  height: ${p => (p.inputSize === 'large' ? '60px' : '40px')};
  width: 100%;
  border: none;
  margin-right: ${p => (p.inputSize === 'large' ? '10px' : '5px')};
`

const CopyButtonWrap = styled.span`
  flex: none;
  margin-right: 10px; // offset the copy button from the right edge
`

const IconCopyWrap = styled.span`
  color: ${p => p.theme.blue};
  display: inline-flex;
  cursor: pointer;

  svg {
    margin-left: 5px;
  }
`

interface CopyInputProps {
  value: string | number
  copyText?: string
  copiedText?: string
  size?: Size
  iconLeft?: IconProps['name']
  className?: string
  onCopy?: () => void
}

export const CopyInput = ({
  size,
  value,
  copyText = 'Copy',
  copiedText = 'Copied',
  iconLeft,
  className,
  onCopy
}: CopyInputProps) => {
  const [copied, setCopied] = useState<string>(copyText)
  const textInputRef = useRef<HTMLInputElement>(null)

  const copy = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      // Copy the text inside the text field
      navigator.clipboard.writeText(String(value)).then(() => {
        ;(e.target as HTMLButtonElement).focus()
        setCopied(copiedText)
        setTimeout(() => setCopied(copyText), 2500)
      })
    },
    [copyText, copiedText, value]
  )

  return (
    <InnerDiv className={className} inputSize={size}>
      {iconLeft ? <Icon name={iconLeft} style={{marginRight: '10px'}} /> : null}
      <InputStyled
        inputSize={size}
        ref={textInputRef}
        value={value}
        readOnly={true}
      />

      {navigator.clipboard && (
        <CopyButtonWrap>
          <Button
            appearance="transparent"
            size={size === 'large' ? 'large' : 'small'}
            onClick={e => {
              copy(e)

              onCopy?.()
            }}
          >
            {copied}
          </Button>
        </CopyButtonWrap>
      )}
    </InnerDiv>
  )
}

interface CopyableProps {
  value: string | number
  copyText?: string
  copiedText?: string
  children?: (copying: boolean) => React.ReactNode
  buttonProps?: ButtonProps
  style?: React.CSSProperties
}
export const Copyable = ({
  value,
  copyText = 'Copy',
  copiedText = 'Copied',
  buttonProps = {},
  style = {},
  children = copying => (
    <span
      // ensure it doesn't jump around on click
      style={{
        display: 'inline-flex',
        ...style
      }}
    >
      <Button appearance="transparent" size="small" {...buttonProps}>
        {copying ? copiedText : copyText}
      </Button>
    </span>
  )
}: CopyableProps) => {
  const [copying, triggerCopying] = useFlash()

  return (
    <CopyToClipboard text={String(value)} onCopy={() => triggerCopying()}>
      {children(copying)}
    </CopyToClipboard>
  )
}

// TODO needs a copy icon and some love
export const CopyIcon = ({value}: CopyableProps) => {
  return (
    <Copyable value={value}>
      {copying => (
        <IconCopyWrap>
          {copying ? <Icon name="check_small" /> : <IconCopy />}
        </IconCopyWrap>
      )}
    </Copyable>
  )
}

const CopyTextInputWrapper = styled.div`
  svg {
    color: ${({theme}) => theme.blue};
  }
`

export const CopyTextInput = ({
  label,
  value
}: {
  label: string
  value: string | number
}) => {
  return (
    <CopyTextInputWrapper>
      <TextInput
        label={label}
        value={String(value)}
        onChange={() => undefined}
        readOnly={true}
        buttonRight={<CopyIcon value={value} />}
      />
    </CopyTextInputWrapper>
  )
}

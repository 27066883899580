import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import type {OrganisationNode} from './use-organisation'
import {getLocalEnvironmentId, setLocalEnvironmentId} from './lib/local-store'
import {useAnalytics} from './lib/analytics/analytics'
import {
  PermissionResource,
  PermissionAction
} from './data/workbench-api/generated/types'

export interface EnvironmentNode {
  id: string
  name: string

  // TODO consider removal, use current org's versions directly instead?
  organisationId: string
  organisationName: string

  hasPermission: (
    resource: PermissionResource,
    action: PermissionAction
  ) => boolean
}

interface EnvironmentContextType {
  organisation: OrganisationNode | undefined
  environment: EnvironmentNode | undefined
  environments: EnvironmentNode[] // TODO remove, this is contained in OrganisationNode
  setEnvironmentId(environmentId?: string): void
  switcherMode: 'environment' | 'organisation'
  setSwitcherMode: (mode: 'environment' | 'organisation') => void
}

export const EnvironmentContext = createContext<
  EnvironmentContextType | undefined
>(undefined)

export const useEnvironment = () => {
  const ctx = useContext(EnvironmentContext)
  if (!ctx) {
    throw new Error('Calling useEnvironment outside environment provider')
  }
  return ctx
}

interface EnvironmentProviderProps {
  organisation?: OrganisationNode
  render: (context: EnvironmentContextType) => React.ReactNode
}

export const EnvironmentProvider = ({
  organisation,
  render
}: EnvironmentProviderProps) => {
  const [environmentId, setEnvironmentIdState] = useState<string | undefined>()
  const [switcherMode, setSwitcherMode] = useState<
    'environment' | 'organisation'
  >('organisation')

  const analytics = useAnalytics()

  useEffect(() => {
    setEnvironmentIdState(
      organisation?.id ? getLocalEnvironmentId(organisation?.id) : undefined
    )
  }, [organisation?.id])

  const setEnvironmentId = useCallback(
    (id?: string) => {
      setEnvironmentIdState(id)

      // analytics
      const previousWorkingEnv =
        organisation?.id && getLocalEnvironmentId(organisation.id)
      if (previousWorkingEnv && previousWorkingEnv !== id) {
        analytics.track({
          event: 'Switched environment',
          properties: {
            org_id: organisation.id,
            environment_id: id,
            category: 'general'
          }
        })
      }

      // save last rendered environment in localStorage
      if (organisation?.id) setLocalEnvironmentId(organisation.id, id || '')
    },
    [organisation?.id, analytics]
  )

  const value = useMemo(() => {
    const environments = organisation?.environments ?? []

    return {
      organisation: organisation,
      environments,

      // It's useful to have a sensible default selected when we don't have an id available.
      environment: environmentId
        ? environments.find(en => en.id === environmentId) ?? environments[0]
        : environments[0],
      setEnvironmentId,
      switcherMode,
      setSwitcherMode
    }
  }, [switcherMode, environmentId, organisation, setEnvironmentId])
  return (
    <EnvironmentContext.Provider value={value}>
      {render(value)}
    </EnvironmentContext.Provider>
  )
}

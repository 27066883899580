import {isRight} from 'fp-ts/lib/Either'
import {useMemo} from 'react'
import {EnvironmentPreferencesCodec} from '../common/types'
import {coerceToBoolValue} from '../common/types/helpers'
import {useGetEnvironmentQuery} from '../data/workbench-api/generated/environment'

export const useEnvPreferences = (environmentId: string) => {
  const {data, loading} = useGetEnvironmentQuery({
    variables: {id: environmentId}
  })

  const preferences = useMemo(() => {
    if (!data || loading) return undefined
    // Coerce legacy 'true' / 'false' values to booleans
    const preferencesResult = EnvironmentPreferencesCodec.decode(
      coerceToBoolValue(
        data?.environment?.preferences as Record<string, unknown>
      )
    )
    return isRight(preferencesResult) ? preferencesResult.right : undefined
  }, [data, loading])

  return {loading, preferences}
}

import React from 'react'
import {useNavigate} from 'react-router-dom'
import {SignInForm} from '../../components/auth'
import {AuthComplete} from '../../components/auth/types'
import {
  clearLastPathBeforeInactivity,
  getLastPathBeforeInactivity
} from '../../components/session-timeout'
import {useAnalytics} from '../../lib/analytics/analytics'
import {Authenticator} from '../../lib/cognito'
import {toRelativeURL} from '../../lib/helpers'
import {AuthLayout} from './layout'

interface SignInProps {
  auth: Authenticator
}

export const SignIn = ({auth}: SignInProps) => {
  const navigate = useNavigate()
  const analytics = useAnalytics()

  const onComplete: AuthComplete = (state, credentials) => {
    clearLastPathBeforeInactivity()
    if (state === 'confirm') {
      navigate(`/signup/confirm?username=${credentials.username}`)
    }
    analytics.trackNoOrg({
      event: 'Logged into workbench',
      properties: {
        email: credentials.username,
        category: 'general'
      }
    })
  }

  const relativeUrl = toRelativeURL(window.location.href)
  // ONLY redirect to the last visited path (the one before the sign out due to inactivity) if it is the root path ('/' or '').
  // This is to avoid redirecting to a path that user didn't entered if the user is already on a different path.
  const redirectToPath = ['', '/'].includes(relativeUrl)
    ? getLastPathBeforeInactivity()
    : relativeUrl

  return (
    <AuthLayout>
      <SignInForm
        auth={auth}
        onComplete={onComplete}
        // Using un/pw: we won't leave the URL anyway. Redirect will be a no-op.
        // Using SSO: we will visit cognito and on return we want to go back to our url.
        redirectToPath={redirectToPath}
      />
    </AuthLayout>
  )
}

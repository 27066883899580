import loadable from '@loadable/component'
import React, {useCallback, useEffect, useState} from 'react'
import {Routes, useNavigate} from 'react-router-dom'
import {KBarProviderWithFrecency} from './cmdk/command-palette'
import {NotFound} from './components/errors'
import {Header} from './components/header'
import {Layout} from './components/layout'
import {GlobalStyle} from './components/library/theme'
import {Loader} from './components/loader'
import {resetActivity, SessionTimeout} from './components/session-timeout'
import {Sidebar} from './components/sidebar'
import {EnvironmentProvider} from './environment-provider'
import {useAnalytics} from './lib/analytics/analytics'
import {AtomicSingleCard, AtomicStream} from './lib/atomic'
import {useAuth} from './lib/auth'
import {ErrorBoundary} from './lib/error-boundary'
import {useOrganisations} from './lib/organisations'
import {PrivateRoute, Route} from './lib/router'
import {ToastProvider} from './lib/toast'
import {OrganisationData} from './use-organisation'
import {OrganisationRedirect} from './organisation-redirect'
import {CrumbProvider} from './providers/crumbs'
import {AtomicSDKProvider} from './providers/atomic-sdk'
import {atomicConfig} from './hooks/atomic-sdk'
import {FrecencyProvider} from './providers/frecency'
import {MfaSetup} from './views/profile/mfa'
import {getInactivityTimeout} from './get-inactivity-timeout'

const DemoApp = loadable(
  async () =>
    (await import(/* webpackPrefetch: true */ './demo-login/app')).App,
  {
    fallback: <Loader />
  }
)

const ConfirmAccount = loadable(
  async () =>
    (await import(/* webpackPrefetch: true */ './views/auth/confirm'))
      .ConfirmAccount,
  {
    fallback: <Loader />
  }
)

const HandleAuthCode = loadable(
  async () =>
    (await import(/* webpackPrefetch: true */ './views/auth/code'))
      .HandleAuthCode,
  {
    fallback: <Loader />
  }
)

const HandleSSOLogin = loadable(
  async () =>
    (await import(/* webpackPrefetch: true */ './views/auth/sso'))
      .HandleSSOLogin,
  {
    fallback: <Loader />
  }
)

const AcceptInvitation = loadable(
  async () =>
    (await import(/* webpackPrefetch: true */ './views/auth')).AcceptInvitation,
  {
    fallback: <Loader />
  }
)

const OrganisationProviderRoute = loadable(
  async () =>
    (await import(/* webpackPrefetch: true */ './organisation-provider-route'))
      .OrganisationProviderRoute,
  {
    fallback: <Loader />
  }
)

export const App = () => {
  const {auth, authState} = useAuth()
  const analytics = useAnalytics()
  const navigate = useNavigate()
  const [organisations] = useOrganisations()
  const [signingOut, setSigningOut] = useState(false)
  const [organisationData, setOrganisationData] = useState<
    OrganisationData | undefined
  >()
  const {currentBusinessUser} = organisationData?.organisation ?? {}

  const signOut = useCallback(async () => {
    setSigningOut(true)
    await analytics.track(
      {
        event: 'Logged out of workbench',
        properties: {
          category: 'general',
          //@ts-ignore in reality this will be set, and if not sending undefined is ok
          org_id: organisationData?.organisationId
        }
      },
      true
    )
    auth.signOut()
  }, [auth, analytics, organisationData?.organisationId])

  const showHeader = true

  useEffect(() => {
    // ensure we reset activity whenever viewing this screen in a logged out
    // state, so that when the user does log in they don't get logged out
    // immediately
    auth.on('stateChange', ({authenticated}) => {
      if (!authenticated) {
        resetActivity()
      }
    })
  }, [auth])

  const inactivityTimeout = organisationData
    ? getInactivityTimeout(
        organisationData.organisationId,
        organisationData.organisation?.preferences
      )
    : undefined

  return signingOut ? (
    <Loader />
  ) : (
    <FrecencyProvider>
      <KBarProviderWithFrecency>
        <CrumbProvider>
          <AtomicSDKProvider>
            <GlobalStyle />
            <EnvironmentProvider
              organisation={organisationData?.organisation}
              render={({environment, switcherMode}) => (
                <Layout
                  authenticated={authState.authenticated}
                  singleCard={
                    <AtomicSingleCard
                      organisation={organisationData?.organisation}
                      environment={environment}
                      currentBusinessUser={currentBusinessUser}
                      streamContainerId={
                        atomicConfig.ATOMIC_HIGH_PRIORITY_CONTAINER_ID
                      }
                    />
                  }
                  stream={
                    <>
                      <AtomicStream
                        organisation={organisationData?.organisation}
                        environment={environment}
                        currentBusinessUser={currentBusinessUser}
                        streamContainerId={atomicConfig.ATOMIC_CONTAINER_ID}
                      />
                    </>
                  }
                  sidebar={
                    authState.authenticated ? (
                      <Sidebar
                        environment={environment}
                        authState={authState}
                        signOut={signOut}
                        organisation={organisationData?.organisation}
                        testUserId={currentBusinessUser?.linkedTestUserId}
                      />
                    ) : undefined
                  }
                  header={
                    authState.authenticated && showHeader ? (
                      <Header
                        environment={environment}
                        authState={authState}
                        organisations={organisations}
                        switcherMode={switcherMode}
                      />
                    ) : null
                  }
                >
                  <ErrorBoundary>
                    <ToastProvider>
                      {!authState.loaded ? (
                        <Loader />
                      ) : (
                        <Routes>
                          <PrivateRoute
                            path="/"
                            render={() => <OrganisationRedirect />}
                          />
                          <PrivateRoute
                            path="/cards"
                            render={() => (
                              <OrganisationRedirect subPath={'cards'} />
                            )}
                          />
                          <PrivateRoute
                            path="/action-flows"
                            render={() => (
                              <OrganisationRedirect subPath={'action-flows'} />
                            )}
                          />
                          <PrivateRoute
                            path="/:capturedOrganisationId/*"
                            render={({capturedOrganisationId}) => (
                              <OrganisationProviderRoute
                                id={capturedOrganisationId}
                                organisations={organisations}
                                setOrganisationData={setOrganisationData}
                                authState={authState}
                                signOut={signOut}
                              />
                            )}
                          />
                          <Route
                            path="/demo-login/:appType"
                            render={({appType}) => {
                              if (appType === 'shell' || appType === 'demo') {
                                return <DemoApp appType={appType} />
                              }

                              return <NotFound />
                            }}
                          />
                          <Route
                            path="/signup/confirm"
                            render={() => <ConfirmAccount />}
                          />
                          <Route
                            path="/auth"
                            render={() => <HandleAuthCode />}
                          />
                          <Route
                            path="/login/:clientId"
                            render={({clientId}) => (
                              <HandleSSOLogin clientId={clientId} />
                            )}
                          />
                          <Route
                            path="/signup/:secretValue/*"
                            render={({secretValue}) => (
                              <AcceptInvitation secretValue={secretValue} />
                            )}
                          />
                          {authState.authenticated && (
                            <Route
                              path="/mfa-setup/*"
                              render={() => (
                                <MfaSetup
                                  authState={authState}
                                  auth={auth}
                                  checkPassword={false}
                                  onComplete={async redirect => {
                                    await auth.refreshAuthState()
                                    navigate(redirect ?? '/')
                                    location.reload()
                                  }}
                                  cancelLink={
                                    new URLSearchParams(
                                      document.location.search
                                    ).get('cancel') ?? '/'
                                  }
                                />
                              )}
                            />
                          )}
                          <Route path="*" render={() => <NotFound />} />
                        </Routes>
                      )}
                    </ToastProvider>
                  </ErrorBoundary>
                </Layout>
              )}
            />
            {auth.getAuthState().authenticated && inactivityTimeout ? (
              <SessionTimeout signOut={signOut} timeout={inactivityTimeout} />
            ) : null}
          </AtomicSDKProvider>
        </CrumbProvider>
      </KBarProviderWithFrecency>
    </FrecencyProvider>
  )
}

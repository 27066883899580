import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import LoaderImage from '../assets/loader.gif'

export type Variant = 'overlay' | 'inline'

const OverlayLoader = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
`

const InlineLoader = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
`

const LoaderElement = styled.img<{opacity?: number; size?: number}>`
  opacity: ${p => (p.opacity ? p.opacity : '0.2')};
  height: ${p => (p.size ? p.size : '40')}px;
  mix-blend-mode: multiply;
`

interface LoaderProps {
  delay?: number
  variant?: Variant
  style?: React.CSSProperties
  opacity?: number
  size?: number
}

export const Loader = ({
  delay,
  style,
  variant = 'inline',
  opacity,
  size
}: LoaderProps) => {
  // Show only after a brief delay, to avoid loader flash
  const [visible, setVisible] = useState<boolean>(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true)
    }, delay ?? 100)

    return () => clearTimeout(timer)
  }, [delay])

  if (visible) {
    if (variant === 'overlay') {
      return (
        <OverlayLoader data-testid={'loader'} style={style}>
          <LoaderElement src={LoaderImage} opacity={opacity} size={size} />
        </OverlayLoader>
      )
    }

    return (
      <InlineLoader data-testid={'loader'} style={style}>
        <LoaderElement src={LoaderImage} opacity={opacity} size={size} />
      </InlineLoader>
    )
  }
  return null
}

export const blob = {
  action: {
    path: [
      'M10.7929 0.792893C11.1834 0.402369 11.8166 0.402369 12.2071 0.792893L15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L12.2071 9.20711C11.8166 9.59763 11.1834 9.59763 10.7929 9.20711C10.4024 8.81658 10.4024 8.18342 10.7929 7.79289L12.5858 6H7.87398C7.42994 7.72523 5.86384 9 4 9C1.79086 9 0 7.20914 0 5C0 2.79086 1.79086 1 4 1C5.86384 1 7.42994 2.27477 7.87398 4H12.5858L10.7929 2.20711C10.4024 1.81658 10.4024 1.18342 10.7929 0.792893ZM4 3C2.89543 3 2 3.89543 2 5C2 6.10457 2.89543 7 4 7C5.10457 7 6 6.10457 6 5C6 3.89543 5.10457 3 4 3Z'
    ],
    viewbox: '0 0 16 10',
    width: '16',
    colors: [],
    fillRule: '',
    height: '10'
  },
  add: {
    path: [
      'M6 0C6.55228 0 7 0.447715 7 1V5H11C11.5523 5 12 5.44772 12 6C12 6.55228 11.5523 7 11 7H7V11C7 11.5523 6.55228 12 6 12C5.44772 12 5 11.5523 5 11V7H1C0.447715 7 0 6.55228 0 6C0 5.44772 0.447715 5 1 5H5V1C5 0.447715 5.44772 0 6 0Z'
    ],
    viewbox: '0 0 12 12',
    width: '12',
    colors: [],
    fillRule: '',
    height: '12'
  },
  android: {
    path: [
      'M0.943412 4.53474C0.702192 4.53474 0.497526 4.61886 0.329287 4.7871C0.161049 4.95534 0.0769043 5.15722 0.0769043 5.39285V9.01062C0.0769043 9.25214 0.161024 9.45663 0.329287 9.62489C0.497526 9.79313 0.702192 9.87728 0.943412 9.87728C1.18445 9.87728 1.38788 9.79316 1.55351 9.62489C1.71881 9.45665 1.80169 9.25214 1.80169 9.01062V5.39285C1.80169 5.1572 1.71757 4.95534 1.54931 4.7871C1.38104 4.61886 1.17904 4.53474 0.943412 4.53474Z',
      'M7.90985 1.28715L8.50721 0.185168C8.54643 0.112212 8.53248 0.056268 8.46524 0.0169296C8.39226 -0.0169915 8.33619 0.000196896 8.297 0.0672797L7.69129 1.17807C7.15829 0.942419 6.59457 0.824379 6.00012 0.824379C5.40553 0.824379 4.84176 0.942444 4.30897 1.17807L3.70323 0.0672797C3.66386 0.000196896 3.60779 -0.0168396 3.53499 0.0169296C3.4676 0.0564452 3.45365 0.112212 3.49302 0.185168L4.09041 1.28715C3.48466 1.59606 3.00222 2.02627 2.64327 2.57873C2.28431 3.13149 2.10476 3.73554 2.10476 4.39194H9.88702C9.88702 3.7357 9.70744 3.13162 9.34851 2.57873C8.98953 2.02627 8.50987 1.59606 7.90985 1.28715ZM4.45615 2.89C4.39155 2.95475 4.31439 2.98698 4.2247 2.98698C4.13483 2.98698 4.05925 2.95475 3.99758 2.89C3.93591 2.82555 3.90508 2.74869 3.90508 2.6587C3.90508 2.56901 3.93591 2.49201 3.99758 2.4274C4.05925 2.36295 4.13501 2.33073 4.2247 2.33073C4.31439 2.33073 4.39155 2.36295 4.45615 2.4274C4.5206 2.49216 4.55298 2.56901 4.55298 2.6587C4.5528 2.74854 4.52045 2.82555 4.45615 2.89ZM8.00232 2.89C7.94053 2.95475 7.86476 2.98698 7.77523 2.98698C7.68534 2.98698 7.6082 2.95475 7.54373 2.89C7.47915 2.82555 7.44693 2.74869 7.44693 2.6587C7.44693 2.56901 7.47915 2.49201 7.54373 2.4274C7.6082 2.36295 7.68534 2.33073 7.77523 2.33073C7.86491 2.33073 7.9405 2.36295 8.00232 2.4274C8.06404 2.49216 8.09482 2.56901 8.09482 2.6587C8.09482 2.74854 8.06401 2.82555 8.00232 2.89Z',
      'M2.13817 10.298C2.13817 10.5562 2.22786 10.7748 2.40726 10.9543C2.58681 11.1337 2.80543 11.2233 3.06351 11.2233H3.68614L3.69467 13.1334C3.69467 13.3746 3.77879 13.5794 3.94703 13.7477C4.11526 13.9159 4.3173 14.0001 4.55277 14.0001C4.79384 14.0001 4.99863 13.9159 5.16689 13.7477C5.33516 13.5794 5.41928 13.3746 5.41928 13.1334V11.2235H6.58029V13.1334C6.58029 13.3746 6.66438 13.5794 6.83265 13.7477C7.00091 13.9159 7.20553 14.0001 7.44677 14.0001C7.68784 14.0001 7.89263 13.9159 8.06089 13.7477C8.22916 13.5794 8.31325 13.3746 8.31325 13.1334V11.2235H8.94429C9.19664 11.2235 9.41245 11.1338 9.59218 10.9544C9.77156 10.775 9.8613 10.5564 9.8613 10.2982V4.69457H2.13817V10.298Z',
      'M11.0565 4.53474C10.8209 4.53474 10.619 4.61762 10.4507 4.78292C10.2825 4.94853 10.1984 5.15196 10.1984 5.39285V9.01062C10.1984 9.25214 10.2825 9.45663 10.4507 9.62489C10.619 9.79316 10.821 9.87728 11.0565 9.87728C11.2976 9.87728 11.5024 9.79316 11.6706 9.62489C11.8389 9.45663 11.923 9.25214 11.923 9.01062V5.39285C11.923 5.15193 11.8389 4.94853 11.6706 4.78292C11.5024 4.61762 11.2976 4.53474 11.0565 4.53474Z'
    ],
    viewbox: '0 0 12 14',
    width: '12',
    colors: [],
    fillRule: '',
    height: '14'
  },
  apple: {
    path: [
      'M9.52109 7.43875C9.50343 5.66576 10.967 4.81545 11.0324 4.7732C10.21 3.56981 8.92899 3.40495 8.47249 3.38589C7.38227 3.2755 6.3452 4.02776 5.7919 4.02776C5.23999 4.02776 4.38619 3.40222 3.48201 3.41865C2.29371 3.43631 1.19802 4.1096 0.586148 5.17384C-0.648476 7.31622 0.269972 10.49 1.47331 12.2275C2.06131 13.078 2.76246 14.0332 3.68304 13.9991C4.56955 13.9637 4.90479 13.4255 5.97658 13.4255C7.04834 13.4255 7.34965 13.9991 8.28778 13.9815C9.24175 13.9638 9.84613 13.1147 10.4301 12.2616C11.1053 11.275 11.3833 10.3197 11.3997 10.2707C11.3786 10.261 9.53952 9.55649 9.52109 7.43875ZM7.7583 2.23565C8.2469 1.64287 8.5767 0.820448 8.48673 0C7.78293 0.0286562 6.92984 0.468808 6.42488 1.06025C5.9718 1.58492 5.57517 2.42233 5.68146 3.22637C6.46715 3.28767 7.26917 2.8271 7.7583 2.23565Z'
    ],
    viewbox: '0 0 12 14',
    width: '12',
    colors: [],
    fillRule: '',
    height: '14'
  },
  arrow_down: {
    path: [
      'M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L6.70711 6.70711C6.31658 7.09763 5.68342 7.09763 5.29289 6.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z'
    ],
    viewbox: '0 0 12 7',
    width: '12',
    colors: [],
    fillRule: '',
    height: '7'
  },
  arrow_right: {
    path: [
      'M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z'
    ],
    viewbox: '0 0 7 12',
    width: '7',
    colors: [],
    fillRule: '',
    height: '12'
  },
  arrow_up: {
    path: [
      'M5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893L11.7071 5.29289C12.0976 5.68342 12.0976 6.31658 11.7071 6.70711C11.3166 7.09763 10.6834 7.09763 10.2929 6.70711L6 2.41421L1.70711 6.70711C1.31658 7.09763 0.683417 7.09763 0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893Z'
    ],
    viewbox: '0 0 12 7',
    width: '12',
    colors: [],
    fillRule: '',
    height: '7'
  },
  attachment: {
    path: [
      'M4 2C2.89543 2 2 2.89543 2 4V12.5C2 14.433 3.567 16 5.5 16C7.433 16 9 14.433 9 12.5V6C9 5.44772 9.44771 5 10 5C10.5523 5 11 5.44772 11 6V12.5C11 15.5376 8.53757 18 5.5 18C2.46243 18 0 15.5376 0 12.5V4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4V12.5C8 13.8807 6.88071 15 5.5 15C4.11929 15 3 13.8807 3 12.5V6C3 5.44772 3.44772 5 4 5C4.55228 5 5 5.44772 5 6V12.5C5 12.7761 5.22386 13 5.5 13C5.77614 13 6 12.7761 6 12.5V4C6 2.89543 5.10457 2 4 2Z'
    ],
    viewbox: '0 0 11 18',
    width: '11',
    colors: [],
    fillRule: '',
    height: '18'
  },
  atomic: {
    path: [
      'm3.8155 16.0439 3.14127-.4826c.7262-.1073.96004-.482.96004-.9372 0-.9378-.70087-1.7148-2.15458-1.7148-1.50568 0-2.33646.9915-2.43974 2.1432l-3.063316-.6697c.207858-2.0627 2.050656-4.3399 5.477726-4.3399 4.04934 0 5.5544 2.3576 5.5544 5.0096v6.483c0 .6965.0779 1.6343.1559 2.0895h-3.16663c-.07795-.3486-.12992-1.0719-.12992-1.5807-.6489 1.0451-1.86942 1.9554-3.76418 1.9554-2.72555 0-4.38647-1.9018-4.38647-3.9645 0-2.3576 1.6869-3.6702 3.8155-3.9913zm4.15404 2.2306v-.6057l-2.80504.468c-.85954.1377-1.54198.6601-1.54198 1.705 0 .7978.53065 1.568 1.61788 1.568 1.41485 0 2.72914-.7427 2.72914-3.1353z',
      'm18.0209 10.4866h2.555v3.1274h-2.555v5.46c0 1.1401.516 1.5109 1.4971 1.5109.4127 0 .8771-.0531 1.0579-.1061v2.9157c-.31.1327-.9294.3177-1.9356.3177-2.4782 0-4.0264-1.5102-4.0264-4.028v-6.0702h-2.2973v-3.1274h.6452c1.3421 0 1.9615-.90134 1.9615-2.06737v-1.8293h3.0976z',
      'm34.8136 17.0216c0 4.0378-2.8991 6.9784-6.7379 6.9784s-6.7379-2.9406-6.7379-6.9784c0-4.0639 2.8991-6.9784 6.7379-6.9784s6.7379 2.9145 6.7379 6.9784zm-3.4776-.0723c0-2.4849-1.5646-3.7407-3.2603-3.7407-1.6951 0-3.2603 1.2558-3.2603 3.7407 0 2.4588 1.5652 3.7414 3.2603 3.7414 1.6957 0 3.2603-1.2559 3.2603-3.7414z',
      'm59.9355 4c1.179 0 2.101.97358 2.101 2.18495 0 1.15802-.922 2.1316-2.101 2.1316-1.1535 0-2.1011-.97358-2.1011-2.1316 0-1.21137.9476-2.18495 2.1011-2.18495zm-1.6664 6.4748h3.3327v13.0935h-3.3327z',
      'm66.7507 17.0216c0 2.3799 1.4954 3.7167 3.2482 3.7167s2.6295-1.203 2.9134-2.2193l3.0159 1.0431c-.567 2.1918-2.5521 4.4379-5.9293 4.4379-3.7385 0-6.677-2.9406-6.677-6.9784 0-4.0639 2.8869-6.9784 6.5738-6.9784 3.4546 0 5.4139 2.2193 5.9551 4.4386l-3.0675 1.0692c-.3097-1.096-1.109-2.2187-2.8102-2.2187-1.7534 0-3.2224 1.3101-3.2224 3.6893z',
      'm36.4257 23.6371v-13.1506h3.333v1.6032c.7085-1.2828 2.3612-1.9778 3.7782-1.9778 1.7578 0 3.1755.7752 3.8308 2.1919 1.0236-1.6033 2.3881-2.1919 4.094-2.1919 2.3875 0 4.6706 1.4702 4.6706 4.9985v8.5267h-3.3849v-7.805c0-1.4167-.6823-2.4855-2.2831-2.4855-1.4958 0-2.3881 1.1758-2.3881 2.5925v7.698h-3.4631v-7.805c0-1.4167-.7085-2.4855-2.2837-2.4855-1.5214 0-2.4137 1.1491-2.4137 2.5925v7.698z'
    ],
    viewbox: '0 0 76 30',
    width: '76',
    height: '30',
    fillRule: 'evenodd',
    colors: ['#00143c', '#00143c', '#00143c', '#00143c', '#00143c', '#00143c']
  },
  audio: {
    path: [
      'M13.2472 0.211804C13.49 0.401311 13.6318 0.692074 13.6318 1.00001V11C13.6318 11.0661 13.6254 11.1307 13.6132 11.1932C13.6255 11.2937 13.6318 11.3961 13.6318 11.5C13.6318 12.8807 12.5125 14 11.1318 14C9.75112 14 8.63184 12.8807 8.63184 11.5C8.63184 10.1193 9.75112 9.00001 11.1318 9.00001C11.3031 9.00001 11.4703 9.01722 11.6318 9.05002V5.28079L5.63184 6.78079V13C5.63184 13.0661 5.62543 13.1307 5.61319 13.1932C5.6255 13.2937 5.63184 13.3961 5.63184 13.5C5.63184 14.8807 4.51255 16 3.13184 16C1.75112 16 0.631836 14.8807 0.631836 13.5C0.631836 12.1193 1.75112 11 3.13184 11C3.30307 11 3.47028 11.0172 3.63184 11.05V3.00001C3.63184 2.54114 3.94413 2.14116 4.3893 2.02987L12.3893 0.0298666C12.688 -0.0448188 13.0045 0.0222965 13.2472 0.211804ZM5.63184 4.71923L11.6318 3.21923V2.28079L5.63184 3.78079V4.71923ZM11.1318 11C10.8557 11 10.6318 11.2239 10.6318 11.5C10.6318 11.7762 10.8557 12 11.1318 12C11.408 12 11.6318 11.7762 11.6318 11.5C11.6318 11.2239 11.408 11 11.1318 11ZM3.13184 13C2.85569 13 2.63184 13.2239 2.63184 13.5C2.63184 13.7762 2.85569 14 3.13184 14C3.40798 14 3.63184 13.7762 3.63184 13.5C3.63184 13.2239 3.40798 13 3.13184 13Z'
    ],
    viewbox: '0 0 14 16',
    width: '14',
    colors: [],
    fillRule: '',
    height: '16'
  },
  back_large: {
    path: [
      'M6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976315 6.31658 -0.0976315 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976315 6.31658 -0.0976315 6.70711 0.292893C7.09763 0.683417 7.09763 1.31658 6.70711 1.70711L3.41421 5L13 5C13.5523 5 14 5.44772 14 6C14 6.55228 13.5523 7 13 7L3.41421 7L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071Z'
    ],
    viewbox: '0 0 14 12',
    width: '14',
    colors: [],
    fillRule: '',
    height: '12'
  },
  back_small: {
    path: [
      'M4.85355 0.646447C5.04882 0.841709 5.04882 1.15829 4.85355 1.35355L2.20711 4H9C9.27614 4 9.5 4.22386 9.5 4.5C9.5 4.77614 9.27614 5 9 5H2.20711L4.85355 7.64645C5.04882 7.84171 5.04882 8.15829 4.85355 8.35355C4.65829 8.54882 4.34171 8.54882 4.14645 8.35355L0.646447 4.85355C0.451184 4.65829 0.451184 4.34171 0.646447 4.14645L4.14645 0.646447C4.34171 0.451184 4.65829 0.451184 4.85355 0.646447Z'
    ],
    viewbox: '0 0 10 9',
    width: '10',
    colors: [],
    fillRule: '',
    height: '9'
  },
  build: {
    path: [
      'M6 0C6.48884 0 6.90603 0.353413 6.98639 0.835601L7.68046 5H19C19.5523 5 20 5.44772 20 6C20 6.55228 19.5523 7 19 7H17V10H19C19.5523 10 20 10.4477 20 11V15C20 15.5523 19.5523 16 19 16H13C12.4477 16 12 15.5523 12 15V11C12 10.4477 12.4477 10 13 10H15V7H8.01379L9.98639 18.8356C10.0347 19.1256 9.95302 19.4221 9.76302 19.6464C9.57302 19.8707 9.29395 20 9 20H3C2.70605 20 2.42698 19.8707 2.23698 19.6464C2.04698 19.4221 1.96528 19.1256 2.01361 18.8356L3.98621 7H1C0.447715 7 0 6.55228 0 6C0 5.44772 0.447715 5 1 5H4.31954L5.01361 0.835601C5.09397 0.353413 5.51116 0 6 0ZM6 7.08276L4.18046 18H7.81954L6 7.08276ZM14 12V14H18V12H14Z'
    ],
    viewbox: '0 0 20 20',
    width: '20',
    colors: [],
    fillRule: '',
    height: '20'
  },
  check: {
    path: [
      'M13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711L0.292893 5.70711C-0.0976311 5.31658 -0.0976311 4.68342 0.292893 4.29289C0.683417 3.90237 1.31658 3.90237 1.70711 4.29289L5 7.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893Z'
    ],
    viewbox: '0 0 14 10',
    width: '14',
    colors: [],
    fillRule: '',
    height: '10'
  },
  check_small: {
    path: [
      'M10.707.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L4 5.586 9.293.293a1 1 0 011.414 0z'
    ],
    viewbox: '0 0 11 8',
    width: '11',
    colors: [],
    fillRule: '',
    height: '8'
  },
  checkbox: {
    path: [
      'M0 2C0 0.895432 0.89543 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895432 16 0 15.1046 0 14V2ZM14 2L2 2V14H14V2ZM11.7071 5.29289C12.0976 5.68342 12.0976 6.31658 11.7071 6.70711L7.70711 10.7071C7.31658 11.0976 6.68342 11.0976 6.29289 10.7071L4.29289 8.70711C3.90237 8.31658 3.90237 7.68342 4.29289 7.29289C4.68342 6.90237 5.31658 6.90237 5.70711 7.29289L7 8.58579L10.2929 5.29289C10.6834 4.90237 11.3166 4.90237 11.7071 5.29289Z'
    ],
    viewbox: '0 0 16 16',
    width: '16',
    colors: [],
    fillRule: '',
    height: '16'
  },
  chevron: {
    path: [
      'M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z'
    ],
    viewbox: '0 0 8 12',
    width: '8',
    colors: [],
    fillRule: '',
    height: '12'
  },
  down: {
    path: [
      'M12.207.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5A1 1 0 012.207.793L6.5 5.086 10.793.793a1 1 0 011.414 0z'
    ],
    viewbox: '0 0 13 8',
    width: '13',
    colors: [],
    fillRule: '',
    height: '8'
  },
  clear: {
    path: [
      'M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L5 3.58579L8.29289 0.292894C8.68342 -0.0976307 9.31658 -0.0976309 9.70711 0.292893C10.0976 0.683418 10.0976 1.31658 9.70711 1.70711L6.41421 5L9.70711 8.29289C10.0976 8.68342 10.0976 9.31658 9.70711 9.70711C9.31658 10.0976 8.68342 10.0976 8.29289 9.70711L5 6.41421L1.70711 9.70711C1.31658 10.0976 0.683418 10.0976 0.292893 9.70711C-0.0976311 9.31658 -0.0976311 8.68342 0.292893 8.29289L3.58579 5L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z'
    ],
    viewbox: '0 0 10 10',
    width: '10',
    colors: [],
    fillRule: '',
    height: '10'
  },
  close: {
    path: [
      'M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z'
    ],
    viewbox: '0 0 12 12',
    width: '12',
    colors: [],
    fillRule: '',
    height: '12'
  },
  condition: {
    path: [
      'M13.7929 0.792893C14.1834 0.402369 14.8166 0.402369 15.2071 0.792893L17.7071 3.29289C18.0976 3.68342 18.0976 4.31658 17.7071 4.70711L15.2071 7.20711C14.8166 7.59763 14.1834 7.59763 13.7929 7.20711C13.4024 6.81658 13.4024 6.18342 13.7929 5.79289L14.5858 5H12.4142L8.70711 8.70711C8.51957 8.89464 8.26522 9 8 9H3C2.44772 9 2 8.55228 2 8C2 7.44772 2.44772 7 3 7H7.58579L11.2929 3.29289C11.4804 3.10536 11.7348 3 12 3H14.5858L13.7929 2.20711C13.4024 1.81658 13.4024 1.18342 13.7929 0.792893ZM13.7929 8.79289C14.1834 8.40237 14.8166 8.40237 15.2071 8.79289L17.7071 11.2929C18.0976 11.6834 18.0976 12.3166 17.7071 12.7071L15.2071 15.2071C14.8166 15.5976 14.1834 15.5976 13.7929 15.2071C13.4024 14.8166 13.4024 14.1834 13.7929 13.7929L14.5858 13H12C11.7348 13 11.4804 12.8946 11.2929 12.7071L9.29289 10.7071C8.90237 10.3166 8.90237 9.68342 9.29289 9.29289C9.68342 8.90237 10.3166 8.90237 10.7071 9.29289L12.4142 11H14.5858L13.7929 10.2071C13.4024 9.81658 13.4024 9.18342 13.7929 8.79289Z',
      'M6 8C6 9.65685 4.65685 11 3 11C1.34315 11 0 9.65685 0 8C0 6.34315 1.34315 5 3 5C4.65685 5 6 6.34315 6 8Z'
    ],
    viewbox: '0 0 18 16',
    width: '18',
    colors: [],
    fillRule: '',
    height: '16'
  },
  configure: {
    path: [
      'M10 2C9.44771 2 9 2.44772 9 3C9 3.55228 9.44771 4 10 4C10.5523 4 11 3.55228 11 3C11 2.44772 10.5523 2 10 2ZM7.17071 2C7.58254 0.834808 8.69378 0 10 0C11.3062 0 12.4175 0.834808 12.8293 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H12.8293C12.4175 5.16519 11.3062 6 10 6C8.69378 6 7.58254 5.16519 7.17071 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H7.17071ZM6 8C5.44772 8 5 8.44772 5 9C5 9.55229 5.44772 10 6 10C6.55228 10 7 9.55229 7 9C7 8.44772 6.55228 8 6 8ZM3.17071 8C3.58254 6.83481 4.69378 6 6 6C7.30622 6 8.41746 6.83481 8.82929 8H15C15.5523 8 16 8.44772 16 9C16 9.55229 15.5523 10 15 10H8.82929C8.41746 11.1652 7.30622 12 6 12C4.69378 12 3.58254 11.1652 3.17071 10H1C0.447715 10 0 9.55229 0 9C0 8.44772 0.447715 8 1 8H3.17071Z'
    ],
    viewbox: '0 0 16 12',
    width: '16',
    colors: [],
    fillRule: '',
    height: '12'
  },
  date: {
    path: [
      'M3 0.5C3.55228 0.5 4 0.947715 4 1.5V2H10V1.5C10 0.947715 10.4477 0.5 11 0.5C11.5523 0.5 12 0.947715 12 1.5V2C13.1046 2 14 2.89543 14 4V14C14 15.1046 13.1046 16 12 16H2C0.89543 16 0 15.1046 0 14V4C0 2.89543 0.895431 2 2 2V1.5C2 0.947715 2.44772 0.5 3 0.5ZM12 4L2 4V5H12V4ZM12 7H2V14H12V7Z',
      'M11 11.5C11 12.3284 10.3284 13 9.5 13C8.67157 13 8 12.3284 8 11.5C8 10.6716 8.67157 10 9.5 10C10.3284 10 11 10.6716 11 11.5Z'
    ],
    viewbox: '0 0 14 18',
    width: '14',
    colors: [],
    fillRule: '',
    height: '18'
  },
  drag: {
    path: [
      'M0 1C0 0.447715 0.447715 0 1 0C1.55228 0 2 0.447715 2 1C2 1.55228 1.55228 2 1 2C0.447715 2 0 1.55228 0 1Z',
      'M0 6C0 5.44772 0.447715 5 1 5C1.55228 5 2 5.44772 2 6C2 6.55228 1.55228 7 1 7C0.447715 7 0 6.55228 0 6Z',
      'M0 11C0 10.4477 0.447715 10 1 10C1.55228 10 2 10.4477 2 11C2 11.5523 1.55228 12 1 12C0.447715 12 0 11.5523 0 11Z',
      'M4 1C4 0.447715 4.44772 0 5 0C5.55228 0 6 0.447715 6 1C6 1.55228 5.55228 2 5 2C4.44772 2 4 1.55228 4 1Z',
      'M4 6C4 5.44772 4.44772 5 5 5C5.55228 5 6 5.44772 6 6C6 6.55228 5.55228 7 5 7C4.44772 7 4 6.55228 4 6Z',
      'M4 11C4 10.4477 4.44772 10 5 10C5.55228 10 6 10.4477 6 11C6 11.5523 5.55228 12 5 12C4.44772 12 4 11.5523 4 11Z'
    ],
    viewbox: '0 0 6 12',
    width: '6',
    colors: [],
    fillRule: '',
    height: '12'
  },
  edit: {
    path: [
      'M8.87868 1.70711C10.0503 0.535536 11.9497 0.535538 13.1213 1.70711L13.2929 1.87868C14.4645 3.05026 14.4645 4.94975 13.2929 6.12132L4.70711 14.7071C4.51957 14.8946 4.26522 15 4 15H1C0.447715 15 0 14.5523 0 14V11C0 10.7348 0.105357 10.4804 0.292893 10.2929L8.87868 1.70711ZM11.7071 3.12132C11.3166 2.7308 10.6834 2.7308 10.2929 3.12132L9.41421 4L11 5.58579L11.8787 4.70711C12.2692 4.31659 12.2692 3.68342 11.8787 3.2929L11.7071 3.12132ZM9.58579 7L8 5.41422L2 11.4142V13H3.58579L9.58579 7Z'
    ],
    viewbox: '0 0 15 15',
    width: '15',
    colors: [],
    fillRule: '',
    height: '15'
  },
  email: {
    path: [
      'M0 2C0 0.89543 0.895431 0 2 0H16C17.1046 0 18 0.895431 18 2V12C18 13.1046 17.1046 14 16 14H2C0.895431 14 0 13.1046 0 12V2ZM14.4634 2H3.53659L8.34921 6.1251C8.7237 6.44609 9.2763 6.44609 9.65079 6.1251L14.4634 2ZM16 3.31708L10.9524 7.64361C9.82891 8.60659 8.17109 8.60659 7.04763 7.64361L2 3.31708V12H16V3.31708Z'
    ],
    viewbox: '0 0 18 14',
    width: '18',
    colors: [],
    fillRule: '',
    height: '14'
  },
  error: {
    height: '20',
    viewbox: '0 0 20 20',
    width: '20',
    fillRule: '',
    path: [
      {
        d: 'M12.5 7.5L10 10m0 0l-2.5 2.5M10 10l2.5 2.5M10 10L7.5 7.5M10 18a8 8 0 110-16 8 8 0 010 16z',
        stroke: '#000',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '2'
      }
    ],
    colors: []
  },
  headline: {
    path: [
      'M0 3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V17C16 18.6569 14.6569 20 13 20H3C1.34315 20 0 18.6569 0 17V3ZM3 2C2.44772 2 2 2.44772 2 3V17C2 17.5523 2.44772 18 3 18H13C13.5523 18 14 17.5523 14 17V3C14 2.44772 13.5523 2 13 2H3ZM4 5C4 4.44772 4.44772 4 5 4H11C11.5523 4 12 4.44772 12 5C12 5.55228 11.5523 6 11 6H5C4.44772 6 4 5.55228 4 5ZM4 8C4 7.44772 4.44772 7 5 7H9C9.55229 7 10 7.44772 10 8C10 8.55228 9.55229 9 9 9H5C4.44772 9 4 8.55228 4 8Z'
    ],
    viewbox: '0 0 16 20',
    width: '16',
    colors: [],
    fillRule: '',
    height: '20'
  },
  image: {
    path: [
      'M16 2a2 2 0 00-2-2H2a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2zM2 2h12v9.586l-2.879-2.879a3 3 0 00-4.242 0L2 13.586V2zm2.414 12l3.879-3.879a1 1 0 011.414 0L13.586 14H4.414z',
      'M4 5.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z'
    ],
    viewbox: '0 0 16 16',
    width: '16',
    colors: [],
    fillRule: '',
    height: '16'
  },
  image_add: {
    path: [
      'M16 0a1 1 0 011 1v2h2a1 1 0 110 2h-2v2a1 1 0 01-2 0V5h-2a1 1 0 110-2h2V1a1 1 0 011-1zM2 4a2 2 0 012-2h5a1 1 0 010 2H4v11.586l4.879-4.879a3 3 0 014.242 0L16 13.586V11a1 1 0 012 0v5a2 2 0 01-2 2H4a2 2 0 01-2-2V4zm4.414 12h9.172l-3.879-3.879a1 1 0 00-1.414 0L6.414 16z',
      'M6 7.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z'
    ],
    viewbox: '0 0 20 20',
    width: '20',
    colors: [],
    fillRule: '',
    height: '20'
  },
  image_banner: {
    path: [
      'M10 3a1 1 0 00-1-1H4a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2v-5a1 1 0 00-2 0v2.586l-2.879-2.879a3 3 0 00-4.242 0L4 15.586V4h5a1 1 0 001-1zM6.414 16l3.879-3.879a1 1 0 011.414 0L15.586 16H6.414z',
      'M12 2a2 2 0 012-2h4a2 2 0 012 2v6a1 1 0 01-1.51.86L16 7.385 13.51 8.86A1 1 0 0112 8V2zm6 0h-4v4.245l.98-.581a2 2 0 012.04 0l.98.581V2zM6 7.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z'
    ],
    viewbox: '0 0 20 20',
    width: '20',
    colors: [],
    fillRule: '',
    height: '20'
  },
  input: {
    path: [
      'M5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893L8 1.58579L9.29289 0.292893C9.68342 -0.0976311 10.3166 -0.0976311 10.7071 0.292893C11.0976 0.683417 11.0976 1.31658 10.7071 1.70711L9 3.41421V10.5858L10.7071 12.2929C11.0976 12.6834 11.0976 13.3166 10.7071 13.7071C10.3166 14.0976 9.68342 14.0976 9.29289 13.7071L8 12.4142L6.70711 13.7071C6.31658 14.0976 5.68342 14.0976 5.29289 13.7071C4.90237 13.3166 4.90237 12.6834 5.29289 12.2929L7 10.5858V3.41421L5.29289 1.70711C4.90237 1.31658 4.90237 0.683417 5.29289 0.292893ZM3 5C2.44772 5 2 5.44772 2 6V8C2 8.55228 2.44772 9 3 9H4C4.55228 9 5 9.44771 5 10C5 10.5523 4.55228 11 4 11H3C1.34315 11 0 9.65685 0 8V6C0 4.34315 1.34315 3 3 3H4C4.55228 3 5 3.44772 5 4C5 4.55228 4.55228 5 4 5H3ZM11 4C11 3.44772 11.4477 3 12 3H17C18.6569 3 20 4.34315 20 6V8C20 9.65685 18.6569 11 17 11H12C11.4477 11 11 10.5523 11 10C11 9.44771 11.4477 9 12 9H17C17.5523 9 18 8.55228 18 8V6C18 5.44771 17.5523 5 17 5H12C11.4477 5 11 4.55228 11 4Z'
    ],
    viewbox: '0 0 20 14',
    width: '20',
    colors: [],
    fillRule: '',
    height: '14'
  },
  label: {
    path: [
      'M0 3C0 1.34315 1.34315 0 3 0H11C12.6569 0 14 1.34315 14 3V17C14 17.3593 13.8072 17.691 13.4951 17.8689C13.1829 18.0467 12.7993 18.0435 12.4902 17.8603L7 14.6068L1.5098 17.8603C1.2007 18.0435 0.817099 18.0467 0.504925 17.8689C0.192752 17.691 0 17.3593 0 17V3ZM3 2C2.44772 2 2 2.44772 2 3V15.245L5.9804 12.8863C6.60908 12.5137 7.39092 12.5137 8.0196 12.8863L12 15.245V3C12 2.44772 11.5523 2 11 2H3Z'
    ],
    viewbox: '0 0 14 18',
    width: '14',
    colors: [],
    fillRule: '',
    height: '18'
  },
  link: {
    path: [
      'M13.435 2.69675C12.654 1.9157 11.3876 1.9157 10.6066 2.69675L7.07104 6.23228C6.68052 6.62281 6.68052 7.25597 7.07104 7.6465L7.77815 8.3536C8.16867 8.74413 8.16867 9.37729 7.77815 9.76782C7.38763 10.1583 6.75446 10.1583 6.36394 9.76782L5.65683 9.06071C4.48526 7.88914 4.48526 5.98964 5.65683 4.81807L9.19236 1.28253C10.7545 -0.279562 13.2871 -0.279562 14.8492 1.28253C16.4113 2.84463 16.4113 5.37729 14.8492 6.93939L13.435 8.3536C13.0445 8.74413 12.4113 8.74413 12.0208 8.3536C11.6303 7.96308 11.6303 7.32991 12.0208 6.93939L13.435 5.52518C14.2161 4.74413 14.2161 3.4778 13.435 2.69675ZM8.48526 6.23228C8.87578 5.84176 9.50894 5.84176 9.89947 6.23228L10.6066 6.93939C11.7781 8.11096 11.7781 10.0105 10.6066 11.182L7.07104 14.7176C5.50894 16.2797 2.97628 16.2797 1.41419 14.7176C-0.14791 13.1555 -0.147909 10.6228 1.41419 9.06071L2.12129 9.76782L1.41419 9.06071L2.8284 7.6465C3.21893 7.25597 3.85209 7.25597 4.24261 7.6465C4.63314 8.03702 4.63314 8.67019 4.24261 9.06071L2.8284 10.4749C2.04735 11.256 2.04735 12.5223 2.8284 13.3033C3.60945 14.0844 4.87578 14.0844 5.65683 13.3033L9.19236 9.76782C9.58289 9.37729 9.58289 8.74413 9.19236 8.3536L8.48526 7.6465C8.09473 7.25597 8.09473 6.62281 8.48526 6.23228Z'
    ],
    viewbox: '0 0 17 16',
    width: '17',
    colors: [],
    fillRule: '',
    height: '16'
  },
  list: {
    path: [
      'M0 1C0 0.447715 0.447715 0 1 0C1.55228 0 2 0.447715 2 1C2 1.55228 1.55228 2 1 2C0.447715 2 0 1.55228 0 1Z M0 5C0 4.44772 0.447715 4 1 4C1.55228 4 2 4.44772 2 5C2 5.55228 1.55228 6 1 6C0.447715 6 0 5.55228 0 5Z M1 8C0.447715 8 0 8.44772 0 9C0 9.55228 0.447715 10 1 10C1.55228 10 2 9.55228 2 9C2 8.44772 1.55228 8 1 8Z M5 0C4.44772 0 4 0.447715 4 1C4 1.55228 4.44772 2 5 2H15C15.5523 2 16 1.55228 16 1C16 0.447715 15.5523 0 15 0H5Z M5 4C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6H15C15.5523 6 16 5.55228 16 5C16 4.44772 15.5523 4 15 4H5Z M5 8C4.44772 8 4 8.44772 4 9C4 9.55229 4.44772 10 5 10H10C10.5523 10 11 9.55229 11 9C11 8.44772 10.5523 8 10 8H5Z'
    ],
    viewbox: '0 0 16 10',
    width: '16',
    colors: [],
    fillRule: '',
    height: '10'
  },
  location: {
    path: [
      'M8 2C4.71266 2 2 4.74909 2 8.2C2 11.135 3.56137 13.6011 5.23118 15.3908C6.05798 16.277 6.88746 16.971 7.51021 17.4429C7.69407 17.5822 7.85919 17.7016 8 17.8004C8.14081 17.7016 8.30593 17.5822 8.48979 17.4429C9.11254 16.971 9.94202 16.277 10.7688 15.3908C12.4386 13.6011 14 11.135 14 8.2C14 4.74909 11.2873 2 8 2ZM8 19C7.48502 19.8572 7.4847 19.857 7.48436 19.8568L7.48354 19.8563L7.48142 19.855L7.47526 19.8513L7.45547 19.8392C7.439 19.829 7.41604 19.8148 7.38704 19.7964C7.32904 19.7598 7.24678 19.7068 7.14372 19.6379C6.93771 19.5003 6.64794 19.2988 6.30229 19.0369C5.61254 18.5142 4.69202 17.7447 3.76882 16.7552C1.93863 14.7936 0 11.8597 0 8.2C0 3.69801 3.55535 0 8 0C12.4446 0 16 3.69801 16 8.2C16 11.8597 14.0614 14.7936 12.2312 16.7552C11.308 17.7447 10.3875 18.5142 9.69771 19.0369C9.35206 19.2988 9.06229 19.5003 8.85628 19.6379C8.75323 19.7068 8.67096 19.7598 8.61296 19.7964C8.58396 19.8148 8.561 19.829 8.54453 19.8392L8.52474 19.8513L8.51859 19.855L8.51646 19.8563L8.51564 19.8568C8.5153 19.857 8.51498 19.8572 8 19ZM8 19L8.51498 19.8572L8 20.1666L7.48502 19.8572L8 19ZM8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7ZM5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8Z'
    ],
    viewbox: '0 0 16 21',
    width: '16',
    colors: [],
    fillRule: '',
    height: '21'
  },
  max: {
    path: [
      'M17 2C17.5523 2 18 2.44772 18 3V17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17V3C16 2.44772 16.4477 2 17 2ZM13.7071 9.29289C14.0976 9.68342 14.0976 10.3166 13.7071 10.7071L9.70711 14.7071C9.31658 15.0976 8.68342 15.0976 8.29289 14.7071C7.90237 14.3166 7.90237 13.6834 8.29289 13.2929L10.5858 11L3 11C2.44771 11 2 10.5523 2 10C2 9.44772 2.44771 9 3 9H10.5858L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L13.7071 9.29289Z'
    ],
    viewbox: '0 0 20 20',
    width: '20',
    colors: [],
    fillRule: '',
    height: '20'
  },
  min: {
    path: [
      'M17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L13.7071 14.7071C13.3166 15.0976 12.6834 15.0976 12.2929 14.7071C11.9024 14.3166 11.9024 13.6834 12.2929 13.2929L14.5858 11H7C6.44771 11 6 10.5523 6 10C6 9.44772 6.44771 9 7 9H14.5858L12.2929 6.70711C11.9024 6.31658 11.9024 5.68342 12.2929 5.29289C12.6834 4.90237 13.3166 4.90237 13.7071 5.29289L17.7071 9.29289ZM3 2C3.55229 2 4 2.44772 4 3L4 17C4 17.5523 3.55229 18 3 18C2.44771 18 2 17.5523 2 17L2 3C2 2.44772 2.44771 2 3 2Z'
    ],
    viewbox: '0 0 20 20',
    width: '20',
    colors: [],
    fillRule: '',
    height: '20'
  },
  number: {
    path: [
      'M0 1C0 0.447715 0.447715 0 1 0H2C3.10457 0 4 0.895431 4 2V10C4 10.5523 3.55228 11 3 11C2.44772 11 2 10.5523 2 10V2H1C0.447715 2 0 1.55228 0 1ZM6 1C6 0.447715 6.44772 0 7 0H10C11.1046 0 12 0.895431 12 2V4.54545C12 5.65002 11.1046 6.54545 10 6.54545H8V9H11C11.5523 9 12 9.44771 12 10C12 10.5523 11.5523 11 11 11H8C6.89543 11 6 10.1046 6 9V6.54545C6 5.44089 6.89543 4.54545 8 4.54545L10 4.54545V2H7C6.44772 2 6 1.55228 6 1ZM14 1C14 0.447715 14.4477 0 15 0H18C19.1046 0 20 0.895431 20 2V9C20 10.1046 19.1046 11 18 11H15C14.4477 11 14 10.5523 14 10C14 9.44771 14.4477 9 15 9H18V6.54545H16C15.4477 6.54545 15 6.09774 15 5.54545C15 4.99317 15.4477 4.54545 16 4.54545H18V2H15C14.4477 2 14 1.55228 14 1Z'
    ],
    viewbox: '0 0 20 11',
    width: '20',
    colors: [],
    fillRule: '',
    height: '11'
  },
  overflow: {
    path: [
      'M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5Z',
      'M3 6.5C3 7.32843 2.32843 8 1.5 8C0.671573 8 0 7.32843 0 6.5C0 5.67157 0.671573 5 1.5 5C2.32843 5 3 5.67157 3 6.5Z',
      'M3 11.5C3 12.3284 2.32843 13 1.5 13C0.671573 13 0 12.3284 0 11.5C0 10.6716 0.671573 10 1.5 10C2.32843 10 3 10.6716 3 11.5Z'
    ],
    viewbox: '0 0 3 13',
    width: '3',
    colors: [],
    fillRule: '',
    height: '13'
  },
  placeholder: {
    path: [
      'M4 7.5C4 6.67157 4.67157 6 5.5 6C6.32843 6 7 6.67157 7 7.5C7 8.32843 6.32843 9 5.5 9C4.67157 9 4 8.32843 4 7.5Z M14 0C14.5523 0 15 0.447715 15 1V3H17C17.5523 3 18 3.44772 18 4C18 4.55228 17.5523 5 17 5H15V7C15 7.55228 14.5523 8 14 8C13.4477 8 13 7.55228 13 7V5H11C10.4477 5 10 4.55228 10 4C10 3.44772 10.4477 3 11 3H13V1C13 0.447715 13.4477 0 14 0ZM0 4C0 2.89543 0.89543 2 2 2H7C7.55228 2 8 2.44772 8 3C8 3.55228 7.55228 4 7 4L2 4V13.9486L4.92653 11.6603C6.08849 10.5481 7.92461 10.5511 9.0829 11.6694L14 15.7062V11C14 10.4477 14.4477 10 15 10C15.5523 10 16 10.4477 16 11V16C16 17.1046 15.1046 18 14 18H2C0.895432 18 0 17.1046 0 16V4ZM11.206 16L7.77968 13.1871C7.75443 13.1664 7.73021 13.1444 7.70711 13.1213C7.31658 12.7308 6.68342 12.7308 6.29289 13.1213L6.24979 13.1644L2.62343 16H11.206Z'
    ],
    viewbox: '0 0 18 18',
    width: '18',
    colors: [],
    fillRule: '',
    height: '18'
  },
  priority: {
    path: [
      'M0 3C0 1.34315 1.34315 0 3 0H8C9.65685 0 11 1.34315 11 3V16C11 17.6569 9.65685 19 8 19H3C1.34315 19 0 17.6569 0 16V3ZM3 2C2.44772 2 2 2.44772 2 3V16C2 16.5523 2.44772 17 3 17H8C8.55228 17 9 16.5523 9 16V3C9 2.44772 8.55228 2 8 2H3Z',
      'M4 5.5C4 4.67157 4.67157 4 5.5 4C6.32843 4 7 4.67157 7 5.5C7 6.32843 6.32843 7 5.5 7C4.67157 7 4 6.32843 4 5.5Z',
      'M4 9.5C4 8.67157 4.67157 8 5.5 8C6.32843 8 7 8.67157 7 9.5C7 10.3284 6.32843 11 5.5 11C4.67157 11 4 10.3284 4 9.5Z',
      'M4 13.5C4 12.6716 4.67157 12 5.5 12C6.32843 12 7 12.6716 7 13.5C7 14.3284 6.32843 15 5.5 15C4.67157 15 4 14.3284 4 13.5Z'
    ],
    viewbox: '0 0 11 19',
    width: '11',
    colors: [],
    fillRule: '',
    height: '19'
  },
  radio: {
    path: [
      'M6 9C6 7.34315 7.34315 6 9 6C10.6569 6 12 7.34315 12 9C12 10.6569 10.6569 12 9 12C7.34315 12 6 10.6569 6 9Z',
      'M0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9ZM9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2Z'
    ],
    viewbox: '0 0 18 18',
    width: '18',
    colors: [],
    fillRule: '',
    height: '18'
  },
  required: {
    path: [
      'M1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10ZM10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3ZM10 6C10.5523 6 11 6.44772 11 7V10C11 10.5523 10.5523 11 10 11C9.44772 11 9 10.5523 9 10V7C9 6.44772 9.44772 6 10 6Z',
      'M11 13C11 13.5523 10.5523 14 10 14C9.44772 14 9 13.5523 9 13C9 12.4477 9.44772 12 10 12C10.5523 12 11 12.4477 11 13Z'
    ],
    viewbox: '0 0 20 20',
    width: '20',
    colors: [],
    fillRule: '',
    height: '20'
  },
  regex: {
    path: [
      'M13.4472 3.10558C13.9412 3.35257 14.1414 3.95324 13.8944 4.44722L7.89443 16.4472C7.64744 16.9412 7.04676 17.1414 6.55279 16.8944C6.05881 16.6474 5.85858 16.0468 6.10557 15.5528L12.1056 3.55279C12.3526 3.05881 12.9532 2.85859 13.4472 3.10558ZM4.70711 6.2929C5.09763 6.68342 5.09763 7.31659 4.70711 7.70711L2.41421 10L4.70711 12.2929C5.09763 12.6834 5.09763 13.3166 4.70711 13.7071C4.31658 14.0976 3.68342 14.0976 3.29289 13.7071L0.292893 10.7071C-0.0976311 10.3166 -0.0976311 9.68342 0.292893 9.2929L3.29289 6.2929C3.68342 5.90237 4.31658 5.90237 4.70711 6.2929ZM15.2929 6.2929C15.6834 5.90237 16.3166 5.90237 16.7071 6.2929L19.7071 9.2929C20.0976 9.68342 20.0976 10.3166 19.7071 10.7071L16.7071 13.7071C16.3166 14.0976 15.6834 14.0976 15.2929 13.7071C14.9024 13.3166 14.9024 12.6834 15.2929 12.2929L17.5858 10L15.2929 7.70711C14.9024 7.31659 14.9024 6.68342 15.2929 6.2929Z'
    ],
    viewbox: '0 0 20 20',
    width: '20',
    colors: [],
    fillRule: '',
    height: '20'
  },
  remove: {
    path: [
      'M6.293.293A1 1 0 017 0h2a1 1 0 01.707.293l.707.707H15a1 1 0 110 2H1a1 1 0 010-2h4.586l.707-.707zM2 5a1 1 0 011 1v8a1 1 0 001 1h8a1 1 0 001-1V6a1 1 0 112 0v8a3 3 0 01-3 3H4a3 3 0 01-3-3V6a1 1 0 011-1zm8 0a1 1 0 011 1v6a1 1 0 11-2 0V6a1 1 0 011-1zM6 5a1 1 0 011 1v6a1 1 0 11-2 0V6a1 1 0 011-1z'
    ],
    viewbox: '0 0 16 17',
    width: '16',
    colors: [],
    fillRule: '',
    height: '17'
  },
  response: {
    path: [
      'M19.7929 0.792893C20.1834 0.402369 20.8166 0.402369 21.2071 0.792893L24.7071 4.29289C25.0976 4.68342 25.0976 5.31658 24.7071 5.70711L21.2071 9.20711C20.8166 9.59763 20.1834 9.59763 19.7929 9.20711C19.4024 8.81658 19.4024 8.18342 19.7929 7.79289L21.5858 6H19C18.4477 6 18 5.55228 18 5C18 4.44772 18.4477 4 19 4H21.5858L19.7929 2.20711C19.4024 1.81658 19.4024 1.18342 19.7929 0.792893ZM13 3C11.8954 3 11 3.89543 11 5C11 6.10457 11.8954 7 13 7C14.1046 7 15 6.10457 15 5C15 3.89543 14.1046 3 13 3ZM9 5C9 2.79086 10.7909 1 13 1C15.2091 1 17 2.79086 17 5C17 7.20914 15.2091 9 13 9C10.7909 9 9 7.20914 9 5ZM2 5C2 4.44772 2.44772 4 3 4H7C7.55228 4 8 4.44772 8 5C8 5.55228 7.55228 6 7 6H3C2.44772 6 2 5.55228 2 5Z',
      'M6 5C6 6.65685 4.65685 8 3 8C1.34315 8 0 6.65685 0 5C0 3.34315 1.34315 2 3 2C4.65685 2 6 3.34315 6 5Z'
    ],
    viewbox: '0 0 25 10',
    width: '25',
    colors: [],
    fillRule: '',
    height: '10'
  },
  seen_by: {
    path: [
      'M2.24133 7.41431C2.80418 8.08237 3.85824 9.22247 5.21744 10.2019C6.63214 11.2214 8.28447 12 10 12C11.7155 12 13.3679 11.2214 14.7826 10.2019C16.1418 9.22247 17.1958 8.08237 17.7587 7.41431C17.2114 6.64951 16.1517 5.29556 14.7757 4.12727C13.3399 2.90822 11.6879 2 10 2C8.31209 2 6.66013 2.90822 5.22433 4.12727C3.84832 5.29556 2.78863 6.64951 2.24133 7.41431ZM3.92989 2.60266C5.51028 1.26085 7.61516 0 10 0C12.3849 0 14.4897 1.26085 16.0701 2.60266C17.6679 3.95922 18.8611 5.51027 19.4398 6.32723C19.9368 7.02875 19.902 7.96669 19.3502 8.62901C18.7448 9.35545 17.5441 10.6771 15.9518 11.8245C14.3695 12.9648 12.3073 14 10 14C7.69277 14 5.63054 12.9648 4.04818 11.8245C2.45591 10.6771 1.25518 9.35545 0.649865 8.62901C0.0979829 7.96669 0.063231 7.02875 0.56018 6.32723C1.13891 5.51027 2.33213 3.95922 3.92989 2.60266ZM7.00001 7C7.00001 5.34315 8.34315 4 10 4C11.6569 4 13 5.34315 13 7C13 8.65685 11.6569 10 10 10C8.34315 10 7.00001 8.65685 7.00001 7ZM10 6C9.44772 6 9.00001 6.44772 9.00001 7C9.00001 7.55228 9.44772 8 10 8C10.5523 8 11 7.55228 11 7C11 6.44772 10.5523 6 10 6Z'
    ],
    viewbox: '0 0 20 14',
    width: '20',
    colors: [],
    fillRule: '',
    height: '14'
  },
  submit: {
    path: [
      'M6 3C6 1.34315 7.34315 0 9 0H17C18.6569 0 20 1.34315 20 3V15C20 16.6569 18.6569 18 17 18H9C7.34315 18 6 16.6569 6 15V14C6 13.4477 6.44772 13 7 13C7.55228 13 8 13.4477 8 14V15C8 15.5523 8.44772 16 9 16H17C17.5523 16 18 15.5523 18 15V3C18 2.44772 17.5523 2 17 2H9C8.44772 2 8 2.44772 8 3V4C8 4.55228 7.55228 5 7 5C6.44772 5 6 4.55228 6 4V3Z',
      'M10.7929 4.79289C11.1834 4.40237 11.8166 4.40237 12.2071 4.79289L15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711L12.2071 13.2071C11.8166 13.5976 11.1834 13.5976 10.7929 13.2071C10.4024 12.8166 10.4024 12.1834 10.7929 11.7929L12.5858 10H5.82929C5.41746 11.1652 4.30622 12 3 12C1.34315 12 0 10.6569 0 9C0 7.34315 1.34315 6 3 6C4.30622 6 5.41746 6.83481 5.82929 8H12.5858L10.7929 6.20711C10.4024 5.81658 10.4024 5.18342 10.7929 4.79289ZM3 8C2.44772 8 2 8.44772 2 9C2 9.55228 2.44772 10 3 10C3.55228 10 4 9.55228 4 9C4 8.44772 3.55228 8 3 8Z'
    ],
    viewbox: '0 0 20 18',
    width: '20',
    colors: [],
    fillRule: '',
    height: '18'
  },
  subtract: {
    path: [
      'M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1C12 1.55228 11.5523 2 11 2H1C0.447715 2 0 1.55228 0 1Z'
    ],
    viewbox: '0 0 12 2',
    width: '12',
    colors: [],
    fillRule: '',
    height: '2'
  },
  success: {
    viewbox: '0 0 20 20',
    width: '20',
    height: '20',
    fillRule: '',
    path: [
      {
        d: 'm13 8-4 4-2-2m3 8c-4.41828 0-8-3.5817-8-8 0-4.41828 3.58172-8 8-8 4.4183 0 8 3.58172 8 8 0 4.4183-3.5817 8-8 8z',
        stroke: '#000',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '2'
      }
    ],
    colors: []
  },
  text: {
    path: [
      'M0 1C0 0.447715 0.447715 0 1 0H15C15.5523 0 16 0.447715 16 1C16 1.55228 15.5523 2 15 2H1C0.447715 2 0 1.55228 0 1ZM0 5C0 4.44772 0.447715 4 1 4H15C15.5523 4 16 4.44772 16 5C16 5.55228 15.5523 6 15 6H1C0.447715 6 0 5.55228 0 5ZM0 9C0 8.44772 0.447715 8 1 8H7C7.55228 8 8 8.44772 8 9C8 9.55229 7.55228 10 7 10H1C0.447715 10 0 9.55229 0 9Z'
    ],
    viewbox: '0 0 16 10',
    width: '16',
    colors: [],
    fillRule: '',
    height: '10'
  },
  tools: {
    path: [
      'm26 12.7048c0-.3601-.1448-.7049-.4026-.9598l-1.9448-1.91946c-.2578-.25447-.6076-.39739-.9724-.39739h-2.1802v-3.39244c0-1.1243-.9234-2.03571-2.0625-2.03571h-6.875c-1.1391 0-2.0625.91141-2.0625 2.03571v3.39286h-2.18023c-.36481 0-.71457.14293-.97239.39739l-1.94476 1.91954c-.25782.2544-.40262.5997-.40262.9597l.00043 3.8305-.00043 7.1072c0 .7494.61574 1.3571 1.375 1.3571h19.25c.7593 0 1.375-.6077 1.375-1.3571zm-14.4375-6.66909h6.875v3.39286h-6.875zm-5.5 6.95029 1.54172-1.5217h14.79108l1.5422 1.5217.0004 3.5497h-3.7817v-.6786c0-.3749-.3076-.6785-.6874-.6785h-.6876c-.3798 0-.6874.3036-.6874.6785v.6786h-6.1876v-.6786c0-.3749-.3076-.6785-.6874-.6785h-.6876c-.3798 0-.68745.3036-.68745.6785v.6786h-3.78082zm17.875 9.9783h-17.875v-4.3929h3.78125v.6786c0 .3749.30765.6786.68745.6786h.6876c.3798 0 .6874-.3037.6874-.6786v-.6786h6.1876v.6786c0 .3749.3076.6786.6874.6786h.6876c.3798 0 .6874-.3037.6874-.6786v-.6786h3.7813z'
    ],
    viewbox: '0 0 30 30',
    width: '30',
    colors: [],
    fillRule: '',
    height: '30'
  },
  toggle: {
    path: [
      'M0 2C0 0.895431 0.895431 0 2 0H16C17.1046 0 18 0.895431 18 2V8C18 9.10457 17.1046 10 16 10H2C0.895431 10 0 9.10457 0 8V2ZM8 2H2V8H8V2ZM10 2V8H16V2H10Z'
    ],
    viewbox: '0 0 18 10',
    width: '18',
    colors: [],
    fillRule: '',
    height: '10'
  },
  users: {
    path: [
      'M6 3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-.5a1 1 0 1 0-2 0v.5a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H7a3 3 0 0 0-3 3v.5a1 1 0 0 0 2 0V3Z',
      'M2.4 8.667a2.667 2.667 0 1 1 5.333 0 2.667 2.667 0 0 1-5.333 0ZM5.067 8a.667.667 0 1 0 0 1.333.667.667 0 0 0 0-1.333ZM0 17.333a5 5 0 1 1 10 0 1 1 0 0 1-1 1H1a1 1 0 0 1-1-1Zm5-3a3.001 3.001 0 0 0-2.83 2h5.66a3.001 3.001 0 0 0-2.83-2Z',
      'M10 7a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1ZM11 10a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2h-3Z'
    ],
    viewbox: '0 0 24 24',
    width: '24',
    colors: [],
    fillRule: '',
    height: '24'
  },
  video: {
    path: [
      'M19 6a2 2 0 00-2-2H7a2 2 0 00-2 2v2.586l-2.44-2.44C1.617 5.202 0 5.871 0 7.207v7.586c0 1.336 1.616 2.006 2.56 1.06L5 13.415V16a2 2 0 002 2h10a2 2 0 002-2V6zM7 6h10v10H7V6zM2 8.414L4.586 11 2 13.586V8.414z'
    ],
    viewbox: '0 0 22 22',
    width: '22',
    colors: [],
    fillRule: '',
    height: '22'
  },
  video_add: {
    path: [
      'M17 2a1 1 0 00-1 1v2h-2a1 1 0 100 2h2v2a1 1 0 002 0V7h2a1 1 0 100-2h-2V3a1 1 0 00-1-1zm-6 3a1 1 0 00-1-1H7a2 2 0 00-2 2v2.586l-2.44-2.44C1.617 5.201 0 5.871 0 7.207v7.586c0 1.336 1.616 2.006 2.56 1.06L5 13.415V16a2 2 0 002 2h10a2 2 0 002-2v-3a1 1 0 00-2 0v3H7V6h3a1 1 0 001-1zM2 8.414L4.586 11 2 13.586V8.414z'
    ],
    viewbox: '0 0 22 22',
    width: '22',
    colors: [],
    fillRule: '',
    height: '22'
  },
  video_banner: {
    path: [
      'M11 5a1 1 0 00-1-1H7a2 2 0 00-2 2v2.586l-2.44-2.44C1.617 5.201 0 5.871 0 7.207v7.586c0 1.336 1.616 2.006 2.56 1.06L5 13.415V16a2 2 0 002 2h10a2 2 0 002-2v-3a1 1 0 00-2 0v3H7V6h3a1 1 0 001-1zM2 8.414L4.586 11 2 13.586V8.414z',
      'M21 4a2 2 0 00-2-2h-4a2 2 0 00-2 2v6a1 1 0 001.51.86L17 9.385l2.49 1.475A1 1 0 0021 10V4zm-6 0h4v4.245l-.98-.581a2 2 0 00-2.04 0l-.98.581V4z'
    ],
    viewbox: '0 0 22 22',
    width: '22',
    colors: [],
    fillRule: '',
    height: '22'
  },
  web: {
    path: [
      'M2.66558 5.25H5.02634C5.22191 4.07137 5.53102 3.12934 5.86578 2.39073C4.48021 2.91821 3.34012 3.94429 2.66558 5.25ZM8 2.6107C7.93513 2.72107 7.86819 2.84345 7.80068 2.97846C7.53335 3.51313 7.25214 4.25607 7.05836 5.25H8.94164C8.74786 4.25607 8.46665 3.51313 8.19932 2.97846C8.13181 2.84345 8.06487 2.72107 8 2.6107ZM9.1712 7.25H6.8288C6.81815 7.49048 6.8125 7.74039 6.8125 8C6.8125 8.25961 6.81815 8.50952 6.8288 8.75H9.1712C9.18186 8.50952 9.1875 8.25961 9.1875 8C9.1875 7.74039 9.18186 7.49048 9.1712 7.25ZM11.1729 8.75C11.1825 8.50736 11.1875 8.25741 11.1875 8C11.1875 7.74259 11.1825 7.49264 11.1729 7.25H13.9537C13.9842 7.4954 14 7.74568 14 8C14 8.25432 13.9842 8.5046 13.9537 8.75H11.1729ZM8.94164 10.75H7.05836C7.25214 11.7439 7.53335 12.4869 7.80068 13.0215C7.86819 13.1566 7.93513 13.2789 8 13.3893C8.06487 13.2789 8.13181 13.1566 8.19932 13.0215C8.46665 12.4869 8.74786 11.7439 8.94164 10.75ZM5.86578 13.6093C5.53102 12.8707 5.22191 11.9286 5.02634 10.75H2.66558C3.34012 12.0557 4.48021 13.0818 5.86578 13.6093ZM2.04631 8.75H4.82706C4.8175 8.50736 4.8125 8.25741 4.8125 8C4.8125 7.74259 4.8175 7.49264 4.82706 7.25H2.04631C2.01577 7.4954 2 7.74568 2 8C2 8.25432 2.01577 8.5046 2.04631 8.75ZM10.1342 13.6093C10.469 12.8707 10.7781 11.9286 10.9737 10.75H13.3344C12.6599 12.0557 11.5198 13.0818 10.1342 13.6093ZM13.3344 5.25H10.9737C10.7781 4.07137 10.469 3.12934 10.1342 2.39073C11.5198 2.91821 12.6599 3.94429 13.3344 5.25ZM0.252094 6.00074C1.14012 2.5505 4.27095 0 8 0C11.729 0 14.8599 2.5505 15.7479 6.00074C15.9127 6.64082 16 7.31094 16 8C16 8.68906 15.9127 9.35918 15.7479 9.99926C14.8599 13.4495 11.729 16 8 16C4.27095 16 1.14012 13.4495 0.252094 9.99926C0.0873488 9.35918 0 8.68906 0 8C0 7.31094 0.0873487 6.64082 0.252094 6.00074Z'
    ],
    viewbox: '0 0 16 16',
    width: '16',
    colors: [],
    fillRule: 'evenodd',
    height: '16'
  },
  info: {
    path: [
      'M9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0ZM2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9ZM8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9V11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H10C10.5523 13 11 12.5523 11 12C11 11.4477 10.5523 11 10 11V8C10 7.44772 9.55229 7 9 7H8ZM9 6C9.55228 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4C8.44771 4 8 4.44772 8 5C8 5.55228 8.44771 6 9 6Z'
    ],
    viewbox: '0 0 18 18',
    width: '18',
    colors: [],
    fillRule: '',
    height: '18'
  },
  profile: {
    path: [
      'M9 2C5.13401 2 2 5.13401 2 9C2 10.8669 2.73081 12.563 3.92195 13.818C5.30272 12.6828 7.07218 12 8.99995 12C10.9277 12 12.6972 12.6828 14.078 13.8181C15.2692 12.5631 16 10.8669 16 9C16 5.13401 12.866 2 9 2ZM12.4514 15.0914C11.475 14.4033 10.285 14 8.99995 14C7.71488 14 6.52493 14.4033 5.5485 15.0913C6.56707 15.6697 7.74496 16 9 16C10.255 16 11.4329 15.6697 12.4514 15.0914ZM0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9ZM9 7C8.44771 7 8 7.44771 8 8C8 8.55229 8.44772 9 9 9C9.55228 9 10 8.55228 10 8C10 7.44772 9.55229 7 9 7ZM6 8C6 6.34315 7.34314 5 9 5C10.6569 5 12 6.34314 12 8C12 9.65685 10.6569 11 9 11C7.34314 11 6 9.65685 6 8Z'
    ],
    viewbox: '0 0 18 18',
    width: '18',
    colors: [],
    fillRule: '',
    height: '18'
  },
  plug: {
    path: [
      'M14 2a2 2 0 00-2 2v2a2 2 0 002 2h1V2h-1zm3 2v2h2a1 1 0 110 2h-2v1a1 1 0 01-1 1h-2a4 4 0 01-4-4H6.5a1.5 1.5 0 100 3 3.5 3.5 0 110 7H1a1 1 0 110-2h5.5a1.5 1.5 0 000-3 3.5 3.5 0 110-7H10a4 4 0 014-4h2a1 1 0 011 1v1h2a1 1 0 110 2h-2z'
    ],
    viewbox: '0 0 20 16',
    width: '20',
    colors: [],
    fillRule: '',
    height: '16'
  },
  time: {
    path: [
      'M0 9a9 9 0 1118 0A9 9 0 010 9zm9-7a7 7 0 100 14A7 7 0 009 2z M8 5a1 1 0 011 1v3h3a1 1 0 110 2H8a1 1 0 01-1-1V6a1 1 0 011-1z'
    ],
    viewbox: '0 0 18 18',
    width: '18',
    colors: [],
    fillRule: '',
    height: '18'
  },
  stream: {
    path: [
      'M0 6a2 2 0 012-2h12a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2V6zm14 0H2v8h12V6zM0 1a1 1 0 011-1h14a1 1 0 110 2H1a1 1 0 01-1-1zM0 19a1 1 0 011-1h14a1 1 0 110 2H1a1 1 0 01-1-1z'
    ],
    viewbox: '0 0 16 20',
    width: '16',
    colors: [],
    fillRule: '',
    height: '20'
  },
  sparkle: {
    path: [
      'M13 3a1 1 0 011 1 5 5 0 005 5 1 1 0 110 2 5 5 0 00-5 5 1 1 0 11-2 0 5 5 0 00-5-5 1 1 0 110-2 5 5 0 005-5 1 1 0 011-1zm0 4.608A7.036 7.036 0 0110.608 10 7.036 7.036 0 0113 12.392 7.036 7.036 0 0115.392 10 7.036 7.036 0 0113 7.608zM4 12a1 1 0 011 1 2 2 0 002 2 1 1 0 110 2 2 2 0 00-2 2 1 1 0 11-2 0 2 2 0 00-2-2 1 1 0 110-2 2 2 0 002-2 1 1 0 011-1zm0 3.646c-.11.125-.229.243-.354.354.125.11.243.229.354.354.11-.125.229-.243.354-.354A4.026 4.026 0 014 15.646zM7 0a1 1 0 011 1 1 1 0 001 1 1 1 0 010 2 1 1 0 00-1 1 1 1 0 01-2 0 1 1 0 00-1-1 1 1 0 010-2 1 1 0 001-1 1 1 0 011-1z'
    ],
    viewbox: '0 0 20 20',
    width: '20',
    colors: [],
    fillRule: '',
    height: '20'
  },
  dropdown: {
    path: [
      'M0 9a9 9 0 1118 0A9 9 0 010 9zm9-7a7 7 0 100 14A7 7 0 009 2zM5.293 7.293a1 1 0 011.414 0L9 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
    ],
    viewbox: '0 0 18 18',
    width: '18',
    colors: [],
    fillRule: '',
    height: '18'
  },
  stepper: {
    path: [
      'M0 9a9 9 0 1118 0A9 9 0 010 9zm9-7a7 7 0 100 14A7 7 0 009 2zm0 2.5a1 1 0 011 1V8h2.5a1 1 0 110 2H10v2.5a1 1 0 11-2 0V10H5.5a1 1 0 110-2H8V5.5a1 1 0 011-1z'
    ],
    viewbox: '0 0 18 18',
    width: '18',
    colors: [],
    fillRule: '',
    height: '18'
  },
  switch: {
    path: [
      'M0 8a8 8 0 018-8h6a8 8 0 110 16H8a8 8 0 01-8-8zm8-6a6 6 0 100 12h6a6 6 0 000-12H8zm2 6a4 4 0 118 0 4 4 0 01-8 0zm4-2a2 2 0 100 4 2 2 0 000-4z'
    ],
    viewbox: '0 0 22 16',
    width: '22',
    colors: [],
    fillRule: '',
    height: '16'
  },
  broken: {
    path: [
      'M5 0a1 1 0 011 1v2a1 1 0 01-2 0V1a1 1 0 011-1zm8.435 2.697a2 2 0 00-2.828 0L8.485 4.818a1 1 0 11-1.414-1.414l2.121-2.121a4 4 0 015.657 5.656l-2.121 2.122a1 1 0 11-1.414-1.414l2.121-2.122a2 2 0 000-2.828zM0 5a1 1 0 011-1h2a1 1 0 010 2H1a1 1 0 01-1-1zm4.95 1.94a1 1 0 010 1.414l-2.122 2.121a2 2 0 102.829 2.828l2.121-2.12a1 1 0 111.414 1.413l-2.121 2.122A4 4 0 011.414 9.06l2.121-2.122a1 1 0 011.415 0zM12 11a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1zm-1 1a1 1 0 011 1v2a1 1 0 11-2 0v-2a1 1 0 011-1z'
    ],
    viewbox: '0 0 16 16',
    width: '16',
    colors: [],
    fillRule: '',
    height: '16'
  },
  avatar_large: {
    path: [
      'M20 2C10.059 2 2 10.059 2 20c0 3.771 1.159 7.27 3.14 10.161A20.937 20.937 0 0120 24c5.804 0 11.06 2.356 14.86 6.161A17.913 17.913 0 0038 20c0-9.941-8.059-18-18-18zm13.627 29.76A18.94 18.94 0 0020 26a18.94 18.94 0 00-13.628 5.76A17.957 17.957 0 0020 38a17.957 17.957 0 0013.627-6.24zM0 20C0 8.954 8.954 0 20 0s20 8.954 20 20c0 4.654-1.591 8.94-4.258 12.338C32.082 37 26.392 40 20 40c-6.391 0-12.082-2.999-15.742-7.662A19.918 19.918 0 010 20zm20-9a4 4 0 100 8 4 4 0 000-8zm-6 4a6 6 0 1112 0 6 6 0 01-12 0z'
    ],
    viewbox: '0 0 40 40',
    width: '40',
    colors: [],
    fillRule: '',
    height: '40'
  },
  avatar_medium: {
    path: [
      'M15 2C7.82 2 2 7.82 2 15c0 2.939.974 5.648 2.618 7.825A15.94 15.94 0 0115 19a15.94 15.94 0 0110.382 3.825A12.939 12.939 0 0028 15c0-7.18-5.82-13-13-13zm9.058 22.325A13.94 13.94 0 0015 21a13.94 13.94 0 00-9.058 3.325A12.954 12.954 0 0015 28c3.522 0 6.716-1.4 9.058-3.675zM0 15C0 6.716 6.716 0 15 0c8.284 0 15 6.716 15 15 0 3.811-1.423 7.292-3.764 9.938A14.966 14.966 0 0115 30c-4.473 0-8.49-1.959-11.236-5.062A14.947 14.947 0 010 15zm15-6a3 3 0 100 6 3 3 0 000-6zm-5 3a5 5 0 1110 0 5 5 0 01-10 0z'
    ],
    viewbox: '0 0 30 30',
    width: '30',
    colors: [],
    fillRule: '',
    height: '30'
  },
  avatar_small: {
    path: [
      'M10 2a8 8 0 00-5.714 13.599A10.953 10.953 0 0110 14c2.091 0 4.048.585 5.714 1.599A8 8 0 0010 2zm3.995 14.933A8.96 8.96 0 0010 16a8.96 8.96 0 00-3.995.933A7.96 7.96 0 0010 18a7.96 7.96 0 003.995-1.067zM0 10C0 4.477 4.477 0 10 0s10 4.477 10 10a9.98 9.98 0 01-3.534 7.629A9.965 9.965 0 0110 20a9.965 9.965 0 01-6.466-2.371A9.98 9.98 0 010 10zm10-3a2 2 0 100 4 2 2 0 000-4zM6 9a4 4 0 118 0 4 4 0 01-8 0z'
    ],
    viewbox: '0 0 20 20',
    width: '20',
    colors: [],
    fillRule: '',
    height: '20'
  },
  avatar_business_large: {
    path: [
      'M0 6a6 6 0 016-6h28a6 6 0 016 6v28a6 6 0 01-6 6H6a6 6 0 01-6-6V6zm6-4a4 4 0 00-4 4v28a4 4 0 004 4h28a4 4 0 004-4V6a4 4 0 00-4-4H6z M9 13a3 3 0 013-3h6a3 3 0 013 3v4h7a3 3 0 013 3v6a3 3 0 01-3 3H12a3 3 0 01-3-3V13zm5 14h2v-1.5h-2V27zm4 0v-2.5a1 1 0 00-1-1h-4a1 1 0 00-1 1V27a1 1 0 01-1-1V13a1 1 0 011-1h6a1 1 0 011 1v14h-1zm3 0h7a1 1 0 001-1v-6a1 1 0 00-1-1h-7v8zm-9-13a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1H13a1 1 0 01-1-1V14zm3.5 0a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1h-.5a1 1 0 01-1-1V14zM12 17.5a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1H13a1 1 0 01-1-1v-.5zm3.5 0a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1h-.5a1 1 0 01-1-1v-.5zM12 21a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1H13a1 1 0 01-1-1V21zm3.5 0a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1h-.5a1 1 0 01-1-1V21zm6.5 0a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1H23a1 1 0 01-1-1V21zm3.5 0a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1h-.5a1 1 0 01-1-1V21zM22 24.5a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1H23a1 1 0 01-1-1v-.5zm3.5 0a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1h-.5a1 1 0 01-1-1v-.5z'
    ],
    viewbox: '0 0 40 40',
    width: '40',
    colors: [],
    fillRule: 'evenodd',
    height: '40'
  },
  avatar_business_medium: {
    path: [
      'M0 6a6 6 0 016-6h18a6 6 0 016 6v18a6 6 0 01-6 6H6a6 6 0 01-6-6V6zm6-4a4 4 0 00-4 4v18a4 4 0 004 4h18a4 4 0 004-4V6a4 4 0 00-4-4H6z M6 10a3 3 0 013-3h6a3 3 0 013 3v.5h3.5a3 3 0 013 3v6a3 3 0 01-3 3H9a3 3 0 01-3-3V10zm5 10.5h2V19h-2v1.5zm4 0V18a1 1 0 00-1-1h-4a1 1 0 00-1 1v2.5a1 1 0 01-1-1V10a1 1 0 011-1h6a1 1 0 011 1v10.5h-1zm3 0h3.5a1 1 0 001-1v-6a1 1 0 00-1-1H18v8zM9 11a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1H10a1 1 0 01-1-1V11zm3.5 0a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1h-.5a1 1 0 01-1-1V11zM9 14.5a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1H10a1 1 0 01-1-1v-.5zm3.5 0a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1h-.5a1 1 0 01-1-1v-.5zm6.5 0a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1H20a1 1 0 01-1-1v-.5zm0 3.5a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1H20a1 1 0 01-1-1V18z'
    ],
    viewbox: '0 0 30 30',
    width: '30',
    colors: [],
    fillRule: 'evenodd',
    height: '30'
  },
  avatar_business_small: {
    path: [
      'M0 6a6 6 0 016-6h8a6 6 0 016 6v8a6 6 0 01-6 6H6a6 6 0 01-6-6V6zm6-4a4 4 0 00-4 4v8a4 4 0 004 4h8a4 4 0 004-4V6a4 4 0 00-4-4H6z M4 7a3 3 0 013-3h2.5a3 3 0 013 3v.5h.5a3 3 0 013 3V13a3 3 0 01-3 3H7a3 3 0 01-3-3V7zm5.5 7v-2.5a1 1 0 00-1-1H8a1 1 0 00-1 1V14a1 1 0 01-1-1V7a1 1 0 011-1h2.5a1 1 0 011 1v1.5a1 1 0 001 1H13a1 1 0 011 1V13a1 1 0 01-1 1H9.5zM7 8a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1H8a1 1 0 01-1-1V8zm3.5 3.5a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1h-.5a1 1 0 01-1-1v-.5z'
    ],
    viewbox: '0 0 20 20',
    width: '20',
    colors: [],
    fillRule: 'evenodd',
    height: '20'
  },
  desktop_windows: {
    path: [
      'M0 1a1 1 0 011-1h6.5a1 1 0 011 1v6.5a1 1 0 01-1 1H1a1 1 0 01-1-1V1zm2 1v4.5h4.5V2H2zm7.5-1a1 1 0 011-1H17a1 1 0 011 1v6.5a1 1 0 01-1 1h-6.5a1 1 0 01-1-1V1zm2 1v4.5H16V2h-4.5zM0 10.5a1 1 0 011-1h6.5a1 1 0 011 1V17a1 1 0 01-1 1H1a1 1 0 01-1-1v-6.5zm2 1V16h4.5v-4.5H2zm7.5-1a1 1 0 011-1H17a1 1 0 011 1V17a1 1 0 01-1 1h-6.5a1 1 0 01-1-1v-6.5zm2 1V16H16v-4.5h-4.5z'
    ],
    viewbox: '0 0 18 18',
    width: '18',
    colors: [],
    fillRule: '',
    height: '18'
  },
  desktop_apple: {
    path: [
      'M8.57 2H3a1 1 0 00-1 1v12a1 1 0 001 1h7.754a44.021 44.021 0 01-.298-1.611c-.446.07-.932.111-1.456.111-1.673 0-2.95-.42-3.822-.856a6.542 6.542 0 01-.997-.61 4.593 4.593 0 01-.372-.312l-.01-.008-.003-.004-.002-.001v-.001L4.5 12l-.707.707a1 1 0 011.408-1.42h4.871c-.02-.262-.036-.524-.048-.787H8a1 1 0 01-1-1c0-1.9.536-4.14 1.041-5.847.185-.622.369-1.186.53-1.653zm1.503 9.29L5.2 11.286v.001l.028.025c.029.025.08.068.152.122.145.11.376.264.691.422.628.313 1.6.644 2.928.644.43 0 .823-.035 1.179-.092a31.19 31.19 0 01-.106-1.118zm2.048.464c-.015-.154-.03-.308-.042-.463h.715l-.023.02c-.029.026-.08.069-.152.123-.114.086-.28.199-.498.32zm.272 2.088A41.843 41.843 0 0012.798 16H15a1 1 0 001-1V3a1 1 0 00-1-1h-4.309l-.062.173a39.09 39.09 0 00-.67 2.049c-.396 1.335-.773 2.89-.907 4.278H11a1 1 0 011 1c0 .586.03 1.189.079 1.786h.72v.001a.301.301 0 00-.005.005l.001-.002.002-.002.002-.002h.001a1 1 0 011.407 1.421L13.5 12c.707.707.707.707.706.708l-.002.002-.004.004-.009.008a2.076 2.076 0 01-.103.095 4.607 4.607 0 01-.27.217c-.23.173-.561.393-.996.61a7.46 7.46 0 01-.428.198zM11.979 18H15a3 3 0 003-3V3a3 3 0 00-3-3H3a3 3 0 00-3 3v12a3 3 0 003 3h8.978zM5 4.5a1 1 0 011 1V6a1 1 0 01-2 0v-.5a1 1 0 011-1zm8 0a1 1 0 011 1V6a1 1 0 11-2 0v-.5a1 1 0 011-1z'
    ],
    viewbox: '0 0 18 18',
    width: '18',
    colors: [],
    height: '18',
    fillRule: 'evenodd'
  },
  phone_android: {
    path: [
      'M0 3a3 3 0 013-3h6a3 3 0 013 3v12a3 3 0 01-3 3H3a3 3 0 01-3-3V3zm3-1a1 1 0 00-1 1v.5h8V3a1 1 0 00-1-1H3zm7 3.5H2v7h8v-7zm0 9H2v.5a1 1 0 001 1h6a1 1 0 001-1v-.5z'
    ],
    viewbox: '0 0 12 18',
    width: '12',
    colors: [],
    fillRule: '',
    height: '18'
  },
  phone_apple: {
    path: [
      'M3 2a1 1 0 00-1 1v12a1 1 0 001 1h6a1 1 0 001-1V3a1 1 0 00-1-1h-.882l-.224.447A1 1 0 017 3H5a1 1 0 01-.894-.553L3.882 2H3zM0 3a3 3 0 013-3h6a3 3 0 013 3v12a3 3 0 01-3 3H3a3 3 0 01-3-3V3z'
    ],
    viewbox: '0 0 12 18',
    width: '12',
    colors: [],
    fillRule: '',
    height: '18'
  },
  arrow_small: {
    path: [
      'M.646.646a.5.5 0 01.708 0L4.5 3.793 7.646.646a.5.5 0 11.708.708l-3.5 3.5a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 010-.708z'
    ],
    viewbox: '0 0 8 5',
    width: '8',
    colors: [],
    fillRule: '',
    height: '5'
  },
  profile_nav: {
    path: [
      'M13 2C6.925 2 2 6.925 2 13c0 2.66.943 5.098 2.514 7 2.017-2.442 5.07-4 8.486-4a10.98 10.98 0 018.486 4A10.952 10.952 0 0024 13c0-6.075-4.925-11-11-11zm7.069 19.428A8.983 8.983 0 0013 18a8.983 8.983 0 00-7.069 3.428A10.952 10.952 0 0013 24c2.692 0 5.157-.966 7.069-2.572zM0 13C0 5.82 5.82 0 13 0s13 5.82 13 13c0 3.637-1.495 6.926-3.9 9.285A12.961 12.961 0 0113 26a12.961 12.961 0 01-9.1-3.715A12.964 12.964 0 010 13zm13-6a3 3 0 100 6 3 3 0 000-6zm-5 3a5 5 0 1110 0 5 5 0 01-10 0z'
    ],
    viewbox: '0 0 26 26',
    width: '26',
    colors: [],
    fillRule: '',
    height: '26'
  },
  cards_nav: {
    path: [
      'M0 3a3 3 0 013-3h14a3 3 0 013 3v18a3 3 0 01-3 3H3a3 3 0 01-3-3V3zm3-1a1 1 0 00-1 1v18a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1H3zm1 8a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zm0 4a1 1 0 011-1h6a1 1 0 110 2H5a1 1 0 01-1-1z M4 5.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z'
    ],
    viewbox: '0 0 20 24',
    width: '20',
    colors: [],
    fillRule: '',
    height: '24'
  },
  events_nav: {
    path: [
      'M6 2a1 1 0 00-1 1v2.5a1 1 0 01-2 0V3a3 3 0 013-3h14a3 3 0 013 3v18a3 3 0 01-3 3H6a3 3 0 01-3-3v-2.5a1 1 0 112 0V21a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1H6zm4.793 5.793a1 1 0 011.414 0l3.5 3.5a1 1 0 010 1.414l-3.5 3.5a1 1 0 01-1.414-1.414L12.586 13H7.874A4.002 4.002 0 010 12a4 4 0 017.874-1h4.712l-1.793-1.793a1 1 0 010-1.414zM4 10a2 2 0 100 4 2 2 0 000-4z'
    ],
    viewbox: '0 0 23 24',
    width: '23',
    colors: [],
    fillRule: '',
    height: '24'
  },
  settings_nav: {
    path: [
      'M8.996 2a2 2 0 012-2h4a2 2 0 012 2v1.78c0 .003.003.006.007.012a.14.14 0 00.058.045 9.988 9.988 0 011.848 1.059c.025.018.05.025.066.026h.013l1.535-.886a2 2 0 012.732.732l2 3.464a2 2 0 01-.732 2.732l-1.514.874a.045.045 0 00-.007.011.14.14 0 00-.01.072 10.103 10.103 0 010 2.158.138.138 0 00.01.072c.003.006.005.01.007.01l1.514.875a2 2 0 01.732 2.732l-2 3.464a2 2 0 01-2.732.732l-1.535-.886h-.013a.137.137 0 00-.066.027 9.99 9.99 0 01-1.848 1.058.14.14 0 00-.058.045.044.044 0 00-.007.011V24a2 2 0 01-2 2h-4a2 2 0 01-2-2v-1.828a.045.045 0 00-.006-.011.138.138 0 00-.056-.045 9.992 9.992 0 01-1.79-1.045.137.137 0 00-.067-.028h-.013l-1.594.921a2 2 0 01-2.732-.732l-2-3.464a2 2 0 01.732-2.732l1.614-.932a.044.044 0 00.007-.01.138.138 0 00.01-.072 10.106 10.106 0 010-2.044.138.138 0 00-.01-.071.042.042 0 00-.007-.01l-1.614-.933a2 2 0 01-.732-2.732l2-3.464a2 2 0 012.732-.732l1.594.92h.013a.137.137 0 00.067-.027 9.992 9.992 0 011.79-1.045.138.138 0 00.056-.045.043.043 0 00.006-.011V2zm0 1.827zM7.064 4.956h.001zm-3.979 6.94zm3.98 9.148zM18.988 4.922zM16.996 3.78v.001-.001zm-2-1.78h-4v1.829c0 .858-.538 1.56-1.238 1.877-.508.23-.987.51-1.432.837-.626.458-1.512.578-2.261.146L4.47 5.768l-2 3.464 1.615.932c.744.43 1.084 1.25 1.006 2.016a8.106 8.106 0 000 1.64c.078.766-.262 1.586-1.006 2.016l-1.615.932-.5-.866 2.5 4.33 1.595-.92c.75-.433 1.635-.313 2.261.145.445.326.924.607 1.432.837.7.316 1.238 1.02 1.238 1.877V24h4v-1.782c0-.867.55-1.575 1.262-1.887a7.985 7.985 0 001.478-.846c.625-.453 1.506-.57 2.251-.14l1.536.887 2-3.464-1.515-.875c-.748-.432-1.087-1.257-1.005-2.028a8.1 8.1 0 000-1.73c-.082-.77.257-1.596 1.005-2.028l1.515-.875-2-3.464-1.536.886c-.745.43-1.626.314-2.251-.139a7.99 7.99 0 00-1.478-.846c-.712-.312-1.262-1.02-1.262-1.887V2zm-2 9a2 2 0 100 4 2 2 0 000-4zm-4 2a4 4 0 118 0 4 4 0 01-8 0z'
    ],
    viewbox: '0 0 25 26',
    width: '25',
    colors: [],
    fillRule: '',
    height: '26'
  },
  logo: {
    path: [
      'M6.293 16.213L12.47 5.514a3.027 3.027 0 115.245 3.028l-3.497 6.064a3.22 3.22 0 01-2.791 1.611l-5.134-.004z',
      'M19.578 8.76l6.177 10.699a3.027 3.027 0 11-5.244 3.027l-3.503-6.06a3.22 3.22 0 010-3.223l2.57-4.443z',
      'M19.381 23.975H7.027a3.027 3.027 0 010-6.056l7-.003a3.22 3.22 0 012.791 1.612l2.563 4.447z'
    ],
    viewbox: '0 0 30 30',
    width: '30',
    colors: ['#FF395A', '#FFC7D7', '#246CFF'],
    fillRule: '',
    height: '30'
  },
  hide: {
    path: [
      'M2.293.293a1 1 0 011.414 0l5.261 5.26a4 4 0 015.478 5.478l5.261 5.262a1 1 0 01-1.414 1.414L16 15.415C14.55 16.32 12.85 17 11 17c-2.644 0-4.987-1.386-6.748-2.863-1.78-1.49-3.095-3.184-3.703-4.03a1.887 1.887 0 010-2.214c.615-.856 1.972-2.603 3.81-4.12L2.293 1.707a1 1 0 010-1.414zM5.78 5.195C4.106 6.54 2.828 8.156 2.216 9c.578.797 1.762 2.298 3.32 3.605C7.154 13.96 9.047 15 11 15c1.218 0 2.415-.404 3.542-1.043l-1.51-1.51a4 4 0 01-5.478-5.478L5.78 5.194zm3.287 3.287a2.003 2.003 0 002.45 2.45l-2.45-2.45zm3.864 1.036a2.002 2.002 0 00-2.45-2.45l2.45 2.45zM11 3c-.58 0-1.154.091-1.72.256a1 1 0 11-.56-1.92A8.123 8.123 0 0111 1c2.644 0 4.986 1.386 6.748 2.863 1.779 1.49 3.095 3.184 3.703 4.03a1.887 1.887 0 010 2.214 23.868 23.868 0 01-2.161 2.588 1 1 0 11-1.44-1.39A21.911 21.911 0 0019.785 9c-.578-.797-1.762-2.298-3.32-3.605C14.846 4.04 12.953 3 11 3z'
    ],
    viewbox: '0 0 22 18',
    width: '22',
    colors: [],
    fillRule: '',
    height: '18'
  },
  show: {
    path: [
      'M2.216 8c.578.797 1.762 2.298 3.32 3.605C7.154 12.96 9.047 14 11 14c1.954 0 3.847-1.04 5.463-2.395 1.56-1.307 2.743-2.808 3.32-3.605-.577-.797-1.76-2.298-3.32-3.605C14.847 3.04 12.954 2 11 2 9.046 2 7.153 3.04 5.537 4.395 3.977 5.702 2.794 7.203 2.217 8zm2.036-5.137C6.013 1.386 8.356 0 11 0s4.986 1.386 6.748 2.863c1.779 1.49 3.095 3.184 3.703 4.03a1.887 1.887 0 010 2.214c-.608.846-1.924 2.54-3.703 4.03C15.986 14.614 13.644 16 11 16s-4.987-1.386-6.748-2.863c-1.78-1.49-3.095-3.184-3.703-4.03a1.887 1.887 0 010-2.214c.608-.846 1.924-2.54 3.703-4.03zM7 8a4 4 0 118 0 4 4 0 01-8 0zm4-2a2 2 0 100 4 2 2 0 000-4z'
    ],
    viewbox: '0 0 22 16',
    width: '22',
    colors: [],
    fillRule: '',
    height: '16'
  },
  subview: {
    path: [
      'M0 3a3 3 0 013-3h8a3 3 0 013 3v12a3 3 0 01-3 3H3a3 3 0 01-3-3V3zm3-1a1 1 0 00-1 1v1h10V3a1 1 0 00-1-1H3zm9 4H2v9a1 1 0 001 1h8a1 1 0 001-1V6z'
    ],
    viewbox: '0 0 14 18',
    width: '14',
    colors: [],
    fillRule: '',
    height: '18'
  },
  duplicate: {
    path: [
      'M4 2a2 2 0 012-2h10a2 2 0 012 2v10a2 2 0 01-2 2H6a2 2 0 01-2-2V2zm12 0H6v10h10V2zM1 5a1 1 0 011 1v10h10a1 1 0 110 2H2a2 2 0 01-2-2V6a1 1 0 011-1z'
    ],
    viewbox: '0 0 18 18',
    width: '18',
    colors: [],
    fillRule: '',
    height: '18'
  },
  send: {
    path: [
      'M17.734.32a1 1 0 01.215.996l-4 12a1 1 0 01-1.504.516L10 12.202l-2.445 1.63A1 1 0 016 13V9.535L.445 5.832A1 1 0 01.758 4.03l16-4a1 1 0 01.976.29zM8 10.869v.264L8.197 11 8 10.868zM3.436 5.422l3.51 2.34 5.851-4.68-9.361 2.34zM14.98 3.896l-2.515 7.546-3.772-2.515 6.287-5.03z'
    ],
    viewbox: '0 0 18 14',
    width: '18',
    colors: [],
    fillRule: 'evenodd',
    height: '14'
  },
  negative: {
    path: [
      'M9.02 0a9 9 0 100 18 9 9 0 000-18zm-7 9a7 7 0 1114 0 7 7 0 01-14 0zm8-3a1 1 0 00-2 0v3a1 1 0 102 0V6zm-1 7a1 1 0 100-2 1 1 0 000 2z'
    ],
    viewbox: '0 0 18 18',
    width: '18',
    colors: [],
    fillRule: '',
    height: '18'
  },
  positive: {
    path: [
      'M.02 9a9 9 0 1118 0 9 9 0 01-18 0zm9-7a7 7 0 100 14 7 7 0 000-14zm3.708 4.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-2-2a1 1 0 111.414-1.414L8.02 9.586l3.293-3.293a1 1 0 011.414 0z'
    ],
    viewbox: '0 0 18 18',
    width: '18',
    colors: [],
    fillRule: '',
    height: '18'
  },
  key: {
    path: [
      'M5.02 2a3 3 0 000 6 4.975 4.975 0 01-1-3c0-1.126.373-2.164 1-3zm2-1.584a5 5 0 00-4.158 9.095l-1.811 7.246a1 1 0 00.727 1.213l2 .5a1 1 0 10.485-1.94l-1.03-.258.515-2.06 1.03.258a1 1 0 10.485-1.94l-1.03-.258.57-2.277a4.986 4.986 0 002.218-.412 4.983 4.983 0 002.218.412l1.812 7.248a1 1 0 001.212.727l2-.5a1 1 0 10-.485-1.94l-1.03.257-.515-2.06 1.03-.257a1 1 0 10-.485-1.94l-1.03.257-.569-2.276A5 5 0 007.02.416zM6.02 5a3 3 0 106 0 3 3 0 00-6 0z'
    ],
    viewbox: '0 0 15 19',
    width: '15',
    colors: [],
    fillRule: 'evenodd',
    height: '19'
  },
  details: {
    path: [
      'M0 3a3 3 0 013-3h6a3 3 0 013 3v.5h3.5a3 3 0 013 3v6a3 3 0 01-3 3H3a3 3 0 01-3-3V3zm5 10.5h2V12H5v1.5zm4 0V11a1 1 0 00-1-1H4a1 1 0 00-1 1v2.5a1 1 0 01-1-1V3a1 1 0 011-1h6a1 1 0 011 1v10.5H9zm3 0h3.5a1 1 0 001-1v-6a1 1 0 00-1-1H12v8zM3 4a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1H4a1 1 0 01-1-1V4zm3.5 0a1 1 0 011-1H8a1 1 0 011 1v.5a1 1 0 01-1 1h-.5a1 1 0 01-1-1V4zM3 7.5a1 1 0 011-1h.5a1 1 0 011 1V8a1 1 0 01-1 1H4a1 1 0 01-1-1v-.5zm3.5 0a1 1 0 011-1H8a1 1 0 011 1V8a1 1 0 01-1 1h-.5a1 1 0 01-1-1v-.5zm6.5 0a1 1 0 011-1h.5a1 1 0 011 1V8a1 1 0 01-1 1H14a1 1 0 01-1-1v-.5zm0 3.5a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1H14a1 1 0 01-1-1V11z'
    ],
    viewbox: '0 0 19 16',
    width: '19',
    colors: [],
    fillRule: 'evenodd',
    height: '16'
  },
  padlock: {
    path: [
      'M3 4a4 4 0 118 0v2a3 3 0 013 3v6a3 3 0 01-3 3H3a3 3 0 01-3-3V9a3 3 0 013-3V4zm2 2h4V4a2 2 0 10-4 0v2zM3 8a1 1 0 00-1 1v6a1 1 0 001 1h8a1 1 0 001-1V9a1 1 0 00-1-1H3z M7 13.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z'
    ],
    viewbox: '0 0 14 18',
    width: '14',
    colors: [],
    fillRule: '',
    height: '18'
  },
  unlock: {
    path: [
      'M4 2a2 2 0 00-2 2v3a1 1 0 01-2 0V4a4 4 0 118 0v2h6a3 3 0 013 3v6a3 3 0 01-3 3H6a3 3 0 01-3-3V9a3 3 0 013-3V4a2 2 0 00-2-2zm2 6a1 1 0 00-1 1v6a1 1 0 001 1h8a1 1 0 001-1V9a1 1 0 00-1-1H6z M10 13.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z'
    ],
    viewbox: '0 0 17 18',
    width: '17',
    colors: [],
    fillRule: '',
    height: '18'
  },
  container: {
    path: [
      'M10 19a1 1 0 102 0h-2zm-6 0a1 1 0 102 0H4zM3 2h10V0H3v2zm11 1v14h2V3h-2zm-1 15H3v2h10v-2zM2 17V3H0v14h2zm1 1a1 1 0 01-1-1H0a3 3 0 003 3v-2zm11-1a1 1 0 01-1 1v2a3 3 0 003-3h-2zM13 2a1 1 0 011 1h2a3 3 0 00-3-3v2zM3 0a3 3 0 00-3 3h2a1 1 0 011-1V0zm3 6h4V4H6v2zm4 0v4h2V6h-2zm0 4H6v2h4v-2zm-4 0V6H4v4h2zm0 0H4a2 2 0 002 2v-2zm4 0v2a2 2 0 002-2h-2zm0-4h2a2 2 0 00-2-2v2zM6 4a2 2 0 00-2 2h2V4zm0 12h4v-2H6v2zm4 0v3h2v-3h-2zm-4 3v-3H4v3h2zm4-3h2a2 2 0 00-2-2v2zm-4-2a2 2 0 00-2 2h2v-2z'
    ],
    viewbox: '0 0 16 20',
    width: '16',
    colors: [],
    fillRule: '',
    height: '20'
  },
  bell: {
    path: [
      'M9 2.5a5 5 0 00-5 5V9c0 1.264-.309 2.252-.72 3h11.44c-.41-.748-.72-1.736-.72-3V7.5a5 5 0 00-5-5zM10.5 14h-3c0 .523.17.88.395 1.105.224.225.582.395 1.105.395s.88-.17 1.105-.395c.225-.224.395-.582.395-1.105zm-5 0H1a1 1 0 01-.45-1.893H.55l-.003.002-.005.003a1.818 1.818 0 00.171-.115c.132-.098.318-.26.507-.497C1.588 11.04 2 10.257 2 9V7.5a7 7 0 1114 0V9c0 1.257.413 2.04.781 2.5.19.237.375.399.507.497a1.818 1.818 0 00.171.115A1 1 0 0117 14h-4.5c0 .977-.33 1.87-.98 2.52-.65.65-1.543.98-2.52.98-.977 0-1.87-.33-2.52-.98-.65-.65-.98-1.543-.98-2.52zM.55 12.107zm.002 0zM1 13a92.144 92.144 0 00-.447-.894L1 13z'
    ],
    viewbox: '0 0 18 18',
    width: '18',
    colors: [],
    fillRule: '',
    height: '18'
  },
  theme: {
    path: [
      'M3.5 2a2 2 0 012-2H17a2 2 0 012 2v1h.5a1 1 0 110 2H19v1a2 2 0 01-2 2H5.5a2 2 0 01-2-2v-.872A2.25 2.25 0 004.25 9.5h5.84A1.91 1.91 0 0112 11.41V12a2 2 0 012 2v4a2 2 0 01-2 2h-2a2 2 0 01-2-2v-4a2 2 0 012-2v-.5H4.25a4.25 4.25 0 01-.75-8.434V2zM17 2H5.5v4H17V2zm-7 12v4h2v-4h-2z'
    ],
    viewbox: '0 0 21 20',
    width: '21',
    colors: [],
    fillRule: '',
    height: '20'
  },
  webhook: {
    path: [
      'M11.026 3.18a3 3 0 00-2.527 5.418 1 1 0 01.367 1.367l-3 5.195a1 1 0 01-1.732-1l2.55-4.416a5 5 0 118.24-2.875 1 1 0 11-1.969-.35 3.001 3.001 0 00-1.929-3.338zM9.5 5.135a1 1 0 011.366.366l2.55 4.416a5 5 0 11-1.631 8.574 1 1 0 111.287-1.53 3.001 3.001 0 10.428-4.9 1 1 0 01-1.367-.365l-3-5.195A1 1 0 019.5 5.134zM4.57 10.56a1 1 0 01-.599 1.28 3.001 3.001 0 104.029 2.821 1 1 0 011-1H15a1 1 0 010 2H9.9a5 5 0 11-6.61-5.7 1 1 0 011.281.599z'
    ],
    viewbox: '0 0 20 20',
    width: '20',
    colors: [],
    fillRule: '',
    height: '20'
  },
  external: {
    path: [
      'M15 0a1 1 0 011 1v5a1 1 0 11-2 0V3.414L8.707 8.707a1 1 0 01-1.414-1.414L12.586 2H10a1 1 0 010-2h5zm1 10v3a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3h3a1 1 0 110 2H3a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1v-3a1 1 0 112 0z'
    ],
    viewbox: '0 0 16 16',
    width: '16',
    colors: [],
    fillRule: '',
    height: '16'
  },
  download: {
    path: [
      'M17.207 18.793a1 1 0 010 1.414l-3.5 3.5a1 1 0 01-1.414 0l-3.5-3.5a1 1 0 111.414-1.414L12 20.586V12a1 1 0 112 0v8.586l1.793-1.793a1 1 0 011.414 0z M7.562 7.249a7 7 0 0113.422 2.277A4 4 0 0119 17h-2a1 1 0 110-2h2a2 2 0 00.665-3.887 1 1 0 01-.667-.97L19 10a5 5 0 00-9.87-1.142 1 1 0 01-1.513.614A3 3 0 106 15h3.157a1 1 0 110 2H6a5 5 0 111.562-9.751z'
    ],
    viewbox: '0 0 24 24',
    width: '24',
    colors: [],
    fillRule: '',
    height: '24'
  },
  upload: {
    path: [
      'M7.562 7.249a7 7 0 0113.422 2.277A4 4 0 0119 17h-1.5a1 1 0 110-2H19a2 2 0 00.665-3.887 1 1 0 01-.667-.97L19 10a5 5 0 00-9.87-1.142 1 1 0 01-1.513.614A3 3 0 106 15h3.5a1 1 0 110 2H6a5 5 0 111.562-9.751z M17.707 13.207a1 1 0 000-1.414l-3.5-3.5a1 1 0 00-1.414 0l-3.5 3.5a1 1 0 101.414 1.414l1.793-1.793V20a1 1 0 102 0v-8.586l1.793 1.793a1 1 0 001.414 0z'
    ],
    viewbox: '0 0 24 24',
    width: '24',
    colors: [],
    fillRule: '',
    height: '24'
  },
  two_step: {
    path: [
      'M0 3a3 3 0 013-3h1a1 1 0 010 2H3a1 1 0 00-1 1v1a1 1 0 01-2 0V3zm15-2a1 1 0 011-1h1a3 3 0 013 3v1a1 1 0 11-2 0V3a1 1 0 00-1-1h-1a1 1 0 01-1-1zM1 15a1 1 0 011 1v1a1 1 0 001 1h1a1 1 0 110 2H3a3 3 0 01-3-3v-1a1 1 0 011-1zm18 0a1 1 0 011 1v1a3 3 0 01-3 3h-1a1 1 0 110-2h1a1 1 0 001-1v-1a1 1 0 011-1zM7 6a3 3 0 016 0v1.17c1.165.413 2 1.524 2 2.83v3a3 3 0 01-3 3H8a3 3 0 01-3-3v-3c0-1.306.835-2.417 2-2.83V6zm2 1h2V6a1 1 0 10-2 0v1zM8 9a1 1 0 00-1 1v3a1 1 0 001 1h4a1 1 0 001-1v-3a1 1 0 00-1-1H8z M10 13a1.5 1.5 0 100-3 1.5 1.5 0 000 3z'
    ],
    viewbox: '0 0 20 20',
    width: '20',
    colors: [],
    fillRule: 'evenodd',
    height: '20'
  },
  hash: {
    path: [
      'M7.316.051a1 1 0 01.633 1.265L7.387 3h3.892l.772-2.316a1 1 0 011.898.632L13.387 3H15a1 1 0 110 2h-2.28l-1.333 4H13a1 1 0 110 2h-2.28l-.771 2.316a1 1 0 11-1.898-.632L8.613 11H4.72l-.772 2.316a1 1 0 11-1.898-.632L2.613 11H1a1 1 0 110-2h2.28l1.333-4H3V3h2.28L6.05.684A1 1 0 017.316.05zM3 4V3a1 1 0 100 2V4zm3.72 1L5.388 9H9.28l1.334-4H6.72z'
    ],
    viewbox: '0 0 16 14',
    width: '16',
    colors: [],
    fillRule: '',
    height: '14'
  },
  none: {
    path: [''],
    viewbox: '0 0 0 0',
    width: '0',
    colors: [],
    fillRule: '',
    height: '0'
  },
  navigate_button: {
    path: [
      'M7 5a3 3 0 013-3h8a3 3 0 013 3v12a3 3 0 01-3 3h-8a3 3 0 01-3-3v-2a1 1 0 112 0v2a1 1 0 001 1h8a1 1 0 001-1V5a1 1 0 00-1-1h-8a1 1 0 00-1 1v2a1 1 0 01-2 0V5zM0 7a3 3 0 013-3h1a1 1 0 010 2H3a1 1 0 00-1 1v8a1 1 0 001 1h1a1 1 0 110 2H3a3 3 0 01-3-3V7zm11.793-.207a1 1 0 011.414 0l3.5 3.5a1 1 0 010 1.414l-3.5 3.5a1 1 0 01-1.414-1.414L13.586 12H5a1 1 0 110-2h8.586l-1.793-1.793a1 1 0 010-1.414z'
    ],
    viewbox: '0 0 22 22',
    width: '22',
    colors: [],
    fillRule: '',
    height: '22'
  },
  submit_button: {
    path: [
      'M6 7a3 3 0 013-3h8a3 3 0 013 3v12a3 3 0 01-3 3H9a3 3 0 01-3-3v-1a1 1 0 112 0v1a1 1 0 001 1h8a1 1 0 001-1V7a1 1 0 00-1-1H9a1 1 0 00-1 1v1a1 1 0 01-2 0V7z',
      'M10.793 8.793a1 1 0 011.414 0l3.5 3.5a1 1 0 010 1.414l-3.5 3.5a1 1 0 01-1.414-1.414L12.586 14H5.829a3.001 3.001 0 110-2h6.757l-1.793-1.793a1 1 0 010-1.414zM3 12a1 1 0 100 2 1 1 0 000-2z'
    ],
    viewbox: '0 0 26 26',
    width: '26',
    colors: [],
    fillRule: '',
    height: '26'
  },
  submit_navigate_button: {
    path: [
      'M7 5a3 3 0 013-3h8a3 3 0 013 3v12a3 3 0 01-3 3h-8a3 3 0 01-3-3 1 1 0 112 0 1 1 0 001 1h8a1 1 0 001-1V5a1 1 0 00-1-1h-8a1 1 0 00-1 1 1 1 0 01-2 0zM0 7a3 3 0 013-3h1a1 1 0 010 2H3a1 1 0 00-1 1v8a1 1 0 001 1h1a1 1 0 110 2H3a3 3 0 01-3-3V7zm11.793-.207a1 1 0 011.414 0l3.5 3.5a1 1 0 010 1.414l-3.5 3.5a1 1 0 01-1.414-1.414L13.586 12H9.829a3.001 3.001 0 110-2h3.757l-1.793-1.793a1 1 0 010-1.414zM7 10a1 1 0 100 2 1 1 0 000-2z'
    ],
    viewbox: '0 0 22 22',
    width: '22',
    colors: [],
    fillRule: '',
    height: '22'
  },
  split: {
    path: [
      'M16.207 2.793a1 1 0 10-1.414 1.414l.793.793h-.93a5 5 0 00-3.535 1.464L9.464 8.121a3 3 0 01-1.648.842 3.001 3.001 0 10.022 2.013 5 5 0 003.04-1.44l1.658-1.657A3 3 0 0114.655 7h.93l-.793.793a1 1 0 001.414 1.414l2.5-2.5a1 1 0 000-1.414l-2.5-2.5zM6 10a1 1 0 10-2 0 1 1 0 002 0z',
      'M16.207 10.793a1 1 0 00-1.414 1.414l.793.793h-.93a3 3 0 01-2.12-.879l-.329-.328a1 1 0 00-1.414 1.414l.328.329A5 5 0 0014.657 15h.929l-.793.793a1 1 0 001.414 1.414l2.5-2.5a1 1 0 000-1.414l-2.5-2.5z'
    ],
    viewbox: '0 0 20 20',
    width: '20',
    colors: [],
    fillRule: '',
    height: '20'
  },
  combine: {
    path: [
      'M5.838 2.025A3.001 3.001 0 000 3a3 3 0 005.816 1.037 3 3 0 011.648.842l1.657 1.657A5 5 0 0012.657 8h1.429l-.793.793a1 1 0 001.414 1.414l2.5-2.5a1 1 0 000-1.414l-2.5-2.5a1 1 0 10-1.414 1.414l.793.793h-1.43a3 3 0 01-2.12-.879L8.879 3.464a5 5 0 00-3.041-1.44zM3 2a1 1 0 100 2 1 1 0 000-2z',
      'M5.816 9.963a3.001 3.001 0 10.022 2.013 5 5 0 003.04-1.44l.33-.329a1 1 0 00-1.415-1.414l-.329.328a3 3 0 01-1.648.842zM3 10a1 1 0 100 2 1 1 0 000-2z'
    ],
    viewbox: '0 0 20 20',
    width: '20',
    colors: [],
    fillRule: '',
    height: '20'
  },
  click: {
    path: [
      'M8.5 2a4.5 4.5 0 00-4.33 5.728 1 1 0 11-1.925.544 6.5 6.5 0 1112.51 0 1 1 0 01-1.924-.544A4.507 4.507 0 008.5 2zM6 6.5a2.5 2.5 0 115 0V10h3a3 3 0 013 3v4a3 3 0 01-3 3H8.657a5 5 0 01-3.536-1.465l-3.578-3.578a2.768 2.768 0 113.914-3.914l.543.543V6.5zM8.5 6a.5.5 0 00-.5.5v6.293c0 1.336-1.616 2.006-2.56 1.06l-1.397-1.396a.768.768 0 00-1.086 1.086l3.579 3.578a3 3 0 002.12.879H14a1 1 0 001-1v-4a1 1 0 00-1-1h-3a2 2 0 01-2-2V6.5a.5.5 0 00-.5-.5z'
    ],
    viewbox: '0 0 17 20',
    width: '17',
    colors: [],
    fillRule: '',
    height: '20'
  },
  comment: {
    path: [
      'M9 2a6 6 0 00-5.282 8.848l.247.458-1.729 3.458 3.458-1.729.458.247A6 6 0 109 2zM1 8a8 8 0 114.678 7.28L3.13 16.552c-1.72.86-3.544-.964-2.684-2.684l1.274-2.546A7.974 7.974 0 011 8z'
    ],
    viewbox: '0 0 17 17',
    width: '17',
    colors: [],
    fillRule: '',
    height: '17'
  },
  expire: {
    path: [
      'M0 2a2 2 0 012-2h8a2 2 0 012 2v.597a6 6 0 01-2.252 4.685L7.601 9l2.522 2.018A5 5 0 0112 14.922V16a2 2 0 01-2 2H2a2 2 0 01-2-2v-1.078a5 5 0 011.877-3.904L4.399 9 2.252 7.282A6 6 0 010 2.597V2zm8.138 4.01l.36-.29A4 4 0 0010 2.597V2H2v.597A4 4 0 003.501 5.72l.362.29C3.907 6.002 3.953 6 4 6h4c.047 0 .093.003.138.01zM6 10.28l-2.874 2.3A3 3 0 002 14.922v.96l3.553-1.776a1 1 0 01.894 0L10 15.882v-.96a3 3 0 00-1.126-2.342L6 10.28z'
    ],
    viewbox: '0 0 12 18',
    width: '12',
    colors: [],
    fillRule: '',
    height: '18'
  },
  empty: {
    path: [
      'M0 9a9 9 0 1118 0A9 9 0 010 9zm9-7a7 7 0 100 14A7 7 0 009 2zM5.793 5.793a1 1 0 011.414 0L9 7.586l1.793-1.793a1 1 0 111.414 1.414L10.414 9l1.793 1.793a1 1 0 11-1.414 1.414L9 10.414l-1.793 1.793a1 1 0 01-1.414-1.414L7.586 9 5.793 7.207a1 1 0 010-1.414z'
    ],
    viewbox: '0 0 18 18',
    width: '18',
    colors: [],
    fillRule: '',
    height: '18'
  },
  folder: {
    path: [
      'M0 3a3 3 0 013-3h1.172a3 3 0 012.12.879l.83.828A1 1 0 007.827 2H15a3 3 0 013 3v8a3 3 0 01-3 3H3a3 3 0 01-3-3V3zm3-1a1 1 0 00-1 1v3h14V5a1 1 0 00-1-1H7.828a3 3 0 01-2.12-.879l-.83-.828A1 1 0 004.173 2H3zm13 6H2v5a1 1 0 001 1h12a1 1 0 001-1V8z'
    ],
    viewbox: '0 0 18 16',
    width: '18',
    colors: [],
    fillRule: '',
    height: '16'
  },
  file: {
    path: [
      'M3 2a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V8H9a3 3 0 01-3-3V2H3zm5 1.414L10.586 6H9a1 1 0 01-1-1V3.414zM0 3a3 3 0 013-3h4a1 1 0 01.707.293l6 6A1 1 0 0114 7v8a3 3 0 01-3 3H3a3 3 0 01-3-3V3z'
    ],
    viewbox: '0 0 14 18',
    width: '14',
    colors: [],
    height: '18',
    fillRule: 'evenodd'
  },
  file_add: {
    path: [
      'M9.707.293A1 1 0 009 0H5a3 3 0 00-3 3v6a1 1 0 102 0V3a1 1 0 011-1h3v3a3 3 0 003 3h3v7a1 1 0 01-1 1h-2a1 1 0 100 2h2a3 3 0 003-3V7a1 1 0 00-.293-.707l-6-6zM12.586 6H11a1 1 0 01-1-1V3.414L12.586 6zM4 12a1 1 0 00-1 1v2H1a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2H5v-2a1 1 0 00-1-1z'
    ],
    viewbox: '0 0 16 20',
    width: '16',
    colors: [],
    height: '20',
    fillRule: 'evenodd'
  },
  variable_add: {
    path: [
      'M16 2a1 1 0 011 1v2h2a1 1 0 110 2h-2v2a1 1 0 11-2 0V7h-2a1 1 0 110-2h2V3a1 1 0 011-1zM8 7a3 3 0 000 6h4c.896 0 1.7-.391 2.25-1.016a1 1 0 111.5 1.323A4.99 4.99 0 0112 15H8A5 5 0 018 5h1a1 1 0 010 2H8z'
    ],
    viewbox: '0 0 20 20',
    width: '20',
    colors: [],
    fillRule: '',
    height: '20'
  },
  view_grid: {
    path: [
      'M0 1a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H1a1 1 0 01-1-1V1zm2 1v1h1V2H2zm5-1a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H8a1 1 0 01-1-1V1zm2 1v1h1V2H9zM0 8a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H1a1 1 0 01-1-1V8zm2 1v1h1V9H2zm5-1a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H8a1 1 0 01-1-1V8zm2 1v1h1V9H9z'
    ],
    viewbox: '0 0 12 12',
    width: '12',
    colors: [],
    fillRule: '',
    height: '12'
  },
  view_list: {
    path: [
      'M0 2a2 2 0 012-2h8a2 2 0 012 2v1a2 2 0 01-2 2H2a2 2 0 01-2-2V2zm10 0H2v1h8V2zM0 9a2 2 0 012-2h8a2 2 0 012 2v1a2 2 0 01-2 2H2a2 2 0 01-2-2V9zm10 0H2v1h8V9z'
    ],
    viewbox: '0 0 12 12',
    width: '12',
    colors: [],
    fillRule: '',
    height: '12'
  },
  trigger: {
    path: [
      'M11.87 5.507a1 1 0 01-.012 1.008l-6 10A1 1 0 014 16v-6H1a1 1 0 01-.97-1.243l2-8A1 1 0 013 0h6a1 1 0 01.928 1.371L8.477 5H11a1 1 0 01.87.507zM9.234 7H7a1 1 0 01-.928-1.371L7.523 2H3.781l-1.5 6H5a1 1 0 011 1v3.39L9.234 7z'
    ],
    viewbox: '0 0 12 17',
    width: '12',
    colors: [],
    fillRule: '',
    height: '17'
  },
  trigger_add: {
    path: [
      'M2.03.757A1 1 0 013 0h6a1 1 0 01.928 1.371l-2 5a1 1 0 01-1.856-.742L7.523 2H3.781l-1.5 6H5a1 1 0 011 1v3.39l1.143-1.905a1 1 0 111.715 1.03l-3 5A1 1 0 014 16v-6H1a1 1 0 01-.97-1.243l2-8zM13 4a1 1 0 011 1v2h2a1 1 0 110 2h-2v2a1 1 0 11-2 0V9h-2a1 1 0 010-2h2V5a1 1 0 011-1z'
    ],
    viewbox: '0 0 17 17',
    width: '17',
    colors: [],
    fillRule: '',
    height: '17'
  },
  edit_small: {
    path: [
      'M5.832.723a2.469 2.469 0 013.49 3.491L8.285 5.253l-4.5 4.5a1 1 0 01-.707.293H1a1 1 0 01-1-1V6.969a1 1 0 01.293-.707l4.5-4.5L5.832.722zm1.745 2.408l.331-.331a.469.469 0 00-.662-.663l-.332.332.663.662zM5.5 3.883L2 7.383v.663h.663l3.5-3.5-.663-.663z'
    ],
    viewbox: '0 0 11 11',
    width: '11',
    colors: [],
    fillRule: '',
    height: '11'
  },
  globe: {
    path: [
      'M8 2.07A7.002 7.002 0 002 9h4a1 1 0 011 1v4a1 1 0 01-.293.707l-.647.647A7 7 0 0015.327 12H12a1 1 0 01-1-1V8H9a1 1 0 01-1-1V2.07zm2 0V6h2a1 1 0 011 1v3h2.93A7.002 7.002 0 0010 2.07zM3.872 16.398A9 9 0 1014.128 1.603 9 9 0 003.872 16.397zm.48-2.163L5 13.586V11H2.29a7.003 7.003 0 002.062 3.234z'
    ],
    viewbox: '0 0 18 18',
    width: '18',
    colors: [],
    height: '18',
    fillRule: 'evenodd'
  },
  thumbsDown: {
    path: [
      'M8 12v-1a1 1 0 01.7 1.7L8 12zm.5 5.5l-.7-.7.7.7zM13 12V2h2v10h-2zm1-9H4.64V1H14v2zm-10.34.8l-1.2 6L.5 9.41l1.2-6 1.96.4zm4.13 13l5.5-5.5 1.42 1.4-5.5 5.5-1.42-1.4zM3.44 11H8v2H3.44v-2zm5.27 1.7l-2.5 2.5-1.42-1.4 2.5-2.5 1.42 1.4zM14 11h2v2h-2v-2zm3-1V4h2v6h-2zm-1-7h-2V1h2v2zm1 1a1 1 0 00-1-1V1a3 3 0 013 3h-2zm-1 7a1 1 0 001-1h2a3 3 0 01-3 3v-2zm-9.8 4.2a1.12 1.12 0 000 1.6l-1.4 1.4a3.12 3.12 0 010-4.4l1.4 1.4zm3 3a3.12 3.12 0 01-4.4 0l1.4-1.4c.45.43 1.15.43 1.6 0l1.4 1.4zM4.65 3a1 1 0 00-.98.8L1.7 3.41A3 3 0 014.64 1v2zM2.46 9.8a1 1 0 00.98 1.2v2A3 3 0 01.5 9.41l1.96.4z'
    ],
    viewbox: '0 0 20 20',
    width: '20',
    colors: [],
    height: '20',
    fillRule: ''
  },
  thumbsUp: {
    path: [
      'M12 8v1a1 1 0 01-.7-1.7l.7.7zm-.5-5.5l.7.7-.7-.7zM7 8v10H5V8h2zm-1 9h9.36v2H6v-2zm10.34-.8l1.2-6 1.96.39-1.2 6-1.96-.4zm-4.13-13L6.7 8.7 5.29 7.3l5.5-5.5 1.42 1.4zM16.56 9H12V7h4.56v2zm-5.27-1.7l2.5-2.5 1.42 1.4-2.5 2.5-1.42-1.4zM6 9H4V7h2v2zm-3 1v6H1v-6h2zm1 7h2v2H4v-2zm-1-1a1 1 0 001 1v2a3 3 0 01-3-3h2zm1-7a1 1 0 00-1 1H1a3 3 0 013-3v2zm9.8-4.2c.43-.45.43-1.15 0-1.6l1.4-1.4a3.12 3.12 0 010 4.4l-1.4-1.4zm-3-3a3.12 3.12 0 014.4 0l-1.4 1.4a1.12 1.12 0 00-1.6 0l-1.4-1.4zM15.35 17a1 1 0 00.98-.8l1.96.39A3 3 0 0115.36 19v-2zm2.18-6.8a1 1 0 00-.98-1.2V7a3 3 0 012.94 3.59l-1.96-.4z'
    ],
    viewbox: '0 0 20 20',
    width: '20',
    colors: [],
    height: '20',
    fillRule: ''
  },
  building: {
    path: [
      'm6.5 13.5c0-.5523-.44772-1-1-1s-1 .4477-1 1zm-2-11h5v-2h-5zm-1 12v-11h-2v11zm7-11v4h2v-4zm1 5h4v-2h-4zm5 1v5h2v-5zm-1 6h-4v2h4zm-5-8v9h2v-9zm-4 9v-3h-2v3zm-2 1h1v-2h-1zm7-2h-6v2h6zm-10-1c0 1.6569 1.34315 3 3 3v-2c-.55228 0-1-.4477-1-1zm15 0c0 .5523-.4477 1-1 1v2c1.6569 0 3-1.3431 3-3zm-7-12c.5523 0 1 .44772 1 1h2c0-1.65685-1.3431-3-3-3zm6 6c.5523 0 1 .44772 1 1h2c0-1.65685-1.3431-3-3-3zm-11-8c-1.65685 0-3 1.34315-3 3h2c0-.55228.44772-1 1-1z'
    ],
    viewbox: '0 0 20 20',
    width: '20',
    colors: [],
    height: '20',
    fillRule: '',
    rects: [
      {height: '2', rx: '1', width: '2', x: '4.5', y: '3.5'},
      {height: '2', rx: '1', width: '2', x: '4.5', y: '9.5'},
      {height: '2', rx: '1', width: '2', x: '4.5', y: '6.5'},
      {height: '2', rx: '1', width: '2', x: '7.5', y: '9.5'},
      {height: '2', rx: '1', width: '2', x: '7.5', y: '12.5'},
      {height: '2', rx: '1', width: '2', x: '7.5', y: '3.5'},
      {height: '2', rx: '1', width: '2', x: '7.5', y: '6.5'},
      {height: '2', rx: '1', width: '2', x: '13.5', y: '12.5'},
      {height: '2', rx: '1', width: '2', x: '13.5', y: '9.5'},
    ]
  },
  media_layout_inline: {
    path: [
      {d: 'M0.5 1.25C0.5 0.835786 0.835786 0.5 1.25 0.5H18.75C19.1642 0.5 19.5 0.835787 19.5 1.25V18.75C19.5 19.1642 19.1642 19.5 18.75 19.5H1.25C0.835787 19.5 0.5 19.1642 0.5 18.75V1.25Z', stroke: '#7E868C'},
      {d: 'M3 6C3 5.44772 3.44772 5 4 5H16C16.5523 5 17 5.44772 17 6V13C17 13.5523 16.5523 14 16 14H4C3.44772 14 3 13.5523 3 13V6Z', fill: '#7E868C'}
    ],
    width: 20,
    height: 20,
    viewbox: '0 0 20 20',
    fill: 'none'
  },
  media_layout_thumb: {
    path: [
      {d: 'M0.5 1.25C0.5 0.835786 0.835786 0.5 1.25 0.5H18.75C19.1642 0.5 19.5 0.835787 19.5 1.25V18.75C19.5 19.1642 19.1642 19.5 18.75 19.5H1.25C0.835787 19.5 0.5 19.1642 0.5 18.75V1.25Z', stroke: '#7E868C'},
      {d: 'M3.5 7C3.5 6.72386 3.72386 6.5 4 6.5H16C16.2761 6.5 16.5 6.72386 16.5 7V12C16.5 12.2761 16.2761 12.5 16 12.5H4C3.72386 12.5 3.5 12.2761 3.5 12V7Z', stroke: '#7E868C'},
      {d: 'M4 7H9V12H4V7Z', fill: '#7E868C'},
    ],
    width: 20,
    height: 20,
    viewbox: '0 0 20 20',
    fill: 'none'
  },
  media_layout_text: {
    path: [
      {d: 'M0.5 1.25C0.5 0.835786 0.835786 0.5 1.25 0.5H18.75C19.1642 0.5 19.5 0.835787 19.5 1.25V18.75C19.5 19.1642 19.1642 19.5 18.75 19.5H1.25C0.835787 19.5 0.5 19.1642 0.5 18.75V1.25Z', stroke: '#7E868C'},
      {fillRule: 'evenodd', clipRule: 'evenodd', fill: '#7E868C', d: 'M5 3.9574C4.44772 3.9574 4 4.40511 4 4.9574V6.29075C4 6.5669 4.22386 6.79075 4.5 6.79075C4.77614 6.79075 5 6.5669 5 6.29075V4.9574L9.50179 4.9574C9.5006 4.97144 9.5 4.98565 9.5 5V15.0407H7.5C7.22386 15.0407 7 15.2646 7 15.5407C7 15.8169 7.22386 16.0407 7.5 16.0407H12.5C12.7761 16.0407 13 15.8169 13 15.5407C13 15.2646 12.7761 15.0407 12.5 15.0407H10.5V5C10.5 4.98565 10.4994 4.97144 10.4982 4.9574L15 4.9574V6.29075C15 6.5669 15.2239 6.79075 15.5 6.79075C15.7761 6.79075 16 6.5669 16 6.29075V4.9574C16 4.40511 15.5523 3.9574 15 3.9574H5Z'},

    ],
    width: 20,
    height: 20,
    viewbox: '0 0 20 20',
    fill: 'none'
  },
  media_layout_inline_selected: {
    path: [
      {d: 'M0.5 1.25C0.5 0.835786 0.835786 0.5 1.25 0.5H18.75C19.1642 0.5 19.5 0.835787 19.5 1.25V18.75C19.5 19.1642 19.1642 19.5 18.75 19.5H1.25C0.835787 19.5 0.5 19.1642 0.5 18.75V1.25Z', stroke: '#1E6EFA'},
      {d: 'M3 6C3 5.44772 3.44772 5 4 5H16C16.5523 5 17 5.44772 17 6V13C17 13.5523 16.5523 14 16 14H4C3.44772 14 3 13.5523 3 13V6Z', fill: '#1E6EFA'}
    ],
    width: 20,
    height: 20,
    viewbox: '0 0 20 20',
    fill: 'none'
  },
  media_layout_thumb_selected: {
    path: [
      {d: 'M0.5 1.25C0.5 0.835786 0.835786 0.5 1.25 0.5H18.75C19.1642 0.5 19.5 0.835787 19.5 1.25V18.75C19.5 19.1642 19.1642 19.5 18.75 19.5H1.25C0.835787 19.5 0.5 19.1642 0.5 18.75V1.25Z', stroke: '#1E6EFA'},
      {d: 'M3.5 7C3.5 6.72386 3.72386 6.5 4 6.5H16C16.2761 6.5 16.5 6.72386 16.5 7V12C16.5 12.2761 16.2761 12.5 16 12.5H4C3.72386 12.5 3.5 12.2761 3.5 12V7Z', stroke: '#1E6EFA'},
      {d: 'M4 7H9V12H4V7Z', fill: '#1E6EFA'},
    ],
    width: 20,
    height: 20,
    viewbox: '0 0 20 20',
    fill: 'none'
  },
  media_layout_text_selected: {
    path: [
      {d: 'M0.5 1.25C0.5 0.835786 0.835786 0.5 1.25 0.5H18.75C19.1642 0.5 19.5 0.835787 19.5 1.25V18.75C19.5 19.1642 19.1642 19.5 18.75 19.5H1.25C0.835787 19.5 0.5 19.1642 0.5 18.75V1.25Z', stroke: '#1E6EFA'},
      {fillRule: 'evenodd', clipRule: 'evenodd', fill: '#1E6EFA', d: 'M5 3.9574C4.44772 3.9574 4 4.40511 4 4.9574V6.29075C4 6.5669 4.22386 6.79075 4.5 6.79075C4.77614 6.79075 5 6.5669 5 6.29075V4.9574L9.50179 4.9574C9.5006 4.97144 9.5 4.98565 9.5 5V15.0407H7.5C7.22386 15.0407 7 15.2646 7 15.5407C7 15.8169 7.22386 16.0407 7.5 16.0407H12.5C12.7761 16.0407 13 15.8169 13 15.5407C13 15.2646 12.7761 15.0407 12.5 15.0407H10.5V5C10.5 4.98565 10.4994 4.97144 10.4982 4.9574L15 4.9574V6.29075C15 6.5669 15.2239 6.79075 15.5 6.79075C15.7761 6.79075 16 6.5669 16 6.29075V4.9574C16 4.40511 15.5523 3.9574 15 3.9574H5Z'},

    ],
    width: 20,
    height: 20,
    viewbox: '0 0 20 20',
    fill: 'none'
  },
  redo: {
    path: [
      {
        d: 'M15.9972 10.29C15.898 6.64506 13.2239 4 10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16C11.3444 16 12.2499 15.7833 13.208 15.0941C13.4322 14.9329 13.7446 14.9838 13.9059 15.208C14.0671 15.4322 14.0162 15.7446 13.792 15.9059C12.6157 16.7521 11.4857 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.8314 3 16.8996 6.14886 16.9976 10.2953L18.1464 9.14645C18.3417 8.95118 18.6583 8.95118 18.8536 9.14645C19.0488 9.34171 19.0488 9.65829 18.8536 9.85355L16.8536 11.8536C16.6583 12.0488 16.3417 12.0488 16.1464 11.8536L14.1464 9.85355C13.9512 9.65829 13.9512 9.34171 14.1464 9.14645C14.3417 8.95118 14.6583 8.95118 14.8536 9.14645L15.9972 10.29Z',
        fillRule: 'evenodd',
        fill: 'currentColor'
      }
    ],
    width: 20,
    height: 20,
    viewbox: '0 0 20 20',
    fill: 'none'
  }
}

import React from 'react'
import styled from 'styled-components'
import Pattern from '../../assets/pattern.svg'

const Content = styled.div`
  padding: 30px;

  > svg {
    display: block;
    margin: auto;
  }
  > h1 {
    font-size: ${p => p.theme.fontSizeXXL};
    text-align: center;
  }
  > p {
    font-size: ${p => p.theme.fontSize};
    text-align: center;
    color: ${p => p.theme.grey50};
  }
`

const PatternContainer = styled.div`
  background: ${p => p.theme.navy};
  justify-content: center;
  display: flex;
  align-items: center;

  > svg {
    display: block;
    width: 60%;
    max-width: 55vh;
  }
`

const Wrap = styled.div`
  min-height: 100vh;
  display: flex;

  ${Content} {
    /* Keep in the middle of the left half of the wrapper */
    align-self: center;
    width: 50%;
    margin: 0 auto;
    max-width: 460px;
    display: flex;
    flex-direction: column;
  }

  ${PatternContainer} {
    width: 50%;
    min-width: 100px;
  }

  @media (max-width: 920px) {
    flex-direction: column;
    ${Content} {
      width: 100%;
      margin: auto 0;
    }
    ${PatternContainer} {
      height: 60px;
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAAAlwSFlzAAALEwAACxMBAJqcGAAAAnJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6dGlmZj0iaHR0cDovL25zLmFkb2JlLmNvbS90aWZmLzEuMC8iCiAgICAgICAgICAgIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyI+CiAgICAgICAgIDx0aWZmOllSZXNvbHV0aW9uPjcyPC90aWZmOllSZXNvbHV0aW9uPgogICAgICAgICA8dGlmZjpDb21wcmVzc2lvbj41PC90aWZmOkNvbXByZXNzaW9uPgogICAgICAgICA8dGlmZjpYUmVzb2x1dGlvbj43MjwvdGlmZjpYUmVzb2x1dGlvbj4KICAgICAgICAgPHhtcDpDcmVhdG9yVG9vbD5GbHlpbmcgTWVhdCBBY29ybiA2LjMuMzwveG1wOkNyZWF0b3JUb29sPgogICAgICAgICA8eG1wOk1vZGlmeURhdGU+MjAxOS0wNi0yOFQxNTo0MDoxNzwveG1wOk1vZGlmeURhdGU+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgqJRiREAAACjklEQVRYCe1XTWvUUBQ9SaYd285C3BShFHVTBN247EaoX124qJHirgvp35BkfoG/QEXQCnWjrVgoCIILN9pFSxfShWtBrGhntM0k8dxkpnbSGbxJIwi+C5mZN+/d+84799z3XgBjhgHDgGHg/2bA+pvLj4PAx06TU9iIasfgDAz4eeer5HXQjI93d328Wwfu3PXw6QtQcWCPjSLe+ojozDjhApbj+JpYpTIYhqFvN38CT196eLgMBCEQRykOxwGq5GPeBdxrdataVQEsjcEEXBh6BAcs8NkLugkKCbbJZ2EFODfhxWxrWBS2S7GEuUfPgft8mnv9Y25/B5ZfIYrazPYfmfSUAjDRnKRVmIvi32ntNbn0r28lOuzVnf3vSCne19zjJQ8PloBWKxu/d7ujy969Xf8WBnhIc1pwNuvy9BhB6JJXGGC7WlPNSdq0xv0Q7hUtPuUyMpPn0lzbN1lCdRC4MQWcn6hrKlhcczFYVHMCzqoNkbkpRLdn67atS29ugLbobJHV+oTVqtWcgBPm3EvAnFvPe9ypT5K40fCxuOLh3rM/byWy9I6NkLmblxHNz+YGJyFUXMdB6GPjA0+JVTW4fc0RHOZmkCetnbXJt06DEVP7Zg2QU0BhqeaGU82ROQGnLYpseB3AFo+l95tZ377tVHOs1gKaywbVAUy8lHI9ouayAFUaRIXDzp7K+na1y9JcV1A2dAxWOOw6t4m3vIR++3HoMlCm5goBFIHHDQK7Ne0l1yle6w6aNcgFcBMuQ3MH48pvHYMcaI0M+XzHAMZPenjxGvi8Lf7AiePA1Ulg+qL6+EoddZ9qgBLO4kuP3IQxeQH4upPOUON2MswLgDHDgGHAMGAYMAz8kwz8AlK26+lZRXljAAAAAElFTkSuQmCC')
        bottom right;
      background-size: 20px 20px;
      width: 100%;
      > svg {
        display: none;
      }
    }
  }
`

interface AuthLayoutProps {
  children: React.ReactNode
}

export const AuthLayout = ({children}: AuthLayoutProps) => (
  <Wrap>
    <Content>{children}</Content>
    <PatternContainer>
      <Pattern />
    </PatternContainer>
  </Wrap>
)

import {fontSmall} from '../../../views/action-flow/components/library/css'
import styled, {css} from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 12px; */
`

export const Label = styled.div`
  ${fontSmall}
  color: ${props => props.theme.black};
  display: flex;
  align-items: center;
  height: 28px;
`

export const StyledToggle = styled.div<{
  isIcon?: boolean
  isWithLabel?: boolean
  readOnly?: boolean
}>`
  display: inline-block;
  ${p => (!p.isIcon || p.isWithLabel) && `width: 200px;`}
  border-radius: ${p => p.theme.borderRadiusPill};
  background: ${p => p.theme.colors.grey3};
  padding: 2px;
  height: 28px;
  transition: background 0.1s ease;
  ${({readOnly}) =>
    readOnly
      ? css`
          background-color: ${({theme}) => theme.colors.grey4};
        `
      : css`
          background-color: ${({theme}) => theme.colors.grey3};
          &:hover {
            background-color: ${({theme}) => theme.colors.grey4};
          }
          &:active {
            background-color: ${({theme}) => theme.colors.grey5};
          }
        `}
`

export const Lozenge = styled.span`
  border-radius: ${p => p.theme.borderRadiusPill};
  background: ${p => p.theme.white};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition:
    box-shadow 0.1s ease,
    margin-left 0.3s ease;
  box-shadow: ${p => p.theme.shadowDepth1};
`

export const Option = styled.span`
  position: relative;
  border-radius: ${p => p.theme.borderRadiusPill};
  transition: color 0.1s ease;
  pointer-events: none;
  min-width: 30px;
  align-self: stretch;
  > input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    pointer-events: auto;
  }
`

export const Inner = styled.div<{
  count: number
  index: number
  isSmall?: boolean
  readOnly?: boolean
}>`
  display: flex;
  position: relative;
  height: 100%;
  ${fontSmall}
  color: ${p => (p.readOnly ? p.theme.colors.grey8 : p.theme.colors.blue11)};
  > ${Lozenge} {
    width: ${p => 100 / p.count + '%'};
    z-index: 0;
    margin-left: ${p => (p.index / p.count) * 100 + '%'};
  }
  &:focus-within {
    ${Lozenge} {
      outline: solid 2px ${p => p.theme.colors.blue12};
    }
  }
  > ${Option} {
    flex: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
    &:before {
      content: '';
      z-index: -1;
      width: 100%;
      height: 100%;
      position: absolute;
    }
    ${({readOnly, theme}) =>
      !readOnly &&
      css`
        &:hover,
        &:focus {
          color: ${theme.colors.blue12};
        }
        &:active {
          color: ${theme.colors.blue13};
          transition: background 0.1s ease;
          background-color: theme.colors.grey5;
        }
      `}
  }
`

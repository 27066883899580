import React, {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState
} from 'react'
import {useAtomicSDK, UseAtomicSDK} from '../hooks/atomic-sdk'
import {useAuth} from '../lib/auth'

const AtomicSDKContext = createContext<
  | {
      initialised: boolean
      cardCount?: number
      updateCardCount: (count: number) => void
      atomicVisible: boolean
      atomicSDK: UseAtomicSDK
      streamVisible: boolean
      setStreamVisible: (visible: boolean) => void
    }
  | undefined
>(undefined)

export const useAtomicSDKContext = () => {
  const context = useContext(AtomicSDKContext)
  if (!context) {
    throw new Error('Calling useAtomicSDKContext outside AtomicSDKProvider')
  }
  return context
}

export const AtomicSDKProvider = ({children}: {children: ReactNode}) => {
  const {auth, authState} = useAuth()
  const atomicSDK = useAtomicSDK(authState.authenticated, auth.getClientId())

  const {initialised, cardCount, updateCardCount} = atomicSDK
  const atomicVisible = authState.authenticated && initialised

  const [streamVisible, setStreamVisible] = useState(false)

  const context = useMemo(
    () => ({
      initialised,
      cardCount,
      updateCardCount,
      atomicVisible,
      atomicSDK,
      streamVisible,
      setStreamVisible
    }),
    [
      initialised,
      cardCount,
      updateCardCount,
      atomicVisible,
      atomicSDK,
      streamVisible,
      setStreamVisible
    ]
  )

  return (
    <AtomicSDKContext.Provider value={context}>
      {children}
    </AtomicSDKContext.Provider>
  )
}

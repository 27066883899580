import {format} from 'date-fns/format'
import {isValid} from 'date-fns/isValid'
import {parseISO} from 'date-fns/parseISO'
import memoizeOne from 'memoize-one'
export {getString} from '../common/types/helpers'

export const toTitleCase = (str: string) =>
  str.toLowerCase().replace(/\w\S*/g, upperCaseFirst)

export const camelToReadable = (s: string): string => {
  const result = s.replace(/([A-Z])/g, ' $1').toLowerCase()
  return upperCaseFirst(result)
}

export const upperCaseFirst = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1)

// matches private.email in database
export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export const isEmptyObject = (obj: unknown) =>
  obj &&
  typeof obj === 'object' &&
  Object.keys(obj).length === 0 &&
  obj.constructor === Object

export const trimAndLimit = (limit: number, str: string) => {
  const source = str.trim()
  if (source.length <= limit) return source
  const trimmed = source.slice(0, Math.max(0, limit)).trim()
  if (trimmed.length > 3) return trimmed.slice(0, -3) + '...'
  return trimmed
}

export const makePageTitle = (title: string, scope?: string): string => {
  const charLimit = 20 // useful when e.g. a card title is really large
  return (scope ? `${trimAndLimit(charLimit, scope)} ` : '') + `(${title})`
}

export const uniqueBy = <T, P extends string | number | boolean>(
  items: T[] | undefined,
  by: (item: T) => P
): T[] | undefined =>
  items ? [...new Map(items.map(v => [by ? by(v) : v, v])).values()] : undefined

export const unique = <T extends string | number | boolean>(
  items: T[] | undefined
): T[] | undefined => uniqueBy(items, v => v)

export const coerceString = (value: unknown) => {
  if (value === undefined || value === null) return undefined
  return String(value)
}

export const toRelativeURL = (url: string) => {
  const urlCopy = new URL(url)
  return urlCopy
    .toString()
    .replace(/^https?:\/\//, '')
    .replace(urlCopy.host, '')
}

export const displayDate = (timestamp: string, withTime = true) => {
  const date = parseISO(timestamp)
  return isValid(date)
    ? format(
        parseISO(timestamp),
        `${withTime ? 'h:mm a, ' : ''}EEE d MMM, yyyy`
      )
    : null
}

export const pruneObject = memoizeOne(
  <T extends Record<string, unknown>>(
    obj: T,
    emptyValues: unknown[] = [null, undefined]
  ): T => {
    const mut_newObj = {...obj}
    for (const [key, value] of Object.entries(mut_newObj)) {
      if (emptyValues.includes(value)) {
        delete mut_newObj[key]
      }
    }
    return mut_newObj
  }
)

//Returns an array containing elements that are in array1 but not in array2.
export const arrayDiff = <T>(array1: T[], array2: T[]): T[] => {
  // Create a Set from array2 for faster membership checking
  const set2 = new Set(array2)

  // Use filter to return elements that are in array1 but not in array2
  return array1.filter(element => !set2.has(element))
}

export const debounce = <A extends any[]>(
  fn: (...args: A) => void,
  delay: number
) => {
  let timeoutId: NodeJS.Timeout | null = null
  return (...args: A): void => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => fn(...args), delay)
  }
}
/**
 * Strip formatting from pasted text, useful for contenteditable divs
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/paste_event#javascript
 */
export const pasteAsText = (event: React.ClipboardEvent<HTMLDivElement>) => {
  const paste = event.clipboardData.getData('text/plain')
  const selection = window.getSelection()
  if (!selection?.rangeCount) return

  event.preventDefault()
  selection.deleteFromDocument()
  selection.getRangeAt(0).insertNode(document.createTextNode(paste))
  selection.collapseToEnd()
}

// Lifted from https://github.com/gregberge/react-merge-refs/blob/main/src/index.tsx
export function mergeRefs<T>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T> | undefined | null>
): React.RefCallback<T> {
  return value => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (ref !== null) {
        ;(ref as React.MutableRefObject<T | null>).current = value
      }
    })
  }
}

import React, {useContext, useEffect, useMemo, useState} from 'react'

import {Authenticator, AuthState} from './cognito'

interface AuthContextType {
  authState: AuthState
  auth: Authenticator
}

export const AuthContext = React.createContext<AuthContextType | undefined>(
  undefined
)

export function useAuth() {
  const ctx = useContext(AuthContext)
  if (!ctx) {
    throw new Error('Calling useAuth outside of AuthProvider')
  }
  return ctx
}

export const AuthProvider = ({
  children,
  auth
}: {
  children: React.ReactNode
  auth: Authenticator
}) => {
  const [authState, setAuthState] = useState<AuthState>(auth.getAuthState())

  useEffect(() => {
    // returns an unsubscribe function, which will be called on cleanup
    // need to emit immediately because useEffect is async
    return auth.on('stateChange', setAuthState, true)
  }, [auth]) // no dependent props means run on mount, cleanup on unmount only

  const value = useMemo(() => {
    return {
      authState,
      auth
    }
  }, [auth, authState])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

import React from 'react'
import {Params, useParams} from 'react-router'
import {AccessDenied} from '../components/errors'
import {Loader} from '../components/loader'
import {
  PermissionAction,
  PermissionResource
} from '../data/workbench-api/generated/types'
import {EnvironmentNode, useEnvironment} from '../environment-provider'
import {SignIn} from '../views/auth/sign-in'
import {useAuth} from './auth'
import {AuthStateAuth, Authenticator} from './cognito'

interface RouteProps {
  path: string
  render: (params: Params) => JSX.Element | null
}

interface PrivateRouteProps {
  path: string
  permissionCheck?: [PermissionResource, PermissionAction]
  authenticate?: (environment?: EnvironmentNode) => boolean
  environment?: EnvironmentNode
  render: (
    params: Params,
    authState: AuthStateAuth,
    auth: Authenticator
  ) => JSX.Element | null
}

// Route component which allows the rendered component to be typed correctly
export const Route = ({render}: RouteProps) => {
  const pathParams = useParams()

  return render(pathParams)
}

export const PrivateRoute = ({
  render,
  permissionCheck,
  authenticate
}: PrivateRouteProps) => {
  const {auth, authState} = useAuth()
  const {environment} = useEnvironment()
  const pathParams = useParams()

  if (!authState.loaded) {
    return <Loader />
  }

  if (authState.authenticated) {
    if (authenticate) {
      return authenticate(environment) ? (
        render(pathParams, authState, auth)
      ) : (
        <AccessDenied />
      )
    }

    if (!permissionCheck) {
      return render(pathParams, authState, auth)
    }

    if (!environment) {
      console.warn(
        `Environment not accessible in PrivateRote. If you require authentication for an organisation pass an authenticate callback`
      )
      return render(pathParams, authState, auth)
    }

    if (
      permissionCheck &&
      environment.hasPermission(permissionCheck[0], permissionCheck[1])
    ) {
      return render(pathParams, authState, auth)
    }
    return <AccessDenied />
  } else {
    return <SignIn auth={auth} />
  }
}

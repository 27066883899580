import {gql} from '@apollo/client'
import {useEffect, useMemo, useState} from 'react'
import {getClient} from '../lib/api'
import {
  GetUseEndUserQuery,
  UseEndUserDetailFragment
} from './generated/use-end-user'

type EndUserIdentifier = {
  organisationId: string | undefined
  environmentId: string | undefined
  id: string | undefined
}
export const useEndUser = ({
  organisationId,
  environmentId,
  id
}: EndUserIdentifier) => {
  const [endUser, setEndUser] = useState<UseEndUserDetailFragment | undefined>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()

  useEffect(() => {
    if (environmentId && organisationId && id) {
      setLoading(true)
      getClient(organisationId)
        .query<GetUseEndUserQuery>({
          query: END_USER_QUERY,
          variables: {environmentId, id}
        })
        .then(({data}) => {
          setEndUser(data.endUser)
        })
        .catch(setError)
        .finally(() => setLoading(false))
    }
  }, [organisationId, environmentId, id])

  const state = useMemo(() => ({error, loading}), [error, loading])
  return [endUser, state] as const
}

// Custom fragment to avoid auth_token nullification on custom endpoints
const END_USER_QUERY = gql`
  query GetUseEndUser($id: String!, $environmentId: String!) {
    endUser: retrieveEndUserQuery(id: $id, environmentId: $environmentId) {
      ...UseEndUserDetail
    }
  }

  fragment UseEndUserDetail on CustomEndUserType {
    id
    allowTest
    nickname
    created
    environmentId
    authSecret # only returned for a subset of users - see private.end_user_auth_secret
    businessUserAccountId
    profile
    organisationBusinessUserAccount {
      email
      name
    }
    status
  }
`

import React, {ReactNode} from 'react'
import styled from 'styled-components'

interface ErrorProps {
  error: Error | ReactNode | string
  title?: string
}

export const ErrorComponent = ({
  error,
  title = 'There was an error'
}: ErrorProps) => (
  <ErrorWrapper>
    <h2>{title}</h2>
    <div>{error instanceof Error ? <p>{error.message}</p> : error}</div>
  </ErrorWrapper>
)

export const NotFound = () => (
  <ErrorComponent
    title="Not found"
    error="The url is invalid, or you don't have access to that resource"
  />
)

const DEFAULT_ACCESS_MESSAGE = 'You do not have access to this resource'

export const AccessDenied = ({children}: {children?: React.ReactNode}) => (
  <ErrorWrapper>
    <h2>Access denied</h2>
    {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
    <div>{children || <p>{DEFAULT_ACCESS_MESSAGE}</p>}</div>
  </ErrorWrapper>
)

const ErrorWrapper = styled.div`
  padding: 30px;

  > h2 {
  }
  > div {
    max-width: 500px;
  }
`

export const NoAccess = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`

import styled, {css} from 'styled-components'

const light = css`
  background-color: ${({theme}) => theme.grey05};
  color: ${({theme}) => theme.grey50};
`

const dark = css`
  background-color: ${({theme}) => theme.grey50};
  color: ${({theme}) => theme.white};
`

const green = css`
  background-color: ${({theme}) => theme.greenLightest};
  color: ${({theme}) => theme.green};
`

const blue = css`
  background-color: ${({theme}) => theme.blueLightest};
  color: ${({theme}) => theme.blue};
`

const red = css`
  background-color: ${({theme}) => theme.redLightest};
  color: ${({theme}) => theme.red};
`

const yellow = css`
  background-color: ${({theme}) => theme.yellowLightest};
  color: ${({theme}) => theme.yellow};
`

const orange = css`
  background-color: ${({theme}) => theme.orangeLightest};
  color: ${({theme}) => theme.orange};
`

interface TagProps {
  appearance: 'light' | 'dark' | 'green' | 'blue' | 'red' | 'yellow' | 'orange'
  size?: 'large' | 'small'
}

const small = css`
  height: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  border-radius: ${({theme}) => theme.borderRadius};

  font-size: ${({theme}) => theme.fontSizeXS};
  line-height: ${({theme}) => theme.lineHeightXS};
`

const large = css`
  height: 30px;
  padding: 7px 12px;
  border-radius: 15px;

  font-size: 13px;
  line-height: ${({theme}) => theme.lineHeightXS};
`

export const Tag = styled.span<TagProps>`
  display: inline-block;
  text-align: center;
  white-space: nowrap;

  ${props => (props.size === 'large' ? large : small)}

  ${props =>
    (props.appearance === 'light' && light) ||
    (props.appearance === 'dark' && dark) ||
    (props.appearance === 'blue' && blue) ||
    (props.appearance === 'red' && red) ||
    (props.appearance === 'yellow' && yellow) ||
    (props.appearance === 'orange' && orange) ||
    (props.appearance === 'green' && green)};
`

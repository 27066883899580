import React, {CSSProperties} from 'react'
import {blob} from '../../../assets/icons/icons'
import DocsIcon from '../../../assets/icons/docs.svg'
import styled from 'styled-components'
import {isLeft} from 'fp-ts/lib/Either'
import {IconCodec} from './types'

interface StyledIconProps {
  width?: string
  height?: string
  rotate?: string
  paths?: string[]
  colors?: string[]
  className?: string
  fill?: string
}

const StyledIcon = styled.svg.attrs<StyledIconProps>(props => props)`
  ${props => (props.fill ? `fill: ${props.fill};` : null)}
  ${props => (props.rotate ? `transform: rotate(${props.rotate}deg)` : null)}
`

export type IconName = keyof typeof blob

export interface IconProps {
  name: IconName
  className?: string
  height?: string
  width?: string
  rotate?: number | string
  style?: CSSProperties
}

export const Icon = ({name, height, width, rotate, ...props}: IconProps) => {
  const maybeIconObject = blob[name]
  const decoded = IconCodec.decode(maybeIconObject)

  if (isLeft(decoded)) {
    console.error('Invalid icon object', decoded.left)
    throw new Error('Invalid icon object')
  }

  const iconObject = decoded.right

  return (
    <StyledIcon
      fill={iconObject.fill ?? 'currentColor'}
      height={height ?? String(iconObject.height)}
      width={width ?? String(iconObject.width)}
      viewBox={iconObject.viewbox}
      rotate={rotate ? String(rotate) : undefined}
      {...props}
    >
      <>
        {iconObject.path.map((path, i) =>
          typeof path === 'string' ? (
            <path
              fillRule={iconObject.fillRule ? iconObject.fillRule : undefined}
              d={path}
              key={i}
              fill={
                iconObject.colors
                  ? iconObject.colors[i]
                  : iconObject.fill ?? undefined
              }
            />
          ) : (
            // path is an object
            <path
              d={path.d}
              key={i}
              strokeLinecap={path.strokeLinecap as any}
              strokeLinejoin={path.strokeLinejoin as any}
              strokeWidth={path.strokeWidth}
              stroke={path.stroke}
              fill={path.fill}
              fillRule={path.fillRule ? path.fillRule : undefined}
              clipRule={path.clipRule}
            />
          )
        )}
        {'rects' in iconObject
          ? iconObject.rects?.map((rect, i) => (
              <rect
                key={`rect-${i}`}
                height={rect.height}
                rx={rect.rx}
                width={rect.width}
                x={rect.x}
                y={rect.y}
              />
            ))
          : null}
      </>
    </StyledIcon>
  )
}

export const StyledDocsIcon = styled(DocsIcon)`
  &:hover [fill] {
    fill: ${p => p.theme.blueDarkest};
  }
  & [fill] {
    fill: ${p => p.theme.blue};
  }

  & [stroke] {
    stroke: ${p => p.theme.blue};
  }
  &:hover [stroke] {
    stroke: ${p => p.theme.blueDarkest};
  }
`

import React, {ReactNode} from 'react'
import styled from 'styled-components'
import SVGChevronDown from '../../assets/icons/chevron-down-xsmall.svg'

export const Crumb = styled.span`
  display: flex;
  height: 20px;
  padding: 0px 4px;
  align-items: center;
  gap: 4px;
  border-radius: 2px;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.24px;
  color: ${({theme}) => theme.colors.grey12};
`

export const CrumbButton = styled(Crumb)`
  &:hover,
  &.active {
    background: ${({theme}) => theme.colors.grey3};
    color: ${({theme}) => theme.colors.grey12};
  }
`

const CrumbSwitcherInner = styled(Crumb)`
  color: ${({theme}) => theme.colors.blue11};
  &:hover,
  &.active {
    background: ${({theme}) => theme.colors.grey3};
    color: ${({theme}) => theme.colors.blue13};
  }
`

export const CrumbSwitcher = ({
  children,
  className
}: {
  children: ReactNode
  className?: string
}) => {
  return (
    <CrumbSwitcherInner role="button" className={className}>
      {children}
      <SVGChevronDown width="8px" />
    </CrumbSwitcherInner>
  )
}

import React from 'react'
import styled from 'styled-components'
import {LinkButton} from '../../../components/library/buttons'
import {Icon, IconName} from '../../../components/library/icon/icon'

export const StepperTitle = styled.div`
  font-size: ${({theme}) => theme.fontSizeL};
  line-height: ${({theme}) => theme.lineHeightXL};
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`

export const StepperMessage = styled.div`
  font-size: ${({theme}) => theme.fontSize};
  line-height: ${({theme}) => theme.lineHeightL};
  border-radius: ${({theme}) => theme.borderRadiusPill};
  margin-bottom: 30px;
  text-align: center;
  color: ${({theme}) => theme.grey50};
`

export const StepperWrap = styled.div<{center?: boolean}>`
  max-width: 1200px;
  flex-grow: 1;
  overflow: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  z-index: 10;
  background: ${({theme}) => theme.white};
  ${p =>
    p.center
      ? `
        justify-content: center;
        > div {
          margin-bottom: 50px;
        }
      `
      : null}
`

export const StepperBody = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  align-self: stretch;
`

export const StepperContent = styled.div`
  max-width: 500px;
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const StepperContain = styled.div`
  width: 100%;
  max-width: 400px;
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const StepperActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  button:nth-child(n + 2) {
    margin-top: 20px;
  }
`

export const StepperBack = styled.div`
  min-height: 30px;
`

const BreadCrumbWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 40px;
`

const BreadCrumbStep = styled.div<{state: string}>`
  flex: none;
  width: 30px;
  height: 30px;
  border-radius: ${({theme}) => theme.borderRadiusPill};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 10px solid white;
  &:nth-child(n + 2) {
    margin-left: 30px;
  }
  ${p => {
    switch (p.state) {
      case 'present':
        return `
          background: ${p.theme.blue};
          color: ${p.theme.white};
          font-size: ${p.theme.fontSizeS};
          font-weight: bold;
          border: none;
        `
      case 'past':
        return `
          background: ${p.theme.blue};
        `
      case 'future':
        return `
          background: ${p.theme.grey10};
          `
      default:
        return null
    }
  }}
`

interface StepperMediaProps {
  icon: IconName
}

export const StepperMedia = ({icon}: StepperMediaProps) => {
  return (
    <StyledStepperMedia>
      <Icon name={icon}></Icon>
    </StyledStepperMedia>
  )
}

const StyledStepperMedia = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({theme}) => theme.grey05};
  border-radius: ${({theme}) => theme.borderRadiusPill};
  margin-bottom: 30px;
`

export const Stepper = ({
  children,
  backLink,
  step,
  steps
}: {
  children: React.ReactNode
  backLink?: string
  step: number
  steps: number
}) => {
  const stepIndex = step - 1
  return (
    <StepperWrap>
      <StepperBack>
        {backLink !== undefined && (
          <LinkButton
            appearance="transparent"
            iconLeft={step >= 2 ? 'back_large' : undefined}
            to={backLink}
          >
            {step === 1 ? 'Cancel' : 'Back'}
          </LinkButton>
        )}
      </StepperBack>
      <BreadCrumbWrap>
        {Array.from({length: steps}).map((_, i) => {
          if (i === stepIndex) {
            return (
              <BreadCrumbStep state={'present'} key={i}>
                {step}
              </BreadCrumbStep>
            )
          }
          if (i < stepIndex) {
            return <BreadCrumbStep state={'past'} key={i} />
          }
          return <BreadCrumbStep state={'future'} key={i} />
        })}
      </BreadCrumbWrap>
      <div>{children}</div>
    </StepperWrap>
  )
}

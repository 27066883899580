// Characters to escape that can otherwise be used to produce broken or confusing regex.
// We only want users searching by plain text, not special patterns.
const specialChars = ['\\', '+', '*', '(', ')', '[', ']', '|', '?', '$', '^']

export const useSearchRegex = (search?: string) => {
  const specialCharsEscaped = search
    ?.split('')
    .map(c => (specialChars.includes(c) ? `\\${c}` : c))
    .join('')

  const searchRegex = search
    ? new RegExp(`(${specialCharsEscaped})`, 'i')
    : undefined

  return searchRegex
}

import React, {useEffect, useMemo} from 'react'
import {Select} from './library/form'
import {EnvironmentStreamContainersRecord} from '../data/workbench-api/generated/types'

export const SelectContainer = ({
  containerId,
  streamContainers,
  onChange,
  disabled
}: {
  containerId?: string
  streamContainers?: EnvironmentStreamContainersRecord[]
  onChange(containerId: string | undefined): void
  disabled?: boolean
}) => {
  const options = useMemo(
    () =>
      streamContainers?.map(c => ({
        text: `${c.name || c.id} (Stream: ${c.streamName || c.streamId})`,
        value: c.id as string
      })) ?? [],
    [streamContainers]
  )

  useEffect(() => {
    if (options.length === 1 && !containerId) {
      onChange(options[0].value)
    }
  }, [options, onChange, containerId])

  return (
    <Select
      iconLeft="container"
      label={!streamContainers ? 'Loading...' : 'Select container...'}
      upLabel="Container"
      options={options}
      value={containerId}
      onChange={onChange}
      disabled={!streamContainers || disabled}
    />
  )
}

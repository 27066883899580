import React, {ReactNode, useState} from 'react'
import {Button} from '../../components/library/buttons'
import {PasswordInput} from '../../components/library/form/password-input'
import {Modal, ModalDescription} from '../../components/library/modal/modal'
import {Authenticator, SignInResult, AuthStateAuth} from '../../lib/cognito'
import {Error as ErrorLayout} from '../../components/auth/layout'
import {ButtonGroup} from '../../components/form'

export const VerifyPassword = ({
  auth,
  authState,
  onVerified,
  onClose,
  title,
  children
}: {
  auth: Authenticator
  authState: AuthStateAuth
  onVerified(verified: SignInResult): void
  onClose(): void
  title: string
  children: ReactNode
}) => {
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState<string>('')
  const [error, setError] = useState<string>('')

  return (
    <Modal style={{width: '400px'}} title={title} handleClose={onClose}>
      <ModalDescription>{children}</ModalDescription>
      {error !== '' && <ErrorLayout margin="0 0 20px">{error}</ErrorLayout>}
      <form
        onSubmit={async e => {
          e.preventDefault()
          setLoading(true)
          let res: SignInResult | undefined = undefined
          try {
            res = await auth.signIn(authState.username, password)
          } catch (err) {
            if (!(err instanceof Error)) throw new Error(String(err))
            const message =
              err.message === 'Incorrect username or password.'
                ? 'Incorrect password'
                : err.message
            setError(message)
            return
          } finally {
            setLoading(false)
          }
          if (res) {
            onVerified(res)
          } else {
            setError('Invalid password')
          }
        }}
      >
        <PasswordInput
          label="Enter your password..."
          upLabel="Password"
          value={password}
          onChange={setPassword}
        />
        <ButtonGroup>
          <Button size="large" disabled={loading || !password} type="submit">
            {loading ? 'Loading...' : 'Continue'}
          </Button>
        </ButtonGroup>
      </form>
    </Modal>
  )
}

import styled from 'styled-components'
import {
  fontSmall,
  fontXSmall
} from '../../../views/action-flow/components/library/css'
import {
  DropdownItem as LegacyDropdownItem,
  DropdownBody as LegacyDropdownBody
} from './legacy'

export const DropdownItem = styled(LegacyDropdownItem)`
  a {
    padding: 5px 16px;
    display: flex;
    align-content: center;
    align-items: center;
    min-height: 30px;
    gap: 12px;
    ${fontSmall}
    svg {
      width: 16px;
      color: ${p => p.theme.colors.grey10};
    }
  }
`

export const DropdownTitle = styled.h3`
  padding: 5px 16px;
  margin: 0;
  margin-top: 8px;
  ${fontXSmall}
  font-weight: 500;
  color: ${p => p.theme.colors.grey11};
  & + ${LegacyDropdownBody} {
    padding-top: 0;
  }
`

export const DropdownBody = styled.div`
  padding: 8px 0px;
`

export const DropdownFooter = styled.footer`
  border-top: 1px solid ${p => p.theme.grey10};
  padding: 8px 0px;
`

export const DropdownSection = styled.div`
  &:not(:first-of-type) {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid ${p => p.theme.grey10};
  }
`

import {ApolloProvider} from '@apollo/client'
import React from 'react'
import {RoutingOrganisation} from '../../common/types'
import {EnvironmentNode} from '../../environment-provider'
import {useEnvironment} from '../../environment-provider'
import {getClient} from '../../lib/api'
import {AtomicBell} from '../../lib/atomic'
import {AuthStateAuth} from '../../lib/cognito'
import {DropdownBody} from '../library/dropdown/legacy'
import {Dropdown} from '../library/dropdown/dropdown'
import styled from 'styled-components'
import {MagicLinkComposer} from '../magic-link-composer'
import {CrumbSwitcher} from './crumb'
import {Crumbs} from './crumbs'
import {EnvironmentSwitcher} from './environment-switcher'
import {OrganisationSwitcher} from './organisation-switcher'
import {useAnalytics} from '../../lib/analytics/analytics'
import {useAtomicSDKContext} from '../../providers/atomic-sdk'

const SwitcherWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`

type HeaderProps = {
  authState: AuthStateAuth
  organisations?: RoutingOrganisation[]
  environment?: EnvironmentNode
  switcherMode?: 'environment' | 'organisation'
}

export const Header = ({
  authState,
  organisations,
  environment,
  switcherMode
}: HeaderProps) => {
  const {
    streamVisible,
    setStreamVisible: toggleStream,
    atomicVisible: showStreamIcon,
    cardCount
  } = useAtomicSDKContext()

  return (
    <>
      <SwitcherWrapper>
        {switcherMode === 'environment' && organisations?.length ? (
          <EnvironmentSwitcher
            organisations={organisations}
            environment={environment}
          />
        ) : null}
        {switcherMode === 'organisation' && organisations?.length ? (
          <OrganisationSwitcher
            organisations={organisations}
            environment={environment}
          />
        ) : null}
        <Crumbs />
      </SwitcherWrapper>
      <div style={{display: 'flex', alignItems: 'center'}}>
        {environment && (
          <ApolloProvider client={getClient(environment.organisationId)}>
            <AtomicConnectLauncher
              environment={environment}
              authState={authState}
            />
          </ApolloProvider>
        )}
        {showStreamIcon ? (
          <AtomicBell
            onClick={() => toggleStream(!streamVisible)}
            cardCount={cardCount}
          />
        ) : null}
      </div>
    </>
  )
}

const EnvironmentSwitcherTrigger = styled(CrumbSwitcher)`
  padding-left: 8px;
`

type AtomicConnectLauncherProps = {
  authState: AuthStateAuth
  environment: EnvironmentNode
}

const AtomicConnectLauncher = ({environment}: AtomicConnectLauncherProps) => {
  const analytics = useAnalytics()
  const env = useEnvironment()
  const {linkedTestUserId} = env.organisation?.currentBusinessUser ?? {}

  return (
    <Dropdown
      style={{
        width: '400px'
      }}
      disabled={!environment}
      trigger={(handleClick, ref, isOpen) => (
        <a
          onClick={() => {
            handleClick()
            analytics.track({
              event: 'Opened atomic connect menu',
              properties: {
                category: 'general',
                org_id: environment.organisationId,
                environment_id: environment.id
              }
            })
          }}
          ref={ref as React.Ref<HTMLAnchorElement>}
        >
          <EnvironmentSwitcherTrigger className={isOpen ? 'active' : ''}>
            Atomic Connect
          </EnvironmentSwitcherTrigger>
        </a>
      )}
    >
      {() => (
        <DropdownBody style={{padding: '0px'}}>
          <MagicLinkComposer
            environmentId={environment.id}
            organisationId={environment.organisationId}
            context="new"
            isModal={false}
            environment={environment}
            defaultUserId={linkedTestUserId}
          />
        </DropdownBody>
      )}
    </Dropdown>
  )
}

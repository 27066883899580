import React from 'react'
import styled, {css} from 'styled-components'

const Group = styled.div<{inline?: boolean}>`
  ${p => p.inline && `display: inline-flex;`}
  > label,
  > button,
  > div {
    border-radius: 0;
    ${p =>
      p.inline
        ? css`
            &:first-child:not(:only-child) {
              border-radius: ${p.theme.borderRadius} 0 0 ${p.theme.borderRadius};
            }
            &:last-child:not(:only-child) {
              border-radius: 0 ${p.theme.borderRadius} ${p.theme.borderRadius} 0;
            }
            &:not(:last-child) {
              margin-right: 1px;
            }
            &:only-child {
              border-radius: ${p.theme.borderRadius};
            }
          `
        : css`
            &:first-child {
              border-radius: ${p.theme.borderRadius} ${p.theme.borderRadius} 0 0;
            }

            &:last-child {
              border-radius: 0 0 ${p.theme.borderRadius} ${p.theme.borderRadius};
            }
            &:not(:last-child) {
              margin-bottom: 1px;
            }
            &:only-child {
              border-radius: ${p.theme.borderRadius};
            }
          `}
  }
`
interface InputGroupProps {
  children: React.ReactNode
  style?: React.CSSProperties
  inline?: boolean
}

export const InputGroup = ({children, style, inline}: InputGroupProps) => {
  return (
    <Group inline={inline} style={style}>
      {children}
    </Group>
  )
}
